import React from 'react';
import { MigrationCard } from './components/MigrationCard/MigrationCard';
import { MigrationContextProvider } from './context/MigrationContext';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Grid } from '@material-ui/core';
import { PracticeShaperOverview } from './components/PracticeShaperOverview/PracticeShaperOverview';
import { PracticeShaperGraphProvider } from './context/PracticeShaperGraphContext';

export const PracticeShaperSettingsPage = () => {
  const configApi = useApi(configApiRef);
  const migrationEnabled = configApi.getOptionalBoolean(
    'practiceShaper.migration.migrationSectionEnabled',
  );

  return (
    <>
      <Grid container spacing={3}>
        {migrationEnabled && (
          <Grid item xs={12}>
            <MigrationContextProvider>
              <MigrationCard />
            </MigrationContextProvider>
          </Grid>
        )}
        <Grid item xs={12}>
          <PracticeShaperGraphProvider>
            <PracticeShaperOverview />
          </PracticeShaperGraphProvider>
        </Grid>
      </Grid>
    </>
  );
};
