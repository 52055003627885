import React from 'react';
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Theme,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'baseline',
    gap: 8,
  },
  bold: {
    fontWeight: 500,
  },
  inputField: {
    paddingBottom: '3px',
  },
  textField: {
    minWidth: '140px',
  },
  select: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));

interface Item {
  key: string;
  value: string;
}

interface Props {
  data: Item[];
  onChange: (value: string) => void;
  title?: string;
  value?: string;
  formatValue?: (value: string) => string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const WbCardSelect: React.FC<Props> = ({
  data,
  onChange,
  title,
  value,
  formatValue,
  style,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {title && (
        <Typography variant="body2" className={classes.bold} color="secondary">
          {title}
        </Typography>
      )}

      <TextField
        value={value}
        size="small"
        className={classes.textField}
        inputProps={{ className: classes.inputField }}
        SelectProps={{
          className: clsx(classes.bold, classes.select),
        }}
        select
        onChange={e => {
          onChange(e.target.value);
        }}
        style={style}
        disabled={disabled}
      >
        {data.map(item => (
          <MenuItem key={item.key} value={item.value}>
            <Typography variant="body2">
              {formatValue ? formatValue(item.value) : item.value}
            </Typography>
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};
