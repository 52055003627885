import {
  Accordion as MuiAccordion,
  AccordionSummary,
  Box,
  Typography,
  AccordionDetails,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles(theme => ({
  accordion: {
    background: theme.palette.white,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  accordionTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  accordionIconOpen: {
    transition: 'all 0.5s',
    transform: 'rotate(180deg)',
  },
  accordionIconClose: {
    transition: 'all 0.5s',
    transform: 'rotate(0deg)',
  },
}));

interface Props {
  label: string;
  bold: string;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeploymentPreviewAccordion: React.FC<Props> = ({
  children,
  label,
  bold,
  expanded,
  setExpanded,
}) => {
  const classes = useStyles();

  return (
    <Accordion
      expanded={expanded}
      className={classes.accordion}
      onChange={() => {
        setExpanded(e => !e);
      }}
    >
      <AccordionSummary>
        <Box className={classes.accordionTitle}>
          <ExpandMoreIcon
            className={
              expanded ? classes.accordionIconOpen : classes.accordionIconClose
            }
          />
          <Typography variant="body2">
            {label}{' '}
            <Typography
              variant="body2"
              component="span"
              style={{ fontWeight: 700 }}
            >
              {bold}
            </Typography>
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
