import React from 'react';
import { MarkdownContent } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export type WbMarkdownHelperTextProps = {
  id?: string;
  helperText: string;
  className?: string;
};

const useStyles = makeStyles(theme => ({
  markdown: {
    '& p': {
      margin: 0,
      marginTop: theme.spacing(0.5),
      ...theme.typography.body2,
    },
    '& pre': {
      margin: 0,
      fontSize: theme.typography.body2.fontSize,
    },
    '& ul': {
      padding: theme.spacing(0, 3),
      marginTop: 0,
    },
    '& ol': {
      padding: theme.spacing(0, 3),
      marginTop: 0,
    },
    '& li': {
      ...theme.typography.body2,
    },
    '& th': {
      ...theme.typography.body2,
      color: theme.palette.text.secondary, // needed for tooltip override
    },
    '& td': {
      ...theme.typography.body2,
      color: theme.palette.text.secondary, // needed for tooltip override
    },
    '& h1,': {
      ...theme.typography.h4,
      fontWeight: theme.typography.fontWeightBold,
    },
    '& h2': {
      ...theme.typography.h5,
      fontWeight: theme.typography.fontWeightBold,
    },
    '& h3': {
      ...theme.typography.h6,
      fontWeight: theme.typography.fontWeightBold,
    },
    '& h4, h5, h6': {
      ...theme.typography.h6,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

export const WbMarkdownHelperText: React.FC<WbMarkdownHelperTextProps> = ({
  helperText,
  className,
}: WbMarkdownHelperTextProps) => {
  const classes = useStyles();

  return (
    <MarkdownContent
      content={helperText}
      linkTarget="_blank"
      className={clsx(classes.markdown, className)}
    />
  );
};
