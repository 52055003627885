import React, { useMemo } from 'react';
import { WbEditor } from '../../Editors/WbEditor';
import { Log } from '../../../types';
import { stringifyLogs } from '../WbLogsCard';

interface Props {
  text?: string;
  logs?: Log[];
  language?: string;
}

export const WbLogsViewer: React.FC<Props> = ({ text, logs, language }) => {
  const logText = useMemo(() => {
    const removeANSIColorRegExp =
      // eslint-disable-next-line no-control-regex
      /[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g;
    let res = text ?? 'No data available';
    if (logs && logs.length > 0 && !text) res = stringifyLogs(logs);
    return res.replace(removeANSIColorRegExp, '');
  }, [text, logs]);

  return (
    <WbEditor
      theme="white"
      value={logText}
      options={{ readOnly: true, minimap: { enabled: false } }}
      wrapperProps={{
        padding: '0',
        margin: '0',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      language={language ?? 'plaintext'}
    />
  );
};
