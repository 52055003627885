import {
  useDrawersContext,
  ThresholdResult,
  WbTag,
  GenericField,
  CodeField,
} from '@agilelab/plugin-wb-platform';
import {
  Box,
  Typography,
  TableRow,
  TableCell,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { GovernanceEntitiesQueryType } from '../../../graphql/governanceEntities';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import { Task } from '../../EditorPage/types';
import {
  GovernanceTestOverviewMetric,
  roundWithoutApprossimation,
  toGovernanceMetric,
} from '@agilelab/plugin-wb-governance';
import { format, parseISO } from 'date-fns';
import { MetricGql } from '@agilelab/plugin-wb-governance-common';
import { useStyles } from './styles';

interface Props {
  task: Task;
  metric: GovernanceEntitiesQueryType['cgp_governance_entity'][0];
}

const MetricRowDrawerContent: React.FC<Props> = ({ task, metric }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box>
      <Box className={classes.detailContainer}>
        <ThresholdResult
          value={task.metricSpecificResult?.value || 0}
          result={task.metricSpecificResult?.threshold}
          hasError={task.errors && task.errors.length > 0}
          variant="reduced"
        />
        <Typography
          variant="h6"
          style={{ fontWeight: 700, marginLeft: theme.spacing(2) }}
        >
          {metric.name}
        </Typography>
      </Box>
      <GovernanceTestOverviewMetric
        kind="controlPanel"
        metric={toGovernanceMetric(metric as unknown as MetricGql)}
        test={{
          errors: task.errors,
          content: task.validatedDescriptor || '',
          name: task.displayName,
        }}
        infoContent={
          <Box style={{ display: 'flex', gap: 50 }}>
            <Tooltip title={task.metricSpecificResult?.value || ''}>
              <Box>
                <GenericField
                  label="Value"
                  value={roundWithoutApprossimation(
                    2,
                    task.metricSpecificResult?.value,
                  )}
                />
              </Box>
            </Tooltip>

            <>
              <GenericField
                label="Latest Update"
                value={format(parseISO(task.startTime), 'yyyy/MM/dd HH:mm:ss')}
              />

              <GenericField
                label="Label"
                value={
                  task.metricSpecificResult?.threshold?.label || 'No Label'
                }
              />
              {task.metricSpecificResult?.details && (
                <GenericField
                  label="Test Detail"
                  value={
                    <CodeField
                      title="Test Detail"
                      value={JSON.stringify(
                        task.metricSpecificResult?.details,
                        null,
                        4,
                      )}
                    />
                  }
                />
              )}
            </>
          </Box>
        }
      />
    </Box>
  );
};

export const MetricRow: React.FC<Props> = ({ task, metric }) => {
  const classes = useStyles();
  const { toggleBoolean } = useDrawersContext();
  const { setValidationTestResultDetailContent } =
    useReleaseDetailPageContext();
  const onRowClick = () => {
    setValidationTestResultDetailContent(
      <MetricRowDrawerContent task={task} metric={metric} />,
    );
    toggleBoolean(1);
  };

  return (
    <TableRow onClick={onRowClick} className={classes.row}>
      <TableCell width="20%" align="left">
        <ThresholdResult
          value={task.metricSpecificResult?.value || 0}
          result={task.metricSpecificResult?.threshold}
          hasError={task.errors && task.errors.length > 0}
        />
      </TableCell>
      <TableCell width="30%">
        <Typography variant="body2" style={{ fontWeight: 700 }}>
          {metric.name} - {metric.version}
        </Typography>
      </TableCell>
      <TableCell width="30%">
        <Typography variant="body2">{metric.description}</Typography>
      </TableCell>
      <TableCell align="right" width="20%">
        <WbTag
          tag={{
            tagFQN: 'Metric',
          }}
        />
      </TableCell>
    </TableRow>
  );
};
