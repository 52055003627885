import React from 'react';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { ReleaseCardAction } from './ReleaseCardActions';
import { ReleaseCardContent } from './ReleaseCardContent';

export const ReleasePage: React.FC = () => {
  return (
    <WbCard
      title="Release List"
      actions={<ReleaseCardAction />}
      cardStyle={{ height: '74vh' }}
    >
      <WbCardContent
        style={{
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ReleaseCardContent />
      </WbCardContent>
    </WbCard>
  );
};
