import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { WbCardActionButton } from '@agilelab/plugin-wb-platform';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import useAsync from 'react-use/lib/useAsync';
import {
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { extensionApiRef } from '../../CatalogExtensionApiRef';
import { CatalogClientExtension } from '@agilelab/plugin-wb-catalog-extension-common';
import _ from 'lodash';
import { Paper, capitalize } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import { ClickableMenuItem } from './ClickableMenuItem';
import { SubMenu } from './SubMenu';
import { WitboostTemplate } from '@agilelab/plugin-wb-builder-common';

const extractType = (entity: WitboostTemplate) => {
  return entity.metadata.classDetails
    ? entity.metadata.classDetails.pluralizedDisplayName ??
        entity.metadata.classDetails.displayName ??
        'Others'
    : entity.spec.type;
};

export function ComponentTypeSelectButton(props: {
  dataproduct: string;
  domain: string;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const navigate = useNavigate();
  const catalogApi = useApi(extensionApiRef) as CatalogClientExtension;
  const configApi = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);
  const knownDataproduct = props?.dataproduct;
  const knownDomain = props?.domain;
  const includeOntologyInAddButtonOnDpPage =
    configApi.getOptionalBoolean('ontology.enabled');
  const areInstanceProcessorsEnabled = configApi.getBoolean(
    'practiceShaper.migration.instanceProcessorsEnabled',
  );
  const {
    value: templateValue,
    loading: templateLoading,
    error: templateError,
  } = useAsync(async () => {
    const { token } = await identityApi.getCredentials();

    const templates = await catalogApi.getEntities(
      { filter: { kind: ['template'] } },
      { token },
    );

    const templatesGroupedByType = _.groupBy(
      templates.items as WitboostTemplate[],
      extractType,
    );

    if (!includeOntologyInAddButtonOnDpPage) {
      // TODO: find a better way to filter components instead of only forbidding "dataproduct" appearance
      return Object.fromEntries(
        Object.entries(templatesGroupedByType)
          .map(([key, value]) => [
            key,
            value.filter(
              template =>
                template.spec.type !== 'dataproduct' &&
                template.metadata.classDetails?.type?.toLowerCase() !==
                  'systemtype',
            ),
          ])
          .filter(([key, value]) => key !== 'dataproduct' && value.length),
      );
    }
    const ontologies = await catalogApi.getEntities(
      { filter: { kind: ['hierarchyclass'] } },
      { token },
    );

    const ontologyTypes = ontologies?.items
      .filter(o => (o?.spec?.type as string).toLowerCase() === 'component')
      .map(m => m?.metadata?.name);

    return Object.fromEntries(
      Object.entries(templatesGroupedByType).filter(([key]) =>
        ontologyTypes.includes(key),
      ),
    );
  }, [catalogApi]);

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <>
      <WbCardActionButton
        onClick={onOpen}
        label="Add"
        icon={<AddCircleIcon />}
      />
      <Popover
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ zIndex: 0 }} // This field is important in order for the submenu to be "sticky"
      >
        {!templateLoading && !templateError ? (
          <MenuList style={{ background: 'white', padding: 0 }}>
            {Object.keys(templateValue!).map((templateType: string) => (
              <SubMenu key={templateType} label={`${capitalize(templateType)}`}>
                {templateValue![templateType]
                  .slice(0, 5)
                  .map((entity: Entity) => (
                    <Paper
                      key={entity.metadata.name}
                      style={{ borderRadius: 0, background: 'white' }}
                    >
                      <ClickableMenuItem
                        onClick={() =>
                          navigate(
                            `/wb-scaffolder/templates/default/${
                              entity.metadata.name
                            }${
                              knownDomain && knownDataproduct
                                ? `?formData=%7B"domain"%3A"${knownDomain}"%2C"dataproduct"%3A"system:${knownDataproduct}"%7D`
                                : ''
                            }`,
                            {
                              state: props,
                            },
                          )
                        }
                        label={entity.metadata.title}
                      />
                    </Paper>
                  ))}
                <Paper style={{ borderRadius: 0, background: 'white' }}>
                  <ClickableMenuItem
                    onClick={() =>
                      navigate(
                        areInstanceProcessorsEnabled
                          ? '/create'
                          : `/create?filters%5Bkind%5D=template&filters%5Btype%5D=${templateType.toLocaleLowerCase()}`,
                        {
                          state: props,
                        },
                      )
                    }
                    label="Show all..."
                  />
                </Paper>
              </SubMenu>
            ))}
          </MenuList>
        ) : (
          <></>
        )}
      </Popover>
    </>
  );
}
