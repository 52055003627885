import { CatalogTableSort } from '../types';
import { useMemo } from 'react';
import { getObjectPropFromString } from '../CatalogTable';
import {
  Entity,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import { EntityErrorResult } from '@agilelab/plugin-wb-catalog-extension-common';
import { GetEntitiesResponse } from '@backstage/catalog-client';
import { buildReservedFieldError } from '../../ReservedFieldsError/ReservedFieldsError';
import {
  getEntityRelations,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import { WitboostSystem } from '@agilelab/plugin-wb-builder-common';

interface Props {
  sortBy: CatalogTableSort;
  entities: Entity[];
  errors: EntityErrorResult[] | undefined;
  dpResponse: GetEntitiesResponse | undefined;
}

export function useRows({ entities, sortBy, errors, dpResponse }: Props) {
  const rows = entities.map(entity => {
    const entityError: string | undefined = (errors ?? []).find(
      e => e.entity_id === entity.metadata.uid,
    )?.errorMessage;

    const reservedFieldError = buildReservedFieldError(entity);

    const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
      kind: 'system',
    });
    const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

    return {
      entity,
      error: entityError || reservedFieldError,
      resolved: {
        name: humanizeEntityRef(entity, {
          defaultKind: 'Component',
        }),
        ownedByRelationsTitle: ownedByRelations
          .map(r => humanizeEntityRef(r, { defaultKind: 'group' }))
          .join(', '),
        ownedByRelations,
        partOfSystemRelationTitle: partOfSystemRelations
          .map(
            r =>
              (
                dpResponse?.items.find(
                  e => e.metadata.name.toLowerCase() === r.name.toLowerCase(),
                ) as WitboostSystem
              )?.spec.mesh.name || r.name,
          )
          .join(', '),
        partOfSystemRelations,
      },
    };
  });

  const filteredRows = useMemo(() => {
    const sorted = rows.sort((a, b) => {
      if (sortBy && sortBy.direction && sortBy.field) {
        const first = getObjectPropFromString(a, sortBy.field);
        const second = getObjectPropFromString(b, sortBy.field);

        if (sortBy.direction === 'asc') {
          if (first > second) return 1;
          return -1;
        }
        if (sortBy.direction === 'desc') {
          if (first > second) return -1;
          return 1;
        }
      }

      return 0;
    });

    return sorted;
  }, [rows, sortBy]);

  return filteredRows;
}
