import React, { useCallback, useEffect } from 'react';
import { useEditorPageContext } from '../context/useEditorPageContext';
import {
  customAlertApiRef,
  WbCardActionButton,
} from '@agilelab/plugin-wb-platform';
import { builderDpPoliciesTest } from '@agilelab/plugin-wb-rbac-common';
import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  useApi,
  identityApiRef,
  configApiRef,
} from '@backstage/core-plugin-api';
import { usePermission } from '@backstage/plugin-permission-react';
import { ButtonProps, Tooltip } from '@material-ui/core';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { panelCatalogApiRef } from '../../../api';
import {
  isRunningStatus,
  WitboostVersionedEntity,
} from '@agilelab/plugin-wb-builder-common';
import { generateTestTooltip } from '../../common/utils';

type Props = ButtonProps & { label?: string };

export const LaunchTestButton: React.FC<Props> = props => {
  const {
    descriptorState,
    fetchLastTest,
    parent,
    entity,
    setSelectedTestTab,
    setShowCurrentTest,
    fetchTests,
    setIsValidatingDescriptor,
    lastTestState,
    setTestLoading,
    testLoading,
    cannotDoActions,
  } = useEditorPageContext();

  const panelCatalog = useApi(panelCatalogApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);
  const configApi = useApi(configApiRef);
  const useAsyncValidation = configApi.getOptionalBoolean(
    'catalog.enableAsyncValidation',
  );

  const dpProduct = parent ?? entity;

  const launchPoliciesTests = useCallback(async () => {
    if (!descriptorState.value || descriptorState.loading) {
      alertApi.post({
        message: 'Cannot launch policies tests without a descriptor.',
        severity: 'error',
      });
      return;
    }

    setTestLoading(true);
    setSelectedTestTab('current');
    setShowCurrentTest(true);

    if (dpProduct) {
      try {
        if (useAsyncValidation) {
          await panelCatalog.validateDescriptorAsync(
            descriptorState.value,
            dpProduct?.metadata.name,
            await identityApi.getCredentials(),
          );
          fetchLastTest();
          return;
        }
        setIsValidatingDescriptor(true);
        await panelCatalog.validateDescriptor(
          descriptorState.value,
          dpProduct?.metadata.name,
          await identityApi.getCredentials(),
        );
        fetchTests();
        fetchLastTest();

        setTestLoading(false);
        setIsValidatingDescriptor(false);
      } catch (error) {
        alertApi.post({
          error,
          severity: 'error',
        });
        setTestLoading(false);
        setIsValidatingDescriptor(false);
      }
    }
  }, [
    descriptorState.value,
    descriptorState.loading,
    setTestLoading,
    setSelectedTestTab,
    setShowCurrentTest,
    dpProduct,
    alertApi,
    useAsyncValidation,
    setIsValidatingDescriptor,
    panelCatalog,
    identityApi,
    fetchTests,
    fetchLastTest,
  ]);

  /**
   * this is only used when useAsyncValidation is true, it is used
   * in order to re-enable the test button when the async validation test finishes
   */
  useEffect(() => {
    if (useAsyncValidation && !lastTestState.loading && lastTestState.value) {
      if (
        isRunningStatus(lastTestState.value.status) ||
        lastTestState.value?.tasks.some(task => isRunningStatus(task.status))
      )
        return;
      setTestLoading(false);
    }
  }, [lastTestState, useAsyncValidation, setTestLoading]);

  const { allowed: canLaunchTest } = usePermission({
    permission: builderDpPoliciesTest,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  return (
    <Tooltip
      title={generateTestTooltip(
        canLaunchTest,
        cannotDoActions,
        descriptorState.loading,
        entity as WitboostVersionedEntity,
      )}
    >
      <span>
        <WbCardActionButton
          icon={<PlayCircleFilledIcon />}
          label={props.label || 'Test'}
          onClick={launchPoliciesTests}
          loading={testLoading}
          disabled={
            testLoading ||
            !canLaunchTest ||
            cannotDoActions ||
            descriptorState.loading
          }
          {...props}
        />
      </span>
    </Tooltip>
  );
};
