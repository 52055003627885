import {
  customAlertApiRef,
  MonacoEditor,
  useSelectorsContext,
  WbMonacoEditor,
  Monaco,
} from '@agilelab/plugin-wb-platform';
import { Entity } from '@backstage/catalog-model';
import {
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useAsyncFn, { AsyncState } from 'react-use/lib/useAsyncFn';
import {
  PaginatedProvisioningPlansResponse,
  ReleaseEntity,
  TaskAction,
  generateURNByKind,
  WitboostVersionedEntity,
} from '@agilelab/plugin-wb-builder-common';
import { panelCatalogApiRef } from '../../../api';
import yaml from 'yaml';
import { EntityWithIcon, Task, Test } from '../types';
import { mapToTemplateIcons, mapToTests } from '../utils';

const getAssistantResponse = (
  url: string,
  assistant: string,
  descriptor: string,
) =>
  fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      assistant,
      descriptor,
    }),
  });

export type EditorPageContextProps = {
  entity: Entity;
  parent?: Entity;
  environment: string;
  releases: ReleaseEntity[] | null;
  addRelease: (release: ReleaseEntity) => void;
  fetchReleaseState: {
    error?: Error;
    loading: boolean;
    value?: ReleaseEntity[];
  };
  currRelease: ReleaseEntity | undefined;
  setCurrRelease: React.Dispatch<
    React.SetStateAction<ReleaseEntity | undefined>
  >;

  testCollapsed: boolean;
  setTestCollapsed: React.Dispatch<React.SetStateAction<boolean>>;

  selectedTestTab: 'current' | 'history';
  setSelectedTestTab: React.Dispatch<
    React.SetStateAction<'current' | 'history'>
  >;

  entitiesState: AsyncState<EntityWithIcon[]>;

  fetchDescriptor: () => Promise<string | null>;
  descriptorState: AsyncState<string | null>;

  fetchTests: () => Promise<PaginatedProvisioningPlansResponse | null>;
  testsState: AsyncState<PaginatedProvisioningPlansResponse | null>;
  testPagination: { limit: number; offset: number };
  tests: Test[];
  onTestPageChange: (newPage: number) => void;
  onTestChangeRowsPerPage: (limit: number) => void;

  selectedTask: Task | null;
  setSelectedTask: React.Dispatch<React.SetStateAction<Task | null>>;

  showCurrentTest: boolean;
  setShowCurrentTest: React.Dispatch<React.SetStateAction<boolean>>;

  lastTestState: AsyncState<Test | null>;
  fetchLastTest: () => Promise<Test | null>;

  selectedTestRow: string | null;
  setSelectedTestRow: React.Dispatch<React.SetStateAction<string | null>>;

  isValidatingDescriptor: boolean;
  setIsValidatingDescriptor: React.Dispatch<React.SetStateAction<boolean>>;

  setVersion?: React.Dispatch<React.SetStateAction<string>>;
  version?: string;

  monacoEditorRef: MutableRefObject<WbMonacoEditor | null>;
  editorRef: { current: WbMonacoEditor } | undefined;
  setMonacoEditorRef: (editor: MonacoEditor, monaco: Monaco) => void;

  focusedEntityUrn: string | null;
  setFocusedEntityUrn: React.Dispatch<React.SetStateAction<string | null>>;
  entitiesAndBranches: { [key: string]: string };
  changeBranches: (entityRef: string, branch: string) => void;

  testLoading: boolean;
  setTestLoading: React.Dispatch<React.SetStateAction<boolean>>;

  cannotDoActions: boolean;

  isAssistantDrawerOpen: boolean;
  setIsAssistantDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchAssistant: () => Promise<string | null>;
  assistantState: AsyncState<string | null>;
};

export const EditorPageContext = React.createContext<EditorPageContextProps>(
  {} as EditorPageContextProps,
);

interface Props {
  entity: Entity;
  relatedEntities?: Entity[] | undefined;
  setVersion?: React.Dispatch<React.SetStateAction<string>>;
  version?: string;
  parent?: Entity;
}

export const EditorPageContextProvider: React.FC<Props> = ({
  children,
  entity,
  relatedEntities = [],
  setVersion,
  parent,
  version,
}) => {
  const configApi = useApi(configApiRef);
  const url = configApi.getOptionalString('assistant.url');

  const { environment } = useSelectorsContext();
  const [isAssistantDrawerOpen, setIsAssistantDrawerOpen] =
    useState<boolean>(false);
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const panelCatalogApi = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const [releases, setReleases] = useState<ReleaseEntity[] | null>(null);
  const [isValidatingDescriptor, setIsValidatingDescriptor] =
    useState<boolean>(false);
  const [selectedTestRow, setSelectedTestRow] = useState<string | null>(null);
  const [showCurrentTest, setShowCurrentTest] = useState<boolean>(false);
  const [focusedEntityUrn, setFocusedEntityUrn] = useState<string | null>(null);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [testCollapsed, setTestCollapsed] = useState(false);
  const [entitiesAndBranches, setEntitiesAndBranches] = useState<{
    [key: string]: string;
  }>({});
  const [testLoading, setTestLoading] = useState<boolean>(false);
  const [selectedTestTab, setSelectedTestTab] = useState<'current' | 'history'>(
    'current',
  );
  const [editorRef, setEditorRef] = useState<
    { current: WbMonacoEditor } | undefined
  >();
  const [testPagination, setTestPagination] = useState({
    limit: 5,
    offset: 0,
  });
  const dpProduct = (parent ?? entity) as WitboostVersionedEntity;
  const monacoEditorRef = useRef<WbMonacoEditor | null>(null);

  const setMonacoEditorRef = useCallback(
    (editorObj: MonacoEditor, monaco: Monaco) => {
      const editor = new WbMonacoEditor(editorObj, monaco);
      monacoEditorRef.current = editor;
      setEditorRef({ current: editor });
    },
    [],
  );

  const [currRelease, setCurrRelease] = useState<ReleaseEntity | undefined>();

  const [entitiesState, fetchEntities] = useAsyncFn(async () => {
    try {
      const response = await catalogApi.getEntities({
        filter: { kind: ['Template'] },
      });
      return mapToTemplateIcons(response.items, [
        dpProduct,
        ...relatedEntities,
      ]);
    } catch (error) {
      alertApi.post({ error, severity: 'error' });
      return [];
    }
  }, [relatedEntities, dpProduct]);

  useEffect(() => {
    if (releases && !currRelease) {
      setCurrRelease(releases[0]);
    }
  }, [releases, currRelease, setCurrRelease]);

  const addRelease = useCallback(
    (release: ReleaseEntity) => {
      if (releases) {
        const index = releases.findIndex(
          r => r.metadata.name === release.metadata.name,
        );
        if (index > -1) {
          releases[index] = release;
          setReleases([...releases]);
        } else {
          setReleases([release, ...releases]);
        }
      }
    },
    [releases],
  );

  const [fetchReleaseState, fetchReleases] = useAsyncFn(async () => {
    if (!entity) {
      return [];
    }

    return panelCatalogApi.fetchReleases(entity.metadata.name, {
      token: (await identityApi.getCredentials()).token,
    });
  }, [entity]);

  useEffect(() => {
    if (environment && entity) {
      fetchReleases();
    }
  }, [entity, environment, fetchReleases]);

  useEffect(() => {
    if (fetchReleaseState.value) {
      setReleases(fetchReleaseState.value);
    }
  }, [fetchReleaseState]);

  const [descriptorState, fetchDescriptor] = useAsyncFn(async () => {
    try {
      if (environment?.name && dpProduct) {
        const entities = Object.entries(entitiesAndBranches).map(
          ([key, value]) => ({ entityRef: key, branch: value }),
        );

        // this is head (what's on git)
        const descriptor = await panelCatalogApi.fetchPreviewDescriptor(
          dpProduct?.metadata.name,
          environment.name,
          await identityApi.getCredentials(),
          entities,
        );
        return descriptor;
      }
    } catch (error) {
      alertApi.post({ error, severity: 'error' });
      return null;
    }
    return null;
  }, [dpProduct, environment?.name, entitiesAndBranches]);

  const [assistantState, fetchAssistant] = useAsyncFn(async () => {
    const assistant = localStorage.getItem('assistant');
    const descriptor = descriptorState.value;
    if (assistant && descriptor && url) {
      return await getAssistantResponse(url, assistant, descriptor).then(r =>
        r.text(),
      );
    }
    return null;
  }, [descriptorState, configApi]);

  const [testsState, fetchTests] = useAsyncFn(async () => {
    try {
      const response =
        await panelCatalogApi.getProvisioningPlansByDpIdAndEnvironment(
          generateURNByKind(dpProduct.metadata.name, dpProduct.kind),
          environment.name,
          true,
          true,
          {
            operations: [TaskAction.VALIDATION],
            version: (dpProduct.spec?.mesh as any)?.version,
            limit: testPagination.limit,
            offset: testPagination.offset,
          },
          await identityApi.getCredentials(),
        );

      return response;
    } catch (error) {
      alertApi.post({ error, severity: 'error' });
      return null;
    }
  }, [dpProduct, environment, testPagination]);

  const [lastTestState, fetchLastTest] = useAsyncFn(async () => {
    try {
      const response =
        await panelCatalogApi.getProvisioningPlansByDpIdAndEnvironment(
          generateURNByKind(dpProduct.metadata.name, dpProduct.kind),
          environment.name,
          true,
          true,
          {
            operations: [TaskAction.VALIDATION],
            version: (dpProduct.spec?.mesh as any)?.version,
            limit: 1,
            offset: 0,
          },
          await identityApi.getCredentials(),
        );

      return mapToTests(response.provisioningPlans)[0];
    } catch (error) {
      alertApi.post({ error, severity: 'error' });
      return null;
    }
  }, [dpProduct, environment, testPagination]);

  const cannotDoActions = useMemo(() => {
    return (
      yaml.parse(descriptorState?.value ?? '')?.id !==
        generateURNByKind(dpProduct?.metadata?.name, 'dp') ||
      yaml.parse(descriptorState?.value ?? '')?.version?.replace('.0', '') !==
        (version?.replace('.0', '') ??
          (dpProduct as WitboostVersionedEntity).spec.mesh.version)
    );
  }, [version, descriptorState.value, dpProduct]);

  useEffect(() => {
    fetchDescriptor();
  }, [fetchDescriptor]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  useEffect(() => {
    setShowCurrentTest(false);
  }, [environment]);

  return (
    <EditorPageContext.Provider
      value={{
        entity,
        parent,
        environment: environment.name,
        addRelease,
        releases,
        fetchReleaseState,
        currRelease,
        setCurrRelease,

        testCollapsed,
        setTestCollapsed,

        selectedTestTab,
        setSelectedTestTab,

        entitiesState,

        descriptorState,
        fetchDescriptor,

        fetchTests,
        testsState,
        tests: mapToTests(testsState?.value?.provisioningPlans || []),
        testPagination,
        onTestPageChange: (newPage: number) => {
          setTestPagination(v => ({
            limit: v.limit,
            offset: newPage * v.limit,
          }));
        },
        onTestChangeRowsPerPage: (limit: number) => {
          setTestPagination({
            limit: limit,
            offset: 0,
          });
        },

        selectedTask,
        setSelectedTask,

        showCurrentTest,
        setShowCurrentTest,

        lastTestState,
        fetchLastTest,

        selectedTestRow,
        setSelectedTestRow,

        isValidatingDescriptor,
        setIsValidatingDescriptor,

        setVersion,
        version,

        monacoEditorRef,
        editorRef,
        setMonacoEditorRef,

        focusedEntityUrn,
        setFocusedEntityUrn,
        entitiesAndBranches,
        changeBranches: (entityRef: string, branch: string) => {
          setEntitiesAndBranches(b => ({ ...b, [entityRef]: branch }));
        },

        testLoading,
        setTestLoading,

        cannotDoActions,

        isAssistantDrawerOpen,
        setIsAssistantDrawerOpen,

        assistantState,
        fetchAssistant,
      }}
    >
      {children}
    </EditorPageContext.Provider>
  );
};
