import { Link } from '@backstage/core-components';
import React, { Fragment } from 'react';
import { WbShowMoreElement } from '../WbShowMoreElement';

type Props = {
  value: string;
  href?: string;
  maxLines?: number;
};

export const ExpandableItem = ({ value, href, maxLines }: Props) => {
  const externalLink = href?.startsWith('http')
    ? href
    : 'https://'.concat(href || '');

  const renderedValue = (
    <WbShowMoreElement maxLines={maxLines}>{value}</WbShowMoreElement>
  );

  return (
    <Fragment>
      {href ? (
        <Link target="_blank" rel="noreferrer" to={externalLink}>
          {renderedValue}
        </Link>
      ) : (
        renderedValue
      )}
    </Fragment>
  );
};
