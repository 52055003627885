import { AppTheme } from '@backstage/core-plugin-api';
import {
  CssBaseline,
  StyleRules,
  ThemeProvider,
  Theme,
} from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import React, { useMemo } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { basicTheme, createCustomThemeOverrides } from './LightAgileTheme';
import { Typography } from '@material-ui/core/styles/createTypography';
import { merge } from 'lodash';

const getPalette = async (): Promise<PaletteOptions | null> => {
  try {
    const res = await fetch('/custom_style/customPalette.json');
    const palette = await res.json();
    return palette;
  } catch (_error) {
    return null;
  }
};

const getOverrides = async (): Promise<Partial<StyleRules<any, {}>> | null> => {
  try {
    const res = await fetch('/custom_style/customOverrides.json');
    const palette = await res.json();
    return palette;
  } catch (_error) {
    return null;
  }
};

const overrideFont = (theme: Theme, fontFamily?: string): Typography => {
  if (fontFamily) {
    return {
      ...theme.typography,
      fontFamily,
      h1: { ...theme.typography.h1, fontFamily },
      h2: { ...theme.typography.h2, fontFamily },
      h3: { ...theme.typography.h3, fontFamily },
      h4: { ...theme.typography.h4, fontFamily },
      h5: { ...theme.typography.h5, fontFamily },
      h6: { ...theme.typography.h6, fontFamily },
      subtitle1: { ...theme.typography.subtitle1, fontFamily },
      subtitle2: { ...theme.typography.subtitle2, fontFamily },
      body1: { ...theme.typography.body1, fontFamily },
      body2: { ...theme.typography.body2, fontFamily },
      button: { ...theme.typography.button, fontFamily },
      caption: { ...theme.typography.caption, fontFamily },
      overline: { ...theme.typography.overline, fontFamily },
    };
  }

  return theme.typography;
};

export function useCustomTheme() {
  const {
    value: palette,
    loading: loadingPalette,
    error: errorPalette,
  } = useAsync(async () => {
    return await getPalette();
  });

  const {
    value: overrides,
    loading: loadingOverrides,
    error: errorOverrides,
  } = useAsync(async () => {
    return await getOverrides();
  });

  const customBasicTheme: Theme | null = useMemo(() => {
    if (palette || overrides) {
      const theme = {
        ...basicTheme,
        palette: merge(basicTheme.palette, palette),
      } as Theme;

      return {
        ...theme,
        overrides: {
          // These are your custom overrides, either to `material-ui` or Backstage components.
          ...createCustomThemeOverrides(theme),
          ...overrides,
        },
        typography: {
          ...overrideFont(theme, (overrides?.typography as any)?.fontFamily),
        },
      };
    }
    return null;
  }, [palette, overrides]);

  return {
    error: errorPalette || errorOverrides,
    loading: loadingPalette || loadingOverrides,
    theme: customBasicTheme
      ? ({
          id: 'custom-theme-light',
          title: 'Custom Light',
          variant: 'light',
          Provider: ({ children }) => {
            return (
              <ThemeProvider theme={customBasicTheme}>
                <CssBaseline>{children}</CssBaseline>
              </ThemeProvider>
            );
          },
        } as Partial<AppTheme> & Omit<AppTheme, 'theme'>)
      : null,
  };
}
