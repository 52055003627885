import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import { customAlertApiRef } from '@agilelab/plugin-wb-platform';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import React from 'react';
import useInterval from 'react-use/lib/useInterval';
import { useEditorPageContext } from '../context/useEditorPageContext';
import { Test } from '../types';
import { panelCatalogApiRef } from '../../../api';
import { TestTableLoadingRow } from './TestTableLoadingRow';

interface Props {
  test: Test;
}

export const AsyncTestTableRow: React.FC<Props> = ({ test }) => {
  const panelCatalog = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const identityApi = useApi(identityApiRef);
  const { fetchLastTest, fetchTests } = useEditorPageContext();

  useInterval(async () => {
    panelCatalog
      .getValidateDescriptorStatus(test.id, await identityApi.getCredentials())
      .then(response => {
        if (response.status !== DagStatus.RUNNING) {
          if (!response.validationSummaryStatus)
            throw new Error('Missing validation summary in the response');
          fetchLastTest();
          fetchTests();
        }
      })
      .catch(error => {
        alertApi.post({ error, severity: 'error' });
      });
  }, 2000);

  return <TestTableLoadingRow startDate={test.startDate} />;
};
