import React, { ReactNode } from 'react';
import { CustomViewRegister } from './registry';

type PropsInclude = {
  children?: ReactNode;
};

CustomViewRegister.register({
  id: 'include',
  function: ({ children }: PropsInclude) => {
    return <>{children}</>;
  },
});
