/*
Custom Presentation Retriver
*/
import { DiscoveryApi } from '@backstage/core-plugin-api';
import yaml from 'yaml';
import fetch from 'cross-fetch';

export type CustomPresentationRetrieverApi = {
  retrieve(id: string, spec: string): Promise<any>;
};

export class CustomPresentationRetriever {
  static cacheDownloads: Record<string, Promise<Response>> = {};
  static cacheText: Record<string, Promise<string>> = {};
  static cache: Record<string, any> = {};
  private readonly discoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = options.discoveryApi;
  }

  getIncludes(view: any) {
    const getIncludes: Function = this.getIncludes.bind(this);
    return (view.children || [])
      .filter((node: any) => (node.type || '') === 'include')
      .concat(
        (view.children || [])
          .map(getIncludes)
          .reduce((a: [], b: []) => a.concat(b), []),
      );
  }

  async retrieve(id: string, spec: string = ''): Promise<any> {
    const getIncludes: Function = this.getIncludes.bind(this);
    const retrieve: Function = this.retrieve.bind(this);

    const baseUrl = await this.discoveryApi.getBaseUrl('platform');
    const cacheKey = `${id}/${spec}`;

    if (cacheKey in CustomPresentationRetriever.cache)
      return CustomPresentationRetriever.cache[cacheKey];
    if (!(cacheKey in CustomPresentationRetriever.cacheDownloads)) {
      let url = `${baseUrl}/custom_presentation/${id}`;
      if (spec !== '') url = `${url}/${spec}`;
      CustomPresentationRetriever.cacheDownloads[cacheKey] = fetch(url);
    }
    const result: Response | undefined =
      await CustomPresentationRetriever.cacheDownloads[cacheKey].catch(
        () => undefined,
      );
    // if another call already resolved the processing
    if (cacheKey in CustomPresentationRetriever.cache)
      return CustomPresentationRetriever.cache[cacheKey];
    if (result) {
      if (result.status === 200) {
        if (!(cacheKey in CustomPresentationRetriever.cacheText))
          CustomPresentationRetriever.cacheText[cacheKey] = result.text();
        const data = yaml.parse(
          await CustomPresentationRetriever.cacheText[cacheKey],
        );
        CustomPresentationRetriever.cache[cacheKey] = data.view;
        await Promise.all(
          (
            await Promise.all(getIncludes(data.view))
          ).map(async (subview: any) => {
            const subnode = await retrieve(
              subview.id,
              subview.spec === undefined ? spec : subview.spec,
            );
            if (subnode) {
              subview.children = subnode.children;
            }
            return true;
          }),
        );
        return data.view;
      }
    }
    return null;
  }
}
