import { gql } from '@apollo/client/core';

export const ADD_REVIEW_BY_INSTANCE_ID = gql`
  mutation AddReviewByInstanceId(
    $instanceId: Int!
    $reviewer: String!
    $reviewer_display_name: String!
    $score: Int!
    $short_comment: String!
    $long_comment: String!
  ) {
    insert_marketplace_Review(
      objects: {
        id_dataproduct_instance: $instanceId
        reviewer: $reviewer
        reviewer_display_name: $reviewer_display_name
        score: $score
        short_comment: $short_comment
        long_comment: $long_comment
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
