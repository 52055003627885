import { DiscoveryApi } from '@backstage/core-plugin-api';
import fetch from 'cross-fetch';
import {
  Acceptance_type,
  WitboostDocument,
  WitboostDocumentWithAcceptanceDate,
} from '../types';
import { handleFailedResponse } from '@agilelab/plugin-wb-platform-common';

export declare type DocumentApiOptions = {
  token?: string;
};

export interface DocumentsApi {
  retrieveSingleDocumentBlob(
    document_id: number,
    options?: DocumentApiOptions,
  ): Promise<Blob>;

  retrieveDocuments(
    queryParams: {
      not_accepted_only?: boolean;
      include_acceptance_date?: boolean;
      acceptance_types?: Acceptance_type[];
      enabled_only?: boolean;
      metadata_only?: boolean;
    },
    options?: DocumentApiOptions,
  ): Promise<WitboostDocumentWithAcceptanceDate[]>;

  acceptDocumentFromUser(
    document_id: number,
    options?: DocumentApiOptions,
  ): Promise<{ id: number }>;
}

export class DocumentsClient implements DocumentsApi {
  private readonly baseUrlPromise: Promise<string>;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    this.baseUrlPromise = options.discoveryApi.getBaseUrl('documents');
  }

  private async getCredentials(options?: DocumentApiOptions): Promise<string> {
    if (options?.token && options.token) {
      return options.token;
    }
    return '';
  }

  async retrieveSingleDocumentBlob(
    document_id: number,
    options?: DocumentApiOptions,
  ): Promise<Blob> {
    const baseUrl = await this.baseUrlPromise;

    const response = await fetch(`${baseUrl}/${document_id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${await this.getCredentials(options)}`,
        'Content-Type': 'application/pdf',
      },
    });
    await handleFailedResponse(response);

    return response.blob();
  }

  async retrieveDocuments(
    queryParams: {
      not_accepted_only?: boolean;
      include_acceptance_date?: boolean;
      acceptance_types?: Acceptance_type[];
      enabled_only?: boolean;
      metadata_only?: boolean;
    },
    options?: DocumentApiOptions,
  ): Promise<WitboostDocumentWithAcceptanceDate[]> {
    const baseUrl = await this.baseUrlPromise;

    const urlParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        const paramValue = Array.isArray(value)
          ? value.join(',')
          : value.toString();
        urlParams.append(key, paramValue);
      }
    });

    const response = await fetch(
      `${baseUrl}?${new URLSearchParams(urlParams)}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/pdf',
        },
      },
    );
    await handleFailedResponse(response);

    const documents: WitboostDocument[] = await response.json();
    const documentsUint8 = documents.map(doc => {
      return {
        ...doc,
        content: doc.content ? Buffer.from(doc.content) : {},
      };
    });

    return documentsUint8;
  }

  async acceptDocumentFromUser(
    document_id: number,
    options?: DocumentApiOptions,
  ): Promise<{ id: number }> {
    const baseUrl = await this.baseUrlPromise;

    const body = {
      document_id: document_id,
    };

    const response = await fetch(`${baseUrl}/user-acceptance`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await this.getCredentials(options)}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    await handleFailedResponse(response);

    return response.json();
  }
}
