import { WbAutocomplete, useFetchUserInfo } from '@agilelab/plugin-wb-platform';
import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import React, { useCallback, useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { deserializeEntityRef } from '../../../api';
import { transformReadsFromIdToURN } from '../../../lib/id.helpers';

type OutputPortFieldProps = {
  idSchema: string;
  outputPort: string | undefined;
  setOutputPort: React.Dispatch<React.SetStateAction<string | undefined>>;
  dataProduct: string | undefined;
  formData: string | null;
  required: boolean;
};

export const OutputPortField = ({
  idSchema,
  dataProduct,
  outputPort,
  setOutputPort,
  formData,
  required,
}: OutputPortFieldProps) => {
  const catalogApi = useApi(catalogApiRef);
  const { token } = useFetchUserInfo();
  const [outputPortState, setOutputPortState] = useState<string | undefined>(
    formData || undefined,
  );
  const { value: outputPorts, loading } = useAsync(async () => {
    if (!dataProduct) return undefined;

    const dataProductEntity = await catalogApi.getEntityByRef(
      deserializeEntityRef(dataProduct),
      { token },
    );

    if (dataProductEntity && dataProductEntity.relations) {
      const dataProductComponents = dataProductEntity.relations
        .filter(relation => relation.type === 'hasPart')
        .map(relation => relation.targetRef);

      return (
        await Promise.all(
          dataProductComponents.map(
            async component =>
              (await catalogApi.getEntityByRef(component)) as Entity,
          ),
        )
      ).filter(component => component.spec!.type === 'outputport');
    }
    return undefined;
  }, [dataProduct]);

  useEffect(() => {
    setOutputPortState(outputPort);
  }, [outputPort]);

  const outputportRefs = outputPorts?.map(
    outputPortEntity =>
      `${transformReadsFromIdToURN(humanizeEntityRef(outputPortEntity))}`,
  );
  const onSelect = useCallback(
    (_: any, value: string | null) => {
      if (value) {
        setOutputPort(value);
      }
    },
    [setOutputPort],
  );

  return (
    <WbAutocomplete
      id={`${idSchema}-outputport`}
      value={outputPortState || ''}
      loading={loading}
      disabled={!dataProduct}
      onChange={onSelect}
      options={outputportRefs || []}
      autoSelect
      freeSolo={false}
      helperText="The Output Port you want to add to this Workload."
      label="Output Port"
      required={required}
    />
  );
};
