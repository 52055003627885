import { OPFilters } from '../types';

export const GET_OUTPUT_PORTS_BY_DATA_PRODUCT_INSTANCE_ID = `
  query GetOutputPortsByDataProductInstanceId($id: Int, $identities: [String!]) {
    outputPorts: marketplace_OutputPort(
      where: { DataProductInstance: { id: { _eq: $id } } }
    ) {
      id
      description
      name
      display_name
      outputporttype
      platform
      technology
      version
      descriptor
      external_id
      ACLs(where: { refs: { _in: $identities } }) {
        refs
        id_output_port
        locked
      }
    }
  }
`;

export const buildWhere = (
  filters: OPFilters,
  search?: string,
  id?: number,
) => {
  return {
    _or: [
      { display_name: { _ilike: `%${search || ''}%` } },
      { outputporttype: { _ilike: `%${search || ''}%` } },
    ],
    DataProductInstance: { id: { _eq: id } },
    outputporttype: {
      ...(filters.outputporttype?.length && { _in: filters.outputporttype }),
    },
  };
};

export const GET_OUTPUT_PORTS_BY_DATA_PRODUCT_INSTANCE_ID_PAGINATION = `
  query GetOutputPortsByDataProductInstanceId($where: marketplace_OutputPort_bool_exp,$limit: Int
    $offset: Int, $search : String) {
    marketplace_OutputPort(
      limit: $limit
      offset: $offset
      where: $where) {
      id
      displayName: display_name
      description
      name
      outputporttype
      platform
      technology
      version
      descriptor
      external_id
    }
    marketplace_OutputPort_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_OUTPUTPORTTYPES = `
query GetOutputPortType {
  marketplace_OutputPort(distinct_on: outputporttype) {
    outputporttype

  }
}`;

export const GET_OUTPUT_PORTS_ID_BY_DATA_PRODUCT_INSTANCE_ID = `
  query GetOutputPortsIdByDataProductInstanceId($id: Int) {
    outputPorts: marketplace_OutputPort(
      where: { DataProductInstance: { id: { _eq: $id } } }
    ) {
      id
    }
  }
`;

export const GET_OUTPUT_PORTS_BY_ID = `
  query GetOutputPortsById($id: Int) {
    outputPorts: marketplace_OutputPort(
      where: { id: { _eq: $id } } 
    ) {
      id
      name
      displayName: display_name
      outputporttype
    }
  }
`;
