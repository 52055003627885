import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import './styles/styles.css';

import {
  AutoLogoutProvider,
  ldapAuthApiRef,
  SignInPage,
} from '@agilelab/plugin-wb-auth';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  MyDataProductsPage,
  ReleaseDetailPage,
} from '@agilelab/plugin-wb-builder-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@agilelab/plugin-wb-catalog-import';
import { PackageListPage } from '@agilelab/plugin-wb-external-resources';
import { GovernancePage } from '@agilelab/plugin-wb-governance';
import { MarketplacePage } from '@agilelab/plugin-wb-marketplace';
import {
  NotificationPage,
  NotificationProvider,
} from '@agilelab/plugin-wb-notification';
import {
  CustomAlertDisplay,
  MeshApolloProvider,
  EnabledFeaturesProvider,
  SelectorsContextProvider,
  WbHomeRedirect,
  WbRequirePermission,
} from '@agilelab/plugin-wb-platform';
import { PlatformSettingsPage } from '@agilelab/plugin-wb-platform-settings';
import {
  HorizontalTemplateLayout,
  ScaffolderLayouts,
  ScaffolderPage,
  scaffolderPlugin,
} from '@agilelab/plugin-wb-scaffolder';
import {
  DocumentsViewerTab,
  UserSettingsPage,
  UserSettingsTab,
} from '@agilelab/plugin-wb-user-settings';
import { createApp } from '@backstage/app-defaults';
import {
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { OAuthRequestDialog } from '@backstage/core-components';
import {
  discoveryApiRef,
  IdentityApi,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  CatalogGraphPage,
  catalogGraphPlugin,
} from '@backstage/plugin-catalog-graph';
import { orgPlugin } from '@backstage/plugin-org';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { SearchPage } from '@backstage/plugin-search';
import {
  techdocsPlugin,
  TechDocsReaderPage,
  DefaultTechDocsHome,
  TechDocsIndexPage,
} from '@backstage/plugin-techdocs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { Route } from 'react-router';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { Root } from './components/Root';
import { searchPage } from './components/search/SearchPage';
import { UserConfigSettings } from './components/settings/UserConfigSettings';
import { UserHeadersSettings } from './components/settings/UserHeadersSettings/UserHeadersSettings';
import { useCustomTheme, LightAgileTheme } from '@agilelab/plugin-wb-platform';
import { setTokenCookie } from './cookieAuth';
import * as plugins from './plugins';
import { TableRowTemplateLayout } from '@agilelab/plugin-wb-scaffolder';
import { ArrayTableTemplateLayout } from '@agilelab/plugin-wb-scaffolder';
import { DocumentsPopup } from './components/documents/DocumentsPopup';
import { CatalogGraphRenderNode } from '@agilelab/plugin-wb-builder-catalog';

const queryClient = new QueryClient();

const SignInComponent: any = (props: any) => {
  const providers = [
    {
      id: 'microsoft-auth-provider',
      title: 'Microsoft',
      message: 'Sign In using Microsoft Azure AD',
      apiRef: microsoftAuthApiRef,
    },
    {
      id: 'simple_ldap',
      title: 'LDAP',
      message: 'Sign In using LDAP',
      apiRef: ldapAuthApiRef,
    },
  ];
  const discoveryApi = useApi(discoveryApiRef);
  return (
    <SignInPage
      {...props}
      providers={[...providers]}
      title="Select a sign-in method"
      align="center"
      onSignInSuccess={async (identityApi: IdentityApi) => {
        setTokenCookie(await discoveryApi.getBaseUrl('cookie'), identityApi);

        props.onSignInSuccess(identityApi);
      }}
    />
  );
};

function routes() {
  return (
    <FlatRoutes>
      <Route path="/" element={<WbHomeRedirect />} />
      <Route
        path="/catalog"
        element={
          <WbRequirePermission when={ctx => ctx.catalogEnabled}>
            <CatalogIndexPage hiddenKinds={['release', 'location']} />{' '}
          </WbRequirePermission>
        }
      />
      <Route
        path="/catalog/my-data-products"
        element={
          <WbRequirePermission when={ctx => ctx.catalogEnabled}>
            <MyDataProductsPage />
          </WbRequirePermission>
        }
      />
      <Route
        path="/catalog/release/:kind/:namespace/:name"
        element={
          <WbRequirePermission when={ctx => ctx.catalogEnabled}>
            <ReleaseDetailPage />
          </WbRequirePermission>
        }
      />
      <Route
        path="/catalog/:namespace/:kind/:name"
        element={
          <WbRequirePermission when={ctx => ctx.catalogEnabled}>
            <CatalogEntityPage />
          </WbRequirePermission>
        }
      >
        {entityPage}
      </Route>
      <Route
        path="/catalog-graph"
        element={
          <WbRequirePermission when={ctx => ctx.catalogEnabled}>
            <CatalogGraphPage
              renderNode={CatalogGraphRenderNode}
              initialState={{
                selectedKinds: ['component', 'domain', 'system', 'group'],
                selectedRelations: [
                  RELATION_OWNER_OF,
                  RELATION_OWNED_BY,
                  RELATION_HAS_PART,
                  RELATION_PART_OF,
                  RELATION_DEPENDS_ON,
                  RELATION_DEPENDENCY_OF,
                ],
              }}
            />
          </WbRequirePermission>
        }
      />
      <Route path="/docs" element={<TechDocsIndexPage />}>
        <DefaultTechDocsHome />
      </Route>
      <Route
        path="/docs/:namespace/:kind/:name/*"
        element={<TechDocsReaderPage />}
      />
      <Route
        path="/create"
        element={
          <WbRequirePermission when={ctx => ctx.templatesEnabled}>
            <ScaffolderPage />
          </WbRequirePermission>
        }
      />
      <Route
        path="/catalog-import"
        element={
          <RequirePermission permission={catalogEntityCreatePermission}>
            <CatalogImportPage />
          </RequirePermission>
        }
      />
      <Route path="/search" element={<SearchPage />}>
        {searchPage}
      </Route>
      <Route path="/settings" element={<UserSettingsPage />}>
        <UserSettingsTab path="/advanced" title="Advanced">
          <UserConfigSettings />
          <UserHeadersSettings />
        </UserSettingsTab>
        <UserSettingsTab path="documents-table" title="Documents">
          <DocumentsViewerTab />
        </UserSettingsTab>
      </Route>
      <Route path="/marketplace" element={<MarketplacePage />} />
      <Route
        path="/wb-scaffolder"
        element={
          <WbRequirePermission when={ctx => ctx.templatesEnabled}>
            <ScaffolderPage />
          </WbRequirePermission>
        }
      >
        <ScaffolderLayouts>
          <TableRowTemplateLayout />
          <ArrayTableTemplateLayout />
          <HorizontalTemplateLayout />
        </ScaffolderLayouts>
      </Route>
      <Route
        path="/governance"
        element={
          <WbRequirePermission when={ctx => ctx.governanceEnabled}>
            <GovernancePage />
          </WbRequirePermission>
        }
      />
      <Route
        path="/external-resources"
        element={
          <WbRequirePermission when={ctx => ctx.externalResourcesEnabled}>
            <PackageListPage />
          </WbRequirePermission>
        }
      />
      <Route path="/notifications" element={<NotificationPage />} />
      <Route
        path="/platform-settings"
        element={
          <WbRequirePermission when={ctx => ctx.platformSettingsEnabled}>
            <PlatformSettingsPage />
          </WbRequirePermission>
        }
      />
    </FlatRoutes>
  );
}

const WitboostApp = () => {
  const { error, loading, theme } = useCustomTheme();
  const themes = useMemo(() => {
    if (theme && !loading && !error) return [theme];
    return [LightAgileTheme];
  }, [error, loading, theme]);

  if (loading) return <></>;

  const app = createApp({
    apis,
    plugins: Object.values(plugins),
    themes: themes,
    components: {
      SignInPage: SignInComponent,
    },
    bindRoutes({ bind }) {
      bind(catalogPlugin.externalRoutes, {
        createComponent: scaffolderPlugin.routes.root,
        viewTechDoc: techdocsPlugin.routes.docRoot,
      });
      bind(catalogGraphPlugin.externalRoutes, {
        catalogEntity: catalogPlugin.routes.catalogEntity,
      });
      bind(scaffolderPlugin.externalRoutes, {
        registerComponent: catalogImportPlugin.routes.importPage,
      });
      bind(orgPlugin.externalRoutes, {
        catalogIndex: catalogPlugin.routes.catalogIndex,
      });
    },
  });

  const AppRoot = app.createRoot(
    <>
      <DocumentsPopup />
      <CustomAlertDisplay />
      <OAuthRequestDialog />
      <AppRouter>
        <MeshApolloProvider>
          <QueryClientProvider client={queryClient}>
            <NotificationProvider>
              <AutoLogoutProvider>
                <SelectorsContextProvider>
                  <EnabledFeaturesProvider>
                    <Root>{routes()}</Root>
                  </EnabledFeaturesProvider>
                </SelectorsContextProvider>
              </AutoLogoutProvider>
            </NotificationProvider>
          </QueryClientProvider>
        </MeshApolloProvider>
      </AppRouter>
    </>,
  );

  return <AppRoot />;
};

export default WitboostApp;
