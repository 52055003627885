import fetch from 'cross-fetch';
import { DiscoveryApi } from '@backstage/core-plugin-api';
import {
  CatalogClient,
  CatalogRequestOptions,
} from '@backstage/catalog-client';
import {
  CatalogApiExtension,
  EntityErrorResult,
  GetRelationsResponse,
  GroupsAncestorsResponse,
} from './types';
import {
  UserEntityRef,
  handleFailedResponse,
} from '@agilelab/plugin-wb-platform-common';

export class CatalogClientExtension
  extends CatalogClient
  implements CatalogApiExtension
{
  private readonly witboostDiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    super({ discoveryApi: options.discoveryApi });
    this.witboostDiscoveryApi = options.discoveryApi;
  }

  /**
   *
   * @param relationType an existing relation type
   * @param options containing a server to server token
   * @returns
   */
  async getRelations(
    getRelationsParameters?: {
      relationType?: string;
      sourceEntityRef?: string;
    },
    options?: CatalogRequestOptions,
  ): Promise<GetRelationsResponse> {
    const baseUrl = await this.witboostDiscoveryApi.getBaseUrl('catalog');

    const urlParams = new URLSearchParams();
    if (getRelationsParameters) {
      Object.entries(getRelationsParameters).forEach(([key, value]) => {
        if (value) {
          urlParams.append(key, value);
        }
      });
    }

    const response = await fetch(`${baseUrl}/relations?${urlParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
    });

    await handleFailedResponse(response);
    return response.json();
  }

  /**
   *
   * @param entityRef the userEntityRef
   * @param options containing a server to server token
   * @returns
   */
  async getGroupsAncestorsForUser(
    entityRef: UserEntityRef,
    options?: CatalogRequestOptions,
  ): Promise<GroupsAncestorsResponse> {
    const baseUrl = await this.witboostDiscoveryApi.getBaseUrl('catalog');

    const response = await fetch(
      `${baseUrl}/groupsAncestors?entityRef=${entityRef}`,
      {
        method: 'GET',
        headers: {
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
          'Content-Type': 'application/json',
        },
      },
    );

    await handleFailedResponse(response);

    return response.json();
  }

  async getEntityErrors(
    entityIdsOrRefs: string[],
    options?: CatalogRequestOptions,
  ): Promise<EntityErrorResult[]> {
    const baseUrl = await this.witboostDiscoveryApi.getBaseUrl('catalog');

    const response = await fetch(`${baseUrl}/errors`, {
      method: 'POST',
      body: JSON.stringify(entityIdsOrRefs),
      headers: {
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        'Content-Type': 'application/json',
      },
    });

    await handleFailedResponse(response);

    return response.json();
  }
}
