import React, { useContext, useState } from 'react';
import {
  configApiRef,
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  catalogEntityCreatePermission,
  catalogEntityReadPermission,
  catalogLocationCreatePermission,
  catalogLocationReadPermission,
} from '@backstage/plugin-catalog-common/alpha';
import {
  CompletePermission,
  UserPermissionsMap,
  cgpEntityEdit,
  cgpEntityView,
  platformSettingsEditPermission,
} from '@agilelab/plugin-wb-rbac-common';
import useAsync from 'react-use/lib/useAsync';

interface EnabledBySource {
  config: Record<string, boolean>;
  permission: Record<string, boolean>;
}

export interface Features {
  meshSupervisionEnabled: boolean;
  governanceEnabled: boolean;
  catalogEnabled: boolean;
  templatesEnabled: boolean;
  blueprintsEnabled: boolean;
  builderEnabled: boolean;
  platformSettingsEnabled: boolean;
  dataContractsEnabled: boolean;
  externalResourcesEnabled: boolean;
}

export interface EnabledFeaturesType {
  loading: boolean;
  error: Error | undefined;
  data: Features;
}

export const EnabledFeatures = React.createContext<EnabledFeaturesType>(
  {} as EnabledFeaturesType,
);

export interface EnabledFeaturesProviderProps {}

export const EnabledFeaturesProvider: React.FC<
  EnabledFeaturesProviderProps
> = ({ children }) => {
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);

  const permissionsEnabled =
    configApi.getOptionalBoolean('permission.enabled') ?? false;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [permissions, setPermissions] = useState<UserPermissionsMap>(
    UserPermissionsMap.fromPermissions([]),
  );

  useAsync(async () => {
    try {
      const identity = await identityApi.getCredentials();

      const response = await fetch(
        `${await discoveryApi.getBaseUrl('rbac')}/permissions`,
        {
          headers: identity.token
            ? { authorization: `Bearer ${identity.token}` }
            : {},
        },
      );

      const data = (await response.json()) as {
        userPermissions: CompletePermission[];
      };

      setPermissions(UserPermissionsMap.fromPermissions(data.userPermissions));
      setLoading(false);
    } catch (fetchError) {
      setError(fetchError);
    }
  }, [discoveryApi, identityApi]);

  const enabled: EnabledBySource = {
    config: {
      meshSupervision:
        configApi.getOptionalBoolean(
          'mesh.marketplace.ui.meshSupervisionPage.enabled',
        ) ?? true,

      blueprints:
        configApi.getOptionalBoolean(
          'mesh.builder.scaffolder.blueprints.enabled',
        ) ?? true,

      platformSettings:
        configApi.getOptionalBoolean('mesh.platformSettings.enabled') ?? false,

      dataContracts:
        configApi.getOptionalBoolean(
          'mesh.marketplace.ui.dataContractsPage.enabled',
        ) ?? false,

      externalResources:
        configApi.getOptionalBoolean('mesh.externalResources') ?? false,
    },

    permission: {
      templates:
        !permissionsEnabled ||
        permissions.hasPermission(catalogEntityCreatePermission.name) ||
        permissions.hasPermission(catalogLocationCreatePermission.name),

      catalog:
        !permissionsEnabled ||
        permissions.hasPermission(catalogEntityReadPermission.name) ||
        permissions.hasPermission(catalogLocationReadPermission.name),

      platformSettings:
        !permissionsEnabled ||
        permissions.hasPermission(platformSettingsEditPermission.name),

      governance:
        !permissionsEnabled ||
        permissions.hasPermission(cgpEntityEdit.name) ||
        permissions.hasPermission(cgpEntityView.name),
    },
  };

  const meshSupervisionEnabled = enabled.config.meshSupervision;
  const governanceEnabled = enabled.permission.governance;
  const catalogEnabled = enabled.permission.catalog;
  const templatesEnabled = enabled.permission.templates;
  const blueprintsEnabled =
    enabled.permission.templates && enabled.config.blueprints;
  const builderEnabled =
    catalogEnabled || templatesEnabled || blueprintsEnabled;
  const platformSettingsEnabled =
    enabled.config.platformSettings && enabled.permission.platformSettings;
  const dataContractsEnabled = enabled.config.dataContracts;
  const externalResourcesEnabled = enabled.config.externalResources;

  return (
    <EnabledFeatures.Provider
      value={{
        loading,
        error,
        data: {
          meshSupervisionEnabled,
          governanceEnabled,
          catalogEnabled,
          templatesEnabled,
          blueprintsEnabled,
          builderEnabled,
          platformSettingsEnabled,
          dataContractsEnabled,
          externalResourcesEnabled,
        },
      }}
    >
      {children}
    </EnabledFeatures.Provider>
  );
};

export const useEnabledFeatures = () => useContext(EnabledFeatures);
