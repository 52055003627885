import React, { FC } from 'react';
import {
  ComponentRegisters,
  ComponentRegister,
  parseNunjucks,
} from '@agilelab/plugin-wb-platform-common';
import { useDataPath } from '../../context';
import _ from 'lodash';

type ComponentEntry = {
  id: string;
  function?: Function;
};

class CustomViewComponentRegister {
  private registry: ComponentRegister;
  constructor() {
    this.registry = ComponentRegisters.getComponentRegister('customView');
  }
  get(componentId: string): Function | undefined {
    return this.registry.get(componentId);
  }
  register(entry: ComponentEntry): void {
    this.registry.register({
      id: entry.id,
      function: function CVComponent(props: Record<string, any>) {
        const cprops = Object.assign({}, props);
        const data = useDataPath('');
        ['label', 'title', 'value'].forEach(p => {
          if (props[p]) cprops[p] = parseNunjucks(props[p], data);
        });
        if (props.path) {
          cprops.value = _.get(data, props.path);
        }
        if (props.hideWhenExists) {
          if (!!_.get(data, props.hideWhenExists)) return <></>;
        }
        if (props.showWhenExists) {
          if (!_.get(data, props.showWhenExists)) return <></>;
        }
        const Component: FC = entry.function as FC;
        return <Component {...cprops} />;
      },
    });
  }
}

export const CustomViewRegister = new CustomViewComponentRegister();
