import React, { useState } from 'react';
import { useEditorPageContext } from '../../context/useEditorPageContext';
import { Box, Typography } from '@material-ui/core';
import { StructureList } from './StructureList';
import { StructureListLoader } from './StructureListLoader';
import { WbSearch } from '@agilelab/plugin-wb-platform';

export const StructureCard: React.FC = () => {
  const { entitiesState, parent, entity } = useEditorPageContext();
  const [searchValue, setSearchValue] = useState<string | undefined>();

  return (
    <Box
      style={{
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Box
        style={{
          marginBottom: '16px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2" style={{ fontWeight: 500 }}>
          Structure
        </Typography>
      </Box>

      <Box style={{ marginBottom: '8px' }}>
        {!parent && (
          <WbSearch
            onChange={value => {
              setSearchValue(value);
            }}
          />
        )}
      </Box>

      <>
        {entitiesState?.loading ? (
          <StructureListLoader />
        ) : (
          <StructureList
            entity={entity}
            entities={(entitiesState?.value || []).filter((e, index) => {
              if (index === 0) return true;

              return searchValue
                ? new RegExp(searchValue, 'ig').test(
                    (e.spec?.mesh as any)?.name,
                  )
                : true;
            })}
          />
        )}
      </>
    </Box>
  );
};
