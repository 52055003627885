import React from 'react';
import { useEffect } from 'react';
import { useReleaseDetailPageContext } from './context/useReleaseDetailPageContext';
import { Skeleton } from '@material-ui/lab';
import { DeploymentUnitStatus } from '@agilelab/plugin-wb-builder-common';
import { Box, Typography, makeStyles, Tooltip } from '@material-ui/core';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import clsx from 'clsx';
import {
  PartiallyDeployedIcon,
  ProvisioningProgressIcon,
} from '../ReleasePage';
import { useReleaseStatusAndVersion } from './hooks/useReleaseStatusAndVersion';
import { Label } from './AboutCard';

const useStyles = makeStyles(theme => ({
  card: {
    padding: '4px 8px',
    borderRadius: '4px',
    alignItems: 'center',
    gap: '4px',
    color: 'white',
    cursor: 'default',
    display: 'flex',
    maxWidth: 'fit-content',
  },
  deployed: {
    background: theme.palette.success.main,
    color: theme.palette.black,
  },
  notDeployed: {
    background: theme.palette.blueGrey[200],
    color: theme.palette.black,
  },
  partiallyDeployed: {
    color: theme.palette.black,
    border: `1px solid ${theme.palette.success.main}`,
  },
  provisioningInProgress: {
    background: theme.palette.accent.main,
    color: theme.palette.white,
  },
  corrupt: {
    background: theme.palette.error.main,
    color: theme.palette.black,
  },
}));

interface Props {
  status?: DeploymentUnitStatus;
  version?: string;
  environment: string;
}

interface VersionCaptionProps {
  version?: string;
}

export const VersionCaption: React.FC<VersionCaptionProps> = ({ version }) => {
  const versionToShow = version?.replace('.0', '');
  return (
    <>
      {versionToShow && (
        <Typography variant="caption">{`(${versionToShow})`}</Typography>
      )}
    </>
  );
};

export const DeploymentChip: React.FC<Props> = ({
  status,
  environment,
  version,
}) => {
  const classes = useStyles();
  const titleTooltip = `${snakeCaseToTitleCase(status?.toLowerCase() ?? '')} ${
    version ?? ''
  } in ${environment}`;

  switch (status) {
    case 'DEPLOYED':
      return (
        <Tooltip title={titleTooltip}>
          <Box className={clsx(classes.card, classes.deployed)}>
            <CheckIcon fontSize="small" />
            <Typography variant="caption">
              {snakeCaseToTitleCase(environment)}
            </Typography>
            <VersionCaption version={version} />
          </Box>
        </Tooltip>
      );
    case 'NOT_DEPLOYED':
      return (
        <Tooltip title={titleTooltip}>
          <Box className={clsx(classes.card, classes.notDeployed)}>
            <CancelOutlinedIcon fontSize="small" />
            <Typography variant="caption">
              {snakeCaseToTitleCase(environment)}
            </Typography>
            <VersionCaption version={version} />
          </Box>
        </Tooltip>
      );
    case 'PARTIALLY_DEPLOYED':
      return (
        <Tooltip title={titleTooltip}>
          <Box className={clsx(classes.card, classes.partiallyDeployed)}>
            <PartiallyDeployedIcon fontSize="small" />
            <Typography variant="caption">
              {snakeCaseToTitleCase(environment)}
            </Typography>
            <VersionCaption version={version} />
          </Box>
        </Tooltip>
      );
    case 'PROVISIONING_IN_PROGRESS':
      return (
        <Tooltip title={titleTooltip}>
          <Box className={clsx(classes.card, classes.provisioningInProgress)}>
            <ProvisioningProgressIcon fontSize="small" />
            <Typography variant="caption">
              {snakeCaseToTitleCase(environment)}
            </Typography>
            <VersionCaption version={version} />
          </Box>
        </Tooltip>
      );
    case 'CORRUPT':
      return (
        <Tooltip title={titleTooltip}>
          <Box className={clsx(classes.card, classes.corrupt)}>
            <ErrorOutlineIcon fontSize="small" />
            <Typography variant="caption">
              {snakeCaseToTitleCase(environment)}
            </Typography>
            <VersionCaption version={version} />
          </Box>
        </Tooltip>
      );
    default:
      return <></>;
  }
};

export const ReleaseDeploymentStatus: React.FC = () => {
  const {
    fetchDeploymentUnitStatus,
    deploymentUnitStatusState,
    environment,
    queryParamEnvironment,
  } = useReleaseDetailPageContext();

  useEffect(() => {
    fetchDeploymentUnitStatus();
  }, [fetchDeploymentUnitStatus]);

  const statusAndVersions = useReleaseStatusAndVersion();

  if (deploymentUnitStatusState.loading) return <Skeleton width={150} />;

  return (
    <>
      {statusAndVersions &&
        statusAndVersions.map((statusAndVersion, index) => {
          return (
            <Label
              title={index === 0 ? 'Release Status' : 'Data Product Status'}
            >
              <DeploymentChip
                status={statusAndVersion.status}
                environment={queryParamEnvironment || environment.name}
                version={statusAndVersion.version}
              />
            </Label>
          );
        })}
    </>
  );
};
