import {
  CodeField,
  GenericField,
  useDrawersContext,
  WbTag,
} from '@agilelab/plugin-wb-platform';
import { Box, Typography, TableRow, TableCell } from '@material-ui/core';
import React from 'react';
import { GovernanceEntitiesQueryType } from '../../../graphql/governanceEntities';
import { Task } from '../../ControlPanel';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import { TestStatus } from '@agilelab/plugin-wb-platform';
import {
  GovernanceTestOverviewPolicy,
  toGovernancePolicy,
} from '@agilelab/plugin-wb-governance';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { format, parseISO } from 'date-fns';
import { useStyles } from './styles';

interface Props {
  task: Task;
  policy: GovernanceEntitiesQueryType['cgp_governance_entity'][0];
}

const PolicyRowDrawerContent: React.FC<Props> = ({ task, policy }) => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.detailContainer}>
        <TestStatus
          status={task.status}
          filled
          severity={policy.content.severity}
          policyStatus={task.governanceEntityStatus}
          variant="reduced"
        />
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          {policy.name}
        </Typography>
      </Box>

      <GovernanceTestOverviewPolicy
        kind="controlPanel"
        policy={toGovernancePolicy(policy)}
        infoContent={
          <Box style={{ display: 'flex', gap: 50 }}>
            <>
              <GenericField
                label="Result Status"
                value={snakeCaseToTitleCase(task.status.toLowerCase())}
              />
              <GenericField
                label="Latest Update"
                value={format(parseISO(task.startTime), 'yyyy/MM/dd HH:mm:ss')}
              />
              {task.details && (
                <GenericField
                  label="Test Detail"
                  value={
                    <CodeField
                      title="Test Detail"
                      value={JSON.stringify(task.details, null, 4)}
                    />
                  }
                />
              )}
            </>
          </Box>
        }
        test={{
          errors: task.errors,
          content: task.validatedDescriptor || '',
          name: task.displayName,
        }}
      />
    </Box>
  );
};

export const PolicyRow: React.FC<Props> = ({ task, policy }) => {
  const classes = useStyles();
  const { toggleBoolean } = useDrawersContext();
  const { setValidationTestResultDetailContent } =
    useReleaseDetailPageContext();
  const onRowClick = () => {
    setValidationTestResultDetailContent(
      <PolicyRowDrawerContent task={task} policy={policy} />,
    );
    toggleBoolean(1);
  };

  return (
    <TableRow onClick={onRowClick} className={classes.row}>
      <TableCell width="20%">
        <TestStatus
          status={task.status}
          filled
          severity={policy.content.severity}
          policyStatus={task.governanceEntityStatus}
        />
      </TableCell>
      <TableCell width="30%">
        <Typography variant="body2" style={{ fontWeight: 700 }}>
          {policy.name} - {policy.version}
        </Typography>
      </TableCell>
      <TableCell width="30%">
        <Typography variant="body2">{policy.description}</Typography>
      </TableCell>
      <TableCell width="20%" align="right">
        <WbTag
          tag={{
            tagFQN: 'Policy',
          }}
        />
      </TableCell>
    </TableRow>
  );
};
