import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { ResourceType, PracticeShaperEntity } from '../types';

const newEntities = [
  {
    kind: 'Taxonomy',
    name: 'Data Mesh Taxonomy',
    description: 'Default taxonomy',
    fetchCatalogInfoContent: async () => ({
      newContent: {
        apiVersion: 'witboost.com/v2',
        kind: 'Taxonomy',
        metadata: {
          name: 'data-mesh-taxonomy',
          displayName: 'Data Mesh',
        },
        spec: {
          enabled: false,
        },
      },
    }),
  },
  {
    kind: 'DomainType',
    name: 'Business Domain',
    description: 'Default domain type',
    fetchCatalogInfoContent: async () => ({
      newContent: {
        apiVersion: 'witboost.com/v2',
        kind: 'DomainType',
        metadata: {
          name: 'business-domain',
          displayName: 'Business Domain',
        },
      },
    }),
  },
] as PracticeShaperEntity[];

function buildCatalogInfo(
  kind: 'SystemType' | 'ComponentType',
  name: string,
  resourceType: string,
  shoppable: boolean = false,
): Record<string, any> {
  if (kind === 'SystemType') {
    return {
      apiVersion: 'witboost.com/v2',
      kind: 'SystemType',
      metadata: {
        name: resourceType,
        displayName: name,
      },
      spec: {
        resourceTypeId: resourceType,
        belongsTo: 'taxonomy:default/data-mesh-taxonomy',
        partOfDomain: 'domaintype:default/business-domain',
      },
    };
  }
  return {
    apiVersion: 'witboost.com/v2',
    kind: 'ComponentType',
    metadata: {
      name: resourceType,
      displayName: name,
    },
    spec: {
      resourceTypeId: resourceType,
      partOfSystem: 'systemtype:default/dataproduct',
      shoppable,
    },
  };
}

const newTypeEntities: { [resourceType: string]: PracticeShaperEntity } = {
  dataproduct: {
    kind: 'SystemType',
    name: 'Data Product',
    description: 'System type associated to resource type dataproduct',
    fetchCatalogInfoContent: async () => ({
      newContent: buildCatalogInfo('SystemType', 'Data Product', 'dataproduct'),
    }),
  },
  outputport: {
    kind: 'ComponentType',
    name: 'Output Port',
    description: 'Component type associated to resource type outputport',
    fetchCatalogInfoContent: async () => ({
      newContent: buildCatalogInfo(
        'ComponentType',
        'Output Port',
        'outputport',
        true,
      ),
    }),
  },
};

function resourceTypeToEntityName(resourceType: string): string {
  const transMap: { [key: string]: string } = {
    dataproduct: 'Data Product',
    outputport: 'Output Port',
    consumerviewproject: 'Consumer View Project',
    consumerview: 'Consumer View',
  };
  return transMap[resourceType] ?? snakeCaseToTitleCase(resourceType);
}

function resourceTypeToTypeEntity(
  resourceType: ResourceType,
): PracticeShaperEntity {
  const kind = resourceType.kind === 'system' ? 'SystemType' : 'ComponentType';
  const name = resourceTypeToEntityName(resourceType.name);
  const description = `${kind} associated to resource type ${resourceType.name}`;
  const fetchCatalogInfoContent = async () => ({
    newContent: buildCatalogInfo(kind, name, resourceType.name),
  });

  return {
    kind,
    name,
    description,
    fetchCatalogInfoContent,
  } as PracticeShaperEntity;
}

function generateResourceTypeEntities(
  resourceType: ResourceType[],
): PracticeShaperEntity[] {
  const typeEntities = resourceType.flatMap(rt =>
    newTypeEntities[rt.name] ? [] : [resourceTypeToTypeEntity(rt)],
  );
  return [...Object.values(newTypeEntities), ...typeEntities];
}

export const generateNewEntities = (props: {
  resourceTypes: ResourceType[];
}) => {
  const { resourceTypes } = props;
  return [...newEntities, ...generateResourceTypeEntities(resourceTypes)];
};
