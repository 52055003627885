import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { customAlertApiRef } from '@agilelab/plugin-wb-platform';
import {
  Acceptance_type,
  WitboostDocument,
} from '@agilelab/plugin-wb-documents-common';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { documentsApiRef } from '@agilelab/plugin-wb-builder-catalog';
import { DocumentsAcceptanceActions } from './AcceptanceActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      width: '60vw',
    },
    container: {
      background: theme.palette.background.paper,
      padding: '0px',
    },
    actionBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
    },
    page: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
);

export function DocumentsPopup(_props: {}) {
  const documentsApi = useApi(documentsApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);
  const configApi = useApi(configApiRef);
  const theme = useTheme();
  const classes = useStyles();

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();

  const [showDocumentsPopup, setShowDocumentsPopup] = useState(false);
  const [documents, setDocuments] = useState<WitboostDocument[]>([]);

  const [loadedNumPages, setLoadedNumPages] = useState<number>();
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [scale, setScale] = useState(1.4);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setLoadedNumPages(numPages);
  }

  const handleZoomIn = () => {
    setScale(prevZoom => prevZoom + 0.2);
  };

  const handleZoomOut = () => {
    if (scale <= 1) {
      return;
    }
    setScale(prevZoom => prevZoom - 0.2);
  };

  const handleAcceptClick = async (): Promise<void> => {
    if (documents[currentDocumentIndex]) {
      const currentDocument = documents[currentDocumentIndex];

      try {
        setLoading(true);
        const { token } = await identityApi.getCredentials();
        await documentsApi.acceptDocumentFromUser(currentDocument.id!, {
          token,
        });

        setCurrentDocumentIndex(prevIndex => prevIndex + 1);
        alertApi.post({
          message: `Document ${currentDocument.name} accepted.`,
          severity: 'success',
          timeoutMillis: 5000,
        });
      } catch (error) {
        alertApi.post({
          message: `Error accepting document ${currentDocument.name}. Contact the platform team for more info.`,
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const closeWithError = (error: Error) => {
    alertApi.post({
      message: `There was an error loading the PDF for user acceptance.\n ${error.message}`,
      severity: 'error',
    });
    setShowDocumentsPopup(false);
  };

  const handleReject = () => {
    identityApi.signOut();
  };

  useEffect(() => {
    if (currentDocumentIndex >= documents.length) {
      setShowDocumentsPopup(false);
    }
  }, [currentDocumentIndex, documents.length, setShowDocumentsPopup]);

  useEffect(() => {
    const documentsEnabled: boolean =
      configApi.getOptionalBoolean(
        'mesh.documents.requireDocumentAcceptance',
      ) ?? false;
    try {
      if (!documentsEnabled) {
        setDocuments([]);
        setShowDocumentsPopup(false);
        return;
      }
      const fetchData = async () => {
        const { token } = await identityApi.getCredentials();
        const fetchedDocuments = await documentsApi.retrieveDocuments(
          {
            not_accepted_only: true,
            enabled_only: true,
            acceptance_types: [
              Acceptance_type.MANDATORY,
              Acceptance_type.ACKNOWLEDGMENT,
            ],
          },
          { token },
        );

        setDocuments(fetchedDocuments);
      };

      fetchData();
    } catch (error) {
      alertApi.post({
        error: error,
        severity: 'error',
      });
      setShowDocumentsPopup(false);
    }
  }, [identityApi, documentsApi, alertApi, configApi]);

  useEffect(() => {
    if (documents.length > 0) {
      setShowDocumentsPopup(true);
    }
  }, [documents, setShowDocumentsPopup]);

  if (!showDocumentsPopup) {
    return null;
  }

  return (
    <Dialog open={showDocumentsPopup} maxWidth="xl">
      <DialogTitle color="primary">
        {documents[currentDocumentIndex]?.name}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description">
          Read and express your acceptance of the following document.
        </DialogContentText>
        {documents[currentDocumentIndex] && (
          <Document
            file={{ data: documents[currentDocumentIndex].content }}
            onLoadSuccess={onDocumentLoadSuccess}
            onSourceError={closeWithError}
            key={`document_${currentDocumentIndex}`}
          >
            <Container className={classes.container}>
              {Array.from({ length: loadedNumPages! }, (_, index) => (
                <Container
                  key={`Container_${index + 1}`}
                  className={classes.container}
                >
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    scale={scale}
                    className={classes.page}
                  />

                  <Box
                    padding={theme.spacing(0.1)}
                    style={{ textAlign: 'center' }}
                  >
                    Page {index + 1} of {loadedNumPages}
                  </Box>
                </Container>
              ))}
            </Container>
          </Document>
        )}
      </DialogContent>
      <Box className={classes.actionBox}>
        <Typography>
          {documents?.length > 1
            ? `Document ${currentDocumentIndex + 1} of ${documents.length}`
            : ''}
        </Typography>
        <DialogActions>
          <Button onClick={handleZoomIn}>
            <ZoomInIcon />
          </Button>

          <Button onClick={handleZoomOut} disabled={scale <= 1}>
            <ZoomOutIcon />
          </Button>

          <DocumentsAcceptanceActions
            type={documents[currentDocumentIndex]?.acceptance_type}
            onAcceptAction={handleAcceptClick}
            onDeclineAction={handleReject}
            loading={loading}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
}
