import { Knex } from 'knex';
import { NotAllowedError, NotFoundError } from '@backstage/errors';

async function handleResult(knex: Knex, id: any, result: any) {
  if (result.length === 0) {
    await handleEmptyResultError(knex, id);
    // An error will be thrown so this line will never be reached
    return {};
  }
  return result;
}

async function handleEmptyResultError(knex: Knex, id: any) {
  // Check if the user is authorized or if no rows satisfy the conditions
  const rowExists = await knex('notification').where('id', id).select('id');

  if (rowExists.length === 0) {
    // Id returns no row
    throw new NotFoundError(`No notification found with id ${id}`);
  } else {
    throw new NotAllowedError(
      `Unauthorized to update notification with id ${id}`,
    );
  }
}

/**
 * Adds a new notification entry in the DB
 */
export function addNotificationRequest(
  knex: Knex,
  kind: any,
  notification_request: any,
  hook_id: string | null,
  recipients: string[],
  sender: string,
) {
  // Authorization already granted
  if (recipients && recipients.length !== 0) {
    return knex('notification').insert(
      recipients.map(recipient => ({
        kind,
        recipient,
        notification_request,
        sender,
        hook_id,
      })),
      ['id'],
    );
  }
  return [];
}

/**
 * Add a response to a notification entry based on its id.
 */
export async function updateNotificationResponse(
  knex: Knex,
  identity: string,
  id: any,
  notification_response: any,
  time: Date,
) {
  const result = await knex('notification')
    .where('recipient', identity) // Check authorization
    .where('id', id)
    .update('notification_response', notification_response)
    .update('updated_at', time)
    .returning([
      'id',
      'kind',
      'notification_request',
      'notification_response',
      'sender',
      'hook_id',
    ]);

  return handleResult(knex, id, result);
}

/**
 * Set the column read_at from null to a value, marking the notification as read.
 */
export async function setNotificationRead(
  knex: Knex,
  identity: string,
  id: any,
  time: Date,
) {
  const result = await knex('notification')
    .where('recipient', identity) // Check authorization
    .where('id', id)
    .update('read_at', time)
    .update('updated_at', time)
    .returning(['id', 'read_at']);

  return handleResult(knex, id, result);
}

/**
 * Set the column read_at from null to a value, marking all the notifications for a recipient as read
 */
export function setAllNotificationsRead(
  knex: Knex,
  recipient: any,
  time: Date,
) {
  // Authorization already granted
  return knex('notification')
    .where('recipient', recipient)
    .update('read_at', time)
    .update('updated_at', time)
    .returning(['read_at']);
}

/**
 * Counts all notification which have the column read_at set to null, by recipient.
 */
export function countUnreadNotificationByRecipient(knex: Knex, recipient: any) {
  // Authorization already granted
  return knex('notification')
    .where('recipient', recipient)
    .whereNull('read_at')
    .count();
}

/**
 * Based on the status passed to the query, it could refer to a Question or an AccessRequest.
 * In the case of an AccessRequest, we have to check if it is accepted or rejected. the status can be found in
 * the column notification_response.
 * In the case of a Question, the presence (or lack thereof) of the column notification_response indicates whether
 * the question has been answered.
 */
function notificationStatusToRawFilter(response_status: string) {
  const upper_response_status = response_status.toUpperCase();
  switch (upper_response_status) {
    case 'ACCEPT':
    case 'REJECT':
      return `notification_response->>'status' = '${upper_response_status}'`;
    case 'TOANSWER':
      return 'notification_response is null';
    case 'ANSWERED':
      return 'notification_response is not null';
    default:
      throw Error('Response status not valid');
  }
}

/**
 * This query retrieves all notification based on the recipient, the kind and the response status (see helper method).
 * Then it retrieves the count along with a certain subset of elements.
 * It is used for pagination.
 */
export async function getWithCount(
  knex: Knex,
  identity?: string,
  recipient?: string,
  offset?: number,
  limit?: number,
  kind?: string,
  isRead?: boolean,
  response_status?: string,
  idDataproductInstance?: number,
  onlyTimestamps?: boolean,
) {
  let queryBuilder = knex('notification');
  // We do not need to check authorization if retrieving questions.
  if (kind !== 'Question') {
    queryBuilder = queryBuilder.where('recipient', identity); // Check authorization
  }

  if (recipient) {
    queryBuilder = queryBuilder.where('recipient', recipient);
  }

  if (kind) {
    queryBuilder = queryBuilder.where('kind', kind);
  }
  if (response_status) {
    const rawResponseFilter = notificationStatusToRawFilter(response_status);
    queryBuilder = queryBuilder.whereRaw(rawResponseFilter);
  }
  if (isRead !== undefined) {
    const rawReadFilter = isRead ? 'read_at is not null' : 'read_at is null';
    queryBuilder = queryBuilder.whereRaw(rawReadFilter);
  }

  if (idDataproductInstance) {
    queryBuilder = queryBuilder.whereRaw(
      "notification_request->>'id_dataproduct_instance' = ?",
      [idDataproductInstance],
    );
  }

  let notificationsQueryBuilder = queryBuilder.clone();
  if (offset) {
    notificationsQueryBuilder = notificationsQueryBuilder.offset(offset);
  }
  if (limit) {
    notificationsQueryBuilder = notificationsQueryBuilder.limit(limit);
  }

  let notifications;

  if (onlyTimestamps) {
    notifications = await notificationsQueryBuilder
      .select('created_at')
      .orderBy('created_at', 'desc');
  } else {
    notifications = await notificationsQueryBuilder
      .select(
        'id',
        'kind',
        'notification_request',
        'notification_response',
        'recipient',
        'created_at',
        'updated_at',
        'read_at',
        'sender',
      )
      .orderBy('created_at', 'desc');
  }

  const count = await queryBuilder.count();

  return { total: count, notifications: notifications };
}
