import {
  ConfirmDialog,
  customAlertApiRef,
  WbCardActionButton,
  WbLink,
} from '@agilelab/plugin-wb-platform';
import {
  identityApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import React, { useState } from 'react';
import { panelCatalogApiRef } from '../../../../api';
import { useReleasePageContext } from '../../context/useReleasePageContext';
import { Tooltip, Typography } from '@material-ui/core';
import {
  ReleaseAndDeployments,
  WitboostVersionedEntity,
} from '@agilelab/plugin-wb-builder-common';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { useNavigate } from 'react-router';
import { releaseDetailRouteRef } from '../../../../routes';
import { getReleaseInfo } from '../../../../utils';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { usePermission } from '@backstage/plugin-permission-react';
import { builderDpCommitPermission } from '@agilelab/plugin-wb-rbac-common';

interface Props {
  release: ReleaseAndDeployments;
}

export const UPDATE_TOOLTIP =
  'Update the main repository branch with the existing draft release files. The previous draft release files will be overwritten by this operation, but you will be able to do additional updates in the future';

export function generateUpdateTooltip(
  canCommit: boolean,
  disabled: boolean,
  descriptorLoading: boolean,
  otherOperationsLoading: boolean,
  entity: WitboostVersionedEntity,
  branches?: string[],
) {
  if (!canCommit) return `You are not allowed to update this draft release`;

  if (descriptorLoading)
    return 'Descriptor is still loading, please wait until it is ready';

  if (otherOperationsLoading)
    return 'Wait for the current operations to finish';
  if (disabled) {
    return `You cannot update a different data product, please use the same data product '${
      entity.metadata.name
    }' and version ${entity.spec?.mesh?.version ?? ''}`;
  }
  return (
    UPDATE_TOOLTIP +
    (branches
      ? `. These are the branches involved: ${branches?.join(',')}`
      : '')
  );
}

export const UpdateButton: React.FC<Props> = ({ release }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const { entity, isCommitting, setIsCommitting, isLoading, addRelease } =
    useReleasePageContext();
  const panelCatalog = useApi(panelCatalogApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);

  const navigate = useNavigate();
  const releaseDetailRoute = useRouteRef(releaseDetailRouteRef);
  const createdFrom = release?.metadata?.createdFrom;
  const setBranches = new Set(
    createdFrom?.map(cf => cf.branch.replace('%2F', '/')),
  );

  const { allowed: canCommit } = usePermission({
    permission: builderDpCommitPermission,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  const [_, commitSnapshot] = useAsyncFn(async () => {
    setIsCommitting(true);
    try {
      const newRelease = await panelCatalog.commitSnapshot(
        entity.metadata.name,
        await identityApi.getCredentials(),
        createdFrom,
      );
      addRelease({ ...newRelease, deployments: release.deployments });

      const { kind, namespace, name } = getReleaseInfo(newRelease);

      alertApi.post({
        actions: (
          <WbLink
            route={releaseDetailRoute({ kind, namespace, name })}
            text={`Open V${newRelease?.metadata?.version}`}
            navigate={navigate}
          />
        ),

        message: 'Draft-Release updated.',
        severity: 'success',
      });
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      setIsCommitting(false);
      setOpenDialog(false);
    }
  }, [
    addRelease,
    alertApi,
    entity.metadata.name,
    identityApi,
    panelCatalog,
    setIsCommitting,
    release.deployments,
  ]);

  return (
    <>
      <Tooltip
        title={generateUpdateTooltip(
          canCommit,
          false,
          false,
          isLoading,
          entity as WitboostVersionedEntity,
          [...setBranches],
        )}
      >
        <span>
          <WbCardActionButton
            label="Update"
            color="primary"
            size="small"
            loading={isCommitting}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpenDialog(true);
            }}
            disabled={!canCommit || isLoading}
          />
        </span>
      </Tooltip>
      <ConfirmDialog
        title="A draft release already exists!"
        description={
          <>
            <Typography variant="body2">
              By updating you will lose the current draft release and a new one
              will be created.
            </Typography>
            <Typography variant="body2" style={{ fontWeight: '700' }}>
              Are you sure you want to proceed with the update?
            </Typography>
          </>
        }
        open={openDialog}
        onConfirm={commitSnapshot}
        onClose={() => setOpenDialog(false)}
        inProgress={isCommitting}
        confirmButtonText="Yes, update"
      />
    </>
  );
};
