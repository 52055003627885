import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '../../../api';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import { ProvisioningRequest } from '@agilelab/plugin-wb-builder-backend';
import {
  Action,
  DeploymentUnitStatus,
  generateURNByKind,
  ProvisioningPreviewResponseOperation,
  Reason,
  TargetStatus,
} from '@agilelab/plugin-wb-builder-common';
import {
  ComponentWithStatus,
  DeploymentPreview,
  DeploymentPreviewData,
} from '../types';
import yaml from 'yaml';
import { useCallback, useState } from 'react';

const getMatchedComponents = (
  components: ComponentWithStatus[],
  operations: ProvisioningPreviewResponseOperation[],
): DeploymentPreview[] =>
  components
    .filter(c => operations.find(op => op.componentId === c.id))
    .map(c => {
      const operation = operations.find(op => op.componentId === c.id);

      return {
        ...c,
        newState: operation?.targetStatus,
        action: operation?.action,
      };
    });

const getNotInTargetDescriptorOperations = (
  operations: ProvisioningPreviewResponseOperation[],
) =>
  operations.filter(
    op =>
      op.reason === Reason.NOT_IN_TARGET_DESCRIPTOR ||
      op.additionalReasons?.includes(Reason.NOT_IN_TARGET_DESCRIPTOR),
  );

const getCleanUpOperations = (
  operations: ProvisioningPreviewResponseOperation[],
) =>
  operations.filter(
    op =>
      op.reason === Reason.CLEANUP ||
      op.additionalReasons?.includes(Reason.CLEANUP),
  );

export function useDeploymentPreviews() {
  const {
    releaseDetailAction,
    components,
    release,
    currentDescriptor,
    entity,
    queryParamEnvironment,
    environment,
    deploymentUnitStatusState,
    dataProductName,
  } = useReleaseDetailPageContext();

  const identityApi = useApi(identityApiRef);
  const panelCatalogApi = useApi(panelCatalogApiRef);

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DeploymentPreviewData | undefined>();
  const [error, setError] = useState<Error>();

  const loadData = useCallback(
    async (targetStatus?: ProvisioningRequest['targetStatus']) => {
      if (releaseDetailAction && deploymentUnitStatusState.value) {
        setError(undefined);
        setLoading(true);
        const rootComponentId = generateURNByKind(entity.metadata.name, 'dp');

        try {
          const entityTarget: ProvisioningRequest['targetStatus'][0] = {
            action: releaseDetailAction,
            targetStatus:
              releaseDetailAction === Action.DEPLOY
                ? 'DEPLOYED'
                : 'NOT_DEPLOYED',
            componentId: rootComponentId,
          };

          const componentTargets: ProvisioningRequest['targetStatus'] =
            components.map(c => ({
              action:
                releaseDetailAction === Action.DEPLOY
                  ? Action.DEPLOY
                  : Action.UNDEPLOY,
              targetStatus:
                releaseDetailAction === Action.DEPLOY
                  ? TargetStatus.DEPLOYED
                  : TargetStatus.NOT_DEPLOYED,
              componentId: c.id,
            }));

          const deploymentUnits =
            await panelCatalogApi.getDeploymentUnitsProvisioningPreview(
              generateURNByKind(release.metadata.dataProductName, 'system'),
              queryParamEnvironment || environment.name,
              {
                descriptor: yaml.parse(currentDescriptor),
                targetStatus: targetStatus || [
                  entityTarget,
                  ...componentTargets,
                ],
                removeData: false,
                preventRedeploy: false,
              },
              await identityApi.getCredentials(),
            );

          const rootComponentOperation = deploymentUnits.operations.find(
            op => op.componentId === rootComponentId,
          );

          const rootComponent: DeploymentPreview = {
            kind: 'Data Product',
            name: dataProductName,
            status:
              (deploymentUnitStatusState.value.provisioningDetails
                ?.componentsStatus[0].status as DeploymentUnitStatus) ??
              DeploymentUnitStatus.NOT_DEPLOYED,
            statusVersion:
              deploymentUnitStatusState.value.provisioningDetails
                ?.componentsStatus[0].descriptorVersion ??
              release.metadata.version,
            action: rootComponentOperation?.action,
            newState: rootComponentOperation?.targetStatus,
            id: rootComponentId,
            description: entity.metadata.description || '',
            fullyQualifiedName: null,
            version: release.metadata.version,
            infrastructureTemplateId: '',
            useCaseTemplateId: '',
            dependsOn: rootComponentOperation?.dependantOn || [],
            platform: '',
            technology: '',
            outputPortType: '',
            creationDate: new Date(release.metadata.createdAt),
            startDate: new Date(release.metadata.createdAt),
            processDescription: null,
            tags: entity.metadata.tags || [],
          };

          const newData = {
            previews: getMatchedComponents(
              components.map(c => ({
                ...c,
                dependsOn: [...c.dependsOn, rootComponentId],
              })),
              deploymentUnits.operations,
            ),
            notInTargetDescriptorOperations: getNotInTargetDescriptorOperations(
              deploymentUnits.operations,
            ).map(op => op.componentId),
            cleanUpOperations: getCleanUpOperations(
              deploymentUnits.operations,
            ).map(op => op.componentId),
            rootComponent: rootComponentOperation ? rootComponent : undefined,
            outcome: deploymentUnits.outcome,
          };

          setData(newData);
        } catch (e) {
          setError(e);
          return;
        } finally {
          setLoading(false);
        }
      }
      return;
    },
    [
      components,
      currentDescriptor,
      dataProductName,
      deploymentUnitStatusState,
      entity,
      environment.name,
      identityApi,
      panelCatalogApi,
      queryParamEnvironment,
      release,
      releaseDetailAction,
    ],
  );

  return { data, loadData, loading, error };
}
