/* eslint-disable no-console */
import { NotificationsIcon } from '@agilelab/plugin-wb-notification';
import {
  CustomMicrofrontendOptions,
  CustomMicrofrontendTarget,
  WbAppToolbar,
  WbAppToolbarItem,
  customFrontendApiRef,
  useEnabledFeatures,
  useHomeUrl,
} from '@agilelab/plugin-wb-platform';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import BallotIcon from '@material-ui/icons/Ballot';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import React, { PropsWithChildren } from 'react';
import { SearchModal } from '../search/SearchModal';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import {
  BlueprintIcon,
  BuilderIcon,
  CreateIcon,
  DpCatalogIcon,
  DpGraphIcon,
  GovernanceIcon,
  GovernanceRegistryIcon,
  GovernanceSearchResultsIcon,
  MarketplaceIcon,
  MeshSupervisionIcon,
  MyDataProductIcon,
  PoliciesOverviewIcon,
  SoftwareCatalogIcon,
} from './Icons/Icons';
import { practiceShaperApiRef } from '@agilelab/plugin-wb-practice-shaper';
import { MaintenancePage } from '@agilelab/plugin-wb-platform';
import { useQuery } from '@tanstack/react-query';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    layout: {},
  }),
);

const Layout = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles();
  return <Box className={classes.layout}>{children}</Box>;
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { loading: enabledFeaturesLoading, data: features } =
    useEnabledFeatures();
  const practiceShaperApi = useApi(practiceShaperApiRef);
  const customFrontendApi = useApi(customFrontendApiRef);
  const identityApi = useApi(identityApiRef);

  const { homeURL } = useHomeUrl();

  const { data: isMaintenanceActive } = useQuery({
    queryKey: ['maintenanceActive'],
    queryFn: async () => {
      return await practiceShaperApi.isMaintenanceActive(
        await identityApi.getCredentials(),
      );
    },
  });

  if (enabledFeaturesLoading) {
    return <></>;
  }

  if (isMaintenanceActive) {
    return <MaintenancePage />;
  }

  const getCustomFrontends = customFrontendApi.makeCustomMicrofrontends(
    CustomMicrofrontendTarget.MarketplacePage,
  );
  const customPages: CustomMicrofrontendOptions[] = getCustomFrontends();

  return (
    <Layout>
      <WbAppToolbar
        homeURL={homeURL}
        searchButton={<SearchModal />}
        notificationsIcon={<NotificationsIcon />}
        showPlatformSettings={features.platformSettingsEnabled}
      >
        <WbAppToolbarItem
          title="Marketplace"
          icon={MarketplaceIcon}
          pathMatch={['marketplace']}
          menuItems={[
            ...(features.meshSupervisionEnabled
              ? [
                  {
                    icon: MeshSupervisionIcon,
                    title: 'Mesh Supervision',
                    to: 'marketplace/dashboard',
                  },
                ]
              : []),
            {
              icon: DpCatalogIcon,
              title: 'Data Product Catalog',
              to: 'marketplace/products-catalog',
            },
            {
              icon: DpGraphIcon,
              title: 'Data Product Graph',
              to: 'marketplace/products-graph',
            },
            ...(features.dataContractsEnabled
              ? [
                  {
                    icon: AccountTreeOutlinedIcon,
                    title: 'Data Contracts',
                    to: 'marketplace/data-contracts',
                  },
                ]
              : []),
            ...customPages.map(page => {
              return {
                icon: BallotIcon,
                title: page.title,
                to: `marketplace${page.route}`,
              };
            }),
          ]}
        />
        {features.builderEnabled && (
          <WbAppToolbarItem
            title="Builder"
            icon={BuilderIcon}
            pathMatch={['builder', 'catalog', 'create', 'wb-scaffolder']}
            menuItems={[
              ...(features.templatesEnabled
                ? [
                    {
                      icon: CreateIcon,
                      title: 'Templates',
                      to: 'create',
                    },
                  ]
                : []),
              ...(features.blueprintsEnabled
                ? [
                    {
                      icon: BlueprintIcon,
                      title: 'Blueprints',
                      to: '/wb-scaffolder/blueprints',
                    },
                  ]
                : []),
              ...(features.catalogEnabled
                ? [
                    {
                      icon: MyDataProductIcon,
                      title: 'My Data Products',
                      to: 'catalog/my-data-products',
                    },
                    {
                      icon: SoftwareCatalogIcon,
                      title: 'Software Catalog',
                      to: 'catalog',
                    },
                  ]
                : []),
            ]}
          />
        )}
        {features.governanceEnabled && (
          <WbAppToolbarItem
            title="Governance"
            icon={GovernanceIcon}
            pathMatch={['governance']}
            menuItems={[
              {
                icon: GovernanceRegistryIcon,
                title: 'Registry',
                to: 'governance/registry',
              },
              {
                icon: PoliciesOverviewIcon,
                title: 'Policies Overview',
                to: 'governance/policies-overview',
              },
              {
                icon: GovernanceSearchResultsIcon,
                title: 'Search Results',
                to: 'governance/search-results',
              },
            ]}
          />
        )}
        {features.externalResourcesEnabled && (
          <WbAppToolbarItem
            title="Data Sharing"
            icon={UnarchiveIcon}
            pathMatch={['external-resources']}
            menuItems={[
              {
                icon: FolderIcon,
                title: 'Dashboard',
                to: '/external-resources/dashboard',
              },
              {
                icon: FolderIcon,
                title: 'Package List',
                to: '/external-resources',
              },
            ]}
          />
        )}
      </WbAppToolbar>
      {children}
    </Layout>
  );
};
