/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Grid } from '@material-ui/core';
import React from 'react';
import { UserSettingsProfileCard } from './UserSettingsProfileCard';
import { UserSettingsIdentityCard } from './UserSettingsIdentityCard';
import {
  RolesTable,
  PermissionsTable,
  RoleContextProvider,
  PermissionContextProvider,
} from '@agilelab/plugin-wb-rbac';
import { WbCard } from '@agilelab/plugin-wb-platform';

/** @public */
export const UserSettingsGeneral = () => {
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} md={6}>
        <UserSettingsProfileCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <UserSettingsIdentityCard />
      </Grid>
      <Grid item xs={12} md={12}>
        <WbCard title="Roles">
          <RoleContextProvider>
            <RolesTable />
          </RoleContextProvider>
        </WbCard>
      </Grid>
      <Grid item xs={12} md={12}>
        <WbCard title="Permissions">
          <PermissionContextProvider>
            <PermissionsTable />
          </PermissionContextProvider>
        </WbCard>
      </Grid>
    </Grid>
  );
};
