import React from 'react';
import {
  Button,
  CircularProgress,
  Box,
  SvgIconProps,
  ButtonProps,
  Popover,
  capitalize,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { WbDivider } from '../WbDivider';

type Props = ButtonProps & {
  icon?: React.ReactElement<SvgIconProps>;
  iconColor?: string;
  label: React.ReactNode;
  loading?: boolean;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  selectProps?: SelectProps;
  select?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.bkg.primary,
    },
  },
}));

interface SelectProps {
  options: any[];
  optionClick: (value?: any) => void;
}

export const WbCardActionButtonComponent = (
  {
    loading = false,
    icon,
    label,
    onClick,
    color = 'primary',
    iconColor,
    variant = 'contained',
    style,
    select = false,
    selectProps,
    ...props
  }: Props,
  ref: React.Ref<HTMLDivElement>,
) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div ref={ref}>
      <Button
        onClick={onClick}
        variant={variant}
        color={color}
        size="small"
        style={{ position: 'relative', whiteSpace: 'nowrap', ...style }}
        {...props}
      >
        {loading && (
          <Box
            style={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CircularProgress
              size={20}
              thickness={8}
              color="secondary"
              style={{ color: 'inherit' }}
            />
          </Box>
        )}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            visibility: loading ? 'hidden' : 'visible',
          }}
        >
          {icon &&
            React.cloneElement(icon, {
              htmlColor: iconColor ?? 'inherit',
              fontSize: 'small',
            })}

          <Typography
            variant="body2"
            style={{ marginLeft: icon ? '4px' : '0px', fontWeight: 500 }}
          >
            {label}
          </Typography>
          {select && (
            <>
              <WbDivider height="20px" />
              <Box display="flex" alignItems="center" onClick={handleClick}>
                <ArrowDropDownIcon fontSize="small" />
              </Box>
              {selectProps && (
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {selectProps.options?.map(op => (
                      <Box
                        className={classes.option}
                        onClick={() => {
                          selectProps.optionClick(op);
                          handleClose();
                        }}
                      >
                        {capitalize(op)}
                      </Box>
                    ))}
                  </Box>
                </Popover>
              )}
            </>
          )}
        </Box>
      </Button>
    </div>
  );
};

export const WbCardActionButton = React.memo(
  React.forwardRef(WbCardActionButtonComponent),
) as typeof WbCardActionButtonComponent;
