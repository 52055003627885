import { gql } from '@apollo/client/core';

export const GET_DP_TOTAL = gql`
  query GetDpTotal {
    DataProductInstance_aggregate: marketplace_DataProductInstance_aggregate(
      where: { DataProduct: { type: { _eq: "dataproduct" } } }
    ) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

export const ALIGNMENT_CHART_DATA = gql`
  query AlignmentChartData {
    DataProductInstance: marketplace_DataProductInstance(
      where: {
        _and: [
          { Environment: { priority: { _eq: 0 } } }
          { InputPorts: { kind: { _is_null: false } } }
          {
            InputPorts: {
              OutputPort: {
                DataProductInstance: {
                  DataProduct: { type: { _eq: "dataproduct" } }
                }
              }
            }
          }
          {
            InputPorts: {
              DataProductInstance: {
                DataProduct: { type: { _eq: "dataproduct" } }
              }
            }
          }
        ]
      }
    ) {
      published_at
      InputPorts {
        kind
      }
    }
  }
`;

export const NETWORK_EFFECT = gql`
  query NetworkEffect {
    DataProductInstance: marketplace_DataProductInstance(
      where: {
        _and: [
          { Environment: { priority: { _eq: 0 } } }
          { DataProduct: { type: { _eq: "dataproduct" } } }
        ]
      }
    ) {
      published_at
      InputPorts_aggregate(
        where: {
          OutputPort: {
            DataProductInstance: {
              DataProduct: { type: { _eq: "dataproduct" } }
            }
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_ALL_DATA_PRODUCTS_REVIEWS = gql`
  query GetAllDataProductsReviews {
    DataProductInstance: marketplace_DataProductInstance(
      where: { DataProduct: { type: { _eq: "dataproduct" } } }
    ) {
      Reviews {
        score
      }
    }
    Review_aggregate: marketplace_Review_aggregate(
      where: {
        DataProductInstance: { DataProduct: { type: { _eq: "dataproduct" } } }
      }
    ) {
      aggregate {
        avg {
          score
        }
      }
    }
  }
`;

export const COUNT_DATA_PRODUCTS_GROUP_BY_ENV = gql`
  query CountDataProductsGroupByEnv {
    marketplace_Environment(order_by: { priority: asc }) {
      DataProductInstances_aggregate(
        where: { DataProduct: { type: { _eq: "dataproduct" } } }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    marketplace_DataProductInstance_aggregate(
      where: { DataProduct: { type: { _eq: "dataproduct" } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const DATA_PRODUCTS_USAGE = gql`
  query DataProductsUsage {
    DataProductInstance_aggregate: marketplace_DataProductInstance_aggregate(
      where: {
        _and: [
          { DataProduct: { type: { _eq: "dataproduct" } } }
          { Environment: { priority: { _eq: 0 } } }
        ]
      }
    ) {
      aggregate {
        count(distinct: true)
      }
    }
    InputPort_aggregate: marketplace_DataProductInstance_aggregate(
      where: {
        _and: [
          { DataProduct: { type: { _eq: "dataproduct" } } }
          { Environment: { priority: { _eq: 0 } } }
          { OutputPorts: { InputPorts: { id: { _is_null: false } } } }
        ]
      }
    ) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`;

export interface ValueByMonth {
  monthOfYear: string; // in the format MMM YYYY
  value: number;
}

export interface AlignmentByMonth {
  monthOfYear: string;
  totalSources: number;
  totalConsumers: number;
}
