import { createElement, FunctionComponent, ReactNode } from 'react';
import _ from 'lodash';
import yaml from 'yaml';

export const dumpJsx = (node: any) => {
  const dump: Record<string, any> = {};
  Object.keys(node.props || {}).forEach(p => {
    if (p !== 'children') dump[p] = node.props[p];
  });
  if (node.props.children) {
    if (node.props.children instanceof Array)
      dump.children = node.props.children.map((n: any) => dumpJsx(n));
    else dump.children = [dumpJsx(node.props.children)];
  }
  return dump;
};

export const dumpJsxAsYaml = (node: any): string => {
  return yaml.stringify({ view: { children: [dumpJsx(node)] } });
};
export const buildJsx = (
  CustomView: FunctionComponent,
  view: any,
): ReactNode => {
  const children = (view.children || []).map((c: ReactNode) =>
    buildJsx(CustomView, c),
  );
  if (!view.type) return children; // top level node
  const nodeProps: Record<string, any> = _.omit(Object.assign({}, view), [
    'children',
  ]);
  // this is required for make the test working...
  if (children.length > 0)
    return createElement(CustomView, nodeProps, children);
  return createElement(CustomView, nodeProps);
};
