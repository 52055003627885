import { ReleaseEntityV1alpha1 } from '@agilelab/plugin-wb-builder-common';

export function getReleaseInfo(release: ReleaseEntityV1alpha1) {
  const namespace = release.metadata.namespace || 'default';
  const name = release.metadata.name;
  const kind = 'Release';
  return {
    namespace,
    name,
    kind,
  };
}
