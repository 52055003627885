import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, Theme, Box, Drawer, Typography } from '@material-ui/core';
import {
  GenericField,
  WbCard,
  WbCardContent,
  WbLogsCard,
} from '@agilelab/plugin-wb-platform';
import { Log, isRunningStatus } from '@agilelab/plugin-wb-builder-common';
import {
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '../../api';
import { useReleaseDetailPageContext } from './context/useReleaseDetailPageContext';
import { extractDagName, getErrors, getParsedResultField } from './utils';
import { useStepIcons } from './hooks/useStepIcons';
import { useDagStatusColor } from './hooks/useDagStatusColor';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    height: '100%',
    width: '50%',
  },
  drawerContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& svg': {
      width: '24px',
      height: '24px',
      marginRight: theme.spacing(1),
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.bkg.primary,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: 0,
  },
  resultWrapper: {
    position: 'relative',
    height: 'calc(100% - 150px)',
    '& > svg': {
      position: 'absolute',
      bottom: theme.spacing(3),
      left: '50%',
      transform: 'translateX(-50%)',
      width: `300px`,
      height: `300px`,
      fillOpacity: '0.2',
      strokeOpacity: '0.2',
    },
  },
  codeSnippetWrapper: {
    height: '100%',
    '& > div': {
      overflow: 'auto',
      height: '100%',
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    gap: 50,
    flexWrap: 'wrap',
  },
}));

export const DeployStepDetailDrawer = () => {
  const dagStatusColors = useDagStatusColor();
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const stepIcons = useStepIcons();
  const { selectedStep, selectedDeploy, setSelectedStep } =
    useReleaseDetailPageContext();

  const result = useMemo(() => {
    if (selectedStep) {
      const res = selectedStep.result;
      if (res) {
        try {
          return JSON.stringify(JSON.parse(res), null, 2);
        } catch (_) {
          return res;
        }
      }
    }
    return null;
  }, [selectedStep]);

  const errors = getErrors(result);
  const parsedResult = getParsedResultField(result, configApi);

  const [logs, setLogs] = useState<Log[]>([]);
  const panelCatalog = useApi(panelCatalogApiRef);
  const identityApi = useApi(identityApiRef);

  useEffect(() => {
    if (selectedDeploy) {
      const loadData = async () => {
        const logsResponse = await panelCatalog.getLogs(
          selectedDeploy.id,
          selectedDeploy.id,
          await identityApi.getCredentials(),
        );
        setLogs(logsResponse);
      };

      loadData();
    }
  }, [identityApi, selectedDeploy, panelCatalog]);

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classes.drawerPaper,
      }}
      PaperProps={{
        style: {
          width: '80%',
        },
      }}
      transitionDuration={200}
      open={!!selectedStep}
      onClose={() => setSelectedStep(undefined)}
    >
      <Box className={classes.drawerContent}>
        {selectedStep && (
          <>
            <Box className={classes.header}>
              {stepIcons[selectedStep.status]}
              <Typography color="primary" variant="h6">
                {extractDagName(selectedStep)}
              </Typography>
            </Box>

            <WbCard title="Details">
              <WbCardContent>
                <Box className={classes.row}>
                  <GenericField
                    label="Start Time"
                    value={selectedStep.startTime}
                    type="date"
                  />
                  {!isRunningStatus(selectedStep.status) &&
                    selectedStep.stopTime && (
                      <GenericField
                        label="Stop Time"
                        value={selectedStep.stopTime}
                        type="date"
                      />
                    )}
                  <GenericField
                    label="Status"
                    value={selectedStep.status}
                    type="text"
                    contentProps={{
                      style: { color: dagStatusColors[selectedStep.status] },
                    }}
                  />
                  <GenericField
                    label="Action"
                    value={selectedStep.action}
                    type="text"
                  />
                </Box>
              </WbCardContent>
            </WbCard>
            {result && (
              <WbLogsCard
                title="Result"
                text={errors?.join('\n') || parsedResult || result}
                style={{
                  height: '100%',
                  marginTop: '16px',
                  overflow: 'auto',
                }}
                language="go"
              />
            )}
            <WbLogsCard
              title="Log File"
              logs={logs}
              style={{ height: '100%', marginTop: '16px', overflow: 'auto' }}
            />
          </>
        )}
      </Box>
    </Drawer>
  );
};
