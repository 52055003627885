import { CatalogClient } from '@backstage/catalog-client';
import {
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { humanizeEntityRef } from '@backstage/plugin-catalog-react';

export type UserEntity = {
  username: string;
  displayName: string;
  loading: boolean;
  error: Error | undefined;
};

/**
 * A standard way to get access to userEntity username and displayName
 * @returns string username or undefined in case of error
 */
export function useUsername(): UserEntity {
  const discoveryApi = useApi(discoveryApiRef);
  const catalogClient = new CatalogClient({ discoveryApi });

  const { backstageIdentity, token } = useFetchUserInfo();

  const userEntityRequest = useAsync(async () => {
    if (backstageIdentity && token) {
      return await catalogClient.getEntityByRef(
        backstageIdentity.userEntityRef,
        {
          token,
        },
      );
    }
    return undefined;
  }, [backstageIdentity, token]);

  return {
    loading: userEntityRequest.loading,
    error: userEntityRequest.error,
    username: userEntityRequest.value
      ? humanizeEntityRef(userEntityRequest.value)
      : undefined,
    displayName: userEntityRequest.value
      ? (userEntityRequest.value.spec?.profile as any).displayName ?? ''
      : undefined,
  } as UserEntity;
}

/**
 * React Hook for getting signed in user's groups.
 * @returns
 */
export function useFetchUserInfo(): any {
  const identityApi = useApi(identityApiRef);

  const identityResult = useAsync(async () => {
    return {
      backstageIdentity: await identityApi.getBackstageIdentity(),
      token: (await identityApi.getCredentials()).token,
    };
  }, [identityApi]);

  if (!identityResult.value || identityResult.loading || identityResult.error) {
    return {
      backstageIdentity: undefined,
      token: undefined,
    };
  }

  return identityResult.value;
}

/**
 * This method is used to fetch logged user name.
 * @returns
 */
export function useLoggedUsername(): string | undefined {
  const { backstageIdentity, token } = useFetchUserInfo();
  const discoveryApi = useApi(discoveryApiRef);
  const catalogClient = new CatalogClient({ discoveryApi });

  const { value, error, loading } = useAsync(async () => {
    if (backstageIdentity && token) {
      return await catalogClient.getEntityByRef(
        backstageIdentity.userEntityRef,
        { token },
      );
    }

    return undefined;
  }, [backstageIdentity, token]);

  if (!value || error || loading) {
    return undefined;
  }

  return humanizeEntityRef(value);
}

/**
 * This method transform an entityRef into a Backstage EntityName type.
 * The entityRef must be a string of the following structure <kind>:<namespace>/<name>.
 * @param entityRef
 * @returns
 */
export function getEntityNameByRef(entityRef: string) {
  const entityRefCheckerRegex = /[a-zA-Z]+:[a-zA-Z]+\/[a-zA-Z._0-9]+$/;

  if (!entityRefCheckerRegex.test(entityRef))
    return { kind: 'user', namespace: 'default', name: entityRef };

  const entityRefRegex = /:|\//;

  const result = entityRef.split(entityRefRegex);

  return { kind: result[0], namespace: result[1], name: result[2] };
}
