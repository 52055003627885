import { JsonArray, JsonObject, JsonValue } from '@backstage/types';

export function isJsonObject(value: JsonValue): value is JsonObject {
  return (
    typeof (value as JsonObject) === 'object' && (value as JsonObject) !== null
  );
}

export function isJsonArray(value: JsonValue | undefined): value is JsonArray {
  return value !== undefined && Array.isArray(value as JsonArray);
}

export function isTagArray(
  value: JsonArray,
): value is Array<{ tagFQN: string }> {
  return (value as Array<{ tagFQN: string }>).every(t => t.tagFQN);
}
