import { Typography } from '@material-ui/core';
import React from 'react';

interface ErrorLinkProps {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  title: string;
}

export const ErrorLink: React.FC<ErrorLinkProps> = ({
  onClick,
  title,
  ...otherProps
}) => (
  <Typography
    color="primary"
    variant="body2"
    component="div"
    role="link"
    tabIndex={0}
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    {...otherProps}
  >
    {title}
  </Typography>
);
