import { DiscoveryApi } from '@backstage/core-plugin-api';
import { fetch as crossfetch } from 'cross-fetch';
import {
  ACLObject,
  ACLRequestObject,
  ACLSystemOwned,
  GetACLBySystemUrnResponse,
  RequestAndACLsByOp,
  RequestAndACLsByRef,
} from '../zodSchema/ACLObject';

/**
 * Wrapper that calls exposed ACL POST and GET routes from marketplace.
 */

export interface AccessControlListApi {
  getACLs(idComponent: string, token: string | undefined): Promise<ACLObject[]>;

  getACLRequestsByRefs(
    idComponent: string,
    refs: string[],
    requestId: string,
    token: string,
  ): Promise<ACLRequestObject[]>;

  getACLRequestRow(
    idComponent: string,
    ref: string,
    requestId: string,
    token: string,
  ): Promise<ACLRequestObject>;

  getACLAndRequestsByOp(
    idComponent: string,
    token: string,
  ): Promise<RequestAndACLsByOp>;

  getACLAndRequestsByRef(
    ref: string,
    token: string,
    environment: string,
    dataProductInstanceId?: number,
  ): Promise<RequestAndACLsByRef>;

  getACLSystemsOwnedByRefs(
    refs: string[],
    token: string,
    environment: string,
  ): Promise<ACLSystemOwned[]>;

  getACLBySystemUrn(
    systemUrn: string,
    token: string,
  ): Promise<GetACLBySystemUrnResponse>;

  addACLs(objects: any, token: string): Promise<any>;

  upsertACLRequest(objects: any, token: string): Promise<any>;
}

export class AccessControlListClient implements AccessControlListApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly fetchFn: typeof fetch = crossfetch,
  ) {}

  async addACLs(objects: any, token: string): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchFn(`${baseUrl}/ACL`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ objects: objects }),
    });

    if (!response.ok) {
      throw new Error(`Unable to add ACL, reason: ${response.statusText}`);
    }

    return response.json();
  }

  async upsertACLRequest(objects: any, token: string): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchFn(`${baseUrl}/ACLRequests`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ objects: objects }),
    });

    if (!response.ok) {
      throw new Error(
        `Unable to upsert the ACL Requests rows, reason: ${response.statusText}`,
      );
    }

    return response.json();
  }

  async getACLs(
    idComponent: string,
    token: string | undefined,
  ): Promise<ACLObject[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchFn(`${baseUrl}/ACL/${idComponent}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(
        `This field has been disabled due to a network error. Please try again or contact the platform team. HTTP Response: ${response.statusText}.`,
      );
    }

    return response.json();
  }

  async getACLRequestsByRefs(
    idComponent: string,
    refs: string[],
    requestId: string,
    token: string,
  ): Promise<ACLRequestObject[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const data = {
      idComponent,
      refs,
      requestId,
    };

    const response = await this.fetchFn(`${baseUrl}/ACLRequestsByRefs`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(
        `Unable to get ACL_Request with output port id ${idComponent}, reason: ${response.statusText}`,
      );
    }

    return response.json();
  }

  async getACLRequestRow(
    idComponent: string,
    ref: string,
    requestId: string,
    token: string,
  ): Promise<ACLRequestObject> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchFn(
      `${baseUrl}/ACLRequests/${idComponent}/${ref}/${requestId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `Unable to get ACL_Request row with output port id ${idComponent}, reason: ${response.statusText}`,
      );
    }

    return response.json();
  }

  async getACLAndRequestsByOp(
    idComponent: string,
    token: string,
  ): Promise<RequestAndACLsByOp> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchFn(
      `${baseUrl}/ACLAndRequestsByOp/${idComponent}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `Unable to get ACL_Request and ACLs with output port id ${idComponent}, reason: ${response.statusText}`,
      );
    }

    return response.json();
  }

  async getACLAndRequestsByRef(
    ref: string,
    token: string,
    environment: string,
    dataProductInstanceId?: number,
  ): Promise<RequestAndACLsByRef> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchFn(
      `${baseUrl}/ACLAndRequestsByRef/${ref}?environment=${environment}&dataProductInstanceId=${dataProductInstanceId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `Unable to get ACL_Request and ACLs with ref ${ref}, reason: ${response.statusText}`,
      );
    }

    return response.json();
  }

  async getACLBySystemUrn(
    systemUrn: string,
    token: string,
  ): Promise<GetACLBySystemUrnResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchFn(
      `${baseUrl}/ACL/by-system/${systemUrn}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `Unable to get ACL by system URN ${systemUrn}, reason: ${response.statusText}`,
      );
    }

    const result: ACLObject[] = await response.json();
    return result.flatMap(aclobject => ({
      userRef: aclobject.refs,
      idOutputPort: aclobject.id_output_port,
    }));
  }

  async getACLSystemsOwnedByRefs(
    refs: string[],
    token: string,
    environment: string,
  ): Promise<ACLSystemOwned[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');
    const body = {
      environment,
      refs,
    };
    const response = await this.fetchFn(`${baseUrl}/ACLSystemsOwnedByRefs`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(
        `Unable to get ACL Systems Owned with body ${JSON.stringify(
          body,
        )}, reason: ${response.statusText}`,
      );
    }

    return response.json();
  }
}
