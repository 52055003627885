import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import React, { useEffect, useState } from 'react';
import { EntityRefBasePicker } from '../EntityRefPicker';
import useAsync from 'react-use/lib/useAsync';
import { ACCESS_CONTROL_CONTEXT_FORM_CONTEXT_PATH } from '../DescriptorPicker/sourceTypes';
import { z } from 'zod';
import { fromZodError } from 'zod-validation-error';
import { accessControlListApiRef } from '@agilelab/plugin-wb-marketplace';
import { ACLObject } from '@agilelab/plugin-wb-marketplace-common';
import { FieldProps } from '@rjsf/core';
import { extractCustomProperties, isHidden } from '../../utils';

const AccessControlListPickerConfigZod = z.object({
  max: z.number().min(1).optional(),
});

export type AccessControlListPickerConfig = z.infer<
  typeof AccessControlListPickerConfigZod
>;

export const AccessControlListPicker = ({
  name,
  formContext,
  onChange,
  required,
  disabled,
  schema,
  idSchema,
  placeholder,
  uiSchema,
}: FieldProps<string>) => {
  const accessControlListClient = useApi(accessControlListApiRef);
  const identityApi = useApi(identityApiRef);
  const [configError, setConfigError] = useState<Error | undefined>(undefined);
  const [config, setConfig] = useState<AccessControlListPickerConfig>({});
  const customProps = extractCustomProperties(uiSchema);

  useEffect(() => {
    const result = AccessControlListPickerConfigZod.safeParse(
      uiSchema ? uiSchema['ui:options'] ?? {} : {},
    );
    if (result.success) {
      setConfig(result.data);
    } else {
      const errorMessage = `This field has been disabled due to a misconfiguration error. ${
        fromZodError(result.error).message
      }. Please contact the platform team
          to address this issue.`;
      setConfigError(new Error(errorMessage));
    }
  }, [uiSchema]);

  const { value: options } = useAsync(async () => {
    if (
      !formContext[ACCESS_CONTROL_CONTEXT_FORM_CONTEXT_PATH]?.id_output_port
    ) {
      setConfigError(
        new Error(
          'This field has been disabled due to a misconfiguration error. Please contact the platform team. No output port was found in the context.',
        ),
      );
      return [];
    }

    const outputPortId =
      formContext[ACCESS_CONTROL_CONTEXT_FORM_CONTEXT_PATH].id_output_port;
    try {
      const response = (await accessControlListClient.getACLs(
        outputPortId,
        (
          await identityApi.getCredentials()
        ).token,
      )) as ACLObject[];
      return response
        .filter(item => !item.locked)
        .map(item => item.refs)
        .sort((a, b) => a.localeCompare(b));
    } catch (e) {
      setConfigError(e);
      return [];
    }
  }, [formContext]);

  return (
    <EntityRefBasePicker
      options={options ?? []}
      hidden={isHidden(uiSchema)}
      prefillValues={formContext[name] ?? []}
      onChange={onChange}
      maxItems={config.max}
      required={required}
      disabled={disabled || (configError !== undefined ? true : false)}
      title={schema.title ?? 'Access Control List'}
      helperText={schema.description ?? 'Select the identities'}
      error={configError ? true : false}
      additionalErrors={configError ? [configError.message] : undefined}
      placeholder={placeholder}
      fieldId={idSchema.$id}
      customProps={customProps}
    />
  );
};
