import {
  Grid,
  makeStyles,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import React, { CSSProperties } from 'react';
import { WbFieldLabel } from '../WbFieldLabel';
import { ExpandableItem } from './ExpandableItem';
import { SimpleItem } from './SimpleItem';

const useStyle = makeStyles(theme => ({
  text: {
    overflow: 'hidden',
    fontSize: theme.typography.fontSize,
    wordBreak: 'break-word',
  },
  label: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '14px',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  wordBreak: {
    wordBreak: 'break-word',
  },
}));

type Props = {
  label?: string;
  value?: React.ReactNode;
  /**
   * supported types are:
   * 'text' | 'date' | 'string'
   */
  type?: string;
  href?: string;
  textVariant?: 'body1' | 'body2';
  maxLines?: number;
  gridItem?: boolean;
  gridSizes?: Record<string, number>;
  contentProps?: TypographyProps;
  labelStyle?: CSSProperties;
};

const Content = ({
  value,
  type,
  href,
  maxLines,
  textVariant,
  contentProps,
}: Omit<Props, 'label'>) => {
  const classes = useStyle();

  if ((type === 'text' || type === 'string') && typeof value === 'string') {
    return (
      <Typography
        variant={textVariant ?? 'body1'}
        className={classes.wordBreak}
        {...contentProps}
      >
        {maxLines ? (
          <ExpandableItem value={value} href={href} maxLines={maxLines} />
        ) : (
          <SimpleItem value={value} href={href} />
        )}
      </Typography>
    );
  }

  if (type === 'date') {
    return (
      <Typography variant="body1" {...contentProps}>
        {format(parseISO(value as string), 'yyyy/MM/dd HH:mm:ss')}
      </Typography>
    );
  }

  if (typeof value === 'string' || typeof value === 'number') {
    return (
      <Typography variant="body1" {...contentProps}>
        {value}
      </Typography>
    );
  }

  if (typeof value === 'boolean') {
    return (
      <Typography variant="body1" {...contentProps}>
        {value.toString()}
      </Typography>
    );
  }

  return <>{value ?? '-'}</>;
};

export const GenericField = ({
  label,
  gridSizes,
  gridItem = true,
  labelStyle,
  ...content
}: Props) => {
  const wrappedContent = (
    <>
      {label && <WbFieldLabel label={label} style={labelStyle} />}
      <Content {...content} />
    </>
  );

  if (gridItem) {
    return (
      <Grid item {...gridSizes}>
        {wrappedContent}
      </Grid>
    );
  }

  return wrappedContent;
};
