import {
  customAlertApiRef,
  useSelectorsContext,
} from '@agilelab/plugin-wb-platform';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '../../api';
import React, { useState, useEffect } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';

export const EditorPageEnvironmentLoader: React.FC = ({ children }) => {
  const panelCatalog = useApi(panelCatalogApiRef);
  const identifyApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);

  const { environment, setEnvironmentList, setEnvironment } =
    useSelectorsContext();

  const [loading, setLoading] = useState(false);
  const [_, fetchEnvs] = useAsyncFn(async (): Promise<string[]> => {
    return panelCatalog.fetchEnvironments(await identifyApi.getCredentials());
  }, []);

  useEffect(() => {
    setLoading(true);

    fetchEnvs()
      .then(value => {
        const envs =
          value
            ?.map(env => ({
              id: 0,
              name: env,
            }))
            .sort((a, b) => (a.name <= b.name ? -1 : 1)) || [];
        const priorityEnv =
          envs.find(
            env => env.name.toLowerCase() === environment.name.toLowerCase(),
          ) ?? envs.at(0);

        setEnvironmentList(envs);

        if (priorityEnv) {
          setEnvironment(priorityEnv);
        }
      })
      .catch(error => alertApi.post({ error, severity: 'error' }))
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{!loading && children}</>;
};
