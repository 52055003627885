import React from 'react';
import { Navigate } from 'react-router';
import { useHomeUrl } from '../../hooks/useHomeUrl';

export function WbHomeRedirect() {
  const { loading, error, homeURL } = useHomeUrl();

  if (loading) {
    return <></>;
  }

  if (error) {
    return <>{error}</>;
  }

  return <Navigate state={homeURL} to={homeURL} />;
}
