/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  ANNOTATION_LOCATION,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  ScmIntegrationIcon,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  catalogApiRef,
  getEntitySourceLocation,
  useEntity,
} from '@backstage/plugin-catalog-react';
import { usePermission } from '@backstage/plugin-permission-react';
import { Box, IconButton } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import React, { useCallback } from 'react';
import { AboutContent } from './AboutContent';
import { catalogEntityRefreshPermission } from '@backstage/plugin-catalog-common/alpha';
import {
  WbCard,
  WbCardContent,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import { CustomError } from '@agilelab/plugin-wb-platform-common';

/**
 * Exported publicly via the EntityAboutCard
 */
export function AboutCard() {
  const { entity } = useEntity();
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(customAlertApiRef);

  const { allowed: allowRefreshPermission } = usePermission({
    permission: catalogEntityRefreshPermission,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  const entitySourceLocation = getEntitySourceLocation(
    entity,
    scmIntegrationsApi,
  );

  const entityLocation = entity.metadata.annotations?.[ANNOTATION_LOCATION];
  // Limiting the ability to manually refresh to the less expensive locations
  const allowRefresh =
    allowRefreshPermission &&
    (entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:'));
  const refreshEntity = useCallback(async () => {
    try {
      await catalogApi.refreshEntity(stringifyEntityRef(entity));
      alertApi.post({
        message: 'Refresh scheduled',
        severity: 'info',
        timeoutMillis: 5000,
      });
    } catch (error) {
      alertApi.post({
        error: new CustomError(
          'Refresh failed',
          `Entity ${stringifyEntityRef(entity)} not found`,
        ),
        severity: 'error',
      });
    }
  }, [catalogApi, alertApi, entity]);

  return (
    <>
      <WbCard
        cardStyle={{ height: '100%' }}
        title="About"
        actions={
          <Box style={{ display: 'flex' }}>
            <IconButton
              component={Link}
              aria-label="View Source"
              disabled={!entitySourceLocation}
              title="View Source"
              to={entitySourceLocation?.locationTargetUrl ?? '#'}
              size="small"
            >
              <ScmIntegrationIcon
                type={entitySourceLocation?.integrationType}
              />
            </IconButton>
            {allowRefresh && (
              <IconButton
                aria-label="Refresh"
                title="Schedule entity refresh"
                onClick={refreshEntity}
                size="small"
              >
                <CachedIcon />
              </IconButton>
            )}
          </Box>
        }
      >
        <WbCardContent>
          <AboutContent entity={entity} />
        </WbCardContent>
      </WbCard>
    </>
  );
}
