import React, { useState } from 'react';
import { Icon, Button, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { getObjectPropFromString } from '../CatalogTable';
import { CatalogTableRow } from '../types';
import { TableCellProps } from '@agilelab/plugin-wb-platform';
import { TableColumn, TableProps } from '@backstage/core-components';
import MoreVertIcon from '@material-ui/icons/MoreVert';

interface Props {
  columns: TableColumn<CatalogTableRow>[];
  actions: TableProps<CatalogTableRow>['actions'];
}

interface ActionColumnProps {
  actions: TableProps<CatalogTableRow>['actions'];
  field: CatalogTableRow;
}

const useStyles = makeStyles(() => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
}));

const ActionColumn: React.FC<ActionColumnProps> = ({ actions, field }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        data-testid="catalog-actions-menu"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={e => {
          e.stopPropagation();
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {(actions || []).map((action, index) => {
          const item =
            // eslint-disable-next-line no-nested-ternary
            typeof action === 'function'
              ? action(field)
              : 'action' in action
              ? action.action(field)
              : action;

          if (item.tooltip?.includes('favorites')) return null;

          return (
            <MenuItem
              disabled={item.disabled}
              key={index}
              className={classes.menuItem}
              onClick={e => item.onClick(e, field)}
            >
              {typeof item.icon === 'string' ? (
                <Icon>{item.icon}</Icon>
              ) : (
                item.icon(item.iconProps as any)
              )}

              {item.tooltip}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export function useColumns({ columns, actions }: Props) {
  const sortableColumns = [
    'Name',
    'Domain',
    'Owner',
    'Description',
    'Type',
    'Dataproduct',
  ];
  const hasActions = Boolean((actions || []).length);
  const colN = columns.length + Number(hasActions);
  const colWidth = `${Math.floor(100 / colN)}%`;

  const newColumns: TableCellProps<CatalogTableRow>[] = columns.map(col => ({
    field: (col.field || '') as keyof CatalogTableRow,
    fieldRender: (field: CatalogTableRow) => {
      const value = getObjectPropFromString(field, col.field || '');
      return col?.render?.(field, 'row') || value;
    },
    headerName: col.title?.toString(),
    cellProps: {
      align: col.align ?? 'left',
      size: 'small',
      width: colWidth,
      style: { wordBreak: 'break-word' },
    },
    sortable: sortableColumns.includes(col.title?.toString() || ''),
  }));
  if (hasActions) {
    const actionsCol: TableCellProps<CatalogTableRow> = {
      field: 'entity',
      fieldRender: (field: CatalogTableRow) => (
        <ActionColumn field={field} actions={actions} />
      ),
      headerName: '',
      cellProps: {
        align: 'left',
        size: 'small',
        width: '5%',
        style: { wordBreak: 'break-word' },
      },
      sortable: false,
    };

    newColumns.push(actionsCol);
  }

  return newColumns;
}
