import { WitboostEntity } from '@agilelab/plugin-wb-builder-common';
import { Entity } from '@backstage/catalog-model';
import { ConfigApi } from '@backstage/core-plugin-api';
import { EntityTextFilter } from '@backstage/plugin-catalog-react';

export class EntitySearchTextFilter extends EntityTextFilter {
  private readonly tagSearch: string;

  constructor(value: string, configApi: ConfigApi) {
    super(value);
    this.tagSearch =
      configApi.getOptionalString('mesh.mapping.tag.generic.search') ??
      'tagFQN';
  }

  filterEntity(entity: Entity): boolean {
    const words = this.toUpperCaseArray(this.value.split(/\s/));
    const exactMatch = this.toUpperCaseArray([
      entity.metadata.tags ?? [],
      this.extractTags(entity as WitboostEntity),
    ]);
    const partialMatch = this.toUpperCaseArray([
      entity.metadata.name,
      entity.metadata.description,
    ]);

    for (const word of words) {
      if (
        exactMatch.every(m => m !== word) &&
        partialMatch.every(m => !m.includes(word))
      ) {
        return false;
      }
    }

    return true;
  }

  private extractTags(entity: WitboostEntity): Array<string> {
    const tags = entity.spec?.mesh?.tags ?? [];
    if (Array.isArray(tags)) {
      return tags.flatMap((t: any) => {
        if (t.hasOwnProperty(this.tagSearch)) {
          return [t[this.tagSearch]];
        }
        return [];
      });
    }
    return [];
  }

  private toUpperCaseArray(
    value: Array<string | string[] | undefined>,
  ): Array<string> {
    return value.flat().flatMap(m => {
      if (m && typeof m === 'string') {
        return [m.toLocaleUpperCase('en-US')];
      }
      return [];
    });
  }
}
