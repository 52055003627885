import { Notification } from '@agilelab/plugin-wb-notification-common';
import { IconButton, IconButtonProps, Tooltip } from '@material-ui/core';
import React, { useCallback } from 'react';
import DraftsIcon from '@material-ui/icons/Drafts';
import { useNotification } from './useNotificationProvider';

export const MarkAsReadButton = (
  props: IconButtonProps & { notification: Notification },
) => {
  const { notification, ...iconButtonProps } = props;
  const { readStatus, setRead } = useNotification();

  const handleClick = useCallback(
    e => {
      e.stopPropagation();
      setRead(notification.id.toString());
    },
    [notification.id, setRead],
  );

  if (readStatus !== 'read' && !notification.read_at)
    return (
      <Tooltip title="Mark as read">
        <IconButton onClick={e => handleClick(e)} {...iconButtonProps}>
          <DraftsIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    );

  return null;
};
