import React, { useEffect } from 'react';
import { get, has } from 'lodash';
import { Theme, useTheme } from '@material-ui/core';
import { CustomMicrofrontendOptions } from './CustomMicrofrontendConfiguration';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { customAlertApiRef } from '../../apis';
import { CustomError } from '@agilelab/plugin-wb-platform-common';

export type MicroFrontendContract = {
  containerId: string;
  theme: Theme;
  token: string;
  parameters: { [key: string]: any };
};

export const CustomMicrofrontendComponent = (
  props: CustomMicrofrontendOptions,
) => {
  const { url, identifier, mountFunction, unmountFunction, data } = props;

  const alertApi = useApi(customAlertApiRef);
  const identityApi = useApi(identityApiRef);
  const theme = useTheme();

  useEffect(() => {
    // We are using this function only to be able to use asynchronous line: `await identityApi.getCredentials();`
    const asyncFunction = async () => {
      try {
        const credentials = await identityApi.getCredentials();
        const scriptId = `micro-frontend-script-${identifier}`;

        const apiContract: MicroFrontendContract = {
          containerId: `${identifier}-container`,
          theme,
          token: credentials.token!,
          parameters: { ...data },
        };

        const renderMicroFrontend = () => {
          if (has(window, mountFunction)) {
            return get(window, mountFunction)(apiContract);
          }
          return undefined;
        };

        if (document.getElementById(scriptId)) {
          renderMicroFrontend();
          return () => {};
        }

        const result = await fetch(`${url}/asset-manifest.json`);
        const manifest = await result.json();
        const script = document.createElement('script');
        script.id = scriptId;
        script.crossOrigin = '';
        script.src = `${url}${manifest.files['main.js']}`;
        script.onload = () => {
          renderMicroFrontend();
        };
        document.head.appendChild(script);
      } catch (err) {
        alertApi.post({
          error: new CustomError(
            `Failed to load microfrontend ${identifier}`,
            `Encountered an error while loading the manifest at ${url}/asset-manifest.json:\n\n${err}`,
          ),
        });
      }
      return () => {};
    };
    asyncFunction();

    const destructor: () => void = () => {
      if (has(window, unmountFunction)) {
        get(window, unmountFunction)(`${identifier}-container`);
      }
    };

    return destructor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <main id={`${identifier}-container`} />;
};
