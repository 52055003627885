import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { LaunchTestButton } from './LaunchTestButton';
import { WbCardActionButton } from '@agilelab/plugin-wb-platform';
import { useEditorPageContext } from '../context/useEditorPageContext';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    marginTop: '36px',
  },
  iconContainer: {
    border: `1px solid ${theme.palette.softened.primary}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px',
    borderRadius: '100%',
    borderStyle: 'dashed',
  },
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  icon: {
    fontSize: '105px',
    color: theme.palette.softened.primary,
  },
}));

export const TestCurrentEmptyState: React.FC = () => {
  const classes = useStyles();
  const { setSelectedTestTab } = useEditorPageContext();
  return (
    <Box className={classes.container}>
      <Typography style={{ fontWeight: 700 }} variant="h6">
        It looks like you haven't run a test in this session yet!
      </Typography>
      <Box className={classes.iconContainer}>
        <PlayCircleFilledIcon className={classes.icon} />
      </Box>
      <Typography variant="body2">
        Run a test and see if your descriptor is compliant.
      </Typography>
      <Box className={classes.buttonsContainer}>
        <WbCardActionButton
          size="large"
          variant="outlined"
          label="Check History"
          onClick={() => setSelectedTestTab('history')}
        />
        <LaunchTestButton size="large" label="Run a Test" />
      </Box>
    </Box>
  );
};
