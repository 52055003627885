import React from 'react';
import { CustomViewRegister } from '../CustomView/registry';
import { GenericField } from '../DisplayValue';
import { Grid, GridSize, useTheme } from '@material-ui/core';

function computeItemXs(colSpan: number, gridColumns = 4) {
  // 12 is a full row in MUI grid
  const factor = 12 / gridColumns;

  return (colSpan * factor) as GridSize;
}

CustomViewRegister.register({
  id: 'string',
  function: function StringView(props: Record<string, any>) {
    const theme = useTheme();

    const maxLines = props.maxlines || 1;
    const value = props.value;
    return (
      <Grid
        item
        xs={computeItemXs(props.colSpan || 1, 4)}
        style={{
          padding: `${theme.spacing(2)}px 0`,
          paddingRight: `${theme.spacing(4)}px`,
          paddingLeft: 0,
        }}
      >
        <GenericField
          maxLines={maxLines}
          gridItem={false}
          value={value}
          label={props.label}
        />
      </Grid>
    );
  },
});

CustomViewRegister.register({
  id: 'date',
  function: function DateView(props: Record<string, any>) {
    const theme = useTheme();

    const value = props.value;
    return (
      <Grid
        item
        xs={computeItemXs(1, 4)}
        style={{
          padding: `${theme.spacing(2)}px 0`,
          paddingRight: `${theme.spacing(4)}px`,
          paddingLeft: 0,
        }}
      >
        <GenericField
          maxLines={1}
          type={value === '' ? 'string' : 'date'}
          gridItem={false}
          value={value}
          label={props.label}
        />
      </Grid>
    );
  },
});
