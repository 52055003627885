import {
  HierarchyClassEntity,
  HierarchyEntity,
  HierarchyStatus,
} from '@agilelab/plugin-wb-ontology-common';
import {
  ANNOTATION_ORIGIN_LOCATION,
  Entity,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { DiscoveryApi } from '@backstage/plugin-permission-common';
import fetch from 'node-fetch';
import { ResponseError, SerializedError } from '@backstage/errors';
import {
  AlphaEntity,
  EntityStatusItem,
} from '@backstage/catalog-model/dist/alpha';
import {
  CatalogApi,
  ENTITY_STATUS_CATALOG_PROCESSING_TYPE,
} from '@backstage/catalog-client';

/**
 * Ontology API for easily access hierarchy related entities
 */
export type OntologyApi = {
  getHierarchyInstanceByName(
    name: string,
    options?: HierarchyRequestOptions,
  ): Promise<HierarchyEntity | undefined>;

  getHierarchies(options?: HierarchyRequestOptions): Promise<HierarchyEntity[]>;

  getParentHierarchyClass(
    entity: Entity,
    options?: HierarchyRequestOptions,
  ): Promise<HierarchyClassEntity | undefined>;

  /**
   * Deletes a hierarchy and all its related hierarchy classes. If no hierarchy is found with the given name, an error is thrown.
   * @param name, hierarchy name found at `metadata.name`
   * @param options
   */
  deleteHierarchyByName(
    name: string,
    options?: HierarchyRequestOptions,
  ): Promise<void>;

  setHierarchyStatus(
    hierarchy: HierarchyEntity,
    status: HierarchyStatus,
    token: string,
  ): Promise<boolean>;

  getHierarchiesProcessingErrors(options?: {
    token?: string;
  }): Promise<HierarchyErrors>;
};

export type HierarchyRequestOptions = {
  token?: string;
  defaultNamespace?: string;
};

const errorFilter = (i: EntityStatusItem) =>
  i.error &&
  i.level === 'error' &&
  i.type === ENTITY_STATUS_CATALOG_PROCESSING_TYPE;

export type HierarchyErrors = {
  [key: string]: SerializedError[];
};

export class OntologyClient implements OntologyApi {
  constructor(
    private readonly catalogClient: CatalogApi,
    private readonly discoveryApi: DiscoveryApi,
  ) {}

  async getHierarchiesProcessingErrors(options?: {
    token?: string;
  }): Promise<HierarchyErrors> {
    const hierarchies = (
      await this.catalogClient.getEntities(
        {
          filter: [{ kind: 'Hierarchy' }],
        },
        { token: options?.token },
      )
    ).items as HierarchyEntity[];

    const hierarchyStatuses = hierarchies.map(hierarchy => ({
      name: hierarchy.metadata.name,
      statuses: (hierarchy as AlphaEntity).status?.items ?? [],
    }));

    const hierarchyErrors: HierarchyErrors = hierarchyStatuses.reduce(
      (accumulator: HierarchyErrors, hierarchy) => {
        const errors = hierarchy.statuses
          .filter(errorFilter)
          .map((e: any) => e.error)
          .filter((e: any): e is SerializedError => Boolean(e));

        if (errors.length > 0) {
          accumulator[hierarchy.name] = errors;
        }

        return accumulator;
      },
      {},
    );

    return hierarchyErrors;
  }

  private async deleteHierarchyStatus(
    hierarchy: HierarchyEntity,
    token?: string,
  ): Promise<void> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl('ontology')}/`;
    const url = new URL('hierarchyStatus', baseUrl);

    const requestBody = {
      hierarchy: hierarchy,
    };

    const response = await fetch(url.toString(), {
      method: 'DELETE',
      headers: token
        ? {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        : { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return;
  }

  async deleteHierarchyByName(
    name: string,
    options?: HierarchyRequestOptions,
  ): Promise<void> {
    const entity = await this.catalogClient.getEntityByRef(
      {
        kind: 'Hierarchy',
        namespace: options?.defaultNamespace ?? 'default',
        name: name,
      },
      { token: options?.token },
    );

    if (!entity) {
      throw new Error(
        `Could not find a hierarchy with name '${name}'. Delete request failed.`,
      );
    }

    const locationRef =
      entity.metadata.annotations?.[ANNOTATION_ORIGIN_LOCATION];
    if (!locationRef) {
      throw new Error(
        `Could not find the origin location annotation inside hierarchy entity: ${stringifyEntityRef(
          entity,
        )}`,
      );
    }

    const location = await this.catalogClient.getLocationByRef(locationRef, {
      token: options?.token,
    });
    if (!location) {
      throw new Error(
        `Could not find the origin location of hierarchy: ${stringifyEntityRef(
          entity,
        )}`,
      );
    }

    this.catalogClient.removeLocationById(location!.id, {
      token: options?.token,
    });
    this.deleteHierarchyStatus(entity as HierarchyEntity, options?.token);
  }

  async getParentHierarchyClass(
    entity: Entity,
    options?: HierarchyRequestOptions | undefined,
  ): Promise<HierarchyClassEntity | undefined> {
    if (!entity.spec?.type) {
      return undefined;
    }

    const hierarchyClass = await this.catalogClient.getEntityByRef(
      {
        kind: 'hierarchyclass',
        namespace: options?.defaultNamespace ?? 'default',
        name: entity.spec.type as string,
      },
      { token: options?.token },
    );

    if (hierarchyClass?.spec?.type !== entity.kind) {
      return undefined;
    }

    return hierarchyClass as HierarchyClassEntity;
  }

  async getHierarchyInstanceByName(
    name: string,
    options?: HierarchyRequestOptions,
  ): Promise<HierarchyEntity | undefined> {
    const hierarchy = await this.catalogClient.getEntityByRef(
      {
        kind: 'hierarchy',
        namespace: options?.defaultNamespace ?? 'default',
        name: name,
      },
      { token: options?.token },
    );
    return hierarchy as HierarchyEntity;
  }

  async getHierarchies(
    options?: HierarchyRequestOptions,
  ): Promise<HierarchyEntity[]> {
    const hierarchies = await this.catalogClient.getEntities(
      {
        filter: { kind: ['Hierarchy'] },
      },
      { token: options?.token },
    );

    return hierarchies.items as HierarchyEntity[];
  }

  async setHierarchyStatus(
    hierarchy: HierarchyEntity,
    status: HierarchyStatus,
    token?: string,
  ): Promise<boolean> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl('ontology')}/`;
    const url = new URL('hierarchyStatus', baseUrl);

    const requestBody = {
      hierarchy: hierarchy,
      status: status,
    };

    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: token
        ? {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        : { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.ok;
  }
}
