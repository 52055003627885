import { Typography } from '@material-ui/core';
import React from 'react';
import { useTestResults } from './hooks/useTestResult';
import { Test } from '../types';

interface Props {
  test: Test;
}

const TestResultsLabel: React.FC<{
  count: number;
  singleLabel: string;
  multiLabel: string;
  message: string;
}> = ({ count, singleLabel, multiLabel, message }) => {
  const label = `${count} ${count > 1 ? multiLabel : singleLabel} ${message}`;

  return Boolean(count) ? (
    <Typography variant="body2">{label}</Typography>
  ) : (
    <></>
  );
};

export const TestResults: React.FC<Props> = ({ test }) => {
  const {
    success,
    policiesFailed,
    policiesWithWarnings,
    metricsFailed,
    componentsFailed,
  } = useTestResults(test);

  return (
    <>
      {success && <Typography variant="body2">Success</Typography>}
      <TestResultsLabel
        count={policiesFailed}
        singleLabel="policy"
        multiLabel="policies"
        message="check failed"
      />

      <TestResultsLabel
        count={metricsFailed}
        singleLabel="metric"
        multiLabel="metrics"
        message="check failed"
      />

      <TestResultsLabel
        count={policiesWithWarnings}
        singleLabel="policy"
        multiLabel="policies"
        message="check with warnings"
      />

      <TestResultsLabel
        count={componentsFailed}
        singleLabel="component"
        multiLabel="components"
        message="check failed"
      />
    </>
  );
};
