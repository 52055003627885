import { WbEnvironmentSelector } from '@agilelab/plugin-wb-platform';
import { Box, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useEditorPageContext } from '../context/useEditorPageContext';
import { EditButton } from '../../common';
import { ReleaseEntityV1alpha1 } from '@agilelab/plugin-wb-builder-common';
import { NewSnapshotButton } from '../NewSnapshotButton';
import { UpdateButton } from '../UpdateButton';
import { Skeleton } from '@material-ui/lab';
import ImportButton from './ImportButton';

export const EditorCardActions: React.FC = () => {
  const theme = useTheme();
  const { entity, releases, fetchReleaseState, testLoading, parent } =
    useEditorPageContext();
  const showImport = entity.kind.toLocaleLowerCase() === 'component';

  // snapshot creation is disabled if there is already a snapshot
  const snapshot = useMemo(() => {
    return releases?.find((r: ReleaseEntityV1alpha1) => r.metadata.isSnapshot);
  }, [releases]);

  return (
    <Box display="flex" alignItems="center">
      <WbEnvironmentSelector disabled={testLoading} />

      <Box
        style={{
          background: theme.palette.grey[300],
          width: '1px',
          height: '24px',
          margin: '0px 12px 0px 12px',
        }}
      />

      <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
        {fetchReleaseState.loading ? (
          <Skeleton width={100} />
        ) : (
          <>
            {showImport && <ImportButton entity={entity} />}
            <EditButton entity={entity} variant="outlined" />
            {!parent && (!snapshot ? <NewSnapshotButton /> : <UpdateButton />)}
          </>
        )}
      </Box>
    </Box>
  );
};
