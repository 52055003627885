import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { TextFieldProps } from '@material-ui/core';
import React from 'react';
import { WbSelect } from '../WbTextField';

export const EditEnvironmentField = React.forwardRef(
  (
    props: {
      onChange: (env: any) => void;
      environments?: string[];
      value: string | string[];
    } & Omit<TextFieldProps, 'value' | 'onChange' | 'select'>,
    _ref,
  ) => {
    const { onChange, environments, value, children, ...textFieldProps } =
      props;

    const handleChange = (event: React.ChangeEvent<any>) => {
      onChange([event.target.value]);
    };

    return (
      <WbSelect
        {...textFieldProps}
        id="select-env-radio"
        label="Environment"
        value={value}
        onChange={handleChange}
        disabled={!environments}
        options={environments || []}
        getOptionLabel={e => snakeCaseToTitleCase(e)}
      />
    );
  },
);
