import React from 'react';
import { CustomViewRegister } from '../CustomView/registry';
import { useDataPath, DataPathContextProvider } from '../../context';

CustomViewRegister.register({
  id: 'sequence',
  function: function SequenceView(props: Record<string, any>) {
    const list = useDataPath(props.path) || [];
    return list.map((_: any, idx: Number) => {
      const index = `${props.path}[${idx}]`;
      return (
        <DataPathContextProvider key={String(idx)} path={index}>
          {props.children}
        </DataPathContextProvider>
      );
    });
  },
});
