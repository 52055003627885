import {
  AccessControlRequestNotification,
  AccessControlResponseNotification,
  InformativeNotification,
  Notification,
  NotificationType,
  QuestionNotification,
} from '@agilelab/plugin-wb-notification-common';
import DraftsIcon from '@material-ui/icons/Drafts';
import {
  Content,
  Header,
  Link,
  Page,
  Progress,
} from '@backstage/core-components';
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import NotificationIcon from '@material-ui/icons/NotificationsNone';
import { Alert, Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { AccessControlRequestNotificationAccordion } from './AccessControlRequestNotification';
import { QuestionNotificationAccordion } from './QuestionNotification';
import { InformativeNotificationAccordion } from './InformativeNotification';
import {
  NotificationDataType,
  useNotification,
} from './useNotificationProvider';
import { Entity } from '@backstage/catalog-model';
import { AccessControlResponseNotificationAccordion } from './AccessControlResponseNotification';
import { NotificationsFilters } from './NotificationsFilters';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { AclVerb } from '@agilelab/plugin-wb-marketplace-common';

export const userCache = new Map<string, Entity>();

function renderNoNotifications(classes: any) {
  return (
    <Grid
      container
      direction="column"
      className={classes.noNotificationsContainer}
    >
      <Grid item>
        <NotificationIcon
          fontSize="inherit"
          className={classes.noNotificationsIcon}
        />
      </Grid>
      <Grid item>
        <Typography className={classes.noNotifications}>
          No notifications
        </Typography>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  noNotifications: {
    fontSize: theme.typography.fontSize * 1.5,
    color: theme.palette.secondary.main,
  },
  noNotificationsIcon: {
    fontSize: theme.typography.fontSize * 4,
  },
  noNotificationsContainer: {
    alignItems: 'center',
  },
  divider: {
    position: 'relative',
    '& hr': {
      borderTop: `1px solid ${theme.palette.primary.light}`,
    },
    '& span': {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '-11px',
      left: '0',
      backgroundColor: theme.palette.background.paper,
      paddingRight: theme.spacing(2),
    },
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
}));

export const NotificationsPageContent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [oldData, setOldData] = useState<NotificationDataType>(undefined);
  const [expandedNotification, setExpandedNotification] = React.useState<
    string | boolean
  >(false);

  const {
    page,
    setPage,
    notificationData,
    loadingNotificationData,
    errorNotificationData,
    kind,
    readStatus,
    requestStatus,
  } = useNotification();

  const handlePaginationChange = (
    _event: React.ChangeEvent<unknown>,
    paginationValue: number,
  ) => {
    setPage(paginationValue);
  };

  const handleNotificationAccordionChange = (newExpanded: string | boolean) => {
    setExpandedNotification(newExpanded);
  };

  const notificationRender = (n: Notification) => {
    switch (n.kind) {
      case NotificationType.QUESTION:
        return (
          <QuestionNotificationAccordion
            key={n.id}
            notification={n as QuestionNotification}
            expandedAccordion={expandedNotification}
            onAccordionExpansionsChange={handleNotificationAccordionChange}
          />
        );
      case NotificationType.INFORMATIVE:
        return (
          <InformativeNotificationAccordion
            key={n.id}
            notification={n as InformativeNotification}
            expandedAccordion={expandedNotification}
            onAccordionExpansionsChange={handleNotificationAccordionChange}
          />
        );
      case NotificationType.ACCESS_REQUEST:
        return (
          <AccessControlRequestNotificationAccordion
            verb={AclVerb.Grant}
            key={n.id}
            notification={n as AccessControlRequestNotification}
            expandedAccordion={expandedNotification}
            onAccordionExpansionsChange={handleNotificationAccordionChange}
          />
        );
      case NotificationType.ACCESS_RESPONSE:
        return (
          <AccessControlResponseNotificationAccordion
            verb={AclVerb.Grant}
            key={n.id}
            notification={n as AccessControlResponseNotification}
            expandedAccordion={expandedNotification}
            onAccordionExpansionsChange={handleNotificationAccordionChange}
          />
        );
      case NotificationType.REVOKE_REQUEST:
        return (
          <AccessControlRequestNotificationAccordion
            verb={AclVerb.Revoke}
            key={n.id}
            notification={n as AccessControlRequestNotification}
            expandedAccordion={expandedNotification}
            onAccordionExpansionsChange={handleNotificationAccordionChange}
          />
        );
      case NotificationType.REVOKE_RESPONSE:
        return (
          <AccessControlResponseNotificationAccordion
            verb={AclVerb.Revoke}
            key={n.id}
            notification={n as AccessControlResponseNotification}
            expandedAccordion={expandedNotification}
            onAccordionExpansionsChange={handleNotificationAccordionChange}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!!notificationData) {
      setOldData(notificationData);
    }
  }, [notificationData]);

  useEffect(() => {
    setExpandedNotification(false);
  }, [kind, readStatus, requestStatus]);

  if (!loadingNotificationData && !notificationData && !errorNotificationData) {
    return (
      <Box style={{ marginTop: theme.spacing(6) }}>
        {renderNoNotifications(classes)}
      </Box>
    );
  }

  return (
    <>
      {loadingNotificationData && <Progress />}
      {errorNotificationData instanceof Error && (
        <Alert severity="error">{errorNotificationData.message}</Alert>
      )}
      {oldData && (
        <>
          <Box style={{ marginTop: theme.spacing(2) }}>
            {oldData.notifications.length === 0 &&
              renderNoNotifications(classes)}

            {oldData.notifications.map(notificationRender)}
            {!!oldData.notifications.length && (
              <div className={classes.paginationWrapper}>
                <Pagination
                  count={Math.ceil(oldData.total / 20)}
                  showFirstButton
                  showLastButton
                  page={page}
                  onChange={handlePaginationChange}
                  size="large"
                  color="primary"
                />
              </div>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export const NotificationsPage = () => {
  const { setAllAsRead } = useNotification();
  return (
    <Page themeId="home">
      <Header
        title="Notification Center"
        subtitle={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              witboost
            </Link>
            <Typography color="textPrimary">Notification Center</Typography>
          </Breadcrumbs>
        }
      />
      <Content>
        <WbCard
          title="Notifications"
          actions={
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<DraftsIcon />}
              onClick={() => setAllAsRead()}
            >
              Mark all as read
            </Button>
          }
        >
          <WbCardContent>
            <NotificationsFilters />
            <NotificationsPageContent />
          </WbCardContent>
        </WbCard>
      </Content>
    </Page>
  );
};
