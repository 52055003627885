export enum Status {
  Draft = 'draft',
  Grace = 'grace',
  Enabled = 'enabled',
  Disabled = 'disabled',
  Deprecated = 'deprecated',
  Deleted = 'deleted',
  DisabledGrace = 'disabled_grace',
}

export enum GovernanceEntityType {
  Policy = 'policy',
  Metric = 'metric',
}

export enum Severity {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export enum Strategy {
  None = 'none',
  Default = 'default',
  PreviousVsCurrent = 'previous_vs_current',
}

export enum ResourceType {
  DataProduct = 'data_product',
  Table = 'table',
}

export enum Context {
  Global = 'global',
  Local = 'local',
}

export enum InteractionType {
  User2User = 'user2user',
  User2Platform = 'user2platform',
}

export enum Timing {
  Runtime = 'runtime',
  Deployment = 'deployment',
}

export enum Trigger {
  Active = 'active',
  Passive = 'passive',
}

export enum ResultType {
  Metric = 'metric',
  Policy = 'policy',
}

export enum TestStatus {
  Pending = 'pending',
  CompletedWithWarning = 'completedWithWarning',
  CompletedWithError = 'completedWithError',
  CompletedWithSuccess = 'completedWithSuccess',
}

export enum Engine {
  Cue = 'cue',
  Remote = 'remote',
  None = 'none',
}

export enum Outcome {
  Ok = 'ok',
  Pending = 'pending',
  NoResource = 'no_resource',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  NotBlockinError = 'not_blocking_error',
  NotExecuted = 'not_executed',
}

export const blockingOutcomes = [
  Outcome.Error as string,
  Outcome.NotExecuted as string,
];
export const notBlockingOutcomes = [
  Outcome.Info as string,
  Outcome.Warning as string,
  Outcome.NotBlockinError as string,
];

export enum ThresholdExpression {
  BelowOrEqual = 'below_or_equal',
  Below = 'below',
  AboveOrEqual = 'above_or_equal',
  Above = 'above',
}
