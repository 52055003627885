export type GraphIOTypes = 'dataproduct' | 'external';

export enum ProductTraitType {
  Event = 'event',
  SQLView = 'SQLView',
  Raw = 'raw',
  Batch = 'batch',
  Streaming = 'streaming',
}

export enum DataProductStatus {
  Published = 'Published',
  NotPublished = 'NotPublished',
  Archived = 'Archived',
}

export type DataProductEntity = {
  id?: number;
  name: string;
  display_name: string;
  Domain: { id: string; name: string; sub_domain_of: string };
  description: string;
  data_product_owner: string;
  data_product_owner_display_name: string;
  external_id: string;
};

export type DataProductsList = {
  dataProductsInstances: DataProductInstanceEntity[];
};

export type ACLEntity = {
  refs: string;
  id_output_port: number;
  locked: boolean;
};

export type DataProductInstanceEntity = {
  id: number;
  version: string;
  descriptor: Record<string, any>;
  private_descriptor: Record<string, any>;
  published_at: string;
  DataProduct: DataProductEntity;
  InputPorts?: InputPortEntity[];
  Environment: EnvironmentEntity;
};

export type InputPortEntity = {
  Resource?: ResourceEntity;
  DataProductInstance: DataProductInstanceEntity;
  OutputPort: OutputPortEntity | null;
  kind: GraphIOTypes;
};

export type ResourceEntity = {
  name: string;
  description: string;
  display_name: string;
};

export type ReviewEntity = {
  id: number;
  long_comment: string;
  reviewer: string;
  reviewer_display_name: string;
  score: number;
  short_comment: string;
  timestamp: string;
};

export type OutputPort = {
  id: number;
  name: string;
  displayName: string;
  type: ProductTraitType;
  version: string;
  platform: string;
  technology: string;
  description?: string;
  descriptor?: any;
};

export interface OPFilters {
  outputporttype?: string[];
}

export interface ACLFilters {
  kind?: string;
  access?: string[];
  status?: string[];
}

export interface ACLByRefFilters {
  type?: string[];
  outputPort?: string[];
  access?: string[];
  status?: string[];
}

export type OutputPortsList = {
  outputPorts: OutputPortEntity[];
};

export type OutputPortEntity = {
  id: number;
  description: string;
  name: string;
  displayName: string;
  display_name: string;
  fully_qualified_name: string;
  domain: string;
  outputporttype: string;
  version: string;
  descriptor: Descriptor;
  DataProductInstance: DataProductInstanceEntity;
  external_id: string;
  ACLs?: ACLEntity[];
};

export type EnvironmentEntity = {
  id: number;
  name: string;
};

export type QuestionEntity = {
  id: number;
  asked_by: string;
  asked_by_display_name: string;
  question: string;
  timestamp: string;
  answer: AnswerEntity;
  DataProductInstance?: DataProductInstanceEntity;
};

export type AnswerEntity = {
  answered_by: string;
  answered_by_display_name: string;
  answer: string;
  timestamp: string;
};

export type AccessAuthorizationEntity = {
  timestamp: string;
  authorized_by: string;
  authorized_by_display_name: string;
  status: string;
};

export type DataProduct = {
  id: number;
  name: string;
  displayName: string;
  domain: string;
  description?: string;
  version: string;
  status: DataProductStatus;
  environment: Environment;
  publishedAt: string;
  lastPublish?: string;
  owner: string;
  ownerDisplayName: string;
};

export type Environment = {
  id: number;
  name: string;
  priority?: number;
};

export type DataProductDetails = {
  rating?: number;
  dependencies?: any;
  reviews?: Review[];
  events?: OutputPort[];
  sqls?: OutputPort[];
  storages?: OutputPort[];
  statusData?: StatusData[];
  observabilities?: Trait[];
  state?: 0 | 1;
  qa?: Question[];
  lastUpdate?: string;
} & DataProduct;

export type Trait = {
  id: number;
  template: string;
  technology: string;
  description: string;
  monitorUrl: string;
  name: string;
  repository: string;
  type: string;
  version: string;
};

export type Question = {
  asked_by: string;
  question: string;
  dataproduct: {
    id: number;
    name: string;
    display_name?: string;
    domain: string;
    version: string;
    environment?: string;
  };
  asked_by_display_name: string;
  id_dataproduct_instance: number;
};

export type Informative = {
  json: string;
  html: string;
  text: string;
  timestamp?: string;
};

export type Answer = {
  answered_by: string;
  answered_by_display_name: string;
  answer: string;
  timestamp?: string;
};

export type Review = {
  score: number;
  reviewer: string;
  reviewer_display_name: string;
  shortComment: string;
  longComment: string;
  timestamp: string;
};

export type StatusData = {
  timestamp: string;
  value: number;
};

export type AccessControlRequest = {
  identities: string[];
  requester: string;
  motivation: string;
  outputport: {
    name: string;
    output_port_type: string;
  };
  dataproduct: {
    id: number;
    name: string;
    display_name?: string;
    domain: string;
    version: string;
    data_product_owner: string;
    data_product_display_name: string;
  };
  environment: string;
  id_output_port: number;
  requester_display_name: string;
  id_dataproduct_instance: number;
  displayFields: AccessControlDisplayField[];
};

export type AccessControlResponse = AccessControlRequest & {
  is_requester: boolean;
  status: 'SUCCESS' | 'FAILURE' | 'REJECTED';
};

export enum AccessControlRequestStatus {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export type AccessControlAuthorization = {
  status: AccessControlRequestStatus;
  timestamp: string;
  authorized_by: string;
  authorized_by_display_name: string;
};

export type AccessRequestsList = {
  AccessRequest: AccessRequestEntity[];
};

export type AccessRequestEntity = {
  id: number;
  idOutputPort: number;
  accessAuthorization?: Partial<AccessAuthorizationEntity>;
};

export type DomainResolver = {
  domainData: {
    name: string;
    sub_domain_of?: string;
  };
};

export type SelectedItem = {
  type: string;
  id: number;
};

export enum NotificationType {
  QUESTION = 'Question',
  ACCESS_REQUEST = 'AccessRequest',
  ACCESS_RESPONSE = 'AccessResponse',
  INFORMATIVE = 'Informative',
  REVOKE_REQUEST = 'RevokeRequest',
  REVOKE_RESPONSE = 'RevokeResponse',
}

export type Notification = {
  id: number;
  kind: NotificationType;
  notification_request: AccessControlRequest | Question | Informative;
  notification_response: Answer | AccessControlAuthorization | null;
  recipient: string;
  created_at: string;
  updated_at: string;
  read_at: string | null;
  hook_id?: string | null;
  sender: string | null;
};

export type AccessRequestNotification = Omit<
  Notification,
  'kind | notification_request | notification_response'
> & {
  kind: NotificationType.ACCESS_REQUEST;
  notification_request: AccessControlRequest;
  notification_response: AccessControlAuthorization | null;
};

export type QuestionNotification = Omit<
  Notification,
  'kind | notification_request | notification_response'
> & {
  kind: NotificationType.QUESTION;
  notification_request: Question;
  notification_response: Answer | null;
};

export type InformativeNotification = Omit<
  Notification,
  'kind | notification_request | notification_response'
> & {
  kind: NotificationType.INFORMATIVE;
  notification_request: Informative;
  notification_response: null;
};

export const MAX_ACCESS_REQUEST_TEMPLATE_AVAILABLE = 1;

export type AccessControlDisplayField = {
  title: string;
  text?: string;
  textList?: string[];
  attachment?: AccessControlAttachment;
};

export type AccessControlAttachment = {
  fileName: string;
  fileContent: string;
};

export type Schema = {
  name: string;
  dataType: string;
  dataTypeDisplay?: string;
  description?: string;
  fullyQualifiedName?: string;
  tags?: Tag[];
  constraint?: string;
  ordinalPosition?: number;
};

export type SchemaList = {
  name: String;
  columns: Schema[];
};

export type Tag = {
  tagFQN: string;
  source: string;
  labelType: string;
  state: string;
  href?: string;
};

export type SemanticLinking = {
  fieldName: string;
  referenceOutputPort: string;
  referenceFieldName: string;
};

export type Descriptor = {
  id: string;
  name: string;
  fullyQualifiedName?: string;
  description: string;
  kind: string;
  version: string;
  infrastructureTemplateId: string;
  useCaseTemplateId?: string;
  dependsOn: string[];
  platform?: string;
  technology?: string;
  outputPortType: string;
  creationDate?: string;
  startDate?: string;
  processDescription?: string;
  dataContract?: {
    schema?: (Schema | SchemaList)[];
    SLA?: {
      intervalOfChange?: string;
      timeliness?: string;
      upTime?: string;
      [key: string]: any;
    };
    termsAndConditions?: string;
    endpoint?: string;
    [key: string]: any;
  };
  dataSharingAgreement: {
    purpose?: string;
    billing?: string;
    security?: string;
    intendedUsage?: string;
    limitations?: string;
    lifeCycle?: string;
    confidentiality?: string;
    [key: string]: any;
  };
  tags: Tag[];
  sampleData?: {
    columns?: string[];
    rows?: Array<string[]>;
  };
  semanticLinking?: SemanticLinking[];
  specific: { [key: string]: any };
  [key: string]: any;
};
