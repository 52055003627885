import React from 'react';
import { CustomViewComponent } from './CustomView';
import { camelToSpacedCase } from '@agilelab/plugin-wb-platform-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

type RawGeneralInfo = {
  type: 'string' | 'date';
  label: string;
  value: string | undefined;
  href?: string;
  colSpan?: number;
};

export const useCustomViewInfoFromConfig = (path: string) => {
  const configApi = useApi(configApiRef);
  return (configApi.getOptional<Array<RawGeneralInfo>>(path) ?? []).map(
    (info: RawGeneralInfo) => {
      return <CustomViewComponent {...info} />;
    },
  );
};
export const getCustomViewInfoFromObject = (obj: Record<string, any>) => {
  return Object.entries(obj).map(([label, value]) => (
    <CustomViewComponent
      label={camelToSpacedCase(label)}
      value={value}
      type="string"
      colSpan="2"
    />
  ));
};
