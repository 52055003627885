import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { PreviewMessage } from '../types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '12px 16px',
    padding: '16px',
    borderRadius: '8px',
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 2px 8px 0px #00000026',
  },
  title: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  info: {
    background: theme.palette.grey[100],
    border: `1px solid ${theme.palette.softened.secondary}`,
  },
  error: {
    background: theme.palette.bkg.error,
    border: `1px solid ${theme.palette.error.main}`,
  },
  warning: {
    background: theme.palette.bkg.warning,
    border: `1px solid ${theme.palette.warning.main}`,
  },
}));

interface Props {
  message: PreviewMessage;
}

export const DeploymentPreviewMessage: React.FC<Props> = ({ message }) => {
  const classes = useStyles();

  const classByType: Record<PreviewMessage['type'], string> = {
    info: classes.info,
    error: classes.error,
    warning: classes.warning,
  };

  return (
    <Box className={clsx(classes.container, classByType[message.type])}>
      <Box className={classes.title}>
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          {message.title}
        </Typography>
      </Box>

      <Typography variant="body2">{message.content}</Typography>
    </Box>
  );
};
