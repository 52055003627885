import {
  customAlertApiRef,
  useDrawersContext,
  WbDrawer,
  WbTable,
} from '@agilelab/plugin-wb-platform';
import { useApi } from '@backstage/core-plugin-api';
import React, { useEffect, useMemo } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import { TableBody, Typography } from '@material-ui/core';
import { Test } from '../../EditorPage/types';
import { useApolloClient } from '@apollo/client';
import {
  GOVERNANCE_ENTITIES,
  GovernanceEntitiesQueryType,
} from '../../../graphql/governanceEntities';
import { ValidationTestRow } from './ValidationTestRow';
import { ValidationTestErrorHeader } from './ValidationTestErrorHeader';
import { getErrorMessage } from '../utils';

export const ValidationTestDrawer: React.FC = () => {
  const client = useApolloClient();
  const { toggleBoolean, drawers } = useDrawersContext();
  const {
    release,
    validationTestResultDetailContent,
    isValidationTestDrawerOpen,
    setIsValidationTestDrawerOpen,
    queryParamVersion,
    tests,
    fetchTests,
  } = useReleaseDetailPageContext();
  const alertApi = useApi(customAlertApiRef);

  const [asyncGovernanceEntities, fetchAsyncGovernanceEntities] =
    useAsyncFn(async () => {
      try {
        if (tests.loading || !tests.value) return undefined;

        const queryResponse = await client.query<GovernanceEntitiesQueryType>({
          query: GOVERNANCE_ENTITIES,
          variables: {
            ids: (tests.value.test?.tasks || [])
              .filter(t => !!t.governanceEntityId)
              .map(p => p.governanceEntityId),
          },
        });

        return { governanceEntities: queryResponse.data };
      } catch (error) {
        alertApi.post({ error, severity: 'error' });
        return undefined;
      }
    }, [tests]);

  useEffect(() => {
    if (isValidationTestDrawerOpen) {
      fetchAsyncGovernanceEntities();
    }
  }, [fetchAsyncGovernanceEntities, fetchTests, isValidationTestDrawerOpen]);

  useEffect(() => {
    if (!drawers.get(0)) {
      setIsValidationTestDrawerOpen(false);
    }
  }, [drawers, setIsValidationTestDrawerOpen]);

  const errorMessage = useMemo(() => {
    const test = tests?.value?.test;
    return test && getErrorMessage(test);
  }, [tests?.value?.test]);

  return (
    <>
      <WbDrawer
        open={drawers.get(0) ?? false}
        setOpen={() => toggleBoolean(0)}
        title="Validation Test"
      >
        <Typography
          style={{ fontWeight: 'normal', marginBottom: errorMessage ? 0 : 16 }}
          color="primary"
          variant="h6"
        >
          <b>{queryParamVersion || release.metadata.version}</b> of{' '}
          {release.metadata.dataProductName}
        </Typography>

        {errorMessage && (
          <ValidationTestErrorHeader errorMessage={errorMessage} />
        )}

        <WbTable<Test>
          stickyHeader
          components={{
            tableLoader: {
              loading: asyncGovernanceEntities.loading,
            },
            tableContent: {
              body: (
                <TableBody>
                  {(tests.value?.test?.tasks || []).map((task, i) => {
                    return (
                      <ValidationTestRow
                        key={i}
                        task={task}
                        governanceEntities={
                          asyncGovernanceEntities.value?.governanceEntities
                            ?.cgp_governance_entity || []
                        }
                      />
                    );
                  })}
                </TableBody>
              ),
            },
          }}
        />
      </WbDrawer>

      <WbDrawer
        open={drawers.get(1) ?? false}
        setOpen={() => toggleBoolean(1)}
        title="Result Details"
      >
        {validationTestResultDetailContent}
      </WbDrawer>
    </>
  );
};
