import { z } from 'zod';
import { NotificationType } from '../utils/types';
import yn from 'yn';

export const getRequestZod = z.object({
  recipient: z.string().optional(),
  offset: z.preprocess(Number, z.number().min(0)).optional(),
  limit: z.preprocess(Number, z.number().positive()).optional(),
  kind: z.nativeEnum(NotificationType).optional(),
  response_status: z.string().optional(),
  id_dataproduct_instance: z.preprocess(Number, z.number().min(0)).optional(),

  // Default is undefined: take both read and unread notifications
  isRead: z
    .preprocess(
      value => yn(value, { default: undefined }),
      z.boolean().optional(),
    )
    .optional(),

  // Default is false: return also notifications rather than only timestamps
  onlyTimestamps: z
    .preprocess(value => yn(value, { default: false }), z.boolean().optional())
    .optional(),
});

export type GetRequest = z.infer<typeof getRequestZod>;
