import {
  Pagination,
  RoleViewFilters,
  RoleDetails,
} from '@agilelab/plugin-wb-rbac-common';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import React, { useCallback, useMemo, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';

export interface PermissionDrawerContextType {
  filters: RoleViewFilters;
  changeFilters: <K extends keyof RoleViewFilters>(
    key: K,
    value: RoleViewFilters[K],
  ) => void;
  pagination: Pagination;
  setPagination: (p: Pagination) => void;
  roles: RoleDetails[];
  rolesCount: number;
  reloadTable: () => void;
}

export const PermissionDrawerContext =
  React.createContext<PermissionDrawerContextType>(
    {} as PermissionDrawerContextType,
  );

export const RoleDrawerContextProvider: React.FC<{
  children: any;
  subject: string;
  target: string;
  role: string;
}> = ({ children, subject, target, role }) => {
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);

  const [permissions, setPermissions] = React.useState<RoleDetails[]>([]);

  const [filters, setFilters] = useState<RoleViewFilters>({
    text: '',
    limit: 25,
  });
  const [pagination, setPagination] = useState<Pagination>({
    limit: filters.limit ?? 25,
    offset: 0,
  });

  useAsync(async () => {
    const identity = await identityApi.getCredentials();
    const token = identity.token!;

    const response = await fetch(
      `${await discoveryApi.getBaseUrl(
        'rbac',
      )}/roledetails?subject=${subject}&target=${target}&role=${role}`,
      {
        headers: { authorization: `Bearer ${token}` },
      },
    );

    const data = await response.json();
    setPermissions(data.roleDetails);
  });

  const filteredRoles = useMemo(() => {
    return permissions.filter(roleDetails => {
      return (
        (roleDetails.description ?? '')
          .toLowerCase()
          .includes((filters.text ?? '').toLowerCase()) ||
        (roleDetails.display_name ?? '')
          .toLowerCase()
          .includes((filters.text ?? '').toLowerCase())
      );
    });
  }, [filters.text, permissions]);

  const paginatedRoles = useMemo(() => {
    return filteredRoles.slice(
      pagination.offset,
      pagination.offset + pagination.limit,
    );
  }, [filteredRoles, pagination]);

  const reloadTable = useCallback(() => {
    setPagination({ ...pagination, offset: 0 });
  }, [setPagination, pagination]);

  const contextValue = useMemo(
    () => ({
      filters,
      changeFilters: <K extends keyof RoleViewFilters>(
        key: K,
        filterValue: RoleViewFilters[K],
      ) => {
        setPagination(p => ({ ...p, offset: 0 }));
        setFilters(f => ({ ...f, [key]: filterValue }));
      },
      pagination,
      setPagination,
      roles: paginatedRoles,
      rolesCount: filteredRoles.length,
      reloadTable,
    }),
    [filters, pagination, paginatedRoles, filteredRoles.length, reloadTable],
  );

  return (
    <PermissionDrawerContext.Provider value={contextValue}>
      {children}
    </PermissionDrawerContext.Provider>
  );
};

export const usePermissionDrawerContext = () =>
  React.useContext(PermissionDrawerContext);
