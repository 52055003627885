import { Box, IconButton, useTheme } from '@material-ui/core';
import React from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClear: () => void;
  isOpened: boolean;
  maxWidth?: string;
  selected?: boolean;
  hideClear?: boolean;
}

export const FilterInput: React.FC<Props> = ({
  onClick,
  onClear,
  isOpened,
  maxWidth = 'none',
  selected,
  children,
  hideClear,
}) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        maxWidth,
        height: '45px',
        borderRadius: '4px',
        padding: '6px 8px 6px 12px',
        border: `1px solid ${theme.palette.grey[300]}`,
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        cursor: 'pointer',
        background: Boolean(selected)
          ? theme.palette.grey[100]
          : theme.palette.background.default,
      }}
      onClick={onClick}
    >
      {children}

      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {selected && (
          <>
            {!hideClear && (
              <IconButton
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClear();
                }}
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}

            <Box
              style={{
                width: '1px',
                height: '20px',
                background: theme.palette.grey[300],
                margin: '0px 5px',
              }}
            />
          </>
        )}

        <IconButton
          style={{
            transition: 'all 0.3s',
            transform: isOpened ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          onClick={onClick}
          size="small"
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
