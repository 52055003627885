import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useEnabledFeatures } from '../context';

const DEFAULT_URL = 'marketplace/products-graph';

export const useHomeUrl = () => {
  let homeURL = DEFAULT_URL;

  const { loading, error, data } = useEnabledFeatures();

  const config = useApi(configApiRef);

  const configUrl = config.getOptionalString('mesh.homeUrl');

  if (!loading && data.catalogEnabled) {
    homeURL = 'catalog/my-data-products';
  } else {
    homeURL = 'marketplace/products-graph';
  }

  if (configUrl) {
    homeURL = configUrl;
  }

  return { loading, error, homeURL };
};
