import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'wb-platform-settings',
});

export const ontologyRouteRef = createSubRouteRef({
  id: 'wb-platform-settings-ontology',
  parent: rootRouteRef,
  path: '/ontology',
});

export const importOntologyRouteRef = createSubRouteRef({
  id: 'wb-platform-settings-ontology-import',
  parent: rootRouteRef,
  path: '/ontology-import',
});
