import { gql } from '@apollo/client/core';

export const GET_DOWNTIMES_BY_INSTANCE_ID_AND_ENV_ID = gql`
  query GetDowntimesByInstanceIdAndEnvId($id: Int!, $envId: Int!) {
    Observability: marketplace_Observability(
      where: { DataProductInstance: { id: { _eq: $id } } }
    ) {
      ObservabilityInstances(where: { Environment: { id: { _eq: $envId } } }) {
        ObservabilityData(where: { metric: { _eq: "DataDowntime" } }) {
          value
          timestamp
          metric
          unit
        }
      }
    }
  }
`;

export const GET_STATUS_BY_INSTANCE_ID_AND_ENV_ID_AND_OBS_ID = gql`
  query GetStatusByInstanceIdAndEnvId($id: Int!, $envId: Int!) {
    Observability: marketplace_Observability(
      where: {
        _and: [
          { DataProductInstance: { id: { _eq: $id } } }
          { name: { _eq: "DQ Metrics" } }
        ]
      }
    ) {
      ObservabilityInstances(where: { Environment: { id: { _eq: $envId } } }) {
        ObservabilityData(where: { metric: { _eq: "Status" } }) {
          value
          timestamp
          metric
        }
      }
    }
  }
`;
