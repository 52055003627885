/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Entity,
  getEntitySourceLocation,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import {
  EntityRefLinks,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { JsonArray } from '@backstage/types';
import { SvgIcon, Box, createStyles, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { LinksGridList } from '../EntityLinksCard/LinksGridList';
import { generateURNByKind } from '@agilelab/plugin-wb-builder-common';
import {
  GenericGridItem,
  isJsonArray,
  isJsonObject,
  isTagArray,
  Tag,
  WbFieldLabel,
  WbGenericFieldGrid,
  WbTagsArray,
} from '@agilelab/plugin-wb-platform';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import _, { get } from 'lodash';
import { IconLink } from '../EntityLinksCard/IconLink';
import {
  camelToSpacedCase,
  isEntityRef,
} from '@agilelab/plugin-wb-platform-common';
import { PRACTICE_SHAPER_ENTITES } from '../common/types';
/**
 * Props for {@link AboutContent}.
 *
 * @public
 */
export interface AboutContentProps {
  entity: Entity;
}

function getLocationTargetHref(
  target: string,
  type: string,
  entitySourceLocation: {
    type: string;
    target: string;
  },
): string {
  if (type === 'url' || target.includes('://')) {
    return target;
  }

  const srcLocationUrl =
    entitySourceLocation.type === 'file'
      ? `file://${entitySourceLocation.target}`
      : entitySourceLocation.target;

  if (type === 'file' || entitySourceLocation.type === 'file') {
    return new URL(target, srcLocationUrl).href;
  }

  return srcLocationUrl;
}

const useStyles = makeStyles(theme =>
  createStyles({
    link: { ...theme.typography.body1 },
  }),
);

const useEllieAIModelUrl = (entity: Entity) => {
  const config = useApi(configApiRef);
  const isSchema =
    (entity.spec?.type as String)?.toLocaleLowerCase('en-us') === 'schema';
  if (isSchema) {
    const accountId = config.getOptionalString(
      'integrations.ellieai.accountId',
    );
    const modelIdProperty = config.getOptionalString(
      'integrations.ellieai.modelIdProperty',
    );
    const modelId = modelIdProperty
      ? get(entity.spec, modelIdProperty, undefined)
      : undefined;
    return accountId && modelId
      ? `https://${accountId}.ellie.ai/#/models/logical/${modelId}`
      : undefined;
  }
  return undefined;
};

const SchemaIcon = () => (
  <SvgIcon>
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="SchemaIcon"
    >
      <path d="M14 9v2h-3V9H8.5V7H11V1H4v6h2.5v2H4v6h2.5v2H4v6h7v-6H8.5v-2H11v-2h3v2h7V9h-7z" />
    </svg>
  </SvgIcon>
);

/** @public */
export function AboutContent(props: AboutContentProps) {
  const { entity } = props;
  const isSystem = entity.kind.toLocaleLowerCase('en-US') === 'system';
  const isResource = entity.kind.toLocaleLowerCase('en-US') === 'resource';
  const isComponent = entity.kind.toLocaleLowerCase('en-US') === 'component';
  const isAPI = entity.kind.toLocaleLowerCase('en-US') === 'api';
  const isTemplate = entity.kind.toLocaleLowerCase('en-US') === 'template';
  const isLocation = entity.kind.toLocaleLowerCase('en-US') === 'location';
  const isGroup = entity.kind.toLocaleLowerCase('en-US') === 'group';
  const ellieAIModelUrl = useEllieAIModelUrl(entity);

  const classes = useStyles();

  const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'system',
  });
  const partOfComponentRelations = getEntityRelations(
    entity,
    RELATION_PART_OF,
    {
      kind: 'component',
    },
  );
  const partOfDomainRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'domain',
  });
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

  let entitySourceLocation:
    | {
        type: string;
        target: string;
      }
    | undefined;
  try {
    entitySourceLocation = getEntitySourceLocation(entity);
  } catch (e) {
    entitySourceLocation = undefined;
  }

  const isPracticeShaperEntity = PRACTICE_SHAPER_ENTITES.has(entity.kind);

  const tags: Tag[] = useMemo(() => {
    const mesh = entity.spec?.mesh;
    const fqnTags =
      mesh &&
      isJsonObject(mesh) &&
      isJsonArray(mesh.tags) &&
      isTagArray(mesh.tags)
        ? mesh.tags
        : [];
    const metadata = entity.metadata;
    const metadataTags = isJsonArray(metadata.tags)
      ? metadata.tags.map(t => {
          return { tagFQN: t };
        })
      : [];
    fqnTags.push(...metadataTags);
    return fqnTags;
  }, [entity.spec?.mesh, entity.metadata]);

  const gridFields: GenericGridItem[] = [
    {
      label: 'URN',
      value: generateURNByKind(entity.metadata.name, entity.kind),
      colSpan: { xs: 4, sm: 2, lg: 2 },
    },
  ];

  if (ellieAIModelUrl)
    gridFields.push({
      label: 'Logical Model',
      value: (
        <IconLink
          href={ellieAIModelUrl}
          text="View on Ellie.AI"
          Icon={SchemaIcon}
        />
      ),
      colSpan: { xs: 4, sm: 2, lg: 1 },
    });

  gridFields.push({
    label: 'Description',
    value: entity?.metadata?.description || 'No description',
    colSpan: { xs: 4, sm: 2, lg: 2 },
  });

  // on practice shaper entities the owner shouldn't be shown
  if (!isPracticeShaperEntity) {
    gridFields.push({
      label: 'Owner',
      value: !!ownedByRelations.length ? (
        <EntityRefLinks
          entityRefs={ownedByRelations}
          defaultKind="group"
          className={classes.link}
        />
      ) : (
        'No Owner'
      ),
      colSpan: { xs: 4, sm: 2, lg: 1 },
    });
  }

  if (isSystem || !!partOfDomainRelations.length)
    gridFields.push({
      label: 'Domain',
      value: !!partOfDomainRelations.length ? (
        <EntityRefLinks
          entityRefs={partOfDomainRelations}
          defaultKind="domain"
          className={classes.link}
        />
      ) : (
        'No Domain'
      ),
      colSpan: { xs: 4, sm: 2, lg: 1 },
    });

  if (isAPI || isComponent || isResource || !!partOfSystemRelations.length)
    gridFields.push({
      label: 'System',
      value: !!partOfSystemRelations.length ? (
        <EntityRefLinks
          entityRefs={partOfSystemRelations}
          defaultKind="system"
          className={classes.link}
        />
      ) : (
        'No System'
      ),
      colSpan: { xs: 4, sm: 2, lg: 1 },
    });

  if (isComponent && !!partOfComponentRelations.length)
    gridFields.push({
      label: 'Parent Component',
      value: (
        <EntityRefLinks
          entityRefs={partOfComponentRelations}
          defaultKind="component"
          className={classes.link}
        />
      ),
      colSpan: { xs: 4, sm: 2, lg: 1 },
    });

  if (
    isAPI ||
    isComponent ||
    isResource ||
    isTemplate ||
    isGroup ||
    isLocation ||
    typeof entity?.spec?.type === 'string'
  )
    gridFields.push({
      label: 'Type',
      value: entity?.spec?.type,
      colSpan: { xs: 4, sm: 2, lg: 1 },
    });

  if (isAPI || isComponent || typeof entity?.spec?.lifecycle === 'string')
    gridFields.push({
      label: 'Lifecycle',
      value: entity?.spec?.lifecycle,
      colSpan: { xs: 4, sm: 2, lg: 1 },
    });

  if (isLocation && (entity?.spec?.targets || entity?.spec?.target))
    gridFields.push({
      label: 'Targets',
      value: (
        <LinksGridList
          cols={1}
          items={((entity.spec.targets as JsonArray) || [entity.spec.target])
            .map(target => target as string)
            .map(target => ({
              text: target,
              href: getLocationTargetHref(
                target,
                (entity?.spec?.type || 'unknown') as string,
                entitySourceLocation!,
              ),
            }))}
        />
      ),
      colSpan: { xs: 4, sm: 2, lg: 1 },
    });

  // for practice shaper entities, add all other spec properties to the grid to show
  if (isPracticeShaperEntity && entity.spec) {
    Object.entries(entity.spec).forEach(([label, val]) => {
      if (typeof val === 'object') return;
      let value: React.ReactNode = val;
      if (typeof val === 'string' && isEntityRef(val)) {
        value = <EntityRefLinks entityRefs={[val]} className={classes.link} />;
      }

      gridFields.push({
        label: camelToSpacedCase(label),
        value,
        colSpan: { xs: 4, sm: 2, lg: 1 },
      });
    });
  }

  return (
    <>
      <WbGenericFieldGrid
        fields={gridFields}
        borderAfterLastRow={!!tags.length}
      />
      {!!tags.length ? (
        <Box
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <WbFieldLabel label="TAGS" />
          <WbTagsArray tags={tags} />
        </Box>
      ) : null}
    </>
  );
}
