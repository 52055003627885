import {
  TableCellProps,
  WbCardActionButton,
  WbTable,
  WbWidget,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import React, { useEffect } from 'react';
import { PracticeShaperEntity } from '../../types';
import { Box, Divider, SvgIcon, Tooltip, Typography } from '@material-ui/core';
import GetApp from '@material-ui/icons/GetApp';
import { useMigrationContext } from '../../hooks/migration/useMigrationContext';
import { generateNewEntities } from '../../utils/newEntities.utils';
import { downloadFile } from '../../utils/utils';
import yaml from 'yaml';
import { useApi } from '@backstage/core-plugin-api';
import { Alert } from '@material-ui/lab';
import { HelpIcon } from '@backstage/core-components';

const kindSorting = new Map([
  ['Taxonomy', 0],
  ['DomainType', 1],
  ['SystemType', 2],
  ['ComponentType', 3],
]);

function YamlFileIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 480 511.65">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 480 511.65"
      >
        <path d="M84.68 237.33H375.8v-81.86h-86.02c-9.02 0-21.62-4.88-27.56-10.83-5.95-5.95-9.6-16.68-9.6-25.7V31.81H33.92c-.77 0-1.34.39-1.72.77-.58.38-.77.96-.77 1.73v443.23c0 .58.38 1.34.77 1.73.38.57 1.15.77 1.72.77h339.39c.76 0 .72-.39 1.1-.77.58-.39 1.39-1.15 1.39-1.73v-46.46H84.68c-17.25 0-31.47-14.16-31.47-31.47V268.79c0-17.31 14.16-31.46 31.47-31.46zm1.86 52.82h29.79l17.57 29.23 17.48-29.23h29.63l-33.71 50.47v36.36h-26.92v-36.36l-33.84-50.47zm143.04 72.52h-30.4l-4.36 14.31h-27.39l32.68-86.83h29.37l32.54 86.83h-28.09l-4.35-14.31zm-5.68-18.79-9.48-31.21-9.52 31.21h19zm44.32-53.73h35.4l13.48 52.84 13.52-52.84h35.23v86.83H343.9v-66.19l-16.94 66.19h-19.89l-16.9-66.19v66.19h-21.95v-86.83zm109.98 0H405v65.49h41.96v21.34H378.2v-86.83zm28.98-52.82h41.36c17.3 0 31.46 14.2 31.46 31.46v130.82c0 17.26-14.2 31.47-31.46 31.47h-41.36v56.4c0 6.72-2.69 12.66-7.1 17.08-4.41 4.41-10.36 7.09-17.07 7.09H24.17c-6.71 0-12.66-2.68-17.07-7.09C2.69 500.14 0 494.2 0 487.48V24.37C0 17.65 2.69 11.7 7.1 7.29 11.51 2.88 17.65.19 24.17.19h244.49c.58-.19 1.16-.19 1.73-.19 2.69 0 5.37 1.15 7.29 2.88h.38c.39.19.58.38.96.77l124.9 126.43c2.11 2.1 3.64 4.98 3.64 8.24 0 .96-.19 1.73-.38 2.69v96.32zM281.13 116.45V37.22l89.22 90.36h-78.09c-3.07 0-5.75-1.34-7.86-3.26-1.92-1.92-3.27-4.8-3.27-7.87z" />
      </svg>{' '}
    </SvgIcon>
  );
}

const sortPositionByKind = (e: PracticeShaperEntity) =>
  kindSorting.get(e.kind) ?? kindSorting.size;

const newEntitiesColumns: TableCellProps<PracticeShaperEntity>[] = [
  {
    field: 'kind',
    cellProps: {
      width: '5%',
      align: 'center',
    },
    fieldRender: _e => <YamlFileIcon />,
  },
  {
    headerName: 'Kind',
    field: 'kind',
    cellProps: {
      width: '10%',
    },
    fieldRender: e => (
      <Typography variant="body2" style={{ fontWeight: 'bold' }}>
        {e.kind}
      </Typography>
    ),
  },
  {
    headerName: 'Name',
    field: 'name',
    fieldRender: e => <Typography variant="body2">{e.name}</Typography>,
  },
  {
    headerName: 'Description',
    field: 'description',
    fieldRender: e => <Typography variant="body2">{e.description}</Typography>,
  },
  {
    field: 'name',
    cellProps: {
      align: 'right',
      width: '10%',
    },
    fieldRender: entity => (
      <span>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          style={{ gap: 8 }}
        >
          <Tooltip title="Download catalog-info.yaml">
            <WbCardActionButton
              label="Download"
              color="primary"
              size="small"
              icon={<GetApp />}
              variant="outlined"
              loading={undefined}
              disabled={undefined}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                const download = async () => {
                  const { newContent } = await entity.fetchCatalogInfoContent();
                  downloadFile(
                    yaml.stringify(newContent),
                    'catalog-info.yaml',
                    'text/yaml',
                  );
                };
                download();
              }}
            />
          </Tooltip>
        </Box>
      </span>
    ),
  },
];

async function downloadUnifiedFile(
  entities: PracticeShaperEntity[],
): Promise<void> {
  let catalogInfo: string = '';
  const promises = entities.map(async e => {
    const { newContent: entityCatalogInfo } = await e.fetchCatalogInfoContent();
    catalogInfo += `---\n${yaml.stringify(entityCatalogInfo)}`;
  });
  await Promise.all(promises);
  downloadFile(catalogInfo, 'catalog-info.yaml', 'text/yaml');
}

export const NewEntitiesWidget = (props: {
  onEntitySelected?: (entity: PracticeShaperEntity) => void;
}) => {
  const { fetchResourceTypesState } = useMigrationContext();
  const entities = fetchResourceTypesState.loading
    ? []
    : generateNewEntities({
        resourceTypes: fetchResourceTypesState.value ?? [],
      }).sort((a, b) => sortPositionByKind(a) - sortPositionByKind(b));

  const alertApi = useApi(customAlertApiRef);

  useEffect(() => {
    if (fetchResourceTypesState.error) {
      alertApi.post({
        message: `Error while fetching entities: ${fetchResourceTypesState.error.message}`,
        severity: 'error',
      });
    }
  }, [fetchResourceTypesState, alertApi]);

  return (
    <WbWidget
      title="New entities to register"
      actions={
        <Tooltip title="Download a single unified catalog-info.yaml file containing the entities definitions">
          <WbCardActionButton
            label="Download as single file"
            color="primary"
            size="small"
            icon={<GetApp />}
            variant="contained"
            loading={undefined}
            disabled={fetchResourceTypesState.loading}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              downloadUnifiedFile(entities);
            }}
          />
        </Tooltip>
      }
    >
      <Box padding={1}>
        <Alert
          severity="info"
          variant="outlined"
          icon={<HelpIcon />}
          style={{ border: 'none' }}
        >
          <Typography variant="body2">
            Download, save in a repository, and register the{' '}
            <code>catalog-info.yaml</code> file of the following Practice Shaper
            entities. This will help you shape and register a default Data Mesh
            taxonomy starting from the currently registered systems and
            components
          </Typography>
        </Alert>
      </Box>
      <Divider />
      <WbTable<PracticeShaperEntity>
        components={{
          tableLoader: { loading: fetchResourceTypesState.loading },
          tableContent: {
            columns: newEntitiesColumns,
            rows: entities,
            onRowClick: props.onEntitySelected,
          },
        }}
      />
    </WbWidget>
  );
};
