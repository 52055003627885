import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navigation: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.error.main,
      '& p': {
        fontWeight: 700,
      },
      '& button': {
        color: theme.palette.error.main,
        '&:disabled': {
          color: theme.palette.error.light,
        },
      },
    },
  }),
);

interface ErrorNavigationProps {
  current: number;
  total: number;
  handleSwitch: (index: number) => void;
}
export const ErrorNavigation: React.FC<ErrorNavigationProps> = props => {
  const classes = useStyles();

  return (
    <Box className={classes.navigation}>
      <IconButton
        aria-label="back"
        title="Back"
        onClick={() => props.handleSwitch(props.current - 1)}
        disabled={props.current === 0}
        size="small"
      >
        <ArrowLeftIcon />
      </IconButton>
      <Typography variant="body2">{`${props.current + 1} of ${
        props.total
      } Error${props.total > 1 ? 's' : ''}`}</Typography>
      <IconButton
        aria-label="back"
        title="Next"
        onClick={() => props.handleSwitch(props.current + 1)}
        disabled={props.current === props.total - 1}
        size="small"
      >
        <ArrowRightIcon />
      </IconButton>
    </Box>
  );
};
