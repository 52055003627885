import React from 'react';
import { WmFieldExtensionComponentProps } from '../../../extensions/types';
import { WbTextField } from '@agilelab/plugin-wb-platform';
import { extractCustomProperties, isHidden } from '../../utils';

export const RegexPicker = (
  props: WmFieldExtensionComponentProps<string, any>,
) => {
  const {
    onChange,
    schema: { title = 'RegExPicker', description = 'RegEx picker component' },
    required,
    rawErrors,
    formData,
    idSchema,
    placeholder,
    uiSchema,
  } = props;
  const userSpecifiedPlaceholder = uiSchema['ui:placeholder'] ?? placeholder;
  const customProps = extractCustomProperties(uiSchema);

  return (
    <WbTextField
      id={idSchema?.$id}
      style={{ display: isHidden(uiSchema) ? 'none' : undefined }}
      label={title}
      placeholder={userSpecifiedPlaceholder}
      helperText={description}
      required={required}
      value={formData ?? ''}
      onChange={({ target: { value } }) => onChange(value)}
      error={rawErrors?.length > 0 && !formData}
      {...customProps}
    />
  );
};
