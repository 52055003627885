import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { TerminationMode } from '@agilelab/plugin-wb-builder-common';
import { WbCardActionButton } from '@agilelab/plugin-wb-platform';
import { ForceTerminateIcon } from './Icons';
import { useReleaseDetailPageContext } from '../../context/useReleaseDetailPageContext';
import CloseIcon from '@material-ui/icons/Close';

interface TerminateDialogProps {
  open: boolean;
  onClose: () => any;
}

function TerminateDialogComponent(props: TerminateDialogProps): JSX.Element {
  const { open, onClose } = props;

  const theme = useTheme();
  const { terminate } = useReleaseDetailPageContext();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle style={{ backgroundColor: theme.palette.bkg.primary }}>
        <Typography variant="body1"> Terminate</Typography>
        <IconButton
          size="small"
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" style={{ gap: '16px' }}>
          <Typography variant="h6">
            Termination of the provisioning process has been requested.
          </Typography>
          <Box>
            <Typography variant="body2">
              <span style={{ fontWeight: 'bold' }}>Terminate tasks: </span>
              stops the execution of all tasks currently running.
            </Typography>
            <Typography variant="caption">
              (No other tasks will be affected byt this action.)
            </Typography>
          </Box>

          <Typography variant="body2">
            <span style={{ fontWeight: 'bold' }}>Force quit: </span>
            will terminate the entire process.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Tooltip
            title={`The plan's execution will be abruptly halted without the transmission of any terminal signals.`}
          >
            <WbCardActionButton
              size="small"
              icon={<ForceTerminateIcon />}
              onClick={_ => {
                terminate(TerminationMode.FORCED);
                onClose();
              }}
              variant="outlined"
              style={{
                color: theme.palette.error.main,
              }}
              label="Force Quit"
            />
          </Tooltip>

          <Box display="flex" style={{ gap: '8px' }}>
            <WbCardActionButton
              color="primary"
              size="small"
              onClick={_ => {
                onClose();
              }}
              variant="outlined"
              label="Cancel"
            />
            <Tooltip
              title={`All running tasks within the plan will receive a terminal signal, 
prompting the plan to wait until each task undergoes a managed termination routine. 
If certain tasks do not support termination, the plan will wait until they are finished.
The execution of tasks in a waiting status will not be prevented.`}
            >
              <WbCardActionButton
                size="small"
                onClick={_ => {
                  terminate(TerminationMode.SAFE);
                  onClose();
                }}
                style={{
                  backgroundColor: theme.palette.error.main,
                }}
                variant="contained"
                label="Terminate tasks"
              />
            </Tooltip>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export const TerminateDialog = React.memo(TerminateDialogComponent);
