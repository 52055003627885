import {
  Box,
  Drawer,
  Theme,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useControlPanel } from '../../useControlPanel';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '../../../../api';
import { Log } from '@agilelab/plugin-wb-builder-common';
import {
  GenericField,
  WbCard,
  WbCardContent,
  WbLogsCard,
} from '@agilelab/plugin-wb-platform';
import { getDagStatusColor, StepIcon } from '../useStepIcon';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    height: '100%',
    width: '50%',
  },
  drawerContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  logTimeContainer: {
    display: 'flex',
    gap: 37,
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    width: '100%',
    gap: 50,
    flexWrap: 'wrap',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& svg': {
      width: '24px',
      height: '24px',
      marginRight: theme.spacing(1),
    },
  },
}));

export const LogsDrawer: React.FC = () => {
  const theme = useTheme();
  const panelCatalog = useApi(panelCatalogApiRef);
  const identityApi = useApi(identityApiRef);
  const classes = useStyles();
  const { selectedDeploy, isLogsDrawerOpen, setIsLogsDrawerOpen } =
    useControlPanel();

  const [logs, setLogs] = useState<Log[]>([]);

  useEffect(() => {
    if (selectedDeploy && isLogsDrawerOpen) {
      const loadData = async () => {
        const logsResponse = await panelCatalog.getLogs(
          selectedDeploy.id,
          undefined,
          await identityApi.getCredentials(),
        );
        setLogs(logsResponse);
      };

      loadData();
    }
  }, [identityApi, panelCatalog, selectedDeploy, isLogsDrawerOpen]);

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classes.drawerPaper,
      }}
      PaperProps={{
        style: {
          width: '80%',
        },
      }}
      transitionDuration={200}
      open={!!isLogsDrawerOpen}
      onClose={() => setIsLogsDrawerOpen(false)}
    >
      {selectedDeploy && (
        <Box className={classes.drawerContent}>
          <Box className={classes.header}>
            <StepIcon status={selectedDeploy.status} />
            <Typography color="primary" variant="h6">
              {selectedDeploy.idRelease}
            </Typography>
          </Box>

          <WbCard title="Details">
            <WbCardContent>
              <Box className={classes.row}>
                <GenericField
                  label="Start Time"
                  value={selectedDeploy.deployDate}
                  type="date"
                />
                {selectedDeploy.deployEndDate && (
                  <GenericField
                    label="Stop Time"
                    value={selectedDeploy.deployEndDate}
                    type="date"
                  />
                )}
                <GenericField
                  label="Status"
                  value={selectedDeploy.status}
                  type="text"
                  contentProps={{
                    style: {
                      color: getDagStatusColor(selectedDeploy.status, theme),
                    },
                  }}
                />
                <GenericField
                  label="Action"
                  value={selectedDeploy.action}
                  type="text"
                />
              </Box>
            </WbCardContent>
          </WbCard>
          <WbLogsCard
            title="Log File"
            logs={logs}
            style={{ height: '100%', marginTop: '16px', overflow: 'auto' }}
          />
        </Box>
      )}
    </Drawer>
  );
};
