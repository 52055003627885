import { DiscoveryApi } from '@backstage/core-plugin-api';
import { Notification, NotificationType } from './types';
import fetch from 'cross-fetch';
import { handleFailedResponse } from '@agilelab/plugin-wb-platform-common';

export declare type NotificationRequestOptions = {
  token?: string;
};

export interface NotificationApi {
  updateNotificationResponse(
    id: string,
    notification_response: any,
    options?: NotificationRequestOptions,
  ): Promise<any>;

  countUnreadByRecipient(
    recipient: string,
    options?: NotificationRequestOptions,
  ): Promise<{ count: number }>;

  readNotification(
    id: string,
    options?: NotificationRequestOptions,
  ): Promise<any>;

  readAllNotifications(
    recipient: string,
    options?: NotificationRequestOptions,
  ): Promise<any>;

  paginatedByRecipient(
    recipient: string,
    offset: number,
    limit: number,
    kind?: NotificationType,
    isRead?: boolean,
    response_status?: string,
    options?: NotificationRequestOptions,
  ): Promise<{ total: number; notifications: Notification[] }>;

  getNotificationQuestions(
    options?: NotificationRequestOptions,
  ): Promise<{ total: number; notifications: Notification[] }>;

  getNotificationByDataProductInstanceID(
    id_dataproduct_instance: string,
    kind: string,
    options?: NotificationRequestOptions,
  ): Promise<any>;

  addNotificationRequest(
    sender: string,
    recipient: string,
    hook_id: string | null,
    kind: string,
    notification_request: any,
    options?: NotificationRequestOptions,
  ): Promise<any>;

  triggerNotificationEvent(
    entityRef: string,
    eventType: string,
    options?: NotificationRequestOptions,
  ): Promise<any>;
}

export class NotificationClient implements NotificationApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly baseUrlPromise: Promise<string>;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = options.discoveryApi;
    this.baseUrlPromise = this.discoveryApi.getBaseUrl('notifications');
  }

  private async getCredentials(
    options?: NotificationRequestOptions,
  ): Promise<string> {
    if (options?.token && options.token) {
      return options.token;
    }
    return '';
  }

  async addNotificationRequest(
    sender: string,
    recipient: string,
    hook_id: string | null,
    kind: string,
    notification_request: any,
    options?: NotificationRequestOptions,
  ): Promise<any> {
    const baseUrl = await this.baseUrlPromise;

    const body = {
      notification_request: notification_request,
      sender: sender,
      recipient: recipient,
      kind: kind.toString(),
      hook_id: hook_id ? hook_id : undefined,
    };

    const response = await fetch(`${baseUrl}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await this.getCredentials(options)}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    await handleFailedResponse(response);

    return response.json();
  }

  async updateNotificationResponse(
    id: string,
    notification_response: any,
    options?: NotificationRequestOptions,
  ): Promise<any> {
    const baseUrl = await this.baseUrlPromise;

    const response = await fetch(
      `${baseUrl}/${id}/response/?${new URLSearchParams({
        replyToSender: 'true', // TODO: this is to be chosen by the user in the UI
      })}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notification_response: notification_response }),
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async readNotification(
    id: string,
    options?: NotificationRequestOptions,
  ): Promise<any> {
    const baseUrl = await this.baseUrlPromise;

    const response = await fetch(`${baseUrl}/${id}/read`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${await this.getCredentials(options)}`,
        'Content-Type': 'application/json',
      },
    });
    await handleFailedResponse(response);

    return response.json();
  }

  async readAllNotifications(
    recipient: string,
    options?: NotificationRequestOptions,
  ): Promise<any> {
    const baseUrl = await this.baseUrlPromise;

    const response = await fetch(
      `${baseUrl}/read/all?${new URLSearchParams({
        recipient,
      })}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async countUnreadByRecipient(
    recipient: string,
    options?: NotificationRequestOptions,
  ): Promise<{ count: number }> {
    const baseUrl = await this.baseUrlPromise;

    const response = await fetch(
      `${baseUrl}/unread/count?${new URLSearchParams({
        recipient: recipient,
      })}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async getNotificationByDataProductInstanceID(
    id_dataproduct_instance: string,
    kind: string,
    options?: NotificationRequestOptions,
  ): Promise<any> {
    const baseUrl = await this.baseUrlPromise;

    const response = await fetch(
      `${baseUrl}?${new URLSearchParams({
        kind: kind.toString(),
        id_dataproduct_instance: id_dataproduct_instance,
      })}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
      },
    );

    return (await response.json()).notifications;
  }

  /**
   * Returns an object like
   * {
   *     total: total number of questions found
   *     notification: array of timestamps of questions
   * }
   */
  async getNotificationQuestions(
    options?: NotificationRequestOptions,
  ): Promise<{ total: number; notifications: Notification[] }> {
    const baseUrl = await this.baseUrlPromise;

    const response = await fetch(
      `${baseUrl}?${new URLSearchParams({
        kind: NotificationType.QUESTION,
        onlyTimestamps: 'true',
      })}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  /**
   * Returns an object like
   * {
   *     total: total number of notifications found
   *     notification: array of notification offset and limited by parameters
   * }
   */
  async paginatedByRecipient(
    recipient: string,
    offset: number,
    limit: number,
    kind?: NotificationType,
    isRead?: boolean,
    response_status?: string,
    options?: NotificationRequestOptions,
  ): Promise<{ total: number; notifications: Notification[] }> {
    const baseUrl = await this.baseUrlPromise;

    const response = await fetch(
      `${baseUrl}?${new URLSearchParams({
        recipient: recipient,
        offset: offset.toString(),
        limit: limit.toString(),
        ...(kind ? { kind: kind.toString() } : {}),
        ...(isRead !== undefined ? { isRead: String(isRead) } : {}),
        ...(response_status
          ? { response_status: response_status.toString() }
          : {}),
      })}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }

  async triggerNotificationEvent(
    entityRef: string,
    eventType: string,
    options?: NotificationRequestOptions,
  ) {
    const baseUrl = await this.baseUrlPromise;

    fetch(
      `${baseUrl}/informative/by-event?${new URLSearchParams({
        entityRef: entityRef,
        event: eventType,
      })}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${await this.getCredentials(options)}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
