import {
  customAlertApiRef,
  WbCardActionButton,
  WbLink,
} from '@agilelab/plugin-wb-platform';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { useMemo } from 'react';
import { useReleasePageContext } from './context/useReleasePageContext';
import {
  identityApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import parseGitUrl from 'git-url-parse';
import { panelCatalogApiRef } from '../../api';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { builderDpSnapshotCreatePermission } from '@agilelab/plugin-wb-rbac-common';
import { usePermission } from '@backstage/plugin-permission-react';
import { ButtonProps, Tooltip } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { releaseDetailRouteRef } from '../../routes';
import { getReleaseInfo } from '../../utils';
import { getEntitySourceLocation } from '@backstage/plugin-catalog-react';
import {
  EntityAndBranch,
  WitboostVersionedEntity,
} from '@agilelab/plugin-wb-builder-common';
import { scmIntegrationsApiRef } from '@backstage/integration-react';

export function generateSnapshotButtonTooltip(
  allowed: boolean,
  disabled: boolean,
  loading?: boolean,
  differentDpName?: boolean,
  entity?: WitboostVersionedEntity,
) {
  if (loading) {
    return 'Descriptor is still loading, please wait until it is ready';
  }
  if (differentDpName) {
    return `You cannot run tests on a different data product, please use the same data product '${
      entity?.metadata.name ?? ''
    }' and version ${entity?.spec.mesh.version ?? ''}`;
  }
  if (!allowed) {
    return 'You are not allowed to create a new draft release.';
  }
  if (!disabled) {
    return 'Create a new draft release on the main repository. A draft release is an intermediate state between the latest release and the new one. The draft release will have the SNAPSHOT suffix';
  }
  return "You can't create a new draft release since there is already an active one. To create a new draft release, you need to promote to release the current one first.";
}

type Props = ButtonProps;

export const NewDraftReleaseButton: React.FC<Props> = props => {
  const {
    isLoading,
    isReleasing,
    setIsReleasing,
    addRelease,
    entity,
    releases,
    relatedEntities,
  } = useReleasePageContext();
  const panelCatalog = useApi(panelCatalogApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);

  const entitySourceLocation = useMemo(() => {
    return entity ? getEntitySourceLocation(entity, scmIntegrationsApi) : null;
  }, [entity, scmIntegrationsApi]);

  const navigate = useNavigate();
  const releaseDetailRoute = useRouteRef(releaseDetailRouteRef);

  const { allowed: canCreateSnapshot } = usePermission({
    permission: builderDpSnapshotCreatePermission,
    resourceRef: stringifyEntityRef({
      kind: entity.kind ?? 'system',
      namespace: 'default',
      name: entity.metadata.name,
    }),
  });

  const createSnapshot = async () => {
    try {
      const entities: EntityAndBranch[] = [entity, ...relatedEntities!].map(
        en => {
          const branchFromLocationUrl = entitySourceLocation?.locationTargetUrl
            ? parseGitUrl(entitySourceLocation.locationTargetUrl).ref
            : 'master';

          return {
            branch: branchFromLocationUrl,
            entityRef: stringifyEntityRef(en),
          };
        },
      );

      setIsReleasing(true);
      const newRelease = await panelCatalog.createSnapshot(
        entity.metadata.name,
        await identityApi.getCredentials(),
        entities,
      );

      const { kind, namespace, name } = getReleaseInfo(newRelease);

      addRelease({ ...newRelease, deployments: [] });
      alertApi.post({
        actions: (
          <WbLink
            route={releaseDetailRoute({ kind, namespace, name })}
            text={`Open V${newRelease?.metadata?.version}`}
            navigate={navigate}
          />
        ),
        message: 'New Draft-Release created successfully.',
        severity: 'success',
      });
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      setIsReleasing(false);
    }
  };

  const hasAlreadySnapshot = useMemo(
    () => Boolean(releases.find(release => release.metadata.isSnapshot)),
    [releases],
  );

  return (
    <Tooltip
      title={generateSnapshotButtonTooltip(
        canCreateSnapshot,
        hasAlreadySnapshot,
      )}
    >
      <span>
        <WbCardActionButton
          icon={<AddCircleIcon />}
          label="New Draft Release"
          loading={isReleasing}
          disabled={isLoading || hasAlreadySnapshot || !canCreateSnapshot}
          onClick={createSnapshot}
          {...props}
        />
      </span>
    </Tooltip>
  );
};
