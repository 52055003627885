import { getEnvironmentsFromConfig } from '@agilelab/plugin-wb-platform-common';
import { Config } from '@backstage/config';
import {
  BasicPermission,
  createPermission,
  ResourcePermission,
} from '@backstage/plugin-permission-common';

/**
 * Resource type binds a permission request the correctly typed integration router and condition exports
 * e.g.
 * we could have a dedicated integration router for data products and another one for domain.
 * each one will load from catalog the corresponding entities
 */
export const RESOURCE_TYPE_WITBOOST_ENTITY = 'witboost-entity';

export const builderDpSnapshotCreatePermission = createPermission({
  name: 'builder.dp.snapshot.create',
  attributes: {},
  resourceType: RESOURCE_TYPE_WITBOOST_ENTITY,
});

export const builderDpReleasePermission = createPermission({
  name: 'builder.dp.release',
  attributes: {},
  resourceType: RESOURCE_TYPE_WITBOOST_ENTITY,
});

export const builderDpCommitPermission = createPermission({
  name: 'builder.dp.commit',
  attributes: {},
  resourceType: RESOURCE_TYPE_WITBOOST_ENTITY,
});

export const builderDpDeployPermission = (env: string) => {
  return createPermission({
    name: `builder.dp.deploy.${env}`,
    attributes: {},
    resourceType: RESOURCE_TYPE_WITBOOST_ENTITY,
  });
};

export const builderDpPoliciesTest = createPermission({
  name: 'builder.dp.policies.test',
  attributes: {},
  resourceType: RESOURCE_TYPE_WITBOOST_ENTITY,
});

export const builderDpNewVersion = createPermission({
  name: 'builder.dp.newversion',
  attributes: {},
  resourceType: RESOURCE_TYPE_WITBOOST_ENTITY,
});

export const cgpEntityEdit = createPermission({
  name: 'cgp.entity.edit',
  attributes: {},
});

export const cgpEntityView = createPermission({
  name: 'cgp.entity.view',
  attributes: {},
});

export const platformSettingsEditPermission = createPermission({
  name: 'platform.settings.edit',
  attributes: {},
});

export const practiceShaperEditPermission = createPermission({
  name: 'practice-shaper.edit',
  attributes: {},
});

export const builderPermissions: (
  config: Config,
) => (ResourcePermission | BasicPermission)[] = (config: Config) => [
  builderDpSnapshotCreatePermission,
  builderDpReleasePermission,
  builderDpCommitPermission,
  ...getEnvironmentsFromConfig(config).map((env: string) =>
    builderDpDeployPermission(env),
  ),
  builderDpPoliciesTest,
  builderDpNewVersion,
];

export const cgpPermissions: () => (
  | ResourcePermission
  | BasicPermission
)[] = () => [cgpEntityView, cgpEntityEdit];

export const platformSettingsPermissions: () => (
  | ResourcePermission
  | BasicPermission
)[] = () => [platformSettingsEditPermission];

export const practiceShaperEditPermissions: () => (
  | ResourcePermission
  | BasicPermission
)[] = () => [practiceShaperEditPermission];
