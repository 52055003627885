export const GET_ENTITY_REF_BY_ACL_REQUEST_ACL = `
  query GetEntitiesRef($ids: [Int!]) {
    marketplace_ACL( where: {id_output_port: {_in: $ids}}, distinct_on: refs) {
      refs
    }
    marketplace_ACLRequest(where: {id_output_port: {_in: $ids}, status: {_neq: "COMPLETED"}}, distinct_on: refs) {
        refs
    }
}
`;
