import {
  TableCellProps,
  WbTruncatedTypographyWrapper,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import { ComponentWithStatus } from '../types';
import { Typography } from '@material-ui/core';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { ComponentDependencies } from '../ProvisioningCard/ComponentsTable/ComponentDependencies';
import { DeploymentPreviewChip } from '../DeploymentPreview/DeploymentPreviewChip';

export function useComponentsTableColumns(): TableCellProps<ComponentWithStatus>[] {
  return [
    {
      field: 'name',
      fieldRender: field => (
        <Typography variant="body2" style={{ fontWeight: 700 }}>
          {field.name}
        </Typography>
      ),
      headerName: 'Name',
    },
    {
      field: 'kind',
      fieldRender: field => (
        <Typography variant="body2">
          {snakeCaseToTitleCase(field.kind)}
        </Typography>
      ),
      headerName: 'Type',
    },
    {
      field: 'description',
      fieldRender: field => (
        <WbTruncatedTypographyWrapper value={field.description} />
      ),
      headerName: 'Description',
      cellProps: {
        width: '30%',
      },
    },
    {
      field: 'dependsOn',
      fieldRender: field => <ComponentDependencies component={field} />,
      headerName: 'Dependencies',
    },
    {
      field: 'status',
      fieldRender: field => (
        <DeploymentPreviewChip
          status={field.status}
          version={field.statusVersion}
        />
      ),
      headerName: 'Status',
    },
  ];
}
