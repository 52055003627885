/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import FormControl from '@material-ui/core/FormControl';
import React from 'react';

import { RepoUrlPickerState } from './types';
import { SelectItem } from '@backstage/core-components';
import { WbSelect, WbTextField } from '@agilelab/plugin-wb-platform';
import { MenuItem } from '@material-ui/core';

export const AzureRepoPicker = (props: {
  allowedOrganizations?: string[];
  allowedOwners?: string[];
  rawErrors: string[];
  state: RepoUrlPickerState;
  onChange: (state: RepoUrlPickerState) => void;
}) => {
  const {
    allowedOrganizations = [],
    allowedOwners = [],
    rawErrors,
    state,
    onChange,
  } = props;

  const organizationItems: SelectItem[] = allowedOrganizations
    ? allowedOrganizations.map(i => ({ label: i, value: i }))
    : [{ label: 'Loading...', value: 'loading' }];

  const ownerItems: SelectItem[] = allowedOwners
    ? allowedOwners.map(i => ({ label: i, value: i }))
    : [{ label: 'Loading...', value: 'loading' }];

  const { organization, owner } = state;

  return (
    <>
      <FormControl
        margin="normal"
        required
        error={rawErrors?.length > 0 && !organization}
      >
        {allowedOrganizations?.length ? (
          <WbSelect
            value={organization}
            label="Organization"
            onChange={e => onChange({ organization: e.target.value })}
            disabled={allowedOrganizations.length === 1}
            helperText="The Organization that this repo will belong to"
          >
            {organizationItems.map(o => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </WbSelect>
        ) : (
          <WbTextField
            label="Organization"
            id="orgInput"
            onChange={e => onChange({ organization: e.target.value })}
            value={organization}
            helperText="The Organization that this repo will belong to"
          />
        )}
      </FormControl>

      <FormControl
        margin="normal"
        required
        error={rawErrors?.length > 0 && !owner}
      >
        {allowedOwners?.length ? (
          <WbSelect
            label="Owner"
            onChange={e => onChange({ owner: e.target.value })}
            disabled={allowedOwners.length === 1}
            helperText="The Project that this repo will belong to"
            value={owner}
          >
            {ownerItems.map(o => (
              <MenuItem value={o.value} key={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </WbSelect>
        ) : (
          <WbTextField
            label="Project"
            id="ownerInput"
            onChange={e => onChange({ owner: e.target.value })}
            value={owner}
            helperText="The Project that this repo will belong to"
          />
        )}
      </FormControl>
    </>
  );
};
