import {
  TableCellProps,
  WbTable,
  WbWidget,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import React, { useEffect } from 'react';
import { ResourceType } from '../../types';
import {
  Box,
  Chip,
  Divider,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { useMigrationContext } from '../../hooks/migration/useMigrationContext';
import { EntityRefLink } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { Alert } from '@material-ui/lab';
import { HelpIcon } from '@backstage/core-components';

const isRegistered = (type: ResourceType) => type.referencedBy !== undefined;

const detectedResourceTypeColumns = (theme: Theme) =>
  [
    {
      headerName: 'Status',
      field: 'status',
      cellProps: {
        align: 'center',
        width: '10%',
      },
      fieldRender: e =>
        isRegistered(e) ? (
          <Tooltip title="Registered">
            <Chip
              label="Registered"
              variant="outlined"
              icon={
                <CheckCircle style={{ color: theme.palette.success.main }} />
              }
            />
          </Tooltip>
        ) : (
          <Tooltip title="Not registered">
            <RadioButtonUnchecked color="disabled" />
          </Tooltip>
        ),
    },
    {
      headerName: 'Name',
      field: 'name',
      fieldRender: e => (
        <Typography
          variant="body2"
          style={isRegistered(e) ? {} : { color: theme.palette.text.disabled }}
        >
          {e.name}
        </Typography>
      ),
    },
    {
      headerName: 'Kind',
      field: 'kind',
      fieldRender: e => (
        <Typography
          variant="body2"
          style={isRegistered(e) ? {} : { color: theme.palette.text.disabled }}
        >
          {e.kind === 'system' ? 'System type' : 'Component type'}
        </Typography>
      ),
    },
    {
      headerName: 'Referenced By',
      field: 'referencedBy',
      fieldRender: e => (
        <Typography
          variant="body2"
          style={isRegistered(e) ? {} : { color: theme.palette.text.disabled }}
        >
          {e.referencedBy ? (
            <EntityRefLink
              title={`${e.referencedBy.name} (${e.referencedBy.kind})`}
              entityRef={e.referencedBy}
            />
          ) : (
            '--'
          )}
        </Typography>
      ),
    },
  ] as TableCellProps<ResourceType>[];

export const DetectedResourceTypesWidget = () => {
  const { fetchResourceTypesState } = useMigrationContext();
  const theme = useTheme();
  const alertApi = useApi(customAlertApiRef);

  useEffect(() => {
    if (fetchResourceTypesState.error) {
      alertApi.post({
        message: `Error while fetching resource types: ${fetchResourceTypesState.error.message}`,
        severity: 'error',
      });
    }
  }, [fetchResourceTypesState, alertApi]);

  return (
    <WbWidget title="Detected resource types">
      <Box padding={1}>
        <Alert
          severity="info"
          variant="outlined"
          icon={<HelpIcon />}
          style={{ border: 'none' }}
        >
          <Typography variant="body2">
            The following resource types were detected by looking at the
            descriptors of existing systems and components. In the Practice
            Shaper, a resource type is considered registered when it is
            referenced in the <code>spec.resourceTypeId</code> property of a{' '}
            <code>SystemType</code> or <code>ComponentType</code>. Register the
            entities in the above panel to complete this step
          </Typography>
        </Alert>
      </Box>
      <Divider />
      <WbTable<ResourceType>
        components={{
          tableLoader: { loading: fetchResourceTypesState.loading },
          tableContent: {
            columns: detectedResourceTypeColumns(theme),
            rows:
              fetchResourceTypesState.value?.sort(
                (a, b) => +isRegistered(a) - +isRegistered(b),
              ) ?? [],
          },
        }}
      />
    </WbWidget>
  );
};
