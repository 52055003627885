import React, { Fragment } from 'react';
import { WbLink } from '../WbLink';
import { makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router';

type Props = {
  value: string;
  href?: string;
};

const useStyles = makeStyles(() => ({
  ellipsis: {
    display: 'inline-block',
    maxWidth: 250,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const SimpleItem = ({ value, href }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return href ? (
    <WbLink
      route={href}
      externalLink={href.startsWith('http') ? href : undefined}
      className={classes.ellipsis}
      navigate={navigate}
      underlineLink="hover"
      text={value}
      showTooltip
    />
  ) : (
    <Fragment>{value}</Fragment>
  );
};
