import { FieldProps } from '@rjsf/core';
import React, { useEffect } from 'react';
import { WmUiSchema } from '../../../extensions/types';
import { WbTextField } from '@agilelab/plugin-wb-platform';
import { generateComponentIdentifier } from '@agilelab/plugin-wb-platform-common';
import { extractCustomProperties, isHidden } from '../../utils';

export const allowArbitraryValues = (uiSchema: WmUiSchema): boolean =>
  (uiSchema['ui:options']?.allowArbitraryValues as boolean) ?? true;

export const ComponentIdentifierPicker = ({
  schema: {
    title = 'Identifier',
    description = 'Unique identifier of the component',
  },
  uiSchema,
  formContext,
  formData,
  onChange,
  placeholder,
  idSchema,
  required,
  rawErrors,
}: FieldProps<string>) => {
  useEffect(() => {
    onChange(
      generateComponentIdentifier(formContext.name, formContext.dataproduct) ??
        '',
    );
  }, [formContext.dataproduct, formContext.name, onChange]);
  const autoFocus = uiSchema['ui:autofocus'];
  const customProps = extractCustomProperties(uiSchema);
  return (
    <WbTextField
      id={idSchema?.$id}
      style={{ display: isHidden(uiSchema) ? 'none' : undefined }}
      label={title}
      disabled={!allowArbitraryValues(uiSchema)}
      placeholder={placeholder}
      helperText={description}
      required={required}
      value={formData ?? ''}
      onChange={({ target: { value } }) => onChange(value)}
      error={rawErrors?.length > 0 && !formData}
      inputProps={{ autoFocus }}
      {...customProps}
    />
  );
};
