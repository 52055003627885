import { customAlertApiRef } from '@agilelab/plugin-wb-platform';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useCallback } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { panelCatalogApiRef } from '../../../api';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import {
  Action,
  TargetStatus,
  generateURNByKind,
} from '@agilelab/plugin-wb-builder-common';
import { Component, DeploymentPreview } from '../types';

export function useDeploymentPreviewOperations(ids: string[]) {
  const { queryParamEnvironment, environment, release } =
    useReleaseDetailPageContext();

  const alertApi = useApi(customAlertApiRef);
  const identityApi = useApi(identityApiRef);
  const panelCatalogApi = useApi(panelCatalogApiRef);

  const loadComponent = useCallback(async (componentId: string) => {
    return panelCatalogApi.getComponentDeploymentUnitsProvisioningPreview(
      generateURNByKind(release.metadata.dataProductName, 'system'),
      componentId,
      queryParamEnvironment || environment.name,
      true,
      await identityApi.getCredentials(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [previewOperations, fetchPreviewOperations] = useAsyncFn(async () => {
    try {
      const descriptors = await Promise.all(ids.map(id => loadComponent(id)));

      const previews = ids.reduce((acc, curr) => {
        const relatedDescriptor = descriptors.find(d =>
          (d.provisioningDetails.descriptor.components as Component[]).find(
            c => c.id === curr,
          ),
        );
        if (relatedDescriptor) {
          const currentComponent = (
            relatedDescriptor.provisioningDetails.descriptor
              .components as Component[]
          ).find(c => c.id === curr);
          if (currentComponent) {
            const preview: DeploymentPreview = {
              ...currentComponent,
              status: relatedDescriptor.status,
              action: Action.UNDEPLOY,
              newState: TargetStatus.NOT_DEPLOYED,
              statusVersion:
                relatedDescriptor.provisioningDetails.descriptorVersion,
            };
            return [...acc, preview];
          }
        }
        return acc;
      }, [] as DeploymentPreview[]);

      return previews;
    } catch (error) {
      alertApi.post({ error, severity: 'error' });
      return [];
    }
  }, [ids]);

  return { previewOperations, fetchPreviewOperations };
}
