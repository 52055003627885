import { AppTheme } from '@backstage/core-plugin-api';
import {
  createTheme,
  genPageTheme,
  lightTheme,
  shapes,
} from '@backstage/theme';
import { Color, CssBaseline, ThemeProvider, Theme } from '@material-ui/core';
import { BackstageOverrides } from '@backstage/core-components';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { AlertClassKey } from '@material-ui/lab/Alert';
import React, { ComponentType, ReactElement, ReactNode } from 'react';
import { Typography } from '@material-ui/core/styles/createTypography';
import { RenderResult } from '@testing-library/react';
import {
  TestAppOptions,
  createTestAppWrapper,
  renderInTestApp,
} from '@backstage/test-utils';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

interface CustomPaletteColor {
  primary: string;
  secondary: string;
  accent: string;
  accentSecondary: string;
  success: string;
  warning: string;
  error: string;
}

interface CustomBannerColor {
  info: string;
  error: string;
  text: string;
  link: string;
}

interface MarketplaceGraphPalette {
  groupRange: Pick<PaletteColor, 'light' | 'dark'>;
  groupLabel: React.CSSProperties['color'];
  groupTooltip: Pick<PaletteColor, 'contrastText' | 'main'>;
  heatmapRange: Pick<PaletteColor, 'light' | 'dark'>;
  systemMain: Pick<PaletteColor, 'contrastText' | 'main'>;
  systemDisconnected: React.CSSProperties['color'];
  systemInput: React.CSSProperties['color'];
  systemOutput: React.CSSProperties['color'];
  systemLabel: React.CSSProperties['color'];
  systemTooltip: Pick<PaletteColor, 'contrastText' | 'main'>;
  navigationGroupSelected: React.CSSProperties['color'];
}

export interface RelationsGraphPalette {
  error: React.CSSProperties['color'];
  warning: React.CSSProperties['color'];
  dataContract: React.CSSProperties['color'];
  dataAtRest: React.CSSProperties['color'];
  dataProduct: React.CSSProperties['color'];
  resource: React.CSSProperties['color'];
  consumer: React.CSSProperties['color'];
  push: React.CSSProperties['color'];
  consumption: React.CSSProperties['color'];
  component: React.CSSProperties['color'];
  domain: React.CSSProperties['color'];
  taxonomy: React.CSSProperties['color'];
  user: React.CSSProperties['color'];
  consumptionWarning: React.CSSProperties['color'];
  dataProductBorder: React.CSSProperties['color'];
  resourceBorder: React.CSSProperties['color'];
  dataContractBorder: React.CSSProperties['color'];
}

type ChartsColors = React.CSSProperties['color'][];

type PaletteColorWithoutContrastText = Omit<PaletteColor, 'contrastText'>;

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    witboostLogo: string;
    blueDeep: string;
    blueSky: string;
    lightBlue: string;
    white: string;
    black: string;
    blueGrey: Omit<Color, 'A100' | 'A200' | 'A400' | 'A700'>;
    accent: PaletteColorWithoutContrastText;
    accentSecondary: PaletteColorWithoutContrastText;
    bkg: CustomPaletteColor;
    softened: CustomPaletteColor;
    banner: CustomBannerColor;
    marketplaceGraph: MarketplaceGraphPalette;
    relationsGraph: RelationsGraphPalette;
    charts: ChartsColors;
    favs: string;
  }
  interface PaletteOptions extends Palette {}
}
// Labs types not included in overrides; https://github.com/mui/material-ui/issues/19427
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAutocomplete: AutocompleteClassKey;
  }
}

export const createCustomThemeOverrides = (
  theme: Theme,
): BackstageOverrides => {
  return {
    ...lightTheme.overrides,
    BackstageHeader: {
      header: {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        boxShadow: 'none',
        padding: theme.spacing(2, 6),
      },
      title: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: theme.typography.h5.fontSize,
      },
      subtitle: {
        marginTop: '12px',
        letterSpacing: '-0.015rem',
        color: theme.palette.text.primary,
      },
      type: {
        color: theme.palette.text.primary,
      },
      rightItemsBox: {
        color: theme.palette.primary.main,
        margin: 0,
      },
    },
    BackstageHeaderLabel: {
      label: {
        color: theme.palette.primary.main,
      },
      value: {
        color: theme.palette.primary.main,
      },
      root: {
        color: theme.palette.primary.main,
      },
    },
    BackstageContent: {
      root: {
        padding: `0 !important`,
        background: `${theme.palette.background.paper} 0% 0% no-repeat padding-box`,
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(6),
        marginBottom: theme.spacing(6),
      },
    },
    BackstageHeaderTabs: {
      selected: {
        color: `${theme.palette.primary.main} !important`,
      },
      tabsWrapper: {
        paddingLeft: theme.spacing(5),
        paddingBottom: theme.spacing(2),
        '& button': {
          padding: `0 !important`,
          margin: `0 8px !important`,
          minWidth: 0,
          color: theme.palette.text.primary,
          opacity: 1,

          textTransform: 'capitalize',
          fontSize: '1.125rem',
          fontWeight: 500,
          letterSpacing: '0.009rem',
          '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'inherit',
          },
        },
      },
    },
    BackstageInfoCard: {
      header: {
        padding: theme.spacing(1.5, 2),
        '& span': {
          margin: 0,
        },
      },
      headerSubheader: {
        paddingTop: 0,
      },
    },
    BackstageTable: {
      root: {
        '& > div': {
          backgroundColor: theme.palette.common.white,
        },
        '& tr:hover': {
          backgroundColor: theme.palette.bkg.primary,
        },
      },
    },
    BackstageTableToolbar: {
      root: {
        padding: theme.spacing(2, 0, 2, 2),
      },
      title: {
        '& > *': {
          fontSize: theme.typography.h6.fontSize,
          margin: 0,
        },
      },
    },
    BackstageTableHeader: {
      header: {
        backgroundColor: theme.palette.primary.main,
        '& *': {
          fill: theme.palette.getContrastText(theme.palette.primary.dark),
          color: `${theme.palette.getContrastText(
            theme.palette.primary.dark,
          )} !important`,
        },
      },
    },
    // This additional theme customization is required to set the DismissableBanner closeButtonColor
    // This problem is solved in the latest Backstage version (set theme.palette.banner.closeButtonColor in basicTheme below)
    BackstageDismissableBanner: {
      content: {
        color: theme.palette.banner.text,
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: '0px 3px 6px #00000029',
      },
    },
    MuiBreadcrumbs: {
      ol: {
        marginTop: theme.spacing(1),
        color: '#B0BEC5',
      },
      li: {
        '&:last-child > p': {
          color: `${theme.palette.primary.main} !important`,
        },
        '& a:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(32, 89, 97, .6)',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
      },
    },
    MuiDialogTitle: {
      root: {
        '& > h2': {
          letterSpacing: '0px',
          fontWeight: 500,
          color: `${theme.palette.primary.main} !important`,
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: theme.spacing(3),
      },
    },
    MuiDrawer: {
      paper: {
        boxShadow: 'none',
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(3),
      },
    },
    MuiButton: {
      root: {
        borderRadius: '20px',
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        border: '1px solid',
        '&:hover': {
          border: '1px solid',
        },
      },
      sizeSmall: {
        textTransform: 'none',
      },
      sizeLarge: {
        textTransform: 'none',
      },
    },
    MuiTableHead: {
      root: {
        '& th': {
          color: theme.palette.primary.main,
          textTransform: 'uppercase',
          fontWeight: 700,
          '& span': {
            color: `${theme.palette.primary.main}`,
            textTransform: 'uppercase',
            fontWeight: 700,
          },
        },
      },
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          backgroundColor: `${theme.palette.bkg.primary} !important`,
        },
      },
      hover: {
        '&:hover': {
          backgroundColor: `${theme.palette.bkg.primary} !important`,
          color: theme.palette.getContrastText(theme.palette.primary.light),
        },
      },
    },
    MuiTableSortLabel: {
      active: {
        color: `${theme.palette.primary.main} !important`,
      },
      root: {
        color: theme.palette.primary.main,
      },
      icon: {
        fill: theme.palette.primary.main,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 0,
      },
      stickyHeader: {
        backgroundColor: theme.palette.background.default,
      },
    },
    MuiCardHeader: {
      root: {
        backgroundColor: theme.palette.bkg.primary,
        color: theme.palette.primary.main,
        padding: theme.spacing(1.5, 2),
      },
      title: {
        fontWeight: 700,
        margin: 0,
        fontSize: theme.typography.h6.fontSize,
      },
      action: {
        marginTop: 0,
      },
    },
    MuiCardContent: {
      root: {
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiChip: {
      colorPrimary: {
        backgroundColor: theme.palette.bkg.primary,
        color: theme.palette.text.primary,
      },
      deleteIconColorPrimary: {
        color: theme.palette.text.primary,

        '&:hover': {
          color: theme.palette.text.primary,
        },
      },
    },
    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          background: theme.palette.grey[100],
        },
      },
    },
  };
};

export const basicTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    witboostLogo: '#ee961a',
    blueDeep: '#20284d',
    blueSky: '#00acc4',
    lightBlue: '#6cdce0',
    bkg: {
      primary: '#e6f7f9',
      secondary: '#edf0f2',
      accent: '#f2edff',
      accentSecondary: '#fdf1fb',
      success: '#e6faee',
      warning: '#fef6e9',
      error: '#ffeeee',
    },
    softened: {
      primary: '#b0e5ed',
      secondary: '#c8d1d8',
      accent: '#d6c8ff',
      accentSecondary: '#fad3f1',
      success: '#b0eeca',
      warning: '#fde4bb',
      error: '#ffc9c9',
    },
    primary: {
      light: '#8ad9e4',
      main: '#00acc4',
      dark: '#005f6c',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#aebac5',
      main: '#4e6980',
      dark: '#2b3a46',
      contrastText: '#FFFFFF',
    },
    white: '#FFFFFF',
    black: '#000000',
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    blueGrey: {
      50: '#eceff1',
      100: '#cfd8dc',
      200: '#b0bec5',
      300: '#90a4ae',
      400: '#78909c',
      500: '#607d8b',
      600: '#546e7a',
      700: '#455a64',
      800: '#37474f',
      900: '#263238',
    },
    accent: {
      light: '#c3adff',
      main: '#7c4dff',
      dark: '#442a8c',
    },
    accentSecondary: {
      light: '#f7beeb',
      main: '#ee72d3',
      dark: '#833f74',
    },
    success: {
      light: '#8ae6b0',
      main: '#00c853',
      dark: '#006e2e',
    },
    warning: {
      light: '#fcd79b',
      main: '#f9a825',
      dark: '#895c14',
    },
    error: {
      light: '#ffafaf',
      main: '#ff5252',
      dark: '#8c2d2d',
    },
    favs: '#FFD600',
    // Useful to override default MUI theme backstage
    text: {
      primary: '#4E6980',
      secondary: '#4E6980',
    },
    info: {
      light: '#34548a',
      main: '#34548a',
      dark: '#263d65',
    },
    background: {
      default: '#FFFFFF',
      paper: '#fafafa',
    },
    // TODO: understand how to convert to existing color
    banner: {
      info: '#343b58',
      error: '#F3ECED',
      text: '#381A20',
      link: '#381A20',
    },
    marketplaceGraph: {
      groupRange: {
        light: '#cbe6eb',
        dark: '#6ebcce',
      },
      groupLabel: '#000000',
      groupTooltip: {
        main: '#54687f',
        contrastText: '#FFFFFF',
      },
      heatmapRange: {
        light: '#a6d0e2',
        dark: '#7274d0',
      },
      systemMain: {
        main: '#7274d0',
        contrastText: '#FFFFFF',
      },
      systemDisconnected: '#7274d04d',
      systemInput: '#3b5488',
      systemOutput: '#ee72d3',
      systemLabel: '#FFFFFF',
      systemTooltip: {
        main: '#54687f',
        contrastText: '#FFFFFF',
      },
      navigationGroupSelected: '#00acc414',
    },
    relationsGraph: {
      error: '#F6675C',
      warning: '#F9A825',
      taxonomy: '#F9A825',
      dataContract: '#00AEC5',
      dataAtRest: '#4F6980',
      dataProduct: '#4F6980',
      resource: '#388AA1',
      component: '#00AEC5',
      domain: '#388AA1',
      user: '#388AA1',
      consumer: '#1BACAD',
      push: '#4F6980',
      consumption: '#12A5C7',
      consumptionWarning: '#12A5C7',
      dataProductBorder: '#34485A',
      resourceBorder: '#34485A',
      dataContractBorder: '#1090A1',
    },
    charts: [
      '#7c4dff',
      '#8ad9e4',
      '#ee72d3',
      '#fde4bb',
      '#ff5252',
      '#833f74',
      '#00acc4',
      '#ffafaf',
      '#1430B8',
    ],
  },
  fontFamily: 'Poppins',
  defaultPageTheme: 'home',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#00acc2', '#00778c'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#00acc2', '#00778c'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#00acc2', '#00778c'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#00acc2', '#00778c'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#00acc2', '#00778c'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#00acc2', '#00778c'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#00acc2', '#00778c'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#00acc2', '#00778c'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#00acc2', '#00778c'], shape: shapes.wave }),
  },
});

export const createTypography = (_theme: Theme): Typography => {
  return {
    ...basicTheme.typography,
  };
};

const theme: Theme = {
  ...basicTheme,
  overrides: {
    // These are your custom overrides, either to `material-ui` or Backstage components.
    ...createCustomThemeOverrides(basicTheme),
  },
  typography: {
    ...createTypography(basicTheme),
  },
};

// TODO remove this if Backstage changes this to pass a custom theme
export function wrapInWitboostTestApp(
  Component: ComponentType | ReactNode,
  options: TestAppOptions = {},
): ReactElement {
  const TestAppWrapper = createTestAppWrapper(options);

  let wrappedElement: React.ReactElement;
  if (Component instanceof Function) {
    wrappedElement = <Component />;
  } else {
    wrappedElement = Component as React.ReactElement;
  }

  return (
    <TestAppWrapper>
      <ThemeProvider theme={theme}>{wrappedElement}</ThemeProvider>
    </TestAppWrapper>
  );
}

// TODO remove this if Backstage changes this to pass a custom theme
export async function renderInWitboostTestApp(
  Component: ComponentType | ReactNode,
  options: TestAppOptions = {},
): Promise<RenderResult> {
  let wrappedElement: React.ReactElement;
  if (Component instanceof Function) {
    wrappedElement = <Component />;
  } else {
    wrappedElement = Component as React.ReactElement;
  }

  const target = <ThemeProvider theme={theme}>{wrappedElement}</ThemeProvider>;

  return renderInTestApp(target, options);
}

export const LightAgileTheme: Partial<AppTheme> & Omit<AppTheme, 'theme'> = {
  id: 'theme-light',
  title: 'Light',
  variant: 'light',
  Provider: ({ children }) => {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    );
  },
};
