import { gql } from '@apollo/client/core';

export const GET_DATA_PRODUCT_INSTANCE_DEPENDENCIES_BY_ID_AND_ENV_ID = gql`
  query GetDataProductInstanceDependenciesByIdAndEnvId(
    $id: Int!
    $envId: Int!
  ) {
    DataProductInstance: marketplace_DataProductInstance(
      where: { id: { _eq: $id }, id_environment: { _eq: $envId } }
    ) {
      InputPorts(
        where: {
          _or: [
            { kind: { _eq: "external" } }
            {
              _and: [
                {
                  OutputPort: {
                    DataProductInstance: {
                      DataProduct: { type: { _eq: "dataproduct" } }
                    }
                  }
                }
                {
                  OutputPort: {
                    DataProductInstance: {
                      Environment: { id: { _eq: $envId } }
                    }
                  }
                }
              ]
            }
          ]
        }
      ) {
        kind
        Resource {
          id
          name
          display_name
          kind
        }
        OutputPort {
          id
          DataProductInstance {
            id
            version
            DataProduct {
              id
              name
              display_name
              Domain {
                name
                sub_domain_of
              }
            }
          }
        }
      }
      DataProduct {
        id
        name
        display_name
        Domain {
          name
          sub_domain_of
        }
        data_product_owner
        data_product_owner_display_name
      }
      OutputPorts(
        where: {
          _and: [
            { DataProductInstance: { id_environment: { _eq: $envId } } }
            {
              DataProductInstance: {
                DataProduct: { type: { _eq: "dataproduct" } }
              }
            }
            { InputPorts: { id: { _is_null: false } } }
          ]
        }
      ) {
        id
        InputPorts(
          where: {
            _and: [
              { DataProductInstance: { Environment: { id: { _eq: $envId } } } }
              {
                DataProductInstance: {
                  DataProduct: { type: { _eq: "dataproduct" } }
                }
              }
            ]
          }
        ) {
          id
          kind
          DataProductInstance {
            id
            version
            Environment {
              name
            }
            DataProduct {
              id
              name
              display_name
              Domain {
                name
                sub_domain_of
              }
            }
          }
        }
      }
    }
  }
`;
