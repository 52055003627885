import { UiSchema } from '@rjsf/core';
import { WmCompleteUiSchema } from '../extensions/types';

export function extractCustomProperties<
  T extends Record<string, any> = UiSchema,
>(uiSchema: T) {
  const customProps: any = {};
  const customStyle = uiSchema && uiSchema['ui:style'];
  if (customStyle) {
    customProps.InputProps = {
      style: customStyle,
    };
  }
  return customProps;
}

export function isHidden<T extends UiSchema, U extends WmCompleteUiSchema>(
  uiSchema: T | U,
): boolean {
  const isHiddenWidget = (s: T | U) =>
    s?.['ui:widget'] ? s['ui:widget'] === 'hidden' : false;
  // checks for legacy reasons if the ui:widget property is inside ui:options as well(deprecated, don't do that)
  const isLegacyHidden = (s: T | U) =>
    (s?.['ui:options'] as any)?.['ui:widget']
      ? (s['ui:options'] as any)?.['ui:widget'] === 'hidden'
      : false;
  return isHiddenWidget(uiSchema) || isLegacyHidden(uiSchema);
}
