import React, { useEffect } from 'react';
import { useReleaseDetailPageContext } from '../../context/useReleaseDetailPageContext';
import { LatestProvisioningDetail } from './LatestProvisioningDetail';
import { Skeleton } from '@material-ui/lab';
import { Box, Divider } from '@material-ui/core';

export const LatestProvisioning: React.FC = () => {
  const { fetchDeploys, deploysState, setTestTaskId } =
    useReleaseDetailPageContext();
  const { loading, value } = deploysState;

  useEffect(() => {
    fetchDeploys({ includeSnapshots: false });
  }, [fetchDeploys]);

  useEffect(() => {
    if (!loading && value?.[0]?.steps?.[0]?.subPlanId) {
      setTestTaskId(value[0].steps[0].subPlanId);
    }
  }, [value, loading, setTestTaskId]);

  return (
    <Box>
      {loading && <Skeleton width="100%" height={50} />}

      {!loading && value && value?.[0] && (
        <Box>
          <LatestProvisioningDetail deploy={value?.[0]} />
          <Divider style={{ marginTop: '8px', marginBottom: '16px' }} />
        </Box>
      )}
    </Box>
  );
};
