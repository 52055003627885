import React from 'react';
import { Header, Link } from '@backstage/core-components';
import { useReleaseDetailPageContext } from './context/useReleaseDetailPageContext';
import { Box, Breadcrumbs, Typography } from '@material-ui/core';

export const ReleaseDetailHeader: React.FC = () => {
  const { release, queryParamVersion, dataProductName } =
    useReleaseDetailPageContext();

  return (
    <Header
      title={
        <Box>
          {dataProductName}{' '}
          <b>{queryParamVersion || release.metadata.version}</b>
        </Box>
      }
      subtitle={
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            witboost
          </Link>
          <Typography>Builder</Typography>
          <Link color="inherit" to="/catalog">
            Software Catalog
          </Link>

          <Link
            color="inherit"
            to={`/catalog/${release.metadata.namespace || 'default'}/system/${
              release.metadata.dataProductName
            }`}
          >
            {dataProductName}
          </Link>

          <Link
            color="inherit"
            to={`/catalog/${release.metadata.namespace || 'default'}/system/${
              release.metadata.dataProductName
            }/release`}
          >
            Release
          </Link>

          <Typography color="textPrimary">
            {queryParamVersion || release.metadata.version}
          </Typography>
        </Breadcrumbs>
      }
      pageTitleOverride={release.metadata.name}
    />
  );
};
