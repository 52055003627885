import { Box, Button, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { useOveflowingElement } from '../../hooks/useOverflowingElement';

const useStyles = makeStyles(() =>
  createStyles({
    truncated: {
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 1,
      overflow: 'hidden',
      display: '-webkit-box',
      wordBreak: 'break-all',
    },
  }),
);

export interface ShowMoreElementProps {
  maxLines?: number;
}

export const WbShowMoreElement: React.FC<ShowMoreElementProps> = ({
  children,
  maxLines = 1,
}) => {
  const classes = useStyles();
  const ref = React.useRef<HTMLSpanElement>(null);
  const isOverflowing = useOveflowingElement(ref);
  const [showMore, setShowMore] = React.useState(false);

  return (
    <Box display="flex" alignItems="end">
      <span
        className={!showMore ? classes.truncated : ''}
        style={!showMore ? { WebkitLineClamp: maxLines } : undefined}
        ref={ref}
      >
        {children}
      </span>
      {(isOverflowing || showMore) && (
        <span>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShowMore(v => !v);
            }}
            style={{
              whiteSpace: 'nowrap',
              paddingTop: 0,
              paddingBottom: 0,
              textDecoration: 'underline',
            }}
          >
            Show {!showMore ? 'More' : 'Less'}
          </Button>
        </span>
      )}
    </Box>
  );
};
