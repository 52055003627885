import { z } from 'zod';

// If you change the following schemas, remember to change all the config.d.ts files that use this structure accordingly.
export const CustomMicrofrontendParametersConfigurationZod = z.object({
  name: z
    .string({
      required_error: "The parameter's name is required",
      invalid_type_error: "The parameter's name must be a string",
    })
    .trim()
    .min(1, "The parameter's name must have at least one character"),
  valuePath: z
    .string({
      required_error: "The parameter's value path is required",
      invalid_type_error: "The parameter's value must be a string",
    })
    .trim()
    .min(1, "The parameter's value must have at least one character"),
});

export const CustomMicrofrontendConfigurationZod = z.object({
  title: z
    .string({
      required_error: 'The title is required',
      invalid_type_error: 'The title must be a string',
    })
    .trim()
    .min(1, 'The title must have at least one character'),
  identifier: z
    .string({
      required_error: 'The identifier is required',
      invalid_type_error: 'The identifier must be a string',
    })
    .trim()
    .min(1, 'The identifier must have at least one character')
    .regex(
      RegExp('^[a-zA-Z0-9_-]*$'),
      'The identifier must contain only alphanumeric characters or - and/or _ (^[a-zA-Z0-9_-]*$)',
    ),
  url: z
    .string({
      required_error: 'The URL is required',
      invalid_type_error: 'The URL must be a string',
    })
    .trim()
    .url('The URL must be a valid URL'),
  route: z
    .string({
      required_error: 'The route is required',
      invalid_type_error: 'The route must be a string',
    })
    .trim()
    .regex(
      RegExp('^/[a-zA-Z0-9_-]*$'),
      'The route must start with a "/" and be a valid routing string for URL (^/[a-zA-Z0-9_-]*$)',
    ),
  mountFunction: z
    .string({
      required_error: 'The mount function is required',
      invalid_type_error: 'The mount function must be a string',
    })
    .trim()
    .min(1, 'The mount function must have at least one character'),
  unmountFunction: z
    .string({
      required_error: 'The unmount function is required',
      invalid_type_error: 'The unmount function must be a string',
    })
    .trim()
    .min(1, 'The unmount function must have at least one character'),
  parameters: z.array(CustomMicrofrontendParametersConfigurationZod),
});

export type CustomMicrofrontendConfiguration = z.infer<
  typeof CustomMicrofrontendConfigurationZod
>;

export type CustomMicrofrontendOptions = CustomMicrofrontendConfiguration & {
  data?: any;
};

/**
 * The target configuration to load.
 * When adding a new value here, remember to change the config.d.ts file accordingly by adding the relative configuration.
 */
export enum CustomMicrofrontendTarget {
  MarketplacePage = 'marketplace.page',
  MarketplaceDataProductPage = 'marketplace.dataProductPage',
  MarketplaceOutputPortPage = 'marketplace.outputPortPage',
}
