import {
  PanelNames,
  SaveDialog,
  WbCardActionButton,
  WbDiffEditor,
  WbDrawer,
  WbEditor,
  WbTag,
} from '@agilelab/plugin-wb-platform';
import {
  Box,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import GetApp from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyToClipboard, downloadFile } from '../../utils/utils';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { RepositoryFile } from '@agilelab/plugin-wb-builder-common';
import {
  alertApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '@agilelab/plugin-wb-builder-catalog';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export type CodeProps = {
  code: string;
  previousCode?: string;
  repositoryFile?: RepositoryFile;
};

export type CodeEntity = {
  title: string;
  tag?: string;
  fileName: string;
  fileType: 'text/yaml';
  language: string;
  fetchCode: () => Promise<CodeProps>;
  entity?: Entity;
};

const useStyles = makeStyles(() =>
  createStyles({
    saveButton: {
      marginRight: '5px',
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
);

export const CodeDrawer = (props: {
  codeEntity?: CodeEntity;
  onClose: () => void;
  onSuccessfullySave: () => void;
}) => {
  const { codeEntity, onClose, onSuccessfullySave } = props;
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const classes = useStyles();
  const panelCatalogApi = useApi(panelCatalogApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(alertApiRef);
  const catalogApi = useApi(catalogApiRef);

  const [fetchCodePropsState, fetchCodeProps] = useAsyncFn(
    async (entity: CodeEntity) => {
      const code = await entity.fetchCode();
      return code;
    },
  );

  const loading = fetchCodePropsState.loading;
  const codeProps = fetchCodePropsState.value;

  useEffect(() => {
    if (codeEntity) fetchCodeProps(codeEntity);
  }, [codeEntity, fetchCodeProps]);

  useEffect(() => {
    setOpen(codeEntity !== undefined);
  }, [codeEntity]);

  const onSaveToRepository = async (
    entity: any,
    content: string,
    commitMessage: string,
    branch?: string,
  ) => {
    try {
      setIsSaving(true);
      await panelCatalogApi.pushCatalogInfo(
        entity,
        content,
        commitMessage,
        branch,
        undefined,
        await identityApi.getCredentials(),
      );
      await catalogApi.refreshEntity(stringifyEntityRef(entity));
      setIsSaving(false);
      onSuccessfullySave();
    } catch (e) {
      alertApi.post({ message: e, severity: 'error' });
      setIsSaving(false);
    }
  };

  return codeEntity ? (
    <WbDrawer title={codeEntity.title} open={open} setOpen={onClose}>
      <Box
        display="flex"
        marginBottom="16px"
        style={{ gap: '12px' }}
        alignItems="center"
      >
        <Grid container>
          <Grid item xs={8}>
            <Box display="flex" style={{ gap: '4px' }} alignItems="center">
              <Typography color="primary" variant="h6">
                {codeEntity.title}
              </Typography>
              {codeEntity.tag && (
                <WbTag
                  tag={{
                    tagFQN: codeEntity.tag,
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end">
              <Tooltip title={`Copy ${codeEntity.fileName}`}>
                <WbCardActionButton
                  style={{
                    marginRight: 20,
                  }}
                  onClick={() => {
                    if (codeProps) copyToClipboard(codeProps.code);
                  }}
                  color="secondary"
                  variant="text"
                  label="Copy"
                  disabled={loading}
                  icon={<FileCopyIcon />}
                  iconColor="secondary"
                />
              </Tooltip>
              <Tooltip title={`Download ${codeEntity.fileName}`}>
                <WbCardActionButton
                  style={{
                    marginRight: 20,
                  }}
                  onClick={() => {
                    if (codeProps)
                      downloadFile(
                        codeProps.code,
                        codeEntity.fileName,
                        codeEntity.fileType,
                      );
                  }}
                  color="secondary"
                  variant="text"
                  disabled={loading}
                  label="Download"
                  icon={<GetApp />}
                  iconColor="secondary"
                />
              </Tooltip>
              {!loading && codeEntity.entity && codeProps?.repositoryFile && (
                <SaveDialog
                  classes={classes}
                  isLoading={isSaving}
                  defaultCommitMessage="Add generates property"
                  catalogInfo={codeProps.repositoryFile}
                  save={(commitMessage: string) =>
                    onSaveToRepository(
                      codeEntity.entity as Entity,
                      codeProps.code,
                      commitMessage,
                      codeProps.repositoryFile?.branch,
                    )
                  }
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 'calc(100vh - 125px)' }}>
        {loading && <LinearProgress />}

        {!loading && codeProps && codeProps.previousCode && (
          <>
            <PanelNames
              leftPanelTitle="Previous version"
              rightPanelTitle="New version"
            />
            <WbDiffEditor
              original={codeProps.previousCode}
              modified={codeProps.code}
              language={codeEntity.language}
              theme="diffEditorWizard"
              options={{
                selectOnLineNumbers: true,
                contextmenu: false,
                readOnly: true,
                diffCodeLens: true,
                originalEditable: false,
                glyphMargin: true,
                linkedEditing: true,
                minimap: {
                  enabled: true,
                },
              }}
            />
          </>
        )}

        {!loading && codeProps && !codeProps.previousCode && (
          <WbEditor
            value={codeProps.code}
            language={codeEntity.language}
            theme="white"
            options={{
              selectOnLineNumbers: true,
              contextmenu: false,
              readOnly: true,
              glyphMargin: true,
              linkedEditing: true,
              minimap: {
                enabled: false,
              },
            }}
          />
        )}
      </Box>
    </WbDrawer>
  ) : (
    <></>
  );
};
