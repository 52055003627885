import React from 'react';
import {
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  UIOptionsType,
  processSelectValue,
} from '@rjsf/utils';
import {
  WbCheckboxMenuItem,
  WbMultiSelect,
  WbSelect,
} from '@agilelab/plugin-wb-platform';
import { UiSchema, WidgetProps } from '@rjsf/core';
import { MenuItem, Popper, PopperProps } from '@material-ui/core';
import { isHidden } from '../../utils';

const WidePopper = (props: PopperProps) => {
  return <Popper {...props} style={{ width: 850 }} placement="bottom-start" />;
};

function extractCustomProperties(uiSchema: UiSchema) {
  const customProps: any = {};
  const widePopper =
    uiSchema && uiSchema['ui:popper'] && uiSchema['ui:popper'] === 'wide';
  const customStyle = uiSchema && uiSchema['ui:style'];
  if (widePopper) {
    customProps.PopperComponent = WidePopper;
  }
  if (customStyle) {
    customProps.InputProps = {
      style: customStyle,
    };
  }
  return customProps;
}

/**
 * This function was introduced since the RJSF processSelectValue is not capable of selecting
 * the value for boolean properties with ui:widget = select
 */
function processValue<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any,
>(schema: S, value?: any, options?: UIOptionsType<T, S, F>): any {
  const { type } = schema;
  if (type === 'boolean' && typeof value === 'boolean') {
    return value;
  }
  return processSelectValue(schema, value, options);
}

export const SelectWidget = ({
  schema,
  id,
  options,
  label,
  required,
  disabled,
  readonly,
  value,
  multiple,
  onChange,
  onBlur,
  onFocus,
  uiSchema,
  rawErrors = [],
}: WidgetProps) => {
  const { enumOptions, enumDisabled } = options;
  const emptyValue = multiple ? [] : '';

  const customProps = extractCustomProperties(uiSchema);

  const _onChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => onChange(processValue(schema, event.target.value, options));
  const _onBlur = (event: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, processValue(schema, event.target.value, options));
  const _onFocus = (event: React.FocusEvent<HTMLInputElement>) =>
    onFocus(id, processValue(schema, event.target.value, options));

  if (multiple) {
    return (
      <WbMultiSelect
        id={id}
        name={id}
        label={schema.title ?? label}
        style={{ display: isHidden(uiSchema) ? 'none' : undefined }}
        value={typeof value === 'undefined' ? emptyValue : value}
        required={required}
        disabled={disabled || readonly}
        error={rawErrors.length > 0}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        {...customProps}
      >
        {(enumOptions as any).map(
          ({ value: optionValue, label: optionLabel }: any, i: number) => {
            const disabledItem: any =
              enumDisabled && (enumDisabled as any).indexOf(optionValue) !== -1;
            return (
              <WbCheckboxMenuItem
                key={i}
                value={optionValue}
                disabled={disabledItem}
                checked={value.includes(optionValue)}
                label={optionLabel}
              />
            );
          },
        )}
      </WbMultiSelect>
    );
  }

  return (
    <WbSelect
      id={id}
      name={id}
      label={schema.title ?? label}
      style={{ display: isHidden(uiSchema) ? 'none' : undefined }}
      value={typeof value === 'undefined' ? emptyValue : value}
      required={required}
      disabled={disabled || readonly}
      error={rawErrors.length > 0}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      {...customProps}
      PopperComponent={WidePopper}
    >
      {(enumOptions as any).map(
        ({ value: optionValue, label: optionLabel }: any, i: number) => {
          const disabledItem: any =
            enumDisabled && (enumDisabled as any).indexOf(optionValue) !== -1;
          return (
            <MenuItem key={i} value={optionValue} disabled={disabledItem}>
              {optionLabel}
            </MenuItem>
          );
        },
      )}
    </WbSelect>
  );
};
