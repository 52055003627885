import { useMemo } from 'react';
import { useReleaseDetailPageContext } from '../../context/useReleaseDetailPageContext';
import { Component } from '../../types';
import React from 'react';
import { Typography } from '@material-ui/core';

interface Props {
  component: Component;
}

export const ComponentDependencies: React.FC<Props> = ({ component }) => {
  const { components } = useReleaseDetailPageContext();

  const componentsMap: Record<string, string> = useMemo(() => {
    return components?.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
      {},
    );
  }, [components]);

  return (
    <>
      {component.dependsOn?.map(d => (
        <Typography style={{ fontWeight: 500 }} variant="body2" color="primary">
          {componentsMap[d] || ''}
        </Typography>
      ))}
    </>
  );
};
