import {
  CollapseButton,
  WbCard,
  WbCardContent,
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
  WbDrawer,
} from '@agilelab/plugin-wb-platform';
import React, { useState } from 'react';
import { PracticesShaperGraph } from './PracticeShaperGraph';
import 'reactflow/dist/style.css';
import { EntityAboutCard } from '@agilelab/plugin-wb-builder-catalog';
import { usePracticeShaperGraph } from '../../context/PracticeShaperGraphContext';
import { EntityProvider } from '@backstage/plugin-catalog-react';
import { Legend } from './Legend';

export function PracticeShaperOverview() {
  const { selectedEntity, setSelectedEntity } = usePracticeShaperGraph();

  const [legendCollapse, setLegendCollapse] = useState(true);

  const entityName = selectedEntity
    ? (selectedEntity.metadata as any).displayName ||
      selectedEntity.metadata.name
    : '';

  return (
    <>
      <WbDockableGroup direction="horizontal" style={{ height: '70vh' }}>
        <WbDockablePanel
          collapsible
          defaultSize={30}
          tab={{
            isCollapsed: legendCollapse,
            setCollapsed: isCollapsed => setLegendCollapse(isCollapsed),
            title: 'Legend',
            direction: 'horizontal',
          }}
        >
          <WbCard
            title="Legend"
            leading={<CollapseButton onClick={() => setLegendCollapse(true)} />}
            cardStyle={{ height: '100%' }}
          >
            <WbCardContent style={{ height: '100%', overflowY: 'auto' }}>
              <Legend />
            </WbCardContent>
          </WbCard>
        </WbDockablePanel>
        <WbDockableResizeHandle direction="horizontal" />
        <WbDockablePanel defaultSize={70} collapsible>
          <WbCard title="Design" cardStyle={{ height: '100%' }}>
            <WbCardContent
              style={{
                height: '100%',
              }}
            >
              <PracticesShaperGraph />
            </WbCardContent>
          </WbCard>
        </WbDockablePanel>
      </WbDockableGroup>
      <EntityProvider entity={selectedEntity ? selectedEntity : undefined}>
        <WbDrawer
          open={!!selectedEntity}
          setOpen={() => setSelectedEntity(null)}
          title={entityName}
        >
          {selectedEntity && <EntityAboutCard />}
        </WbDrawer>
      </EntityProvider>
    </>
  );
}
