import { Config } from '@backstage/config';
import {
  CannotResolveGeneratedTypeError,
  EntityNotFoundError,
  GetComponentTypesRequest,
  GetComponentTypesResponse,
  GetDomainTypesRequest,
  GetDomainTypesResponse,
  GetSystemTypesRequest,
  GetSystemTypesResponse,
  GetTaxonomiesRequest,
  GetTaxonomiesResponse,
  PracticeShaperApi,
  RequestOptions,
  ResolveCreationTemplateGeneratedTypeRequest,
  ResolveCreationTemplateGeneratedTypeResponse,
} from './api';
import { Entity, getCompoundEntityRef } from '@backstage/catalog-model';
import {
  WitboostSystem,
  WitboostComponent,
  SystemType,
  ComponentType,
} from '@agilelab/plugin-wb-builder-common';
import { isComponentType, isSystemType } from './utils';
import { CatalogApi } from '@backstage/catalog-client';
import { BELONGS_TO, INSTANCE_OF } from './relations';
import { handleFailedResponse } from '@agilelab/plugin-wb-platform-common';
import { DiscoveryApi } from '@backstage/core-plugin-api';

export class PracticeShaperClient implements PracticeShaperApi {
  private readonly config: Config;
  private readonly catalogApi: CatalogApi;
  private readonly discoveryApi: DiscoveryApi;
  private readonly baseUrlPromise: Promise<string>;

  constructor(options: {
    config: Config;
    catalogApi: CatalogApi;
    discoveryApi: DiscoveryApi;
  }) {
    this.config = options.config;
    this.catalogApi = options.catalogApi;
    this.discoveryApi = options.discoveryApi;
    this.baseUrlPromise = this.discoveryApi.getBaseUrl('practice-shaper');
  }

  isDeployableSystem(entity: Entity): boolean {
    if (
      entity.kind.toLocaleLowerCase('en-US') !== 'system' ||
      !entity.spec?.type
    ) {
      return false;
    }
    const supportedSystemTypes = this.config.getStringArray(
      'practiceShaper.migration.supportedSystemTypes',
    );
    const systemType = entity.spec.type.toString();
    return (
      supportedSystemTypes.find(
        type =>
          type.toLocaleLowerCase('en-US') ===
          systemType.toLocaleLowerCase('en-US'),
      ) !== undefined
    );
  }

  async isMaintenanceActive(options?: RequestOptions): Promise<boolean> {
    const baseUrl = await this.baseUrlPromise;

    const response = await fetch(`${baseUrl}/maintenance`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${options?.token || ''}`,
      },
    });

    await handleFailedResponse(response);

    return response.json();
  }

  async getTaxonomies(
    request: GetTaxonomiesRequest,
    options?: RequestOptions,
  ): Promise<GetTaxonomiesResponse> {
    const { items: taxonomies } = await this.catalogApi.getEntities(
      {
        filter: {
          kind: 'Taxonomy',
          'spec.enabled': request.filter?.enabled?.toString() ?? [],
        },
      },
      options,
    );
    return { items: taxonomies } as GetTaxonomiesResponse;
  }

  async getDomainTypes(
    _request: GetDomainTypesRequest,
    options?: RequestOptions,
  ): Promise<GetDomainTypesResponse> {
    const { items: domainTypes } = await this.catalogApi.getEntities(
      {
        filter: {
          kind: 'DomainType',
        },
      },
      options,
    );
    return { items: domainTypes } as GetDomainTypesResponse;
  }

  async getSystemTypes(
    request: GetSystemTypesRequest,
    options?: RequestOptions,
  ): Promise<GetSystemTypesResponse> {
    const resourceTypeIdFilter = request.filter?.resourceTypeId ?? [];
    const taxonomyRefFilter = request.filter?.taxonomyRef ?? [];
    const { items: systemTypes } = await this.catalogApi.getEntities(
      {
        filter: {
          kind: 'SystemType',
          'spec.resourceTypeId': resourceTypeIdFilter,
          [`relations.${BELONGS_TO}`]: taxonomyRefFilter,
        },
      },
      options,
    );
    return { items: systemTypes } as GetSystemTypesResponse;
  }

  async getComponentTypes(
    request: GetComponentTypesRequest,
    options?: RequestOptions,
  ): Promise<GetComponentTypesResponse> {
    const resourceTypeIdFilter = request.filter?.resourceTypeId ?? [];
    const taxonomyRefFilter = request.filter?.taxonomyRef ?? [];
    const { items: componentTypes } = await this.catalogApi.getEntities(
      {
        filter: {
          kind: 'ComponentType',
          'spec.resourceTypeId': resourceTypeIdFilter,
          [`relations.${BELONGS_TO}`]: taxonomyRefFilter,
        },
      },
      options,
    );
    return { items: componentTypes } as GetComponentTypesResponse;
  }

  async resolveCreationTemplateGeneratedType(
    request: ResolveCreationTemplateGeneratedTypeRequest,
    options?: RequestOptions,
  ): Promise<ResolveCreationTemplateGeneratedTypeResponse> {
    const { templateRef } = request;
    const { name, namespace = 'default' } = templateRef;
    const templateEntity = await this.catalogApi.getEntityByRef(
      {
        name,
        namespace,
        kind: 'Template',
      },
      options,
    );
    if (!templateEntity)
      throw new EntityNotFoundError(
        `Creation template with name ${name} (namespace: ${namespace}) cannot be found`,
      );

    const generatedTypeRef = templateEntity.spec?.generates?.toString(); // TODO: query the relation when implemented
    const generatedTypeEntity = generatedTypeRef
      ? await this.catalogApi.getEntityByRef(generatedTypeRef, options)
      : undefined;

    if (!generatedTypeEntity)
      throw new CannotResolveGeneratedTypeError(
        `Error while resolving the template's spec.generates property. Cannot find the referenced SystemType or ComponentType entity (${generatedTypeRef}). Make sure the spec.generates property is correctly set`,
      );

    if (isSystemType(generatedTypeEntity))
      return { generatedType: generatedTypeEntity as SystemType };

    if (isComponentType(generatedTypeEntity))
      return { generatedType: generatedTypeEntity as ComponentType };

    throw new CannotResolveGeneratedTypeError(
      `Error while resolving the template's spec.generates property. Cannot find a SystemType or ComponentType entity with entity ref: ${generatedTypeRef}`,
    );
  }

  async resolveSystemTypeOf(
    system: WitboostSystem,
    options?: RequestOptions,
  ): Promise<SystemType> {
    return await this.resolveTypeOf(system, options);
  }

  async resolveComponentTypeOf(
    component: WitboostComponent,
    options?: RequestOptions,
  ): Promise<ComponentType> {
    return await this.resolveTypeOf(component, options);
  }

  private async resolveTypeOf<T extends SystemType | ComponentType>(
    instance: WitboostSystem | WitboostComponent,
    options?: RequestOptions,
  ): Promise<T> {
    const catalogInstanceEntity = await this.catalogApi.getEntityByRef(
      getCompoundEntityRef(instance),
      options,
    );

    if (!catalogInstanceEntity) {
      throw new EntityNotFoundError(
        `Cannot find an instance with name '${instance.metadata.name}' in the catalog.`,
      );
    }

    const instanceOfRelation = catalogInstanceEntity.relations?.find(
      relation => relation.type === INSTANCE_OF,
    );

    if (!instanceOfRelation) {
      throw new EntityNotFoundError(
        `Cannot find an 'instanceOf' relation for the instance entity '${catalogInstanceEntity.metadata.name}'.`,
      );
    }

    const targetEntity = await this.catalogApi.getEntityByRef(
      instanceOfRelation.targetRef,
      options,
    );

    if (!targetEntity) {
      throw new EntityNotFoundError(
        `Cannot find a SystemType or ComponentType with entity ref: ${instanceOfRelation.targetRef}`,
      );
    }

    return targetEntity as T;
  }
}
