import { WbLegend } from '@agilelab/plugin-wb-platform';
import { useTheme } from '@material-ui/core';
import React from 'react';
import { ShoppableIcon } from './icons/ShoppableIcon';

export const Legend = () => {
  const theme = useTheme();

  const entryDefaultProp = {
    labelTextColor: theme.palette.white,
    labelContainerStyle: { borderRadius: theme.spacing(1) },
  };

  const legendEntries = [
    {
      label: 'Taxonomy',
      color: theme.palette.relationsGraph.taxonomy,
      description:
        'Structured representation of concepts or entities, organised in a manner that reflects their inherent relationships and levels of abstraction.',
      ...entryDefaultProp,
    },
    {
      label: 'Domain',
      description:
        'A distinct and focused area or scope of knowledge, data, or functionality within an interconnected ecosystem.',
      color: theme.palette.relationsGraph.domain,
      ...entryDefaultProp,
    },
    {
      label: 'System',
      description:
        'A collection of interconnected and interdependent components or elements collaborating together to achieve a common goal or purpose.',
      color: theme.palette.relationsGraph.dataProduct,
      ...entryDefaultProp,
    },
    {
      label: 'Component',
      description:
        'A self-contained and modular part or element within a system, possessing specific functionality or capability.',
      color: theme.palette.relationsGraph.dataContract,
      ...entryDefaultProp,
    },
    {
      label: 'Shoppable',
      description:
        'Refers to data or information that is ready to be transmitted, used, or processed by systems or components. It signifies that the output is in a format or state that can be readily consumed.',
      icon: <ShoppableIcon fontSize="small" />,
    },
  ];
  return (
    <>
      <WbLegend entries={legendEntries} />
    </>
  );
};
