import {
  EvaluationResult,
  EvaluationResultByIdQuery,
  EvaluationResultSpecific,
  MetricGql,
  PolicyGql,
  ResultType,
} from '@agilelab/plugin-wb-governance-common';
import { toGovernancePolicy } from './to-governance-policy.mapper';
import { toGovernanceMetric } from './to-governance-metric.mapper';

export const toEvaluationResultSpecific = (
  data: EvaluationResultByIdQuery,
): EvaluationResultSpecific => ({
  ...data.cgp_evaluation_result_by_pk,
  outcome: data.cgp_evaluation_result_by_pk.outcome.toUpperCase() as any,
  hasError:
    (data.cgp_evaluation_result_by_pk.result?.errors &&
      data.cgp_evaluation_result_by_pk.result?.errors?.length !== 0) ??
    false,
  startTestDate: new Date(
    data.cgp_evaluation_result_by_pk.evaluation_report.creation_time,
  ),
  endTestDate: new Date(
    data.cgp_evaluation_result_by_pk.evaluation_report.update_time,
  ),
  truePolicy:
    data.cgp_evaluation_result_by_pk.governance_entity.result_type ===
    ResultType.Policy
      ? toGovernancePolicy(
          data.cgp_evaluation_result_by_pk.governance_entity as PolicyGql,
        )
      : undefined,
  metric:
    data.cgp_evaluation_result_by_pk.governance_entity.result_type ===
    ResultType.Metric
      ? toGovernanceMetric(
          data.cgp_evaluation_result_by_pk.governance_entity as MetricGql,
        )
      : undefined,
});

export const fromEvaluationReportToEvaluationResultSpecific = (
  data: EvaluationResult,
): EvaluationResultSpecific => ({
  ...data,
  hasError: data.result?.errors?.length !== 0 ?? false,
  startTestDate: new Date(data.evaluation_report.creation_time),
  endTestDate: new Date(data.evaluation_report.update_time),
  truePolicy:
    data.governance_entity.result_type === ResultType.Policy
      ? toGovernancePolicy(data.governance_entity as PolicyGql)
      : undefined,
  metric:
    data.governance_entity.result_type === ResultType.Metric
      ? toGovernanceMetric(data.governance_entity as MetricGql)
      : undefined,
});
