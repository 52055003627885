import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Status } from '@agilelab/plugin-wb-governance-common';
import { useStatusInfo } from '../../GovernanceRegistryPage/GovernanceRegistryStatus';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& svg': {
      width: theme.typography.body1.fontSize,
      height: theme.typography.body1.fontSize,
      marginRight: theme.spacing(0.5),
    },
  },
}));

interface StatusFieldProps {
  status: Status;
}

export const StatusField: React.FC<StatusFieldProps> = ({ status }) => {
  const classes = useStyles();
  const { statusInfo } = useStatusInfo();

  return (
    <Box className={classes.container}>
      {statusInfo[status].icon}
      <Typography variant="body2">{statusInfo[status].label}</Typography>
    </Box>
  );
};
