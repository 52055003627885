import { Box, Typography, makeStyles } from '@material-ui/core';
import Icon from './TestHistoryEmptyState.svg';
import React from 'react';
import { LaunchTestButton } from './LaunchTestButton';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    marginTop: '36px',
  },
}));

export const TestHistoryEmptyState: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography style={{ fontWeight: 700 }} variant="h6">
        Your test history is currently empty.
      </Typography>
      <img
        src={Icon}
        alt="Illustration describing that the test history is empty"
      />
      <Typography variant="body2">
        Let's fill it up, by adding some tests!
      </Typography>
      <LaunchTestButton size="large" label="Run your first Test" />
    </Box>
  );
};
