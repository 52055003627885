import React from 'react';
import { EntityRefLinks } from '@backstage/plugin-catalog-react';
import { useDataPath } from '../../context';
import { CustomViewRegister } from '../CustomView/registry';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    link: { ...theme.typography.body1 },
  }),
);

CustomViewRegister.register({
  id: 'link',
  function: function LinkView(props: Record<string, any>) {
    const classes = useStyles();
    const values = useDataPath(props.path);
    return (
      <EntityRefLinks
        entityRefs={values}
        defaultKind={props.kind}
        className={classes.link}
      />
    );
  },
});
