import { ReleaseAndDeployments } from '@agilelab/plugin-wb-builder-common';
import { Box, Tooltip, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { BookmarkIcon } from './icons/BookmarkIcon';

interface Props {
  release: ReleaseAndDeployments;
}

export const ReleaseName: React.FC<Props> = ({ release }) => {
  const theme = useTheme();
  const name = `v${release.metadata.version}`;

  return (
    <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
      {release.metadata.isSnapshot && (
        <Tooltip title="This is a draft release">
          <BookmarkIcon
            fontSize="small"
            style={{ color: theme.palette.accent.main }}
          />
        </Tooltip>
      )}
      <Typography variant="body2" style={{ fontWeight: 700 }}>
        {name}
      </Typography>
    </Box>
  );
};
