import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import {
  CustomPresentationRetriever,
  CustomPresentationRetrieverApiRef,
} from './apis';

export const platformPlugin = createPlugin({
  id: 'platform',
  apis: [
    createApiFactory({
      api: CustomPresentationRetrieverApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
      },
      factory: ({ discoveryApi }) =>
        new CustomPresentationRetriever({ discoveryApi }),
    }),
  ],
});
