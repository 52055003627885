import {
  DomainResolver,
  SEARCH_FOR_PARENT_DOMAIN,
} from '@agilelab/plugin-wb-marketplace-common';
import { ApolloClient, gql } from '@apollo/client';

/**
 * Extract the whole hierarchy of a given domain and a parent domain from the Marketplace
 * database.
 * @param domains: a list containing the starting domain name
 * @param apolloClient: used to query the Marketplace database
 * @param subDomainOf: the parent domain
 * @returns
 */
export async function resolveDomainRelations(
  domains: string[],
  apolloClient: ApolloClient<object>,
  subDomainOf?: string,
): Promise<string[]> {
  if (!subDomainOf) return domains;

  const result = await apolloClient.query<DomainResolver>({
    query: gql(SEARCH_FOR_PARENT_DOMAIN),
    variables: {
      subDomainOf,
    },
  });

  return await resolveDomainRelations(
    [result.data.domainData.name, ...domains],
    apolloClient,
    result.data.domainData.sub_domain_of,
  );
}
