import { gql } from '@apollo/client/core';

export const GET_PRODUCTS_GRAPH_BY_ENV = gql`
  query GetProductsGraphByEnv($_env: String!, $_descriptorFilter: jsonb) {
    DataProductInstance: marketplace_DataProductInstance(
      where: {
        _and: [
          { Environment: { name: { _eq: $_env } } }
          { DataProduct: { type: { _eq: "dataproduct" } } }
          { descriptor: { _contains: $_descriptorFilter } }
        ]
      }
    ) {
      id
      version
      InputPorts(
        where: {
          _and: [
            {
              OutputPort: {
                DataProductInstance: { Environment: { name: { _eq: $_env } } }
              }
            }
            {
              OutputPort: {
                DataProductInstance: {
                  DataProduct: { type: { _eq: "dataproduct" } }
                }
              }
            }
          ]
        }
      ) {
        OutputPort {
          DataProductInstance {
            id
            id_environment
          }
        }
      }
      descriptor
      DataProduct {
        id
        name
        display_name
        Domain {
          id
        }
      }
      Environment {
        name
      }
    }
  }
`;

export const GET_DATA_CONTRACTS_BY_ENV = gql`
  query GetDataContractByEnv($_env: String!) {
    marketplace_InputPort(
      where: {
        _and: [
          { kind: { _neq: "external" } }
          {
            OutputPort: {
              DataProductInstance: { Environment: { name: { _eq: $_env } } }
            }
          }
          { DataProductInstance: { Environment: { name: { _eq: $_env } } } }
        ]
      }
    ) {
      DataProductInstance {
        id
        version
        DataProduct {
          display_name
          external_id
          type
          data_product_owner_display_name
          Domain {
            name
          }
        }
      }
      OutputPort {
        id
        mode
        display_name
        external_id
        outputporttype
        version
        DataProductInstance {
          id
          version
          DataProduct {
            display_name
            type
            external_id
            data_product_owner_display_name
            Domain {
              name
            }
          }
        }
        OutputPortWarnings {
          id
          kind
          message
          content
        }
      }
    }
  }
`;

export const GET_DOMAINS = gql`
  query GetDomains {
    Domains: marketplace_Domain {
      id
      name
      sub_domain_of
    }
  }
`;

export const GET_ENVIRONMENTS = gql`
  query getEnvs {
    marketplace_Environment(order_by: { priority: asc }) {
      name
      id
      priority
    }
  }
`;

export const GET_OWNERS = gql`
  query getOwners($_env: String!) {
    marketplace_DataProduct(
      distinct_on: data_product_owner
      where: { DataProductInstances: { Environment: { name: { _eq: $_env } } } }
    ) {
      data_product_owner
      data_product_owner_display_name
    }
  }
`;
