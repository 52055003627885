import { WbTable } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { useReleasePageContext } from './context/useReleasePageContext';
import { ReleaseAndDeployments } from '@agilelab/plugin-wb-builder-common';
import { useReleasesTableColumns } from './hooks/useReleasesTableColumns';
import { useRouteRef } from '@backstage/core-plugin-api';
import { releaseDetailRouteRef } from '../../routes';
import { useNavigate } from 'react-router';
import { ReleaseTableNoData } from './ReleaseTableNoData';
import { getReleaseInfo } from '../../utils';
import { ReleaseFilters } from './ReleaseFilters';
import { Box } from '@material-ui/core';

export const ReleaseTable: React.FC = () => {
  const { releasesState, releases } = useReleasePageContext();
  const navigate = useNavigate();
  const releaseDetailRoute = useRouteRef(releaseDetailRouteRef);
  const columns = useReleasesTableColumns();

  const hasData = !!releasesState?.value && !!releases?.length;

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {hasData && (
        <Box
          style={{
            display: 'flex',
            marginBottom: '16px',
          }}
        >
          <Box style={{ width: '100%' }}>
            <ReleaseFilters />
          </Box>
        </Box>
      )}

      <WbTable<ReleaseAndDeployments>
        stickyHeader
        components={{
          tableLoader: {
            loading: releasesState?.loading,
          },
          tableContent: {
            onRowClick: release => {
              const { kind, namespace, name } = getReleaseInfo(release);

              navigate(releaseDetailRoute({ kind, namespace, name }));
            },
            columns,
            rows: releasesState?.value ? releases : undefined,
          },
        }}
        noDataComponent={<ReleaseTableNoData />}
        styles={{
          container: { height: '100%', flex: '1 1 0' },
        }}
      />
    </Box>
  );
};
