import React, { useCallback, useMemo, useState } from 'react';
import { WitBoostOutputPort } from '../DataContractEditor';

export interface DataContractEditorContextType {
  entity: WitBoostOutputPort;
  hasChangedForm: boolean;
  hasApproved: boolean;
  setHasApproved: (input: boolean) => void;
  formData: Record<string, any>;
  setFormData: (input: Record<string, any>) => void;
  handleFieldChange: (
    field: string,
    key: string,
    value: string,
    index?: number,
    customIndex?: number,
  ) => void;
}

export const DataContractEditorContext =
  React.createContext<DataContractEditorContextType>(
    {} as DataContractEditorContextType,
  );

export const DataContractEditorContextProvider: React.FC<{
  entity: WitBoostOutputPort;
}> = ({ children, entity }) => {
  const [hasApproved, setHasApproved] = useState<boolean>(false);
  const [hasChangedForm, setHasChangedForm] = useState<boolean>(false);
  const initFormData = useCallback((outputPort: WitBoostOutputPort) => {
    const dataAgreementValues =
      outputPort.spec.mesh.dataSharingAgreement ?? undefined;
    const slaValues = {
      ...outputPort.spec.mesh.dataContract?.SLA,
    };
    if (outputPort.spec.mesh.dataContract?.termsAndConditions !== undefined) {
      slaValues.termsAndConditions =
        outputPort.spec.mesh.dataContract?.termsAndConditions;
    }

    const qualityValues =
      outputPort.spec.mesh.quality &&
      Array.isArray(outputPort.spec.mesh.quality) &&
      outputPort.spec.mesh.quality.length > 0
        ? outputPort.spec.mesh.quality
        : undefined;

    return {
      dataAgreementValues: dataAgreementValues,
      slaValues: slaValues,
      qualityValues: qualityValues,
    } as Record<string, any>;
  }, []);
  const [formData, setFormData] = useState(initFormData(entity));
  const handleFieldChange = useCallback(
    (
      field: string,
      key: string,
      value: string,
      index?: number,
      customIndex?: number,
    ) => {
      const updatedFormData = { ...formData };
      if (index !== undefined) {
        if (customIndex !== undefined) {
          updatedFormData[field][index][key][customIndex].value = value;
        } else {
          updatedFormData[field][index][key] = value;
        }
      } else {
        updatedFormData[field][key] = value;
      }
      setHasChangedForm(true);
      setFormData(updatedFormData);
    },
    [formData, setFormData],
  );

  const values: DataContractEditorContextType = useMemo(
    () => ({
      entity,
      hasApproved,
      setHasApproved,
      formData,
      setFormData,
      handleFieldChange,
      hasChangedForm,
    }),
    [
      entity,
      hasApproved,
      setHasApproved,
      formData,
      setFormData,
      handleFieldChange,
      hasChangedForm,
    ],
  );
  return (
    <DataContractEditorContext.Provider value={values}>
      {children}
    </DataContractEditorContext.Provider>
  );
};
