import { Box, Popover, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { WbTextField } from '../WbTextField';
import { StringTableFilter } from './types';
import { FiltersButton } from './FilterButtons';
import { FilterInput } from './FilterInput';

const StringFilterValue = ({ field, value }: StringTableFilter) => {
  const ellipsis: React.CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <Typography style={ellipsis} variant="body2">
      {field}
      {value && (
        <>
          <Typography component="span" variant="body2">
            :
          </Typography>
          <Typography
            style={{ fontWeight: '600', marginLeft: '5px' }}
            variant="body2"
            component="span"
          >
            {value}
          </Typography>
        </>
      )}
    </Typography>
  );
};

export const StringFilter = (filter: StringTableFilter) => {
  const [value, setValue] = useState(filter.value);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setTimeout(() => setValue(filter.value), 500);
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setValue(filter.value);
  }, [filter.value]);

  return (
    <Box>
      <FilterInput
        onClick={handleClick}
        onClear={() => {
          filter.onChange(undefined);
          setValue(undefined);
        }}
        isOpened={open}
        selected={Boolean(filter.value)}
        maxWidth="300px"
        hideClear={filter.hideClear}
      >
        <StringFilterValue {...filter} />
      </FilterInput>

      <Popover
        disableRestoreFocus
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box style={{ width: '280px', background: 'white' }}>
          <Box style={{ margin: '8px' }}>
            <WbTextField
              variant="outlined"
              label=""
              placeholder={
                filter.placeholder
                  ? filter.placeholder
                  : 'Start typing to filter'
              }
              InputLabelProps={{ shrink: true }}
              onChange={e => setValue(String(e.target.value))}
              value={value}
              inputProps={{ style: { height: '64px' } }}
            />
          </Box>

          <FiltersButton
            cancel={{
              onClick: handleClose,
            }}
            apply={{
              onClick: () => {
                filter.onChange(value);
                setAnchorEl(null);
              },
            }}
          />
        </Box>
      </Popover>
    </Box>
  );
};
