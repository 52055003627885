import React, { useEffect } from 'react';
import { Deploy } from '../../types';
import { WbCardActionButton, WbTable } from '@agilelab/plugin-wb-platform';
import { ForceTerminateIcon } from './Icons';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  useTheme,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { DeployProcess } from './DeployProcess';
import { useStepIcons } from '../../hooks/useStepIcons';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { useReleaseDetailPageContext } from '../../context/useReleaseDetailPageContext';
import {
  TaskAction,
  isRunningStatus,
  DagStatus,
  TerminationMode,
} from '@agilelab/plugin-wb-builder-common';

import { LatestProvisioningActions } from './LatestProvisionigActions';

interface Props {
  deploy: Deploy;
}

export const LatestProvisioningDetail: React.FC<Props> = ({ deploy }) => {
  const stepIcons = useStepIcons();
  const {
    isDeployingAll,
    isUndeployingAll,
    setIsDeployingAll,
    setIsUndeployingAll,
    terminateMode,
    terminate,
  } = useReleaseDetailPageContext();
  const theme = useTheme();

  const hide =
    isDeployingAll ||
    isUndeployingAll ||
    deploy.status !== DagStatus.TERMINATED;

  const getAction = (action: string) => {
    switch (action) {
      case 'PROVISION':
        return 'Deploy';
      case 'UNPROVISION':
        return 'Undeploy';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (isRunningStatus(deploy.status)) {
      if (deploy.action === TaskAction.PROVISION) {
        setIsDeployingAll(true);
      }
      if (deploy.action === TaskAction.UNPROVISION) {
        setIsUndeployingAll(true);
      }
    }
  }, [deploy, setIsDeployingAll, setIsUndeployingAll]);

  return (
    <>
      <WbTable<Deploy>
        stickyHeader
        components={{
          tableHeader: (
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 500 }}>Latest Action</TableCell>
                <TableCell style={{ fontWeight: 500 }}>Date</TableCell>
                <TableCell style={{ fontWeight: 500 }}>Status</TableCell>
                <TableCell style={{ fontWeight: 500 }}>Process</TableCell>
                <TableCell style={{ fontWeight: 500 }} />
              </TableRow>
            </TableHead>
          ),
          tableContent: {
            body: (
              <TableBody>
                <TableRow>
                  <TableCell width="15%">
                    <Typography variant="body2" style={{ fontWeight: 700 }}>
                      {getAction(deploy.action)}
                    </Typography>
                  </TableCell>
                  <TableCell width="15%">
                    {format(parseISO(deploy.deployDate), 'yyyy/MM/dd HH:mm:ss')}
                  </TableCell>
                  <TableCell width="10%">
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ gap: '4px' }}
                    >
                      {stepIcons[deploy.status]}
                      <Typography variant="body2" style={{ fontWeight: 700 }}>
                        {snakeCaseToTitleCase(deploy.status.toLowerCase())}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ maxWidth: '350px' }}>
                    <DeployProcess deploy={deploy} />
                  </TableCell>
                  <TableCell width="auto" style={{ minWidth: '220px' }}>
                    <LatestProvisioningActions deploy={deploy} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ),
          },
        }}
      />
      {terminateMode === TerminationMode.SAFE &&
        deploy.status === DagStatus.RUNNING && (
          <Box
            padding={1}
            margin={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{
              backgroundColor: theme.palette.background.paper,
              gap: 16,
              marginTop: 16,
            }}
          >
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              Process termination requestes.
            </Typography>
            <Typography variant="body2">
              The operation may take some time, please wait. If the wait lasts
              longer than expected, click
            </Typography>
            <WbCardActionButton
              color="primary"
              size="small"
              icon={<ForceTerminateIcon />}
              onClick={_ => {
                terminate(TerminationMode.FORCED);
              }}
              variant="contained"
              style={{
                backgroundColor: theme.palette.error.main,
              }}
              label="Force Terminate"
            />
          </Box>
        )}

      {!hide && (
        <Box
          padding={1}
          margin={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            backgroundColor: theme.palette.background.paper,
            gap: 16,
            marginTop: 16,
          }}
        >
          <Typography variant="body2" style={{ fontWeight: 600 }}>
            The process has been terminated.
          </Typography>
        </Box>
      )}
    </>
  );
};
