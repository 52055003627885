import React from 'react';
import { Box, Button, CircularProgress, useTheme } from '@material-ui/core';
import useAsync from 'react-use/lib/useAsync';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '@agilelab/plugin-wb-builder-catalog'; // TODO FIXME WHEN WE DECIDE HOW TO FETCH ENVS FROM A SINGLE SOURCE

export const EnvironmentButtons = React.forwardRef(
  (props: { onClick: (env: string) => void }, _ref) => {
    const { onClick } = props;
    const panelCatalog = useApi(panelCatalogApiRef);
    const identifyApi = useApi(identityApiRef);
    const theme = useTheme();

    const { loading, value: fetchedEnvs } = useAsync(async (): Promise<
      string[]
    > => {
      return await panelCatalog.fetchEnvironments(
        await identifyApi.getCredentials(),
      );
    }, []);

    if (loading) return <CircularProgress />;

    return (
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          maxWidth: '400px',
          gap: theme.spacing(2),
          marginTop: theme.spacing(2),
        }}
      >
        {fetchedEnvs?.map(env => (
          <Button
            key={env}
            color="primary"
            variant="contained"
            onClick={() => onClick(env)}
            size="small"
          >
            {env}
          </Button>
        ))}
      </Box>
    );
  },
);
