import {
  createPlugin,
  createComponentExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const ontologyPlugin = createPlugin({
  id: 'wb-ontology',
  routes: {
    root: rootRouteRef,
  },
});

/**
 * The import hierarchy page.
 *
 * @public
 */
export const OntologyImportPage = ontologyPlugin.provide(
  createComponentExtension({
    name: 'OntologyImportPage',
    component: {
      lazy: () =>
        import('./components/OntologyImportPage/OntologyImportPage').then(
          m => m.OntologyImportPage,
        ),
    },
  }),
);

/**
 * The main page of the plugin.
 *
 * @public
 */
export const OntologyPage = ontologyPlugin.provide(
  createComponentExtension({
    name: 'OntologyPage',
    component: {
      lazy: () => import('./components/OntologyPage').then(m => m.OntologyPage),
    },
  }),
);
