/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useState } from 'react';
import {
  Box,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import {
  EntityAboutCard,
  EntityLayout,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  hasCatalogProcessingErrors,
  isOrphan,
  ReservedFieldsError,
  ControlPanelPage,
  hasReservedFieldsError,
  ComponentTypeSelectButton,
  EntityCatalogGraphCard,
  EditorPageContextProvider,
  EditorPage,
  ReleasePage,
  ReleasePageContextProvider,
} from '@agilelab/plugin-wb-builder-catalog';
import {
  Entity,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
} from '@backstage/catalog-model';
import {
  InfoCard,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import {
  useEntity,
  useRelatedEntities,
  getEntityRelations,
  EntityRefLinks,
} from '@backstage/plugin-catalog-react';

import { EntityLayoutWrapper } from './EntityLayoutWrapper';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import {
  WitboostSystem,
  applyWitboostVersionedEntity,
  WitboostComponent,
} from '@agilelab/plugin-wb-builder-common';
import {
  TableCellProps,
  WbCard,
  WbCardContent,
  WbTable,
  WbWidget,
} from '@agilelab/plugin-wb-platform';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import { DEFAULT_NAMESPACE, parseEntityRef } from '@backstage/catalog-model';
import { configApiRef, useApi, useRouteRef } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router';
import pluralize from 'pluralize';
import { camelToSpacedCase } from '@agilelab/plugin-wb-platform-common';

const useStyles = makeStyles(() => ({
  truncated: {
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    display: '-webkit-box',
  },
}));

const TooltipCell: React.FC<{ value: string }> = ({ value }) => {
  const classes = useStyles();
  return (
    <Tooltip placement="bottom-start" title={value}>
      <Typography className={classes.truncated} variant="body2">
        {value}
      </Typography>
    </Tooltip>
  );
};

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasReservedFieldsError}>
        <Grid item xs={12}>
          <ReservedFieldsError />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const createOwnerColumn = (): TableCellProps<WitboostSystem> => {
  return {
    headerName: 'Owner',
    field: 'spec',
    cellProps: {
      width: '30%',
    },

    fieldRender: e => (
      <EntityRefLinks
        entityRefs={getEntityRelations(e, RELATION_OWNED_BY)}
        defaultKind="group"
      />
    ),
  };
};
const createDescColumn = (): TableCellProps<WitboostSystem> => {
  return {
    headerName: 'Description',
    field: 'kind',
    cellProps: {
      width: '40%',
    },
    fieldRender: e => <TooltipCell value={e.metadata.description || ''} />,
  };
};

const createNameColumn = (): TableCellProps<WitboostSystem> => {
  return {
    headerName: 'Name',
    field: 'spec',
    cellProps: {
      width: '30%',
    },
    fieldRender: e => {
      const title = e.spec?.mesh?.name || e.metadata?.title;

      return (
        <Tooltip placement="bottom-start" title={title!}>
          <Typography variant="body2">{title}</Typography>
        </Tooltip>
      );
    },
  };
};

export const componentEntityColumns: TableCellProps<any>[] = [
  createNameColumn(),
  createOwnerColumn(),
  createDescColumn(),
];

type Props = {
  variant?: 'gridItem';
  title: string;
};

export function DataProductPage({ variant = 'gridItem', title }: Props) {
  const { entity } = useEntity();
  const versionedEntity = applyWitboostVersionedEntity(entity);
  const [version, setVersion] = useState(versionedEntity.spec.mesh.version);
  const entityRoute = useRouteRef(entityRouteRef);
  const navigate = useNavigate();
  const configApi = useApi(configApiRef);
  const theme = useTheme();
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_HAS_PART,
    kind: 'Component',
  });

  const isEditorEnabled = configApi.getOptionalBoolean(
    'catalog.editor.enabled',
  );
  const componentTitle = (componentType: string) => {
    switch (componentType.toLowerCase()) {
      case 'outputport': {
        return 'Output Ports';
      }
      case 'workload': {
        return 'Workloads';
      }
      case 'storage': {
        return 'Storages';
      }
      case 'observability': {
        return 'Observability';
      }
      case 'schema': {
        return 'Schema';
      }
      default: {
        return pluralize(camelToSpacedCase(componentType));
      }
    }
  };

  const components = entities as WitboostComponent[] | undefined;
  const componentsByType = components?.reduce((acc, component) => {
    const type = component.metadata.classDetails
      ? component.metadata.classDetails.pluralizedDisplayName ??
        (component.metadata.classDetails.displayName
          ? pluralize(component.metadata.classDetails.displayName)
          : undefined) ??
        componentTitle(component.spec.type)
      : componentTitle(component.spec.type);
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(component);
    return acc;
  }, {} as Record<string, WitboostComponent[]>);

  if (loading) {
    return (
      <InfoCard variant={variant} title={title}>
        <Progress />
      </InfoCard>
    );
  }

  if (error) {
    return (
      <InfoCard variant={variant} title={title}>
        <ResponseErrorPanel error={error} />
      </InfoCard>
    );
  }

  // For each component type we crate an independent table.
  // The table code is a replica of plugin-catalog/RelatedEntitiesCard.
  // We don't use RelatedEntitiesCard directly because here we load all entities just once
  // instead of doing it once for each component type.
  const componentChildren = Object.keys(componentsByType ?? {}).map(
    (componentType, index) => {
      return (
        <WbWidget key={index} title={componentType}>
          <WbTable<Entity>
            components={{
              tableLoader: { loading },
              tableContent: {
                columns: componentEntityColumns,
                rows: componentsByType ? componentsByType[componentType] : [],
                onRowClick: (e, ev) => {
                  const tag = (ev.target as HTMLElement).tagName;
                  if (tag !== 'A') {
                    const data = {
                      name: e?.metadata.name || '',
                      kind: e?.kind,
                      namespace: e?.metadata.namespace || '',
                    };

                    data.kind = data.kind.toLocaleLowerCase('en-US');
                    data.namespace =
                      data.namespace?.toLocaleLowerCase('en-US') ??
                      DEFAULT_NAMESPACE;

                    const ref = parseEntityRef(data);
                    navigate(entityRoute(ref));
                  }
                },
              },
            }}
          />
        </WbWidget>
      );
    },
  );

  return (
    <EntityLayoutWrapper isWrappingDataProduct version={version}>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          {entityWarningContent}
          <Grid item md={6}>
            <EntityAboutCard />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard />
          </Grid>
          <Grid item md={12} xs={12}>
            {/* If no components to display in the table */}
            {!!componentChildren.length ? (
              <WbCard
                title="Components"
                actions={
                  <ComponentTypeSelectButton
                    dataproduct={entity.metadata.name}
                    domain={entity.spec?.domain as string}
                  />
                }
              >
                <WbCardContent>
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{ gap: '16px' }}
                  >
                    {componentChildren}
                  </Box>
                </WbCardContent>
              </WbCard>
            ) : (
              <WbCard
                title="Components"
                actions={
                  <ComponentTypeSelectButton
                    dataproduct={entity.metadata.name}
                    domain={entity.spec?.domain as string}
                  />
                }
              >
                <div style={{ textAlign: 'center' }}>
                  <Typography
                    variant="body1"
                    style={{
                      padding: theme.spacing(3),
                    }}
                  >
                    There are no components available. You can create one by
                    clicking on the "Add" button.
                  </Typography>
                </div>
              </WbCard>
            )}
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route path="/docs" title="Docs">
        <EntityTechdocsContent />
      </EntityLayout.Route>

      {isEditorEnabled && (
        <EntityLayout.Route
          path="/editor"
          title="Edit and Test"
          tabProps={{ style: { textTransform: 'none' } }}
        >
          <EditorPageContextProvider
            entity={entity}
            relatedEntities={entities}
            setVersion={setVersion}
            version={version}
          >
            <EditorPage />
          </EditorPageContextProvider>
        </EntityLayout.Route>
      )}

      {isEditorEnabled && (
        <EntityLayout.Route path="/release" title="Deployment">
          <ReleasePageContextProvider
            entity={entity}
            relatedEntities={entities}
            setVersion={setVersion}
          >
            <ReleasePage />
          </ReleasePageContextProvider>
        </EntityLayout.Route>
      )}

      {!isEditorEnabled && (
        <EntityLayout.Route path="/deploy" title="Control Panel">
          <ControlPanelPage entity={versionedEntity} setVersion={setVersion} />
        </EntityLayout.Route>
      )}
    </EntityLayoutWrapper>
  );
}
