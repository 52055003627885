import { transformUrnToWitboostId } from '@agilelab/plugin-wb-builder-common';
import {
  RoleSettingsView,
  Pagination,
  RoleViewFilters,
} from '@agilelab/plugin-wb-rbac-common';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { getEntitiesDisplayNames } from './enrichDetails';

export interface RoleContextType {
  filters: RoleViewFilters;
  changeFilters: <K extends keyof RoleViewFilters>(
    key: K,
    value: RoleViewFilters[K],
  ) => void;
  pagination: Pagination;
  setPagination: (p: Pagination) => void;
  roles: RoleSettingsView[];
  loading: boolean;
  roleCount: number;
  reloadTable: () => void;
  dispatchOrder: (order: { field: string; direction: 'asc' | 'desc' }) => void;
}

export const RoleContext = React.createContext<RoleContextType>(
  {} as RoleContextType,
);

export const RoleContextProvider: React.FC = ({ children }) => {
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);

  const orderReducer = (
    _state: any,
    action: { field: string; direction: 'asc' | 'desc' },
  ) => {
    switch (action.field) {
      case 'Scope':
        return {
          field: 'entity_ref_displayname',
          direction: action.direction,
        };
      case 'Description':
        return {
          field: 'description',
          direction: action.direction,
        };
      case 'Role':
        return {
          field: 'displayName',
          direction: action.direction,
        };

      case 'Subject':
        return {
          field: 'subjectdisplayname',
          direction: action.direction,
        };

      case 'unsorted':
        return {};
      default:
        return {};
    }
  };

  const [order, dispatchOrder] = useReducer(orderReducer, {});
  const [loading, setLoading] = useState(true);
  const [rolesTotal, setRolesTotal] = useState<number>(0);

  const [roles, setRoles] = useState<RoleSettingsView[]>([]);

  const [filters, setFilters] = useState<RoleViewFilters>({
    text: '',
    limit: 25,
  });
  const [pagination, setPagination] = useState<Pagination>({
    limit: filters.limit ?? 25,
    offset: 0,
  });

  useAsync(async () => {
    const identity = await identityApi.getCredentials();

    const response = await fetch(
      `${await discoveryApi.getBaseUrl('rbac')}/rolesandsubjects?offset=${
        pagination.offset
      }&limit=${pagination.limit}&searchKeyword=${filters.text}`,
      {
        headers: identity.token
          ? { authorization: `Bearer ${identity.token}` }
          : {},
      },
    );

    const data = await response.json();
    const enrichedRoles = data.roles.map((role: any) => {
      const [, name] = (
        transformUrnToWitboostId(role.entity_ref ?? '') ?? ''
      ).split(':');
      return { transformed_entity_ref: name ?? '', ...role };
    });

    const entitiesFilters: { 'metadata.name': string }[] = (
      [
        ...new Set(
          enrichedRoles.map(
            (role: RoleSettingsView) => role.transformed_entity_ref,
          ),
        ),
      ] as string[]
    ).map((entityRef: string) => ({
      'metadata.name': entityRef,
    }));

    // holds a map of entity name and its display name
    const displayNamesMap = await getEntitiesDisplayNames(
      catalogApi,
      entitiesFilters,
      {
        chunkLimit: 50,
      },
    );

    const userFriendlyRoles = enrichedRoles.flatMap(
      (role: RoleSettingsView) => {
        return displayNamesMap.has(role.transformed_entity_ref.toLowerCase())
          ? [
              {
                ...role,
                entity_ref_displayname:
                  displayNamesMap.get(role.transformed_entity_ref) ??
                  role.transformed_entity_ref,
              } as RoleSettingsView,
            ]
          : [
              {
                ...role,
                entity_ref_displayname: role.transformed_entity_ref,
                warning: role.transformed_entity_ref !== '',
              } as RoleSettingsView,
            ];
      },
    );

    setRoles(userFriendlyRoles);
    setRolesTotal((data.total as number) ?? 0);
    setLoading(false);
  }, [
    catalogApi,
    identityApi,
    discoveryApi,
    pagination.offset,
    pagination.limit,
    filters.text,
  ]);

  const sortedRoles = useMemo(() => {
    return roles.sort((a: RoleSettingsView, b: RoleSettingsView) => {
      if (order?.field) {
        if (order.direction === 'asc') {
          return a[order.field as keyof RoleSettingsView] >
            b[order.field as keyof RoleSettingsView]
            ? 1
            : -1;
        }
        return a[order.field as keyof RoleSettingsView] <
          b[order.field as keyof RoleSettingsView]
          ? 1
          : -1;
      }
      return 1;
    });
  }, [order.direction, order.field, roles]);

  const reloadTable = useCallback(() => {
    setPagination({ ...pagination, offset: 0 });
  }, [setPagination, pagination]);

  const contextValue = useMemo(
    () => ({
      filters,
      changeFilters: <K extends keyof RoleViewFilters>(
        key: K,
        filterValue: RoleViewFilters[K],
      ) => {
        setPagination(p => ({ ...p, offset: 0 }));
        setFilters(f => ({ ...f, [key]: filterValue }));
      },
      pagination,
      setPagination,
      roles: sortedRoles,
      roleCount: rolesTotal,
      reloadTable,
      dispatchOrder,
      loading,
    }),
    [
      filters,
      pagination,
      sortedRoles,
      rolesTotal,
      reloadTable,
      dispatchOrder,
      loading,
    ],
  );

  return (
    <RoleContext.Provider value={contextValue}>{children}</RoleContext.Provider>
  );
};

export const useRoleContext = () => React.useContext(RoleContext);
