import {
  WitboostComponent,
  WitboostEntity,
} from '@agilelab/plugin-wb-builder-common';
import {
  customAlertApiRef,
  GenericField,
  MeshCardHeader,
  ProgressButton,
  WbWidget,
} from '@agilelab/plugin-wb-platform';
import {
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  Card,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { cloneDeep } from 'lodash';
import React, { useCallback, useState } from 'react';
import { DescriptionField } from './DescriptionField';
import { Schema, Tag, WitBoostOutputPort } from './DataContractEditor';
import { TagsField } from './TagsField';
import { panelCatalogApiRef } from '../../api';
import yaml from 'yaml';
import { useDataContractEditorContext } from './context/useDataContractEditorContext';
import MissingKeyCardContent from './MissingKeyCardContent';
import { approvalsList } from './ApprovalsCard';
import { camelToSpacedCase } from '@agilelab/plugin-wb-platform-common';

const useStyles = makeStyles(theme => ({
  container: {},
  tableHead: {
    '& th': {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

function addDescriptionsToSchema(
  schema: Schema,
  descriptions: Record<string, string>,
): Schema {
  return schema.map(s => ({
    ...s,
    description: s.description || descriptions[s.name],
  }));
}
function addTagsToSchema(
  schema: Schema,
  businessTerms: Record<string, string[]>,
): Schema {
  return schema.map(s => ({
    ...s,
    tags:
      s.tags ||
      businessTerms[s.name].map(
        businessTerm => ({ tagFQN: businessTerm } as Tag),
      ),
  }));
}

export function updateOutputPortWithNewSchema(
  schema: Schema,
  op: WitBoostOutputPort,
): WitBoostOutputPort {
  return {
    ...op,
    spec: {
      ...op.spec,
      mesh: {
        ...op.spec.mesh,
        dataContract: {
          ...op.spec.mesh.dataContract,
          schema: schema,
        },
      },
    },
  };
}

const stringifyCatalogInfo = (
  entity: WitBoostOutputPort,
  formData: Record<string, any>,
  schema: Schema | undefined,
  addApprovals: boolean = false,
): string => {
  const res = { ...entity };
  if (addApprovals) res.spec.mesh.approvals = approvalsList.map(a => a.id);
  res.spec.mesh.dataSharingAgreement = formData.dataAgreementValues;
  res.spec.mesh.quality = formData.qualityValues;
  if (res.spec.mesh.dataContract) {
    const terms = formData.slaValues.termsAndConditions;
    delete formData.slaValues.termsAndConditions;
    res.spec.mesh.dataContract.SLA = formData.slaValues;
    res.spec.mesh.dataContract.termsAndConditions = terms;
    if (schema)
      res.spec.mesh.dataContract.schema = JSON.parse(JSON.stringify(schema));
  }

  return yaml.stringify(res);
};

export const DataContractCard = (props: {
  dataProduct: WitboostEntity;
  businessTerms: string[];
}) => {
  const { dataProduct, businessTerms } = props;
  const {
    entity: outputPort,
    setHasApproved,
    formData,
    handleFieldChange,
    hasApproved,
    hasChangedForm,
  } = useDataContractEditorContext();
  const classes = useStyles();
  const panelCatalogApi = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);
  const theme = useTheme();
  const [schema, setSchema] = useState(
    cloneDeep(outputPort.spec.mesh.dataContract?.schema),
  );
  const [disableGenerate, setDisableGenerate] = useState(schema === undefined);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [loadingApproval, setLoadingApproval] = useState(false);

  const handleGenerate = useCallback(async () => {
    setLoadingGenerate(true);
    if (!schema) return;
    try {
      const descriptionResponse = await fetch(
        `https://schemaassistant.azurewebsites.net/api/GenerateDescriptions?code=lO7qplEogUpLUXT7-H9T3v1fr_zG2Adv8_LtQ39vWdX-AzFu8yMjuQ==`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ dataProduct, outputPort }),
        },
      );

      const fetchedDescriptions = (await descriptionResponse.json()) as Record<
        string,
        string
      >;

      const updatedSchemaWithDescriptions = addDescriptionsToSchema(
        schema,
        fetchedDescriptions,
      );

      const bestBusinessTermsResponse = await fetch(
        `https://schemaassistant.azurewebsites.net/api/PickupBestBusinessTerm?code=tUrH5lURVRvgwu-fo212ZDonj-eTCawjThLgKFaCq3edAzFuu95aPQ==`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            dataProduct,
            outputPort: updateOutputPortWithNewSchema(
              updatedSchemaWithDescriptions,
              outputPort,
            ),
            businessTerms,
          }),
        },
      );

      const fetchedBestBusinessTerms =
        (await bestBusinessTermsResponse.json()) as Record<string, string[]>;

      const updatedSchemaWithTags = addTagsToSchema(
        updatedSchemaWithDescriptions,
        fetchedBestBusinessTerms,
      );

      setSchema(updatedSchemaWithTags);
      setLoadingGenerate(false);
      setDisableGenerate(true);
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      setLoadingGenerate(false);
    }
  }, [alertApi, businessTerms, dataProduct, outputPort, schema]);

  const enableApprovals = configApi.getOptionalBoolean(
    'mesh.builder.enableApprovals',
  );

  const handleApproval = useCallback(async () => {
    setLoadingApproval(true);
    let catalogInfo;
    try {
      catalogInfo = await panelCatalogApi.fetchCatalogInfo(
        outputPort as WitboostComponent,
        await identityApi.getCredentials(),
      );
    } catch (err) {
      alertApi.post({ error: err, severity: 'error' });
    }
    try {
      if (enableApprovals) {
        const delay = (ms: number) =>
          new Promise(resolve => setTimeout(resolve, ms));
        await delay(2000);
        setHasApproved(true);
        await delay(8000);
        alertApi.post({
          message: `Users approved`,
          severity: 'success',
        });
        setLoadingApproval(false);
      }
      await panelCatalogApi.pushCatalogInfo(
        outputPort as WitboostComponent,
        stringifyCatalogInfo(
          catalogInfo?.parsedCatalogInfo,
          formData,
          schema,
          enableApprovals,
        ),
        '[witboost] Catalog-info updated',
        catalogInfo?.branch,
        undefined,
        await identityApi.getCredentials(),
      );
      if (!enableApprovals) {
        alertApi.post({
          message: `File updated with changes`,
          severity: 'success',
        });
      }
    } catch (err) {
      alertApi.post({ error: err, severity: 'error' });
    } finally {
      setLoadingApproval(false);
    }
  }, [
    alertApi,
    formData,
    identityApi,
    outputPort,
    panelCatalogApi,
    schema,
    setHasApproved,
    enableApprovals,
  ]);

  const handleTagsChange = useCallback((tags: string[], name: string) => {
    const transformedTags = tags.map(tag => ({ tagFQN: tag } as Tag));
    setSchema(s =>
      s?.map(e => (e.name === name ? { ...e, tags: transformedTags } : e)),
    );
  }, []);

  const handleDescriptionChange = useCallback(
    (description: string, name: string) => {
      setSchema(s =>
        s?.map(e => (e.name === name ? { ...e, description } : e)),
      );
    },
    [],
  );

  return (
    <Card>
      <MeshCardHeader title="Data Contract">
        {enableApprovals ? (
          <Tooltip title="Request permissions to users and push changes">
            <span>
              <ProgressButton
                inProgress={loadingApproval}
                style={{ marginRight: '10px' }}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleApproval}
                disabled={loadingApproval || hasApproved || !hasChangedForm}
              >
                Request for approval
              </ProgressButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="Save changes to file">
            <span>
              <ProgressButton
                inProgress={loadingApproval}
                style={{ marginRight: '10px' }}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleApproval}
                disabled={loadingApproval}
              >
                Save
              </ProgressButton>
            </span>
          </Tooltip>
        )}
        <Tooltip
          title={
            disableGenerate
              ? 'No schema available, cannot generate tags'
              : 'Generate tags'
          }
        >
          <span>
            <ProgressButton
              inProgress={loadingGenerate}
              size="small"
              variant="contained"
              color="primary"
              onClick={handleGenerate}
              disabled={disableGenerate}
            >
              Generate
            </ProgressButton>
          </span>
        </Tooltip>
      </MeshCardHeader>

      <div style={{ marginRight: '10px' }}>
        <WbWidget
          title="Schema"
          cardStyle={{
            marginTop: '5px',
            marginBottom: '5px',
            marginLeft: '5px',
          }}
        >
          {schema && schema.length > 0 ? (
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="schema table" size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell width="10%">Type</TableCell>
                    <TableCell width="20%">Name</TableCell>
                    <TableCell width="40%">Description</TableCell>
                    <TableCell width="30%">Tag</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schema.map(s => (
                    <TableRow key={s.name} selected={false}>
                      <TableCell>
                        <Typography
                          variant="body2"
                          style={{
                            color: theme.palette.grey[500],
                          }}
                        >
                          {s.dataType}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <GenericField
                          value={s.name}
                          contentProps={{
                            variant: 'body2',
                            style: { wordBreak: 'break-word' },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <DescriptionField
                          value={s.description}
                          field={s.name}
                          loading={loadingGenerate}
                          businessTerms={businessTerms}
                          onChange={v => handleDescriptionChange(v, s.name)}
                          dataProduct={dataProduct}
                          outputPort={updateOutputPortWithNewSchema(
                            schema,
                            outputPort,
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <TagsField
                          values={s.tags?.map(tag => tag.tagFQN)}
                          field={s.name}
                          loading={loadingGenerate}
                          businessTerms={businessTerms}
                          onChange={v => handleTagsChange(v, s.name)}
                          dataProduct={dataProduct}
                          outputPort={updateOutputPortWithNewSchema(
                            schema,
                            outputPort,
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <MissingKeyCardContent keyProp="Schema" />
          )}
        </WbWidget>
      </div>
      <div style={{ marginRight: '10px' }}>
        <WbWidget
          title="SLA"
          cardStyle={{
            margin: '5px',
          }}
        >
          <Paper
            style={{
              background: 'white',
              boxShadow: 'none',
              marginTop: '10px',
              marginBottom: '10px',
              marginRight: '15px',
              marginLeft: '15px',
            }}
          >
            {formData.slaValues &&
            Object.values(formData.slaValues).length > 0 ? (
              Object.keys(formData.slaValues).map(key => (
                <Grid container spacing={2} key={key}>
                  <Grid item xs={6}>
                    <GenericField
                      value={camelToSpacedCase(key)}
                      contentProps={{
                        variant: 'body2',
                        style: { wordBreak: 'break-word' },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      fullWidth
                      size="small"
                      key={key}
                      value={formData.slaValues[key] ?? ''}
                      onChange={e =>
                        handleFieldChange('slaValues', key, e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              ))
            ) : (
              <MissingKeyCardContent keyProp="SLA" />
            )}
          </Paper>
        </WbWidget>
      </div>
    </Card>
  );
};
