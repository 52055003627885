import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useReleaseDetailPageContext } from './context/useReleaseDetailPageContext';
import { WbDrawer, WbEditor, WbTag } from '@agilelab/plugin-wb-platform';

export const DescriptorDrawer: React.FC = () => {
  const {
    isDescriptorDrawerOpen,
    setIsDescriptorDrawerOpen,
    queryParamVersion,
    release,
    environment,
    currentDescriptor,
    dataProductName,
  } = useReleaseDetailPageContext();

  return (
    <WbDrawer
      title="Descriptor"
      open={isDescriptorDrawerOpen}
      contentContainerStyle={{ height: '100%' }}
      setOpen={() => setIsDescriptorDrawerOpen(!isDescriptorDrawerOpen)}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          display="flex"
          marginBottom="16px"
          style={{ gap: '12px' }}
          alignItems="center"
        >
          <Box display="flex" style={{ gap: '4px' }} alignItems="center">
            <Typography color="primary" variant="h6">
              {queryParamVersion || release.metadata.version}
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              style={{ fontWeight: '500' }}
            >
              of {dataProductName}
            </Typography>
          </Box>
          <WbTag
            tag={{
              tagFQN: environment.name,
            }}
          />
        </Box>
        <Box flexGrow={1}>
          <WbEditor
            value={currentDescriptor}
            theme="white"
            options={{ readOnly: true }}
          />
        </Box>
      </Box>
    </WbDrawer>
  );
};
