import React, { useMemo } from 'react';
import {
  useTheme,
  Box,
  Tooltip,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';
import {
  MetricThresholdColor,
  ThresholdResult as Result,
} from '@agilelab/plugin-wb-governance-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { thresholdValueToString } from './utils/ThresholdValueToString';
import clsx from 'clsx';
import { useDefaultThresholdColors } from '../../hooks';
import { IconLabelBox } from '../IconLabelBox';
import { KoFilledIcon } from '../TestStatus/Icons/Icons';

interface Props {
  value: number;
  result?: Result | null;
  hasError?: boolean;
  dimension?: number;
  style?: React.CSSProperties;
  variant?: 'defalt' | 'reduced';
}

const useStyles = makeStyles<
  Theme,
  { dimension: number; backgroundColor: string; maxWidth?: string }
>(theme => ({
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    width: ({ dimension }) => dimension,
    minWidth: ({ dimension }) => dimension,
    height: ({ dimension }) => dimension,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  defaultBox: {
    display: 'flex',
    gap: '4px',
    width: 'fit-content',
    borderRadius: '4px',
    padding: '4px',
  },
  resultBox: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: theme.palette.black,
  },
}));

export const ThresholdResult: React.FC<Props> = ({
  value,
  result,
  hasError = false,
  dimension = 36,
  style,
  variant = 'default',
}) => {
  const theme = useTheme();
  const { defaultThresholdColors } = useDefaultThresholdColors();

  const configApi = useApi(configApiRef);
  const DEFAULT_COLOR = theme.palette.primary.main;

  const colors: MetricThresholdColor[] =
    configApi.getOptional('mesh.governance.metrics.thresholds.colors') ||
    defaultThresholdColors;

  const resultLabel = useMemo(() => {
    if (result?.label) return result?.label;

    const defaultLabel =
      colors.find(c => c.label === result?.labelColor)?.defaultLabel ??
      'evaluated';
    return defaultLabel;
  }, [colors, result?.label, result?.labelColor]);

  const backgroundColor: string = useMemo(() => {
    const relatedColor = colors.find(c => c.label === result?.labelColor);

    return relatedColor?.value || DEFAULT_COLOR;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors, result]);
  const classes = useStyles({ dimension, backgroundColor });

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        maxWidth: '160px',
        ...style,
      }}
    >
      {hasError ? (
        <>
          <Tooltip title="An error occured">
            <span>
              {variant === 'reduced' ? (
                <KoFilledIcon
                  htmlColor={theme.palette.error.main}
                  className={classes.icon}
                  style={style}
                />
              ) : (
                <IconLabelBox
                  label="Error"
                  backgroundColor={theme.palette.error.main}
                  icon={
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.black,
                        fontWeight: '700',
                      }}
                    >
                      KO
                    </Typography>
                  }
                />
              )}
            </span>
          </Tooltip>
        </>
      ) : (
        <Tooltip title={`${resultLabel} - ${value}`}>
          <span>
            {variant === 'reduced' ? (
              <>
                <Box className={clsx(classes.icon, classes.resultBox)}>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: '700',
                    }}
                  >
                    {thresholdValueToString(value)}
                  </Typography>
                </Box>
              </>
            ) : (
              <IconLabelBox
                label={resultLabel}
                backgroundColor={backgroundColor}
                icon={
                  <Typography
                    variant="body2"
                    style={{
                      color: theme.palette.black,
                      fontWeight: '700',
                    }}
                  >
                    {thresholdValueToString(value)}
                  </Typography>
                }
              />
            )}
          </span>
        </Tooltip>
      )}
    </Box>
  );
};
