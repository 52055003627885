import { makeStyles, createStyles, Typography, Chip } from '@material-ui/core';
import React from 'react';
import { DetailIcon } from '../DetailIcon';
import { DagStatus } from '@agilelab/plugin-wb-builder-common';

interface Props {
  description: string;
  componentName?: string;
  status: DagStatus;
}

const useStyles = makeStyles(() =>
  createStyles({
    componentId: {
      flex: 1,
    },
    chip: {
      margin: 0,
    },
  }),
);

export const ComponentItem: React.FC<Props> = ({
  description,
  status,
  componentName,
}) => {
  const classes = useStyles();

  return (
    <>
      <DetailIcon status={status} filled />
      <div className={classes.componentId}>
        <Typography variant="body2">
          <strong>{description}</strong>
        </Typography>
        {!!componentName && (
          <Typography color="primary" variant="body2">
            {componentName}
          </Typography>
        )}
      </div>
      <Chip
        label="Technology Adapter"
        className={classes.chip}
        color="primary"
      />
    </>
  );
};
