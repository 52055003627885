import React from 'react';
import { LayoutTemplate, createScaffolderLayout } from '../../../layouts';
import { scaffolderPlugin } from '../../../plugin';
import { Tooltip, useTheme } from '@material-ui/core';

function getTableRowCell(element: {
  content: React.ReactElement;
  name: string;
  disabled: boolean;
  readonly: boolean;
  hidden: boolean;
}) {
  const errors: string[] = element.content.props?.errorSchema?.__errors || [];

  const cloned = React.cloneElement(element.content, {
    schema: {
      ...element.content.props.schema,
      description: '',
      title: '',
    },
    uiSchema: {
      ...element.content.props.uiSchema,
      'ui:style': {
        borderRadius: 0,
        width: '100%',
        height: '100%',
        minWidth: 200,
        ...element.content.props?.uiSchema?.['ui:style'],
      },
      'ui:popper': 'wide',
    },
  });
  return (
    <td key={element.content.key} style={{ height: '100%', padding: 0 }}>
      {errors.length ? (
        <Tooltip title={errors.join('\n')}>
          <div style={{ width: '100%', height: '100%' }}>{cloned}</div>
        </Tooltip>
      ) : (
        cloned
      )}
    </td>
  );
}

export const TableRowTemplate: LayoutTemplate = props => {
  const theme = useTheme();

  const headerProperties: string[] = (props.schema as any)?.columnHeaders
    ? Array.from((props.schema as any)?.columnHeaders?.keys())
    : [];
  const tableRowCells = headerProperties.map(headerProperty => {
    const cell = props.properties.find(
      p => p.content.props.name === headerProperty,
    );
    if (cell) {
      return getTableRowCell(cell);
    }
    return (
      <td
        key={headerProperty}
        style={{
          height: '100%',
          padding: 0,
          border: `1px solid ${theme.palette.grey[400]}`,
          backgroundColor: theme.palette.grey[200],
        }}
      />
    );
  });
  return (
    <tr style={{ background: 'white' }}>
      {tableRowCells}
      {(props.schema as any).RowButtons}
    </tr>
  );
};

export const TableRowTemplateLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'TableRowTemplate',
    component: TableRowTemplate,
  }),
);
