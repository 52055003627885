import { PracticeShaperApi } from '@agilelab/plugin-wb-practice-shaper-common';
import { useApi } from '@backstage/core-plugin-api';
import { useQuery } from '@tanstack/react-query';
import { practiceShaperApiRef } from '../../../..';

const fetchData = async (api: PracticeShaperApi) => {
  const [taxonomies, domains, components, systems] = await Promise.all([
    await api.getTaxonomies({}),
    await api.getDomainTypes({}),
    await api.getComponentTypes({}),
    await api.getSystemTypes({}),
  ]);

  return { taxonomies, domains, components, systems };
};

export const usePracticeShaperGraphData = () => {
  const api = useApi(practiceShaperApiRef);

  const { data, isLoading, error } = useQuery({
    queryKey: ['practice-shaper-graph'],
    queryFn: async () => {
      return fetchData(api);
    },
  });

  return { data, isLoading, error };
};
