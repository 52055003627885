import React, { useState } from 'react';
import {
  SearchModal as BackStageSearchModal,
  SearchModalProps,
} from '@backstage/plugin-search';
import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
  DefaultResultListItem,
  SearchBar,
  SearchResult,
  SearchResultPager,
  useSearch,
} from '@backstage/plugin-search-react';
import { Link, useContent } from '@backstage/core-components';

import LaunchIcon from '@material-ui/icons/Launch';
import { CatalogSearchResultListItem } from '@agilelab/plugin-wb-builder-catalog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchButton: {
      marginRight: theme.spacing(1),
      boxShadow: 'none',
      textTransform: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    container: {
      display: 'flex',
    },
    input: {
      borderRadius: 30,
      flex: 1,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.bkg.primary,
      padding: theme.spacing(1, 0),
      '& svg': {
        margin: theme.spacing(0, 1),
        color: theme.palette.primary.main,
      },
    },
    dialogActionsContainer: {
      padding: theme.spacing(0, 3),
      backgroundColor: theme.palette.bkg.primary,
    },
    viewResultsLink: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      '& span': {
        marginRight: theme.spacing(1),
      },
    },
  }),
);

const SearchModalContent = ({ toggleModal }: SearchModalProps) => {
  const classes = useStyles();
  const { term } = useSearch();
  const { focusContent } = useContent();
  const { transitions } = useTheme();

  return (
    <>
      <DialogTitle>Search in witboost</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <div className={classes.container}>
              <SearchBar
                className={classes.input}
                placeholder="Type to search"
              />
            </div>
          </Grid>
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Link
              onClick={() => {
                toggleModal();
                setTimeout(focusContent, transitions.duration.leavingScreen);
              }}
              to={`search?query=${term}`}
              className={classes.viewResultsLink}
            >
              <span>Advanced Search</span>
              <LaunchIcon color="primary" />
            </Link>
          </div>
          <Grid item>
            <SearchResult>
              {({ results }) => (
                <List>
                  {results.map(({ type, document, highlight, rank }) => {
                    switch (type) {
                      case 'builder':
                        return (
                          <CatalogSearchResultListItem
                            key={document.location}
                            result={document}
                            highlight={highlight}
                            rank={rank}
                            onClick={toggleModal}
                          />
                        );
                      case 'marketplace':
                        return (
                          <CatalogSearchResultListItem
                            key={document.location}
                            result={document}
                            highlight={highlight}
                            rank={rank}
                            onClick={toggleModal}
                          />
                        );
                      default:
                        return (
                          <DefaultResultListItem
                            key={document.location}
                            result={document}
                            highlight={highlight}
                            rank={rank}
                          />
                        );
                    }
                  })}
                </List>
              )}
            </SearchResult>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActionsContainer}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <SearchResultPager />
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export const SearchModal = () => {
  const [openSearch, setOpenSearch] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setOpenSearch(true)}
        startIcon={<SearchIcon />}
        className={classes.searchButton}
        size="small"
      >
        Search
      </Button>

      <BackStageSearchModal
        toggleModal={() => setOpenSearch(!openSearch)}
        open={openSearch}
        children={() => (
          <SearchModalContent toggleModal={() => setOpenSearch(!openSearch)} />
        )}
      />
    </>
  );
};
