import { CopyButton, ProgressButton } from '@agilelab/plugin-wb-platform';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

const styles = {
  button: {
    minWidth: 0,
  },
  icon: {
    width: '22px',
    height: '22px',
  },
};

interface EditorInfoProps {
  editable?: boolean;
  clipboardText: string;
  className?: string;
  loading?: boolean;
  handleSave?: () => void;
  showSaveButton?: boolean;
}

export const EditorInfo: React.FC<EditorInfoProps> = ({
  editable,
  clipboardText,
  className,
  loading = false,
  handleSave,
  showSaveButton = false,
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.container, className)}>
      <>
        {editable ? (
          <Typography variant="body2" component="span">
            editable
          </Typography>
        ) : (
          <Typography variant="body2" component="span">
            not editable
          </Typography>
        )}

        <CopyButton
          text={clipboardText}
          hideIcon
          size="small"
          color="secondary"
          variant="text"
          style={styles.button}
        >
          <FileCopyIcon style={styles.icon} />
        </CopyButton>
        {showSaveButton && (
          <ProgressButton
            size="small"
            color="primary"
            variant="contained"
            onClick={handleSave}
            inProgress={loading}
          >
            Save
          </ProgressButton>
        )}
      </>
    </Box>
  );
};
