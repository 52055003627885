import {
  WbCardActionButton,
  WbEnvironmentSelector,
} from '@agilelab/plugin-wb-platform';
import { Box, makeStyles, Tooltip, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import { useSearchParams } from 'react-router-dom';
import {
  Action,
  buildReleaseVersion,
} from '@agilelab/plugin-wb-builder-common';
import { usePermission } from '@backstage/plugin-permission-react';
import { builderDpDeployPermission } from '@agilelab/plugin-wb-rbac-common';
import { stringifyEntityRef } from '@backstage/catalog-model';

const useStyles = makeStyles(theme => ({
  success: {
    background: theme.palette.success.main,
    '&:hover': {
      background: theme.palette.success.main,
    },
  },
  error: {
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.main,
    },
  },
}));

export const ProvisioningCardActions: React.FC = () => {
  const classes = useStyles();
  const {
    isDeployingAll,
    isUndeployingAll,
    queryParamVersion,
    queryParamEnvironment,
    setIsDescriptorDrawerOpen,
    currentDescriptor,
    deploymentUnitStatusState,
    release,
    setReleaseDetailAction,
    setIsDeploymentPreviewOpen,
    environment,
    entity,
  } = useReleaseDetailPageContext();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const isCurrentlyDeployed = useMemo(() => {
    const response = deploymentUnitStatusState.value;
    const releaseVersion = queryParamVersion
      ? buildReleaseVersion({ v: queryParamVersion })
      : buildReleaseVersion({ release });
    const responseVersion = response?.provisioningDetails?.descriptorVersion;

    const sameVersion = responseVersion
      ? responseVersion?.includes(releaseVersion.split('-')[0])
      : false;

    return Boolean(sameVersion);
  }, [deploymentUnitStatusState.value, release, queryParamVersion]);

  const { allowed: canDeployUndeploy } = usePermission({
    permission: builderDpDeployPermission(environment.name),
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  const getDeployTooltip = () => {
    if (isDeployingAll)
      return 'Wait for the current deployment operation to end';
    if (isUndeployingAll)
      return 'Wait for the current undeployment operation to end';
    if (Boolean(queryParamVersion))
      return 'This is a read-only mode. Please go to the latest release to deploy this version';

    if (!canDeployUndeploy)
      return `You are not allowed to deploy the selected release from the selected environment`;
    return 'Deploy the selected release to the selected environment. This will start a provisioning operation consisting of multiple tasks. If all the tasks are successful, the selected release will be published in the marketplace';
  };

  const getUndeployTooltip = () => {
    if (isDeployingAll)
      return 'Wait for the current deployment operation to end';
    if (isUndeployingAll)
      return 'Wait for the current undeployment operation to end';
    if (Boolean(queryParamVersion))
      return 'This is a read-only mode. Please go to the latest release to undeploy this version';

    if (!isCurrentlyDeployed) return 'This release is currently not deployed';
    if (!canDeployUndeploy)
      return `You are not allowed to undeploy the selected release from the selected environment`;
    return 'Undeploy the selected release or old deployed draft release from the selected environment. This will start an unprovisioning operation consisting of multiple tasks. If all the tasks are successful, the selected release will be removed from the marketplace';
  };

  return (
    <>
      <WbEnvironmentSelector
        disabled={Boolean(queryParamEnvironment && queryParamVersion)}
        onChange={e => {
          if (searchParams.get('env')) setSearchParams({ env: e });
        }}
      />
      <Box
        style={{
          background: theme.palette.grey[300],
          width: '1px',
          height: '24px',
          margin: '0px 12px 0px 12px',
        }}
      />

      <WbCardActionButton
        label="Show Descriptor"
        variant="outlined"
        onClick={() => {
          setIsDescriptorDrawerOpen(true);
        }}
        disabled={currentDescriptor === ''}
      />

      <Box
        style={{
          background: theme.palette.grey[300],
          width: '1px',
          height: '24px',
          margin: '0px 12px 0px 12px',
        }}
      />
      <Box style={{ display: 'flex', gap: '8px' }}>
        <Tooltip title={getDeployTooltip()}>
          <span>
            <WbCardActionButton
              className={classes.success}
              label="Deploy"
              onClick={() => {
                setIsDeploymentPreviewOpen(true);
                setReleaseDetailAction(Action.DEPLOY);
              }}
              loading={isDeployingAll}
              disabled={
                isDeployingAll ||
                isUndeployingAll ||
                Boolean(queryParamVersion) ||
                !currentDescriptor ||
                !canDeployUndeploy
              }
            />
          </span>
        </Tooltip>
        <Tooltip title={getUndeployTooltip()}>
          <span>
            <WbCardActionButton
              className={classes.error}
              label="Undeploy"
              onClick={() => {
                setIsDeploymentPreviewOpen(true);
                setReleaseDetailAction(Action.UNDEPLOY);
              }}
              loading={isUndeployingAll}
              disabled={
                isDeployingAll ||
                isUndeployingAll ||
                Boolean(queryParamVersion) ||
                !isCurrentlyDeployed ||
                !currentDescriptor ||
                !canDeployUndeploy
              }
            />
          </span>
        </Tooltip>
      </Box>
    </>
  );
};
