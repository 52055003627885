import { Tooltip, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

export const useTruncatedStyles = (maxLines: number = 2) => {
  const classes = makeStyles(_ => ({
    truncated: {
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': maxLines,
      overflow: 'hidden',
      display: '-webkit-box',
      wordBreak: 'break-word',
    },
  }))();

  return classes;
};

export const WbTruncatedTypographyWrapper: React.FC<{
  value: string;
  style?: React.CSSProperties;
  maxLines?: number;
  enableTooltip?: boolean;
  placement?: string;
}> = ({
  value,
  style,
  maxLines = 2,
  enableTooltip = true,
  placement = 'bottom-start' as any,
}) => {
  const classes = useTruncatedStyles(maxLines);
  return (
    <Tooltip placement={placement} title={enableTooltip ? value : ''}>
      <Typography className={classes.truncated} style={style} variant="body2">
        {value}
      </Typography>
    </Tooltip>
  );
};
