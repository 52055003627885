import { z } from 'zod';
import { compactStringRef } from '@agilelab/plugin-wb-builder-common';

export const questionNotificationRequestDataZod = z.object({
  asked_by: z.preprocess(
    value => compactStringRef(value as string),
    z.string().regex(new RegExp('^user:.+$')),
  ),
  asked_by_display_name: z.string(),
  question: z.string(),
  id_dataproduct_instance: z.number(),
  dataproduct: z
    .object({
      id: z.number(),
      name: z.string(),
      display_name: z.string().optional(),
      environment: z.string().optional(),
      domain: z.string(),
      version: z.string(),
      urn: z.string(),
    })
    .optional(),
});

export type QuestionNotificationRequestData = z.infer<
  typeof questionNotificationRequestDataZod
>;
