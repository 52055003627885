import {
  TableCellProps,
  WbCardContent,
  WbPagination,
  WbTable,
  WbTableFilters,
  WbWidget,
  getObjectPropFromString,
} from '@agilelab/plugin-wb-platform';
import { useTheme } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { SystemItem } from './DataAccessTab';

export const SystemList: React.FC<{
  setSelectedRow: React.Dispatch<React.SetStateAction<SystemItem | undefined>>;
  systems?: SystemItem[];
}> = ({ systems, setSelectedRow }) => {
  const theme = useTheme();
  const [search, setSearch] = useState<string>('');
  const [tableRows, setTableRows] = useState<SystemItem[]>(systems ?? []);

  const [paginationOP, setPaginationOP] = useState<{
    limit: number;
    offset: number;
  }>({
    limit: 25,
    offset: 0,
  });

  const columns = useMemo<TableCellProps<SystemItem>[]>(
    () => [
      {
        field: 'displayName',
        fieldRender: item =>
          `${item.displayName} v${item.systemVersion.split('.')[0]}`,
        tooltip: false,
        cellProps: {
          size: 'medium',
          align: 'left',
          style: { wordBreak: 'break-word' },
        },
        typographyProps: {
          component: 'p',
          variant: 'body2',
        },
        headerName: 'Name',
      },
      {
        field: 'domain',
        tooltip: false,
        cellProps: {
          size: 'medium',
          align: 'left',
          style: { wordBreak: 'break-word' },
        },
        typographyProps: {
          component: 'p',
          variant: 'body2',
        },
        headerName: 'Domain',
      },
      {
        field: 'kind',
        tooltip: false,
        cellProps: {
          size: 'medium',
          align: 'left',
          style: { wordBreak: 'break-word' },
        },
        typographyProps: {
          component: 'p',
          variant: 'body2',
        },
        headerName: 'Kind',
      },
    ],
    [],
  );

  useEffect(() => {
    if (systems && systems?.length) {
      setSelectedRow(systems[0]);
    }
  }, [systems, setSelectedRow]);

  useMemo(() => {
    const fields = columns.map(col => col.field || '');
    const filtered = systems?.filter((row: SystemItem) => {
      const rowItems = fields.map(field => getObjectPropFromString(row, field));
      return rowItems.find(
        item => item && item?.toLowerCase().includes(search.toLowerCase()),
      );
    });

    setTableRows(filtered!);
  }, [systems, search, columns]);

  return (
    <WbWidget
      cardStyle={{ height: '100%', overflow: 'auto' }}
      headerStyle={{ overflow: 'none' }}
      title="Systems"
      footer={
        <WbPagination
          count={systems?.length ?? 0}
          offset={paginationOP.offset}
          limit={paginationOP.limit}
          onPageChange={newPage => {
            setPaginationOP({
              limit: paginationOP.limit,
              offset: newPage * paginationOP.limit,
            });
          }}
          onRowsPerPageChange={newRowsPerPage => {
            setPaginationOP({
              limit: newRowsPerPage,
              offset: 0,
            });
          }}
          style={{
            alignSelf: 'flex-end',
            backgroundColor: 'white',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        />
      }
    >
      <WbCardContent style={{ height: '100%', padding: '0px' }}>
        <WbTableFilters
          style={{ padding: '12px 16px' }}
          onClear={() => {
            setSearch('');
          }}
          searchValue={search}
          onSearch={(value: string) => {
            setPaginationOP({
              limit: paginationOP.limit,
              offset: paginationOP.offset,
            });
            setSearch(value);
          }}
        />
        <WbTable<SystemItem>
          styles={{
            container: {
              maxHeight: '500px',
              overflow: 'auto',
            },
            header: { position: 'sticky', top: 0, zIndex: 1 },
            body: {
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
            },
          }}
          components={{
            tableContent: {
              columns,
              rows: tableRows || [],
              onRowClick: row => {
                setSelectedRow(row);
              },
              selectable: true,
            },
          }}
        />{' '}
      </WbCardContent>
    </WbWidget>
  );
};
