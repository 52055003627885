import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'wb-marketplace',
});

export const productsCatalogRouteRef = createSubRouteRef({
  id: 'wb-marketplace-dp-catalog',
  parent: rootRouteRef,
  path: '/products-catalog',
});

export const dataProductRouteRef = createSubRouteRef({
  id: 'wb-marketplace:dataproduct',
  parent: rootRouteRef,
  path: '/products-catalog/:id/*',
});

export const outputPortRouteRef = createSubRouteRef({
  id: 'wb-marketplace:outputPort',
  parent: rootRouteRef,
  path: '/output-port/:id/*',
});

export const dashboardRouteRef = createSubRouteRef({
  id: 'wb-marketplace-dashboard',
  parent: rootRouteRef,
  path: '/dashboard',
});

export const productsGraphRouteRef = createSubRouteRef({
  id: 'wb-marketplace-dp-graph',
  parent: rootRouteRef,
  path: '/products-graph',
});

export const dataContractsRouteRef = createSubRouteRef({
  id: 'wb-marketplace-data-contracts',
  parent: rootRouteRef,
  path: '/data-contracts',
});
