import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { panelCatalogApiRef } from '../../../api';
import {
  buildReleaseVersion,
  DagStatus,
  generateURNByKind,
  PaginatedProvisioningPlansResponse,
  PolicyValidateResult,
  ProvisioningPlan,
  ReleaseEntity,
  TaskAction,
  WitboostVersionedEntity,
} from '@agilelab/plugin-wb-builder-common';
import {
  MetricSpecificResult,
  PublicInfo,
  SpecificCoordinatorResult,
  Task,
  Test,
} from '../types';
import {
  GovernanceEntityType,
  Pagination,
  Status as PolicyStatus,
} from '@agilelab/plugin-wb-governance-common';
import { useControlPanel } from '../useControlPanel';
import yaml from 'yaml';

type TestCardContextValues = {
  tests: Test[] | null;
  setTests: (value: Test[] | null) => void;
  fetchTestsState: {
    error?: Error;
    loading: boolean;
    value?: PaginatedProvisioningPlansResponse;
  };
  fetchTests: () => void;
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  totalTestCount: number;
  setTotalTestCount: React.Dispatch<React.SetStateAction<number>>;
};

export const TestCardContext = createContext<TestCardContextValues>(
  {} as unknown as TestCardContextValues,
);

type TestCardProps = {
  children: JSX.Element[] | JSX.Element;
  entity: WitboostVersionedEntity;
  environment: string;
  release: ReleaseEntity | null;
};

export function useTestCard() {
  return useContext(TestCardContext);
}

const parseResultString = (
  action: TaskAction,
  obj: string | null,
): Array<{
  displayName?: string;
  errors: string[];
  details?: any;
  status?: DagStatus;
  governanceEntityId?: string;
  governanceEntityStatus?: PolicyStatus;
  validatedDescriptor?: string;
  governanceEntityType?: GovernanceEntityType;
  metricSpecificResult?: MetricSpecificResult;
}> => {
  if (!obj) return [{ errors: [] }];

  try {
    const jsonResult = JSON.parse(obj);

    if (action === TaskAction.POLICY_VALIDATE_COMPONENT)
      return (jsonResult as PolicyValidateResult).evaluationResults.map(
        item => ({
          governanceEntityId: item.governanceEntityId,
          status: item.outcome.toUpperCase() as DagStatus,
          errors: item.result?.errors || [],
          details: item.result?.details,
          governanceEntityStatus: item.governanceEntityStatus,
          governanceEntityType: item.governanceEntityType,
          validatedDescriptor: item.resource?.descriptor,
          displayName: item.resource?.displayName,
          metricSpecificResult:
            item.governanceEntityType === GovernanceEntityType.Metric
              ? {
                  value: item.result?.value,
                  threshold: item.result?.thresholdResult,
                  details: item.result?.details,
                }
              : undefined,
        }),
      );

    // The sync validation has the public info directly in the result, so we don't need to unwrap it
    // We need to check it at runtime since there is no way to understand that the validation was from a sync one
    if (!jsonResult.info) {
      return [
        {
          errors: (jsonResult as PublicInfo).error
            ? (jsonResult as PublicInfo).error!.errors
            : [],
        },
      ];
    }

    return [
      {
        errors: (jsonResult as SpecificCoordinatorResult).info.publicInfo.error
          ? (jsonResult as SpecificCoordinatorResult).info.publicInfo.error!
              .errors
          : [],
      },
    ];
  } catch (error) {
    return [{ errors: [obj] }];
  }
};

const mapToTests = (provisioningPlans: ProvisioningPlan[]): Test[] => {
  if (!provisioningPlans) return [];
  return provisioningPlans.map(pp => {
    return {
      id: pp.dag.id,
      startDate: pp.dag.startTime,
      status: pp.dag.status,
      tasks: pp.dag.dependsOnTasks.flatMap(t => {
        const parsedResultsJson = parseResultString(t.action, t.result);

        return parsedResultsJson.map(result => ({
          id: t.id + result.governanceEntityId,
          action: t.action,
          startTime: t.startTime,
          name: t.name,
          displayName: result?.displayName || t.displayName,
          status: t.status,
          componentName: t.componentName,
          ...result,
        })) as Array<Task>;
      }),
    };
  });
};

export function TestCardProvider({
  children,
  entity,
  environment,
  release,
}: TestCardProps): JSX.Element {
  const panelCatalogApi = useApi(panelCatalogApiRef);
  const identityApi = useApi(identityApiRef);
  const { fetchPreviewDescriptorState } = useControlPanel();
  const [pagination, setPagination] = useState<Pagination>({
    limit: 5,
    offset: 0,
  });
  const [tests, setTests] = useState<Test[] | null>(null);
  const [totalTestCount, setTotalTestCount] = useState<number>(0);

  const [fetchTestsState, fetchTests] = useAsyncFn(
    async (v?: string) => {
      const version = release
        ? buildReleaseVersion({ release })
        : entity.spec.mesh.version;
      return panelCatalogApi.getProvisioningPlansByDpIdAndEnvironment(
        generateURNByKind(entity.metadata.name, entity.kind),
        environment,
        true,
        true,
        {
          operations: [TaskAction.VALIDATION],
          version: v ?? version,
          limit: pagination.limit,
          offset: pagination.offset,
        },
        await identityApi.getCredentials(),
      );
    },
    [entity, environment, release, pagination],
  );

  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  useEffect(() => {
    if (
      !fetchPreviewDescriptorState.loading &&
      !fetchPreviewDescriptorState.error &&
      fetchPreviewDescriptorState.value &&
      entity
    ) {
      const parsedDescriptor = yaml.parse(fetchPreviewDescriptorState.value);
      if (parsedDescriptor.version !== entity.spec.mesh.version) {
        fetchTests(parsedDescriptor.version);
      }
    }
  }, [fetchPreviewDescriptorState, entity, fetchTests]);

  useEffect(() => {
    if (fetchTestsState.value) {
      setTests(mapToTests(fetchTestsState.value.provisioningPlans));
      setTotalTestCount(fetchTestsState.value.page.count);
    }
  }, [fetchTestsState]);

  const values: TestCardContextValues = useMemo(
    () => ({
      tests,
      setTests,
      fetchTestsState,
      fetchTests,
      pagination,
      setPagination,
      totalTestCount,
      setTotalTestCount,
    }),
    [fetchTests, fetchTestsState, pagination, tests, totalTestCount],
  );

  return (
    <TestCardContext.Provider value={values}>
      {children}
    </TestCardContext.Provider>
  );
}
