import React, { useCallback, useEffect, useState } from 'react';
import { useApi, useRouteRefParams } from '@backstage/core-plugin-api';
import { releaseDetailRouteRef } from '../../routes';
import { ReleaseDetailPageContextProvider } from './context/ReleaseDetailPageContext';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  DrawersContextProvider,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import { ReleaseEntity } from '@agilelab/plugin-wb-builder-common';
import { Content, Page, Progress } from '@backstage/core-components';
import { ReleaseDetailHeader } from './ReleaseDetailHeader';
import { AboutCard } from './AboutCard';
import { ProvisioningCard } from './ProvisioningCard/ProvisioningCard';
import { Box } from '@material-ui/core';
import { ReleaseDetailPageEnvironmentLoader } from './ReleaseDetailPageEnvironmentLoader';
import { Entity } from '@backstage/catalog-model';
import { DeployStepDetailDrawer } from './DeployStepDetailDrawer';
import { LogsDrawer } from './LogsDrawer';
import { ValidationTestDrawer } from './ValidationTestDrawer/ValidationTestDrawer';
import { useSearchParams } from 'react-router-dom';
import { DescriptorDrawer } from './DescriptorDrawer';
import { DeploymentPreviewModal } from './DeploymentPreview/DeploymentPreviewModal';
import { DeploymentPreviewContextProvider } from './DeploymentPreview/context/DeploymentPreviewContext';

export const ReleaseDetailPage: React.FC = () => {
  const { kind, namespace, name } = useRouteRefParams(releaseDetailRouteRef);

  const [params] = useSearchParams();
  const env = params.get('env');
  const version = params.get('version');

  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const [loading, setLoading] = useState<boolean>(false);
  const [entity, setEntity] = useState<Entity | undefined>();
  const [release, setRelease] = useState<ReleaseEntity | undefined>();

  const fetchRelease = useCallback(async () => {
    setLoading(true);
    try {
      const r = (await catalogApi.getEntityByRef({
        kind,
        namespace,
        name,
      })) as ReleaseEntity;
      const e = await catalogApi.getEntityByRef({
        kind: 'system',
        namespace: 'default',
        name: r.metadata.dataProductName,
      });

      setRelease(r);
      setEntity(e);
    } catch (error) {
      alertApi.post({ error, severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, [alertApi, catalogApi, kind, namespace, name]);

  useEffect(() => {
    fetchRelease();
  }, [fetchRelease]);

  if (loading) return <Progress />;

  return (
    <>
      {release && entity && (
        <ReleaseDetailPageContextProvider
          release={release}
          entity={entity}
          fetchRelease={fetchRelease}
          queryParamEnvironment={env}
          queryParamVersion={version}
        >
          <ReleaseDetailPageEnvironmentLoader>
            <DrawersContextProvider>
              <Page themeId="home">
                <ReleaseDetailHeader />
                <Content>
                  <AboutCard />
                  <Box marginBottom="16px" />
                  <ProvisioningCard />
                </Content>

                <DeployStepDetailDrawer />
                <LogsDrawer />

                <ValidationTestDrawer />
              </Page>
            </DrawersContextProvider>

            <DescriptorDrawer />

            <DeploymentPreviewContextProvider>
              <DeploymentPreviewModal />
            </DeploymentPreviewContextProvider>
          </ReleaseDetailPageEnvironmentLoader>
        </ReleaseDetailPageContextProvider>
      )}
    </>
  );
};
