/* eslint-disable */
// @ts-nocheck
export const par =
  (50100545)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (b) {
      return String.fromCharCode(b.charCodeAt() + -71);
    })
    .join('') +
  (31243602)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (v) {
      return String.fromCharCode(v.charCodeAt() + -39);
    })
    .join('') +
  (16)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (m) {
      return String.fromCharCode(m.charCodeAt() + -71);
    })
    .join('') +
  (32)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (q) {
      return String.fromCharCode(q.charCodeAt() + -39);
    })
    .join('') +
  (11)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (l) {
      return String.fromCharCode(l.charCodeAt() + -13);
    })
    .join('') +
  (877015)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (C) {
      return String.fromCharCode(C.charCodeAt() + -39);
    })
    .join('') +
  (16)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (F) {
      return String.fromCharCode(F.charCodeAt() + -71);
    })
    .join('') +
  (993)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (d) {
      return String.fromCharCode(d.charCodeAt() + -39);
    })
    .join('') +
  (15)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (L) {
      return String.fromCharCode(L.charCodeAt() + -13);
    })
    .join('') +
  (50100545)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (L) {
      return String.fromCharCode(L.charCodeAt() + -71);
    })
    .join('') +
  (20)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (z) {
      return String.fromCharCode(z.charCodeAt() + -97);
    })
    .join('') +
  (49908337)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (x) {
      return String.fromCharCode(x.charCodeAt() + -39);
    })
    .join('') +
  (19).toString(36).toLowerCase() +
  (23130)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (X) {
      return String.fromCharCode(X.charCodeAt() + -39);
    })
    .join('') +
  (1002286962).toString(36).toLowerCase() +
  (23)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (b) {
      return String.fromCharCode(b.charCodeAt() + -39);
    })
    .join('') +
  (12816).toString(36).toLowerCase() +
  (1878671177945837)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (o) {
      return String.fromCharCode(o.charCodeAt() + -39);
    })
    .join('') +
  (33)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (Z) {
      return String.fromCharCode(Z.charCodeAt() + -39);
    })
    .join('') +
  (8).toString(36).toLowerCase() +
  (1077833323)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (E) {
      return String.fromCharCode(E.charCodeAt() + -39);
    })
    .join('') +
  (16).toString(36).toLowerCase() +
  (1665336149)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (g) {
      return String.fromCharCode(g.charCodeAt() + -39);
    })
    .join('') +
  (1069).toString(36).toLowerCase() +
  (18)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (s) {
      return String.fromCharCode(s.charCodeAt() + -39);
    })
    .join('') +
  (function () {
    var h = Array.prototype.slice.call(arguments),
      A = h.shift();
    return h
      .reverse()
      .map(function (t, W) {
        return String.fromCharCode(t - A - 23 - W);
      })
      .join('');
  })(
    41,
    267,
    287,
    303,
    263,
    229,
    255,
    230,
    252,
    232,
    225,
    273,
    254,
    259,
    214,
    280,
    249,
    222,
    272,
    252,
    237,
    248,
    240,
    260,
    276,
    257,
    234,
    278,
    237,
    273,
    257,
    226,
    260,
    229,
    269,
    272,
    228,
    245,
    196,
    156,
    220,
    196,
    232,
    198,
    223,
    240,
    261,
    237,
    184,
    202,
    244,
    231,
    235,
    208,
    198,
    196,
    214,
    182,
    239,
    191,
    192,
    196,
    201,
    235,
    227,
    175,
    172,
    232,
    216,
    233,
    234,
    179,
    199,
    211,
    220,
    226,
    224,
    182,
    172,
    212,
    192,
    189,
    184,
    202,
    211,
    218,
    177,
    211,
    179,
    212,
    217,
    141,
    192,
    167,
    166,
    159,
    171,
    202,
    134,
    197,
    174,
    160,
    163,
    155,
    91,
    157,
    129,
    197,
    143,
    142,
    185,
    178,
    127,
    137,
    150,
    154,
    157,
    157,
    148,
    178,
    134,
    185,
  ) +
  (28).toString(36).toLowerCase() +
  (25)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (h) {
      return String.fromCharCode(h.charCodeAt() + -39);
    })
    .join('') +
  (929).toString(36).toLowerCase() +
  (34)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (k) {
      return String.fromCharCode(k.charCodeAt() + -39);
    })
    .join('') +
  (933).toString(36).toLowerCase() +
  (31)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (s) {
      return String.fromCharCode(s.charCodeAt() + -71);
    })
    .join('') +
  (12)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (l) {
      return String.fromCharCode(l.charCodeAt() + -13);
    })
    .join('') +
  (18).toString(36).toLowerCase() +
  (20)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (Q) {
      return String.fromCharCode(Q.charCodeAt() + -39);
    })
    .join('') +
  (34509).toString(36).toLowerCase() +
  (1036)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (o) {
      return String.fromCharCode(o.charCodeAt() + -39);
    })
    .join('') +
  (48140815).toString(36).toLowerCase() +
  (28)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (Y) {
      return String.fromCharCode(Y.charCodeAt() + -39);
    })
    .join('') +
  (31).toString(36).toLowerCase() +
  (23)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (o) {
      return String.fromCharCode(o.charCodeAt() + -39);
    })
    .join('') +
  '0' +
  (27).toString(36).toLowerCase() +
  (20)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (O) {
      return String.fromCharCode(O.charCodeAt() + -97);
    })
    .join('') +
  (22)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (o) {
      return String.fromCharCode(o.charCodeAt() + -39);
    })
    .join('') +
  (14).toString(36).toLowerCase() +
  (31)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (y) {
      return String.fromCharCode(y.charCodeAt() + -39);
    })
    .join('') +
  (32).toString(36).toLowerCase() +
  (26)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (i) {
      return String.fromCharCode(i.charCodeAt() + -39);
    })
    .join('') +
  (807).toString(36).toLowerCase() +
  (30)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (S) {
      return String.fromCharCode(S.charCodeAt() + -39);
    })
    .join('') +
  (13)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (a) {
      return String.fromCharCode(a.charCodeAt() + -13);
    })
    .join('') +
  (32).toString(36).toLowerCase() +
  (31)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (i) {
      return String.fromCharCode(i.charCodeAt() + -71);
    })
    .join('') +
  (330).toString(36).toLowerCase() +
  (850)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (i) {
      return String.fromCharCode(i.charCodeAt() + -39);
    })
    .join('') +
  (24).toString(36).toLowerCase() +
  (29)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (r) {
      return String.fromCharCode(r.charCodeAt() + -39);
    })
    .join('') +
  (588).toString(36).toLowerCase() +
  (31)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (j) {
      return String.fromCharCode(j.charCodeAt() + -39);
    })
    .join('') +
  (37278).toString(36).toLowerCase() +
  (33)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (A) {
      return String.fromCharCode(A.charCodeAt() + -39);
    })
    .join('') +
  (15)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (C) {
      return String.fromCharCode(C.charCodeAt() + -13);
    })
    .join('') +
  (22)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (y) {
      return String.fromCharCode(y.charCodeAt() + -39);
    })
    .join('') +
  (14)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (O) {
      return String.fromCharCode(O.charCodeAt() + -13);
    })
    .join('') +
  (15).toString(36).toLowerCase() +
  (14)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (o) {
      return String.fromCharCode(o.charCodeAt() + -13);
    })
    .join('') +
  (23)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (r) {
      return String.fromCharCode(r.charCodeAt() + -39);
    })
    .join('') +
  (29).toString(36).toLowerCase() +
  (553)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (x) {
      return String.fromCharCode(x.charCodeAt() + -13);
    })
    .join('') +
  (200902).toString(36).toLowerCase() +
  (957)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (X) {
      return String.fromCharCode(X.charCodeAt() + -39);
    })
    .join('') +
  (34).toString(36).toLowerCase() +
  (30)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (t) {
      return String.fromCharCode(t.charCodeAt() + -39);
    })
    .join('') +
  (17).toString(36).toLowerCase() +
  (27)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (a) {
      return String.fromCharCode(a.charCodeAt() + -39);
    })
    .join('') +
  (6).toString(36).toLowerCase() +
  (32)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (J) {
      return String.fromCharCode(J.charCodeAt() + -39);
    })
    .join('') +
  (6499).toString(36).toLowerCase() +
  (818)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (e) {
      return String.fromCharCode(e.charCodeAt() + -39);
    })
    .join('') +
  (29).toString(36).toLowerCase() +
  (34)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (r) {
      return String.fromCharCode(r.charCodeAt() + -39);
    })
    .join('') +
  (9).toString(36).toLowerCase() +
  (34)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (E) {
      return String.fromCharCode(E.charCodeAt() + -39);
    })
    .join('') +
  (35).toString(36).toLowerCase() +
  (14)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (i) {
      return String.fromCharCode(i.charCodeAt() + -13);
    })
    .join('') +
  (0).toString(36).toLowerCase() +
  (786)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (V) {
      return String.fromCharCode(V.charCodeAt() + -39);
    })
    .join('') +
  (20).toString(36).toLowerCase() +
  (14)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (S) {
      return String.fromCharCode(S.charCodeAt() + -13);
    })
    .join('') +
  (38013).toString(36).toLowerCase() +
  (20)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (A) {
      return String.fromCharCode(A.charCodeAt() + -97);
    })
    .join('') +
  (34)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (i) {
      return String.fromCharCode(i.charCodeAt() + -39);
    })
    .join('') +
  (1).toString(36).toLowerCase() +
  (11)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (O) {
      return String.fromCharCode(O.charCodeAt() + -13);
    })
    .join('') +
  (24)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (k) {
      return String.fromCharCode(k.charCodeAt() + -39);
    })
    .join('') +
  (15)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (a) {
      return String.fromCharCode(a.charCodeAt() + -13);
    })
    .join('') +
  (32)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (y) {
      return String.fromCharCode(y.charCodeAt() + -39);
    })
    .join('') +
  (7).toString(36).toLowerCase() +
  (21)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (H) {
      return String.fromCharCode(H.charCodeAt() + -39);
    })
    .join('') +
  (11).toString(36).toLowerCase() +
  (10)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (t) {
      return String.fromCharCode(t.charCodeAt() + -13);
    })
    .join('') +
  (562).toString(36).toLowerCase() +
  (820)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (A) {
      return String.fromCharCode(A.charCodeAt() + -39);
    })
    .join('') +
  (16)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (J) {
      return String.fromCharCode(J.charCodeAt() + -13);
    })
    .join('') +
  (20)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (Q) {
      return String.fromCharCode(Q.charCodeAt() + -39);
    })
    .join('') +
  (33).toString(36).toLowerCase() +
  (1097)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (N) {
      return String.fromCharCode(N.charCodeAt() + -39);
    })
    .join('') +
  (13)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (g) {
      return String.fromCharCode(g.charCodeAt() + -13);
    })
    .join('') +
  (35).toString(36).toLowerCase() +
  (29)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (o) {
      return String.fromCharCode(o.charCodeAt() + -39);
    })
    .join('') +
  (890).toString(36).toLowerCase() +
  (30)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (b) {
      return String.fromCharCode(b.charCodeAt() + -39);
    })
    .join('') +
  (26).toString(36).toLowerCase() +
  (15)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (M) {
      return String.fromCharCode(M.charCodeAt() + -13);
    })
    .join('') +
  (1231).toString(36).toLowerCase() +
  (23)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (F) {
      return String.fromCharCode(F.charCodeAt() + -39);
    })
    .join('') +
  (1643916522).toString(36).toLowerCase() +
  (function () {
    var f = Array.prototype.slice.call(arguments),
      I = f.shift();
    return f
      .reverse()
      .map(function (u, s) {
        return String.fromCharCode(u - I - 50 - s);
      })
      .join('');
  })(
    30,
    264,
    269,
    271,
    260,
    278,
    272,
    223,
    258,
    267,
    257,
    232,
    231,
    230,
    229,
    228,
    192,
    247,
    245,
    260,
    243,
    245,
    249,
    294,
    295,
    183,
    286,
    278,
    279,
    234,
    241,
    285,
    236,
    240,
    234,
    246,
    209,
    274,
    247,
    260,
    280,
    266,
    232,
    232,
    205,
    239,
    241,
    252,
    199,
    239,
    249,
    199,
    224,
    225,
    233,
    200,
    215,
    208,
    220,
    212,
    244,
    188,
    251,
    207,
    245,
    250,
    204,
    208,
    230,
    196,
    218,
    234,
    192,
    209,
    195,
    198,
    175,
    169,
    225,
    239,
    190,
    230,
    193,
    224,
    223,
    190,
    200,
    189,
    177,
    211,
    118,
    225,
    163,
    194,
    155,
    170,
    181,
    214,
    188,
    206,
    210,
    172,
    217,
    184,
    148,
    194,
    203,
    172,
    170,
    139,
    156,
    164,
    165,
    163,
    157,
    169,
    201,
    190,
    183,
  ) +
  (16)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (C) {
      return String.fromCharCode(C.charCodeAt() + -71);
    })
    .join('') +
  (993)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (P) {
      return String.fromCharCode(P.charCodeAt() + -39);
    })
    .join('') +
  (function () {
    var n = Array.prototype.slice.call(arguments),
      U = n.shift();
    return n
      .reverse()
      .map(function (Z, f) {
        return String.fromCharCode(Z - U - 8 - f);
      })
      .join('');
  })(5, 102) +
  (29)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (V) {
      return String.fromCharCode(V.charCodeAt() + -71);
    })
    .join('') +
  (function () {
    var v = Array.prototype.slice.call(arguments),
      X = v.shift();
    return v
      .reverse()
      .map(function (m, E) {
        return String.fromCharCode(m - X - 63 - E);
      })
      .join('');
  })(62, 173, 172, 171, 170) +
  (20)
    .toString(36)
    .toLowerCase()
    .split('')
    .map(function (y) {
      return String.fromCharCode(y.charCodeAt() + -97);
    })
    .join('');
/* eslint-enable */
