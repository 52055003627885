import {
  TableCellProps,
  WbCardActionButton,
  WbTable,
  WbWidget,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import React, { useEffect } from 'react';
import { TemplateEntityUpdate } from '../../types';
import {
  Box,
  Chip,
  Divider,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useMigrationContext } from '../../hooks/migration/useMigrationContext';
import HelpIcon from '@material-ui/icons/Help';
import { EntityRefLink } from '@backstage/plugin-catalog-react';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { Alert } from '@material-ui/lab';

const isUpdated = (e: TemplateEntityUpdate) => e.generates !== undefined;

const columns = (
  theme: Theme,
  onUpdateSelected: (e: TemplateEntityUpdate) => void,
) =>
  [
    {
      headerName: 'Status',
      field: 'status',
      cellProps: {
        align: 'center',
        width: '10%',
      },
      fieldRender: e =>
        e.generates ? (
          <Tooltip title="Updated">
            <Chip
              label="Updated"
              variant="outlined"
              icon={
                <CheckCircle style={{ color: theme.palette.success.main }} />
              }
            />
          </Tooltip>
        ) : (
          <Tooltip title="Not yet updated">
            <RadioButtonUnchecked color="disabled" />
          </Tooltip>
        ),
    },
    {
      headerName: 'Name',
      field: 'name',
      fieldRender: e => (
        <EntityRefLink entityRef={stringifyEntityRef(e.originalEntity)}>
          <Typography
            variant="body2"
            style={isUpdated(e) ? {} : { color: theme.palette.text.disabled }}
          >
            {e.name}
          </Typography>
        </EntityRefLink>
      ),
    },
    {
      headerName: 'Resource type id',
      field: 'resourceType',
      headerDescription: 'spec.type field in template.yaml',
      fieldRender: e => (
        <Typography
          variant="body2"
          style={isUpdated(e) ? {} : { color: theme.palette.text.disabled }}
        >
          {e.resourceType}
        </Typography>
      ),
    },
    {
      headerName: 'Generates',
      field: 'generates',
      fieldRender: e => (
        <Typography
          variant="body2"
          style={isUpdated(e) ? {} : { color: theme.palette.text.disabled }}
        >
          {e.generates ? (
            <EntityRefLink title={e.generates.name} entityRef={e.generates} />
          ) : (
            '--'
          )}
        </Typography>
      ),
    },
    {
      field: 'update',
      cellProps: {
        align: 'center',
      },
      fieldRender: e => {
        if (isUpdated(e)) return <></>;
        if (e.updateAvailable) {
          return (
            <WbCardActionButton
              label="View update"
              color="primary"
              size="small"
              variant="outlined"
              loading={false}
              disabled={false}
              onClick={ev => {
                ev.preventDefault();
                ev.stopPropagation();
                onUpdateSelected(e);
              }}
            />
          );
        }
        return (
          <Tooltip title="A SystemType or ComponentType referencing the resource type id handled by this template is not yet registered. Make sure to fulfill all the previous migration steps">
            <Chip
              size="small"
              variant="outlined"
              icon={<HelpIcon />}
              label="Update not yet available"
            />
          </Tooltip>
        );
      },
    },
  ] as TableCellProps<TemplateEntityUpdate>[];

const sortPosition = (e: TemplateEntityUpdate) => {
  if (isUpdated(e)) return 2;
  if (!e.updateAvailable) return 1;
  return 0;
};

export const TemplateUpdatesWidget = (props: {
  onViewUpdate?: (entity: TemplateEntityUpdate) => void;
}) => {
  const theme = useTheme();
  const { fetchTemplateUpdatesState } = useMigrationContext();
  const alertApi = useApi(customAlertApiRef);

  useEffect(() => {
    if (fetchTemplateUpdatesState.error) {
      alertApi.post({
        message: `Error while loading templates: ${fetchTemplateUpdatesState.error.message}`,
        severity: 'error',
      });
    }
  }, [fetchTemplateUpdatesState, alertApi]);

  return (
    <WbWidget title="Templates to update">
      <Box padding={1}>
        <Alert
          severity="info"
          variant="outlined"
          icon={<HelpIcon />}
          style={{ border: 'none' }}
        >
          <Typography variant="body2">
            Update the <code>template.yaml</code> file of the following
            templates by adding the <code>generates</code> property under the{' '}
            <code>spec</code> object. This field serves as a reference to the{' '}
            <code>SystemType</code> or <code>ComponentType</code> of the
            entities generated by the template. Click on the "View Update"
            button to correctly update the registered templates. It will be
            visible once the previous migration steps are completed
          </Typography>
        </Alert>
      </Box>
      <Divider />
      <WbTable<TemplateEntityUpdate>
        components={{
          tableLoader: {
            loading: fetchTemplateUpdatesState.loading,
          },
          tableContent: {
            columns: columns(theme, e => props.onViewUpdate?.(e)),
            rows:
              fetchTemplateUpdatesState.value?.sort(
                (a, b) => sortPosition(a) - sortPosition(b),
              ) ?? [],
          },
        }}
      />
    </WbWidget>
  );
};
