/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { ReactNode, useMemo, useState } from 'react';
import {
  Box,
  Chip,
  createStyles,
  Divider,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { useAnalytics } from '@backstage/core-plugin-api';
import {
  IndexableDocument,
  ResultHighlight,
} from '@backstage/plugin-search-common';
import { HighlightedSearchResultText } from '@backstage/plugin-search-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexContainer: {
      flexWrap: 'wrap',
    },
    itemText: {
      width: '100%',
      wordBreak: 'break-all',

      '& > p': {
        color: theme.palette.text.primary,
      },
      '& mark': {
        backgroundColor: theme.palette.bkg.primary,
      },
    },
    link: {
      '&:hover': {
        textDecoration: 'none',
        '& *': {
          color: theme.palette.primary.main,
        },
      },
      color: theme.palette.text.primary,
    },
    metaTagsWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
    },
    metaTag: {
      maxWidth: '250px',
      '& > span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }),
);

const TagTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    '& mark': {
      backgroundColor: theme.palette.bkg.primary,
    },
  },
}))(Tooltip);

/**
 * Props for {@link CatalogSearchResultListItem}.
 *
 * @public
 */
export interface CatalogSearchResultListItemProps {
  icon?: ReactNode;
  result: IndexableDocument;
  highlight?: ResultHighlight;
  rank?: number;
  onClick?: () => void;
}

function SearchedMetaTags(props: {
  tags: string[];
  preTag: string;
  postTag: string;
}) {
  const { tags, preTag, postTag } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const filteredTags = useMemo(() => {
    return tags.filter(t => t.includes(preTag));
  }, [preTag, tags]);

  return (
    <>
      {filteredTags.slice(0, 3).map((t, i) => (
        <TagTooltip
          key={i}
          title={
            <HighlightedSearchResultText
              text={t}
              preTag={preTag}
              postTag={postTag}
            />
          }
        >
          <Chip
            label={t.replace(new RegExp(`${preTag}|${postTag}`, 'g'), '')}
            size="small"
            variant="outlined"
            className={classes.metaTag}
            color="primary"
          />
        </TagTooltip>
      ))}
      {!expanded && filteredTags.length > 3 ? (
        <TagTooltip title="expand">
          <Chip
            label="+"
            size="small"
            variant="outlined"
            className={classes.metaTag}
            color="primary"
            onClick={() => setExpanded(true)}
          />
        </TagTooltip>
      ) : (
        filteredTags.slice(3, filteredTags.length).map((t, i) => (
          <TagTooltip
            key={`ex${i}`}
            title={
              <HighlightedSearchResultText
                text={t}
                preTag={preTag}
                postTag={postTag}
              />
            }
          >
            <Chip
              label={t.replace(new RegExp(`${preTag}|${postTag}`, 'g'), '')}
              size="small"
              variant="outlined"
              className={classes.metaTag}
              color="primary"
            />
          </TagTooltip>
        ))
      )}
    </>
  );
}

/** @public */
export function CatalogSearchResultListItem(
  props: CatalogSearchResultListItemProps,
) {
  const result = props.result as any;
  const theme = useTheme();

  const classes = useStyles();
  const analytics = useAnalytics();
  const handleClick = () => {
    analytics.captureEvent('discover', result.title, {
      attributes: { to: result.location },
      value: props.rank,
    });
    if (props.onClick) props.onClick();
  };

  return (
    <>
      <ListItem
        alignItems="flex-start"
        style={{ padding: theme.spacing(2, 0) }}
      >
        <div className={classes.flexContainer}>
          <Link
            noTrack
            to={result.location}
            onClick={handleClick}
            className={classes.link}
          >
            <ListItemText
              className={classes.itemText}
              primaryTypographyProps={{ variant: 'h6' }}
              primary={
                props.highlight?.fields.title ? (
                  <HighlightedSearchResultText
                    text={props.highlight.fields.title}
                    preTag={props.highlight.preTag}
                    postTag={props.highlight.postTag}
                  />
                ) : (
                  result.title
                )
              }
              secondary={
                props.highlight?.fields.description ? (
                  <HighlightedSearchResultText
                    text={`Description: ${props?.highlight?.fields.description}`}
                    preTag={props?.highlight.preTag}
                    postTag={props?.highlight.postTag}
                  />
                ) : (
                  <>{`Description: ${result.description}`}</>
                )
              }
            />
            <Box style={{ marginBottom: theme.spacing(1) }}>
              {result.location && (
                <Typography
                  variant="body2"
                  component="span"
                  style={{ marginRight: theme.spacing(1) }}
                >
                  {`Source: `}
                  <b>{result.source}</b>
                </Typography>
              )}
              {result.type && (
                <Typography
                  component="span"
                  variant="body2"
                  style={{ marginRight: theme.spacing(1) }}
                >
                  {`Type: `}
                  <b>{result.type}</b>
                </Typography>
              )}
              {result.version && (
                <Typography
                  component="span"
                  variant="body2"
                  style={{ marginRight: theme.spacing(1) }}
                >
                  {`Version: `}
                  <b>{result.version}</b>
                </Typography>
              )}
            </Box>
          </Link>
          <Box className={classes.metaTagsWrapper}>
            <span>MetaTags: </span>
            {props?.highlight?.fields.text ? (
              <SearchedMetaTags
                tags={props?.highlight?.fields.text.split(' # ')}
                preTag={props?.highlight.preTag}
                postTag={props?.highlight.postTag}
              />
            ) : (
              <>
                {(result.text as string)
                  .split(' # ')
                  .slice(0, 3)
                  .filter(t => t !== '')
                  .map((t, i) => (
                    <TagTooltip title={t} key={`def${i}`}>
                      <Chip
                        label={t}
                        size="small"
                        variant="default"
                        color="default"
                        className={classes.metaTag}
                      />
                    </TagTooltip>
                  ))}
              </>
            )}
          </Box>
        </div>
      </ListItem>
      <Divider component="li" />
    </>
  );
}
