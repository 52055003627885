import { WbAutocomplete, useFetchUserInfo } from '@agilelab/plugin-wb-platform';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import React, { useCallback, useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';

type DomainFieldProps = {
  idSchema: string;
  domain: string | undefined;
  setDomain: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const DomainField = ({
  idSchema,
  domain,
  setDomain,
}: DomainFieldProps) => {
  const catalogApi = useApi(catalogApiRef);
  const { token } = useFetchUserInfo();
  const [domainState, setDomainState] = useState<string | undefined>(undefined);

  const { value: domains, loading } = useAsync(async () => {
    return await catalogApi.getEntities(
      { filter: { kind: ['Domain'] } },
      { token },
    );
  });

  useEffect(() => {
    setDomainState(domain);
  }, [domain]);

  const domainRefs = domains?.items.map(domainEntity =>
    humanizeEntityRef(domainEntity),
  );
  const onSelect = useCallback(
    (_: any, value: string | null) => {
      setDomain(value || undefined);
    },
    [setDomain],
  );

  return (
    <WbAutocomplete
      id={`${idSchema}-domain`}
      value={domainState || ''}
      loading={loading}
      onChange={onSelect}
      options={domainRefs || []}
      autoSelect
      freeSolo={false}
      required
      helperText="The domain of the Output Port"
      label="Domain"
    />
  );
};
