import React, { ReactElement, isValidElement } from 'react';
import { Box, Grid, useTheme } from '@material-ui/core';
import { WbCard, WbCardContent } from '../WbCard';
import { WbWidget } from '../WbWidget';
import { CustomViewRegister } from '../CustomView/registry';

CustomViewRegister.register({
  id: 'container',
  function: function ContainerView(props: Record<string, any>) {
    return (
      <Grid
        container
        spacing={props.spacing || 3}
        alignItems="stretch"
        style={{
          padding: '12px 16px',
        }}
      >
        {props.children}
      </Grid>
    );
  },
});

CustomViewRegister.register({
  id: 'griditem',
  function: function ItemView(props: Record<string, any>) {
    return (
      <Grid item xs={props.size || 12}>
        {props.children}
      </Grid>
    );
  },
});

CustomViewRegister.register({
  id: 'box',
  function: function BoxView(props: Record<string, any>) {
    return <Box>{props.children}</Box>;
  },
});

CustomViewRegister.register({
  id: 'group',
  function: function BoxView(props: Record<string, any>) {
    const theme = useTheme();
    return (
      <div
        style={{
          borderLeft: `1px solid ${theme.palette.grey[200]}`,
          paddingLeft: `${theme.spacing(2)}px`,
          gap: `${theme.spacing(2)}px`,
          width: '100%',
        }}
      >
        {props.children}
      </div>
    );
  },
});

const getChildren = (props: Record<string, any>): ReactElement<any>[] => {
  if (!props.children) return [];
  return React.Children.toArray(props.children).filter(isValidElement);
};

CustomViewRegister.register({
  id: 'hbox',
  function: function HBoxView(props: Record<string, any>) {
    return (
      <Box display="flex">
        {getChildren(props).map((child: ReactElement, idx: Number) => {
          const isLast = idx === getChildren(props).length - 1;
          return (
            <Box
              key={String(idx)}
              flex={child.props.size || 1}
              style={{ paddingRight: isLast ? '' : '10px' }}
            >
              {child}
            </Box>
          );
        })}
      </Box>
    );
  },
});

CustomViewRegister.register({
  id: 'gridlist',
  function: function HBoxView(props: Record<string, any>) {
    return (
      <Grid container spacing={props.spacing || 3}>
        {getChildren(props).map((child: ReactElement, idx: Number) => {
          return (
            <Grid key={String(idx)} item xs={child.props.size || 12}>
              {child}
            </Grid>
          );
        })}
      </Grid>
    );
  },
});

CustomViewRegister.register({
  id: 'card',
  function: function CardView(props: Record<string, any>) {
    return (
      <WbCard title={props.title || ''}>
        <WbCardContent>{props.children}</WbCardContent>
      </WbCard>
    );
  },
});

CustomViewRegister.register({
  id: 'subcard',
  function: function SubCardView(props: Record<string, any>) {
    return (
      <WbWidget title={props.title || ''}>
        <WbCardContent>{props.children}</WbCardContent>
      </WbWidget>
    );
  },
});

CustomViewRegister.register({
  id: 'vspace',
  function: function SubCardView() {
    const theme = useTheme();
    return <Box style={{ marginBottom: theme.spacing(2) }} />;
  },
});

CustomViewRegister.register({
  id: 'hline',
  function: function SubCardView() {
    const theme = useTheme();
    return (
      <Box
        style={{
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
          width: '95%',
        }}
      />
    );
  },
});
