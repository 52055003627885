import React from 'react';
import { Tooltip, useTheme } from '@material-ui/core';
import { useStyles } from './style';
import { Severity } from '@agilelab/plugin-wb-governance-common';
import { IconLabelBox } from '../../IconLabelBox';
import { InfoOutlinedIcon } from './Icons';
import InfoIcon from '@material-ui/icons/Info';

interface Props {
  severity?: Severity;
  style?: React.CSSProperties;
  variant?: 'reduced' | 'default';
}

export const InformationIcon: React.FC<Props> = ({
  severity,
  style,
  variant = 'default',
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Tooltip
      title={`Failed with severity ${severity}. This policy is just a warning, and will not block the deployment, but remember to check what errors made it fail`}
    >
      <span>
        {variant === 'reduced' ? (
          <InfoIcon
            className={`${classes.icon}`}
            htmlColor={theme.palette.primary.main}
            style={{ ...style, transform: 'scale(1.2)' }}
          />
        ) : (
          <IconLabelBox
            label="Warning"
            backgroundColor={theme.palette.primary.main}
            icon={<InfoOutlinedIcon />}
          />
        )}
      </span>
    </Tooltip>
  );
};
