import {
  TableCellProps,
  WbTable,
  WbWidget,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import React, { useEffect } from 'react';
import { MigrationStep } from '../../types';
import {
  Box,
  Chip,
  Divider,
  LinearProgress,
  LinearProgressProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { useMigrationContext } from '../../hooks/migration/useMigrationContext';
import { useApi } from '@backstage/core-plugin-api';
import { Alert } from '@material-ui/lab';
import { HelpIcon } from '@backstage/core-components';

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number },
) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const migrationStatusColumns = (theme: Theme) =>
  [
    {
      field: 'isComplete',
      cellProps: {
        align: 'center',
      },
      fieldRender: e =>
        e.isComplete ? (
          <Tooltip title="Completed">
            <Chip
              label="Completed"
              variant="outlined"
              icon={
                <CheckCircle style={{ color: theme.palette.success.main }} />
              }
            />
          </Tooltip>
        ) : (
          <Tooltip title="To do">
            <RadioButtonUnchecked color="disabled" />
          </Tooltip>
        ),
    },
    {
      field: 'description',
      fieldRender: e => {
        const desc = (
          <Typography
            variant="body2"
            style={e.isComplete ? {} : { color: theme.palette.text.disabled }}
          >
            {e.description}
          </Typography>
        );
        return e.additionalInfo ? (
          <Tooltip title={e.additionalInfo}>{desc}</Tooltip>
        ) : (
          desc
        );
      },
    },
  ] as TableCellProps<MigrationStep>[];

export const MigrationStepsWidget: React.FC<{}> = () => {
  const theme = useTheme();
  const { fetchMigrationStepsState } = useMigrationContext();
  const migrationSteps = fetchMigrationStepsState.value ?? [];
  const alertApi = useApi(customAlertApiRef);

  useEffect(() => {
    if (fetchMigrationStepsState.error) {
      alertApi.post({
        message: `Error while fetching migration steps: ${fetchMigrationStepsState.error.message}`,
        severity: 'error',
      });
    }
  }, [fetchMigrationStepsState, alertApi]);

  return (
    <WbWidget
      title="Migration steps"
      footer={
        !fetchMigrationStepsState.loading && migrationSteps.length ? (
          <Box sx={{ width: '100%' }} padding={1}>
            <LinearProgressWithLabel
              value={
                (migrationSteps.filter(v => v.isComplete).length /
                  migrationSteps.length) *
                100
              }
            />
          </Box>
        ) : (
          <></>
        )
      }
    >
      <Box padding={1}>
        <Alert
          severity="info"
          variant="outlined"
          icon={<HelpIcon />}
          style={{ border: 'none' }}
        >
          <Typography variant="body2">
            Complete the following steps in order. The panels on this page will
            guide you through the process
          </Typography>
        </Alert>
      </Box>
      <Divider />
      <WbTable<MigrationStep>
        components={{
          tableLoader: { loading: fetchMigrationStepsState.loading },
          tableContent: {
            columns: migrationStatusColumns(theme),
            rows: migrationSteps,
          },
        }}
      />
    </WbWidget>
  );
};
