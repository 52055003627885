import { WbDrawer } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { useEditorPageContext } from '../../context/useEditorPageContext';
import { MarkdownContent } from '@backstage/core-components';
import { TextBox } from './TextBox';
import { Box, Typography, makeStyles, useTheme } from '@material-ui/core';
import { Feedbacks } from './Feedbacks';
import { UserAvatar } from './UserAvatar';
import { JsonObject } from '@backstage/types';
import { WittyIcon } from '../WittyIcon';

const useStyles = makeStyles(() => ({
  markdown: {
    flex: 1,
    overflow: 'auto',
    paddingLeft: '40px',
  },
}));

export const AssistantDrawer: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const {
    setIsAssistantDrawerOpen,
    isAssistantDrawerOpen,
    assistantState,
    entity,
  } = useEditorPageContext();

  return (
    <WbDrawer
      open={isAssistantDrawerOpen}
      setOpen={() => {
        setIsAssistantDrawerOpen(false);
      }}
      cardStyle={{ overflow: 'hidden' }}
      contentContainerStyle={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        gap: '15px',
      }}
      title="Witty"
    >
      <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
        <UserAvatar />
        <Typography style={{ fontWeight: 600 }} variant="body2">
          You:
        </Typography>
      </Box>

      <Box paddingLeft="40px" marginBottom="20px">
        <Typography variant="body2">
          Test Data Product <b>{(entity.spec?.mesh as JsonObject)?.name}</b>{' '}
          against Governance Guardrails
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
        <WittyIcon style={{ color: theme.palette.witboostLogo }} />
        <Typography style={{ fontWeight: 600 }} variant="body2">
          Witty:
        </Typography>
      </Box>

      <MarkdownContent
        className={classes.markdown}
        content={assistantState.value || ''}
      />

      <Feedbacks />
      <TextBox />
    </WbDrawer>
  );
};
