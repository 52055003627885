import {
  Dag,
  DagStatus,
  ProvisioningPlan,
  TaskAction,
} from '@agilelab/plugin-wb-builder-common';
import { Entity } from '@backstage/catalog-model';
import { Component, Deploy } from './types';
import * as yaml from 'yaml';
import { ConfigApi } from '@backstage/core-plugin-api';
import lodash from 'lodash';
import { Test } from '../EditorPage/types';
import { GovernanceEntityType } from '@agilelab/plugin-wb-governance-common';

export const mapToDeploys = (
  provisioningPlans: ProvisioningPlan[],
  entity: Entity,
): Deploy[] => {
  if (!provisioningPlans) return [];

  return provisioningPlans.map(pp => {
    return {
      id: pp.dag.id,
      idRelease: `${entity.metadata.name}.${
        pp.dag.version.split('-')[0].split('.')[1]
      }`,
      version: pp.dag.version,
      status: pp.dag.status,
      action: pp.dag.action,
      deployDate: new Date(pp.dag.startTime).toISOString(),
      deployEndDate: new Date(pp.dag.stopTime).toISOString(),
      steps: pp.dag.dependsOnTasks,
    };
  });
};

export const getComponentsFromDescriptor = (
  descriptor: string,
): Component[] => {
  return yaml.parse(descriptor)?.components || [];
};

export function extractDagName(dag: Dag): string {
  if (dag.action === TaskAction.PROVISION_DATAPRODUCT) {
    return 'Data Product Provision';
  }
  if (dag.action === TaskAction.UNPROVISION_DATAPRODUCT) {
    return 'Data Product Unprovision';
  }
  if (dag.action === TaskAction.PROVISION) {
    return `${dag.name.split(':').at(-1) || dag.name} Provision`;
  }
  if (dag.action === TaskAction.UNPROVISION) {
    return `${dag.name.split(':').at(-1) || dag.name} Unprovision`;
  }
  return dag.name;
}

function isValidJSON(json: string) {
  try {
    JSON.parse(json);
  } catch (e) {
    return false;
  }
  return true;
}

/** Processes a stringified result to extract the correct errors, if any.
 *
 * @param result the original result that needs to be parsed
 * @returns an array containing the parsed error strings, if present
 */
export function getErrors(result: string | null): string[] | null {
  if (!result || !isValidJSON(result)) return null;

  const json = JSON.parse(result);

  if (json.error) {
    if (typeof json.error === 'string') return [json.error];

    const errors = json.error.errors;

    if (errors && Array.isArray(errors)) return errors;
  }

  return null;
}

/** Processes a stringified result to extract the correct result field, if specified from config.
 *
 * @param result the original result that needs to be parsed
 * @param configApi configApi to retrieve the result field to extract
 * @returns the parsed result field extracted from the stringified result
 */
export function getParsedResultField(
  result: string | null,
  configApi: ConfigApi,
): string | undefined {
  const resultFieldPath = configApi.getOptionalString(
    'mesh.provisioner.deployStep.resultField',
  );

  if (!result || !isValidJSON(result) || !resultFieldPath) return undefined;

  const json = JSON.parse(result);

  const resultField = lodash.get(json, resultFieldPath);

  return typeof resultField === 'string'
    ? resultField
    : JSON.stringify(resultField);
}

export const getErrorMessage = (
  test: Test,
): { status: string; message: string } | undefined => {
  const gravity = new Map([
    [DagStatus.FAILED, 10],
    [DagStatus.ERROR, 10],
    [DagStatus.PASSED, 0],
    [DagStatus.NOT_EXECUTED, 0],
    [DagStatus.NOT_STARTED, 0],
    [DagStatus.TERMINATED, 0],
    [DagStatus.TERMINATING, 0],
    [DagStatus.WAITING, 0],
    [DagStatus.RUNNING, 0],
    [DagStatus.INFO, 6],
    [DagStatus.NOT_BLOCKING_ERROR, 5],
    [DagStatus.OK, 0],
    [DagStatus.WARNING, 7],
  ]);

  const sortedTasks = test.tasks
    .filter(t => t.governanceEntityType !== GovernanceEntityType.Metric)
    .sort((a, b) => {
      return (gravity.get(b.status) ?? 0) - (gravity.get(a.status) ?? 0);
    });

  const mostRelevantTask = sortedTasks.length > 0 ? sortedTasks[0] : undefined;
  if (!mostRelevantTask) return undefined;

  switch (mostRelevantTask.status) {
    case DagStatus.FAILED:
    case DagStatus.ERROR:
      return {
        status: 'Failed.',
        message:
          'You need to solve the errors reported since they will prevent the deployment.',
      };
    case DagStatus.WARNING:
      return {
        status: 'Attention.',
        message:
          'This is just a warning, and will not block the deployment, but remember to check what errors made it fail.',
      };
    case DagStatus.INFO:
      return {
        status: 'Attention.',
        message:
          'This is just a warning, and will not block the deployment, but remember to check what errors made it fail.',
      };
    case DagStatus.NOT_BLOCKING_ERROR:
      return {
        status: 'Attention.',
        message:
          'Some policies failed but they are either deprecated or in grace period, so it is not blocking the deployment. Check the errors.',
      };
    default:
      return undefined;
  }
};

export const buildChildrenMap = (components: Component[]) => {
  const map: Record<string, string[]> = {};

  const traverse = (
    item: Component,
    children: string[] = [],
    rootId: string,
  ) => {
    // Handle circular deps
    if (children.includes(item.id)) return children;

    if (item.id !== rootId) children.push(item.id);

    const dependants = components.filter(element =>
      element.dependsOn.includes(item.id),
    );

    if (dependants.length > 0) {
      dependants.forEach(d => {
        traverse(d, children, rootId);
      });
    }

    return children;
  };

  for (const component of components) {
    map[component.id] = traverse(component, [], component.id);
  }

  return map;
};

export const buildParentMap = (components: Component[]) => {
  const map: Record<string, string[]> = {};

  const traverse = (
    item: Component,
    children: string[] = [],
    rootId: string,
  ) => {
    // Handle circular deps
    if (children.includes(item.id)) return children;

    if (item.id !== rootId) children.push(item.id);
    if (item.dependsOn.length > 0) {
      item.dependsOn.forEach(dep => {
        const current = components.find(el => el.id === dep);
        if (current) traverse(current, children, rootId);
      });
    }

    return children;
  };

  for (const component of components) {
    map[component.id] = traverse(component, [], component.id);
  }

  return map;
};
