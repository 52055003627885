import React from 'react';
import { WbCard, WbCardProps } from '../WbCard';
import { useTheme } from '@material-ui/core';

export const WbWidget: React.FC<WbCardProps> = ({
  children,
  cardStyle,
  headerStyle,
  footerStyle,
  ...props
}) => {
  const theme = useTheme();

  return (
    <WbCard
      headerStyle={{
        background: theme.palette.grey[100],
        height: '45px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        ...headerStyle,
      }}
      cardStyle={{
        boxShadow: 'none',
        border: `1px solid ${theme.palette.divider}`,
        ...cardStyle,
      }}
      footerStyle={{
        background: theme.palette.grey[100],
        ...footerStyle,
      }}
      typographyProps={{
        variant: 'body2',
      }}
      {...props}
    >
      {children}
    </WbCard>
  );
};
