import { BELONGS_TO } from '@agilelab/plugin-wb-practice-shaper-common';
import { EntityFilter } from '@backstage/plugin-catalog-react';

export type ResourceTypeFilterValue = 'all' | string[];

/**
 * Filter resource entities based on resource type id.
 * @public
 */
export class ResourceTypeFilter implements EntityFilter {
  readonly selectedValues: ResourceTypeFilterValue;
  readonly valuesSet: string[];

  /**
   * @param values Selected values
   * @param allValues Finite set of of allowed values. When provided, the 'all' value will filter for all the provided values here
   */
  constructor(
    private readonly values: ResourceTypeFilterValue,
    allValues?: string[],
  ) {
    this.valuesSet = allValues ?? [];
    if (this.values === 'all') this.selectedValues = 'all';
    else this.selectedValues = this.values;
  }

  getCatalogFilters(): Record<string, string | symbol | (string | symbol)[]> {
    const filterValues =
      this.selectedValues === 'all' ? this.valuesSet : this.selectedValues;
    return {
      'spec.type': filterValues,
    };
  }

  toQueryValue(): ResourceTypeFilterValue {
    if (this.values === 'all') return 'all';
    return this.selectedValues;
  }
}

/**
 * Filters entities with a belongsTo relation towards one of the provided taxonomies
 */
export class TaxonomyFilter implements EntityFilter {
  constructor(private readonly taxonomyRefs: string[]) {}

  getCatalogFilters(): Record<string, string | symbol | (string | symbol)[]> {
    return {
      [`relations.${BELONGS_TO}`]: this.taxonomyRefs,
    };
  }
}

export class KindFilter implements EntityFilter {
  constructor(private readonly kinds: string[]) {}

  getCatalogFilters(): Record<string, string | symbol | (string | symbol)[]> {
    return {
      kind: this.kinds,
    };
  }
}
