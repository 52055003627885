import {
  IconButton,
  Popover,
  Box,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  searchPopoverIcon: {
    color: theme.palette.primary.main,
  },
  searchPopover: {
    padding: 20,
    width: 400,
  },
}));

export const SearchTitle: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const open = Boolean(anchorEl);
  return (
    <>
      Search
      <IconButton
        className={classes.searchPopoverIcon}
        disableRipple
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <InfoIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Box className={classes.searchPopover}>
          <Typography>
            Writing some terms in the search bar lets you search for all those
            terms inside the Data Products and Components. For a more specific
            search, you can specify the attributes where you want to look for
            the terms, like:
          </Typography>
          <br />
          <Typography>
            <b>Metatag</b>
          </Typography>
          <Typography>
            <code>key = value</code>
          </Typography>
          <br />
          <Typography>
            Example: if you want to search for the term "finance" but just in
            the name of the entities, simply write:
          </Typography>
          <Typography>
            <code>name = finance</code>
          </Typography>
        </Box>
      </Popover>
    </>
  );
};
