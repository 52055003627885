import { Entity } from '@backstage/catalog-model';

export function isSystemType(entity: Entity): boolean {
  return entity.kind.toLocaleLowerCase('en-US') === 'systemtype';
}

export function isSystemInstance(entity: Entity): boolean {
  return entity.kind.toLocaleLowerCase('en-US') === 'system';
}

export function isComponentType(entity: Entity): boolean {
  return entity.kind.toLocaleLowerCase('en-US') === 'componenttype';
}

export function isComponentInstance(entity: Entity): boolean {
  return entity.kind.toLocaleLowerCase('en-US') === 'component';
}
