import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import MdKeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';

interface WbAccordionProps {
  headerInfo: JSX.Element;
  title: string;
  footer?: JSX.Element;
  cardClassName?: string;
  expanded: boolean;
  handleChange: () => void;
}

const useStyles = makeStyles(theme => ({
  accordionHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
    height: '20px',
  },
  accordionDetails: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0px 0px 0px',
  },
  card: {
    background: theme.palette.background.default,
  },
  footer: {
    textAlign: 'end',
    padding: '8px',
    background: theme.palette.background.default,
  },
}));

export const WbAccordion: React.FC<WbAccordionProps> = ({
  title,
  children,
  footer,
  headerInfo,
  cardClassName,
  expanded,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <Accordion expanded={expanded} onChange={() => handleChange()}>
      <AccordionSummary
        style={{ minHeight: '20px' }}
        expandIcon={<MdKeyboardArrowDownIcon color="primary" />}
        color="primary"
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box className={classes.accordionHeader}>
          <Typography
            color="primary"
            variant="body1"
            style={{
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
          {headerInfo}
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Box className={clsx(classes.card, cardClassName)}>
          {children}
          <Box className={classes.footer}>{footer}</Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
