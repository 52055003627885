import React from 'react';
import { useSelectorsContext } from '../../context/SelectorsContext';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { WbCardSelect } from '../WbCardSelect';

interface Props {
  disabled?: boolean;
  defaultValue?: string;
  onChange?: (e: string) => void;
}

export const WbEnvironmentSelector: React.FC<Props> = ({
  disabled,
  onChange,
}) => {
  const { environmentList, environment, setEnvironment } =
    useSelectorsContext();

  if (!environmentList.length) {
    return <></>;
  }

  return (
    <WbCardSelect
      title="Environment:"
      value={environment.name}
      disabled={disabled}
      onChange={e => {
        setEnvironment(environmentList.find(env => env.name === e)!);

        if (onChange) onChange(e);
      }}
      data={environmentList.map(e => ({ key: e.name, value: e.name }))}
      formatValue={value => snakeCaseToTitleCase(value)}
    />
  );
};
