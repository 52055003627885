import {
  Pagination,
  RoleViewFilters,
  PermissionDetail,
} from '@agilelab/plugin-wb-rbac-common';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import React, { useCallback, useMemo, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';

export interface RoleDrawerContextType {
  filters: RoleViewFilters;
  changeFilters: <K extends keyof RoleViewFilters>(
    key: K,
    value: RoleViewFilters[K],
  ) => void;
  pagination: Pagination;
  setPagination: (p: Pagination) => void;
  permissions: PermissionDetail[];
  permissionCount: number;
  reloadTable: () => void;
}

export const RoleDrawerContext = React.createContext<RoleDrawerContextType>(
  {} as RoleDrawerContextType,
);

export const RoleDrawerContextProvider: React.FC<{
  children: any;
  subject: string[];
  target: string;
  permission: string;
}> = ({ children, permission, target }) => {
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);

  const [permissions, setPermissions] = React.useState<PermissionDetail[]>([]);

  const [filters, setFilters] = useState<RoleViewFilters>({
    text: '',
    limit: 25,
  });
  const [pagination, setPagination] = useState<Pagination>({
    limit: filters.limit ?? 25,
    offset: 0,
  });

  useAsync(async () => {
    const identity = await identityApi.getCredentials();
    const token = identity.token!;

    const response = await fetch(
      `${await discoveryApi.getBaseUrl(
        'rbac',
      )}/permissiondetails?permission=${permission}&target=${target}`,
      {
        headers: { authorization: `Bearer ${token}` },
      },
    );

    const data = await response.json();

    setPermissions(data.permissionDetails);
  });

  const filteredPermissions = useMemo(() => {
    return permissions.filter(tempPermission => {
      return (
        (tempPermission.subjectdisplayname ?? '')
          .toLowerCase()
          .includes((filters.text ?? '').toLowerCase()) ||
        (tempPermission.display_name ?? '')
          .toLowerCase()
          .includes((filters.text ?? '').toLowerCase()) ||
        (tempPermission.description ?? '')
          .toLowerCase()
          .includes((filters.text ?? '').toLowerCase())
      );
    });
  }, [filters.text, permissions]);

  const paginatedPermisions = useMemo(() => {
    return filteredPermissions.slice(
      pagination.offset,
      pagination.offset + pagination.limit,
    );
  }, [filteredPermissions, pagination]);

  const reloadTable = useCallback(() => {
    setPagination({ ...pagination, offset: 0 });
  }, [setPagination, pagination]);

  const contextValue = useMemo(
    () => ({
      filters,
      changeFilters: <K extends keyof RoleViewFilters>(
        key: K,
        filterValue: RoleViewFilters[K],
      ) => {
        setPagination(p => ({ ...p, offset: 0 }));
        setFilters(f => ({ ...f, [key]: filterValue }));
      },
      pagination,
      setPagination,
      permissions: paginatedPermisions,
      permissionCount: filteredPermissions.length,
      reloadTable,
    }),
    [
      filters,
      pagination,
      paginatedPermisions,
      filteredPermissions.length,
      reloadTable,
    ],
  );

  return (
    <RoleDrawerContext.Provider value={contextValue}>
      {children}
    </RoleDrawerContext.Provider>
  );
};

export const useRoleDrawerContext = () => React.useContext(RoleDrawerContext);
