import React, { ReactNode } from 'react';
import { useCustomPresentation } from '../../hooks';
import { DataRootPathContextProvider } from '../../context';
import { buildJsx } from '../../utils';
import { CustomViewRegister } from './registry';

type Props = {
  id: string;
  spec: string;
  data: any;
  children?: ReactNode;
};

export const CustomViewComponent = (props: Record<string, any>) => {
  const subComponent = CustomViewRegister.get(props.type);
  if (subComponent) return subComponent(props);
  return <></>;
};

export const CustomView = ({ id, spec, data, children }: Props) => {
  const view = useCustomPresentation(id, spec);
  if (view.loading) {
    // TOVERIFY: show loader?
  }
  if (view.value) {
    return (
      <DataRootPathContextProvider data={data}>
        {buildJsx(CustomViewComponent, view.value)}
      </DataRootPathContextProvider>
    );
  }

  return (
    <DataRootPathContextProvider data={data}>
      {children}
    </DataRootPathContextProvider>
  );
};
