import {
  WbCard,
  WbCardContent,
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import { EditorCardActions } from './EditorCardActions';
import { DescriptorCard } from './DescriptorCard';
import { StructureCard } from './StructureCard/StructureCard';

export const EditorCard: React.FC = () => {
  return (
    <WbCard
      title="Editor"
      cardStyle={{ height: '100%', overflow: 'hidden' }}
      actions={<EditorCardActions />}
    >
      <WbDockableGroup direction="horizontal">
        <WbDockablePanel
          style={{ display: 'flex' }}
          defaultSize={35}
          maxSize={50}
        >
          <WbCardContent
            style={{
              height: '100%',
              overflow: 'hidden',
              padding: '12px 8px 12px 16px',
              width: '100%',
              display: 'flex',
            }}
          >
            <StructureCard />
          </WbCardContent>
        </WbDockablePanel>

        <WbDockableResizeHandle
          showIconOnHover
          divider
          direction="horizontal"
        />

        <WbDockablePanel defaultSize={65}>
          <WbCardContent
            style={{
              height: '100%',
              overflow: 'hidden',
              padding: '12px 16px 12px 8px',
              width: '100%',
            }}
          >
            <DescriptorCard />
          </WbCardContent>
        </WbDockablePanel>
      </WbDockableGroup>
    </WbCard>
  );
};
