import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import React, { useCallback, useState } from 'react';
import { panelCatalogApiRef } from '../../../../api';
import { useReleaseDetailPageContext } from '../../context/useReleaseDetailPageContext';
import {
  ProvisioningPreviewResponse,
  generateURNByKind,
} from '@agilelab/plugin-wb-builder-common';
import yaml from 'yaml';
import { ProvisioningRequest } from '@agilelab/plugin-wb-builder-backend';

export type DeploymentPreviewContextProps = {
  isAdvanced: boolean;
  setIsAdvanced: React.Dispatch<React.SetStateAction<boolean>>;

  checkPreview: (
    targetStatus: ProvisioningRequest['targetStatus'],
  ) => Promise<ProvisioningPreviewResponse>;
  partialProvision: (
    targetStatus: ProvisioningRequest['targetStatus'],
  ) => Promise<ProvisioningPreviewResponse>;
};

export const DeploymentPreviewContext =
  React.createContext<DeploymentPreviewContextProps>(
    {} as DeploymentPreviewContextProps,
  );

export const DeploymentPreviewContextProvider: React.FC = ({ children }) => {
  const { environment, queryParamEnvironment, release, currentDescriptor } =
    useReleaseDetailPageContext();
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);

  const identityApi = useApi(identityApiRef);
  const panelCatalogApi = useApi(panelCatalogApiRef);

  const deploymentUnitId = generateURNByKind(
    release.metadata.dataProductName,
    'system',
  );
  const selectedEnvironment = queryParamEnvironment || environment.name;

  const checkPreview = useCallback(
    async (targetStatus: ProvisioningRequest['targetStatus']) =>
      panelCatalogApi.getDeploymentUnitsProvisioningPreview(
        deploymentUnitId,
        selectedEnvironment,
        {
          descriptor: yaml.parse(currentDescriptor),
          targetStatus,
          removeData: false,
          preventRedeploy: false,
        },
        await identityApi.getCredentials(),
      ),
    [
      currentDescriptor,
      deploymentUnitId,
      identityApi,
      panelCatalogApi,
      selectedEnvironment,
    ],
  );

  const partialProvision = useCallback(
    async (targetStatus: ProvisioningRequest['targetStatus']) =>
      panelCatalogApi.partialProvision(
        deploymentUnitId,
        selectedEnvironment,
        {
          descriptor: yaml.parse(currentDescriptor),
          targetStatus,
          removeData: false,
          preventRedeploy: false,
        },
        false,
        await identityApi.getCredentials(),
      ),
    [
      currentDescriptor,
      deploymentUnitId,
      identityApi,
      panelCatalogApi,
      selectedEnvironment,
    ],
  );

  return (
    <DeploymentPreviewContext.Provider
      value={{
        isAdvanced,
        setIsAdvanced,

        checkPreview,
        partialProvision,
      }}
    >
      {children}
    </DeploymentPreviewContext.Provider>
  );
};
