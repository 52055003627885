import { Box, Typography, makeStyles } from '@material-ui/core';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import React from 'react';
import { NewDraftReleaseButton } from './NewDraftReleaseButton';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    marginTop: '-36px',
  },
  iconContainer: {
    border: `1px solid ${theme.palette.softened.primary}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px',
    borderRadius: '100%',
    borderStyle: 'dashed',
  },
  icon: {
    fontSize: '105px',
    color: theme.palette.softened.primary,
  },
}));

export const ReleaseTableNoData: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography style={{ fontWeight: 700 }} variant="h6">
        No release seems to have been created.
      </Typography>
      <Box className={classes.iconContainer}>
        <LayersClearIcon className={classes.icon} />
      </Box>
      <Typography variant="body2">
        Let’s start and create a new release
      </Typography>
      <NewDraftReleaseButton size="large" />
    </Box>
  );
};
