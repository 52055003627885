import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import LockOpenSharpIcon from '@material-ui/icons/LockOpenSharp';
import HttpsSharpIcon from '@material-ui/icons/HttpsSharp';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { ConfirmDialog } from '@agilelab/plugin-wb-platform';

const useStyles = makeStyles(() => ({
  formRow: {
    display: 'flex',
  },
}));

export type ConfigInput = {
  key: string;
  value: string;
  isSensitive: boolean;
};

export type ConfigFormProps = {
  active: boolean;
  editableKey: boolean;
  forceSensitiveValue?: boolean;
  configName?: string;
  keyHelperText?: string;
  keyPlaceholder?: string;
  valueHelperText?: string;
  sensitiveValueHelperText?: string;
  valuePlaceholder?: string;
  sensitiveValuePlaceholder?: string;
  initialValue: ConfigInput;
  keyPrefix?: string;
  actionsEnabled: boolean;
  isSaving: boolean;
  validator?: z.ZodObject<{
    key: z.ZodString;
    value: z.ZodString;
    isSensitive: z.ZodBoolean;
  }>;
  deleteDialogTitle?: string;
  deleteDialogConfirmBtnText?: string;
  deleteDialogContent?: React.ReactNode | React.ReactNode[];
  onStartEditMode?: () => any;
  onCancelEditMode?: (value: ConfigInput) => any;
  onDeleteAction?: () => any;
  onSaveAction?: (updatedValue: ConfigInput) => any;
  onValidate?: (
    value: ConfigInput,
  ) => { fieldName: 'key' | 'value'; error: string }[];
};

const defaultValidator = z.object({
  key: z.string(),
  value: z.string(),
  isSensitive: z.boolean(),
});

export const ConfigForm = (props: ConfigFormProps) => {
  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm<ConfigInput>({
    mode: 'onSubmit',
    resolver: zodResolver(props.validator ?? defaultValidator),
    defaultValues: {
      ...props.initialValue,
      isSensitive: props.forceSensitiveValue
        ? true
        : props.initialValue.isSensitive,
    },
  });
  const classes = useStyles();
  const watchedValues = watch();
  const [showSensitiveValue, setShowSensitiveValue] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const onSubmit = (data: ConfigInput) => {
    const validationErrors = props.onValidate?.(data);
    if (validationErrors !== undefined && validationErrors.length > 0) {
      validationErrors.forEach(err => {
        setError(err.fieldName, { type: 'string', message: err.error });
      });
    } else {
      props.onSaveAction?.(data);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Box className={classes.formRow}>
                  {props.editableKey ? (
                    <TextField
                      aria-label="key"
                      aria-invalid={errors.key ? true : false}
                      placeholder={props.keyPlaceholder}
                      style={{ flex: 1 }}
                      disabled={!props.active}
                      error={errors.key ? true : false}
                      helperText={errors.key?.message ?? props.keyHelperText}
                      InputProps={
                        props.keyPrefix
                          ? {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography
                                    style={{
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {props.keyPrefix}
                                  </Typography>
                                </InputAdornment>
                              ),
                            }
                          : undefined
                      }
                      {...register('key')}
                    />
                  ) : (
                    <Box>
                      <Typography variant="body1">
                        {props.configName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {props.keyHelperText}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.formRow}>
                  <TextField
                    aria-label="value"
                    aria-invalid={errors.value ? true : false}
                    disabled={!props.active}
                    placeholder={
                      watchedValues.isSensitive &&
                      props.sensitiveValuePlaceholder
                        ? props.sensitiveValuePlaceholder
                        : props.valuePlaceholder
                    }
                    style={{ flex: 1 }}
                    error={errors.value ? true : false}
                    type={
                      watchedValues.isSensitive && !showSensitiveValue
                        ? 'password'
                        : 'text'
                    }
                    helperText={
                      errors.value?.message ??
                      (watchedValues.isSensitive &&
                      props.sensitiveValueHelperText
                        ? props.sensitiveValueHelperText
                        : props.valueHelperText)
                    }
                    InputProps={{
                      endAdornment: props.forceSensitiveValue ? (
                        <IconButton
                          title="Show value"
                          aria-label="sensitive value visibility"
                          style={{
                            display: !props.active ? 'none' : 'initial',
                          }}
                          size="small"
                          onMouseDown={() => setShowSensitiveValue(true)}
                          onMouseUp={() => setShowSensitiveValue(false)}
                          onMouseLeave={() => setShowSensitiveValue(false)}
                        >
                          {showSensitiveValue ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      ) : (
                        <Checkbox
                          title="Toggle sensitivity"
                          aria-label="sensitivity"
                          style={{ padding: 0 }}
                          disabled={!props.active}
                          size="small"
                          icon={<LockOpenSharpIcon />}
                          checkedIcon={<HttpsSharpIcon />}
                          checked={watchedValues.isSensitive}
                          onChange={event => {
                            setValue('isSensitive', event.target.checked);
                          }}
                        />
                      ),
                    }}
                    {...register('value')}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid item style={props.active ? { display: 'none' } : {}}>
              <IconButton
                title="Edit"
                disabled={!props.actionsEnabled}
                type="button"
                aria-label="edit"
                size="small"
                onClick={() => {
                  props.onStartEditMode?.();
                  if (watchedValues.isSensitive) setValue('value', '');
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                title="Delete"
                disabled={!props.actionsEnabled}
                aria-label="delete"
                size="small"
                onClick={() => setShowDeleteDialog(true)}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item style={!props.active ? { display: 'none' } : {}}>
              <IconButton
                title="Save"
                disabled={props.isSaving}
                aria-label="save"
                size="small"
                color="primary"
                type="submit"
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                title="Cancel"
                disabled={props.isSaving}
                aria-label="cancel"
                size="small"
                onClick={() => {
                  reset();
                  props.onCancelEditMode?.(props.initialValue);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ConfirmDialog
        title={props.deleteDialogTitle ?? 'Delete configuration'}
        description={
          props.deleteDialogContent ??
          'Are you sure you want to delete this configuration?'
        }
        open={showDeleteDialog}
        onConfirm={() => {
          props.onDeleteAction?.();
          setShowDeleteDialog(false);
        }}
        onClose={() => setShowDeleteDialog(false)}
        inProgress={false}
        confirmButtonText={props.deleteDialogConfirmBtnText ?? 'Delete'}
      />
    </>
  );
};
