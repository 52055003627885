import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import React from 'react';
import { EnumFilter } from '../WbTableFilters';
import {
  useResourceTypeFilter,
  // @ts-ignore
  PracticeShaperFilters,
} from '@agilelab/plugin-wb-practice-shaper';
// @ts-ignore
import { EntityListProvider } from '@backstage/plugin-catalog-react';

export interface ResourceTypePickerProps {
  availableResourceTypes: string[];
  allowedResourceTypes?: string[];
  initialFilter?: string[];
  hidden?: boolean;
}

/**
 * For a correct usage, this picker should rendered within the context of an entity list provided by {@link EntityListProvider} and filtered with {@link PracticeShaperFilters}.
 * @public
 */
export const ResourceTypePicker = (props: ResourceTypePickerProps) => {
  const {
    initialFilter,
    availableResourceTypes,
    allowedResourceTypes,
    hidden,
  } = props;
  const { setSelectedTypes, selectedTypes } = useResourceTypeFilter({
    allowedResourceTypes,
    initialValues: initialFilter ?? 'all',
  });

  const items = [
    ...availableResourceTypes.map((type: string) => ({
      value: type,
      label: type,
    })),
  ];

  const getLabel = (option: string) => {
    const item = items.find(e => e.value === option);
    return item ? snakeCaseToTitleCase(item.label) : '';
  };

  const computeEnumFilterValue = () =>
    selectedTypes === 'all'
      ? []
      : selectedTypes.filter(v => availableResourceTypes.includes(v));

  return hidden ? null : (
    <EnumFilter<string>
      field="Resource type"
      value={computeEnumFilterValue()}
      options={items.map(item => item.value)}
      onChange={v => {
        if (v === undefined || v.length === 0) setSelectedTypes('all');
        else setSelectedTypes(v);
      }}
      renderOption={getLabel}
      renderValue={getLabel}
      onSearch={v =>
        items.filter(o => new RegExp(v, 'ig').test(o.label)).map(o => o.value)
      }
    />
  );
};
