import { z } from 'zod';

import {
  Context,
  Engine,
  InteractionType,
  ResourceType,
  ResultType,
  Severity,
  Strategy,
  Timing,
  Trigger,
} from '../../enums';

export const PolicyEditRequest = z.object({
  policyId: z.string(),
  groupId: z.string(),
  name: z.string(),
  description: z.string().optional(),
  context: z.nativeEnum(Context),
  strategy: z.nativeEnum(Strategy),
  engine: z.nativeEnum(Engine),
  timing: z.nativeEnum(Timing),
  interactionType: z.nativeEnum(InteractionType),
  resourceType: z.nativeEnum(ResourceType),
  resultType: z.nativeEnum(ResultType),
  version: z.number(),
  externalUrl: z.string().nullable(),
  selectors: z.array(
    z.object({
      description: z.string(),
      path: z.string(),
      values: z.array(z.string()),
    }),
  ),
  tags: z.array(z.string()),
  environments: z.array(z.string()),
  cronExpression: z.string().optional(),
  preprocessing: z.array(z.string()).optional(),
  externalSpecific: z
    .object({
      tresholds: z.array(
        z.object({
          range: z.object({
            min: z.number(),
            max: z.number(),
          }),
          severity: z.nativeEnum(Severity),
          message: z.string().optional(),
        }),
      ),
    })
    .optional(),
  additionalMetadata: z.string(),
  trigger: z.nativeEnum(Trigger),
  cueScript: z.string().nullable(),
  severity: z.nativeEnum(Severity),
});

export type PolicyEdit = z.infer<typeof PolicyEditRequest>;
