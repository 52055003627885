import {
  makeStyles,
  createStyles,
  Typography,
  Box,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import { TestStatus } from '@agilelab/plugin-wb-platform';
import { WbTag } from '@agilelab/plugin-wb-platform';

interface Props {
  description: string;
  componentName?: string;
  status: DagStatus;
}

const useStyles = makeStyles(() =>
  createStyles({
    componentId: {
      flex: 1,
    },
    chip: {
      margin: 0,
    },
  }),
);

export const ComponentItem: React.FC<Props> = ({
  description,
  status,
  componentName,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        width: '100%',
        gap: theme.spacing(1),
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <TestStatus status={status} filled />
        <WbTag
          tag={{
            tagFQN: 'Technology Adapter',
          }}
        />
      </Box>

      <div className={classes.componentId}>
        <Typography variant="body2">
          <strong>{description}</strong>
        </Typography>
        {!!componentName && (
          <Typography variant="body2">{componentName}</Typography>
        )}
      </div>
    </Box>
  );
};
