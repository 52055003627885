import {
  GovernanceEntityType,
  ResourceType,
  Status as PolicyStatus,
  TestStatus,
} from '@agilelab/plugin-wb-governance-common';

export type ProxyResponse<T> = {
  httpStatus: number;
  body: T;
};

export type ValidationPhaseSummary = {
  results: ValidationPhaseResult[];
};

export enum DagStatus {
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  WAITING = 'WAITING',
  TERMINATED = 'TERMINATED', // execution aborted (e.g. timeout, manual termination)
  TERMINATING = 'TERMINATING',
  NOT_STARTED = 'NOT_STARTED',
  NOT_BLOCKING_ERROR = 'NOT_BLOCKING_ERROR',
  OK = 'OK',
  INFO = 'INFO',
  WARNING = 'WARNING',
  PASSED = 'COMPLETED',
  NOT_EXECUTED = 'NOT_EXECUTED',
  ERROR = 'ERROR',
}

export const isRunningStatus = (status: DagStatus) =>
  [DagStatus.RUNNING, DagStatus.WAITING].includes(status);

export enum DeploymentUnitStatus {
  DEPLOYED = 'DEPLOYED',
  PARTIALLY_DEPLOYED = 'PARTIALLY_DEPLOYED',
  NOT_DEPLOYED = 'NOT_DEPLOYED',
  PROVISIONING_IN_PROGRESS = 'PROVISIONING_IN_PROGRESS',
  CORRUPT = 'CORRUPT',
}

export enum TargetStatus {
  DEPLOYED = 'DEPLOYED',
  NOT_DEPLOYED = 'NOT_DEPLOYED',
}

export enum Action {
  DEPLOY = 'DEPLOY',
  UNDEPLOY = 'UNDEPLOY',
  UNDEPLOY_AND_REMOVE_DATA = 'UNDEPLOY_AND_REMOVE_DATA',
  NO_ACTION = 'NO_ACTION',
}

export enum Reason {
  NOT_IN_TARGET_DESCRIPTOR = 'NOT_IN_TARGET_DESCRIPTOR',
  CLEANUP = 'CLEANUP',
  REQUEST = 'REQUEST',
  DEPENDENCY_OF = 'DEPENDENCY_OF',
  DEPENDANT_ON = 'DEPENDANT_ON',
}

export enum Outcome {
  OK = 'OK',
  ABORTED_FOR_CONFLICTS = 'ABORTED_FOR_CONFLICTS',
}

export type ProvisioningDetails = {
  descriptorVersion: string;
  descriptor?: Record<string, any>;
  provisioningInfo: {
    privateInfo?: {
      marketplaceInfo?: {
        label?: string;
        type?: string;
        href?: string;
        value?: string;
      };
    };
  };
  componentsStatus: {
    componentId: string;
    status: Omit<DeploymentUnitStatus, DeploymentUnitStatus.PARTIALLY_DEPLOYED>;
    descriptorVersion: string;
  }[];
};

export type ProvisioningPreviewResponseOperation = {
  componentId: string;
  targetStatus: TargetStatus;
  action: Action;
  reason: Reason;
  additionalReasons: Reason[];
  dependencyOf?: string[];
  dependantOn?: string[];
};

export type ProvisioningPreviewResponse = {
  outcome: Outcome;
  operations: ProvisioningPreviewResponseOperation[];
  conflictingOperations?: ProvisioningPreviewResponseOperation[];
};

export interface ProvisioningPreviewComponentResponse {
  status: DeploymentUnitStatus;
  provisioningDetails: {
    descriptorVersion: string;
    descriptor: Record<string, any>;
  };
}

export type ValidationPhaseResult = {
  validationPhaseKind: ValidationPhaseKind;
  validatedDescriptor: string | null;
  policyId: string;
  errors: ValidationError;
  status: DagStatus;
};

export type ValidationResponse = ProxyResponse<
  ValidationPhaseSummary | ValidationInvalidInput | ValidationSystemError
>;

export type ValidationResponseStatus = {
  status: 'RUNNING' | 'COMPLETED' | 'FAILED';
  validationSummaryStatus: {
    results: Array<ValidationPhaseResult>;
  };
};

export enum TerminationMode {
  SAFE = 'safe',
  FORCED = 'forced',
}

export type ValidationError = {
  errors: string[];
};

export type Pagination = {
  offset: number;
  limit: number | null;
  count: number;
};

export type Dag = {
  id: string;
  name: string;
  displayName: string;
  descriptor: string;
  version: string;
  status: DagStatus;
  action: TaskAction;
  result: string | null;
  startTime: string;
  stopTime: string;
  dependsOnTasks: Dag[];
  componentName?: string;
  subPlanId?: string;
};

export type ProvisioningPlan = {
  environment: string;
  dag: Dag;
};

export type PaginatedProvisioningPlansResponse = {
  provisioningPlans: ProvisioningPlan[];
  page: Pagination;
};

export type DeployStatusWithResult = {
  status: DagStatus;
  result: string | null;
};

export type GetProvisioningStatusResponse = {
  status: DeploymentUnitStatus;
  provisioningDetails?: ProvisioningDetails;
};

export enum ValidationPhaseKind {
  INTERNAL_VALIDATION_PHASE = 'INTERNAL_VALIDATION_PHASE', // TODO da chiedere ERRORE NON GESTITO => result === null
  POLICY_DATAPRODUCT_VALIDATION_PHASE = 'POLICY_DATAPRODUCT_VALIDATION_PHASE', // CUE -> result policy non ci sarà più
  POLICY_COMPONENT_VALIDATION_PHASE = 'POLICY_COMPONENT_VALIDATION_PHASE', // CUE
  COMPONENT_VALIDATION_PHASE = 'COMPONENT_VALIDATION_PHASE', // result -> technology adapter
  DATAPRODUCT_VALIDATION_PHASE = 'DATAPRODUCT_VALIDATION_PHASE',
  DATAPRODUCT_CATALOG_VALIDATION_PHASE = 'DATAPRODUCT_CATALOG_VALIDATION_PHASE',
}

// Bug specific down result = string

export type ValidationInvalidInput = {
  errors: string[];
};

export type ValidationSystemError = {
  error: string;
};

export type PolicyValidateResult = {
  status: TestStatus;
  id: string;
  evaluationScope: 'evaluation';
  environment: string;
  evaluationResults: {
    governanceEntityId: string;
    governanceEntityStatus: PolicyStatus;
    governanceEntityType: GovernanceEntityType;
    outcome: string;
    resource?: {
      id: string;
      displayName: string;
      environment: string;
      resourceType: ResourceType;
      descriptor: string;
      filter: string | null;
    };
    result?: {
      details: Record<string, any>;
      satisfiesPolicy?: boolean;
      value?: number;
      thresholdResult?: any;
      isError: boolean;
      errors: string[];
    };
  }[];
};

export enum TaskAction {
  PROVISION = 'PROVISION',
  UNPROVISION = 'UNPROVISION',
  UPDATEACL = 'UPDATEACL',
  MARKETPLACE_UPDATE = 'MARKETPLACE_UPDATE',
  PROVISION_DATAPRODUCT = 'PROVISION_DATAPRODUCT',
  UNPROVISION_DATAPRODUCT = 'UNPROVISION_DATAPRODUCT',
  VALIDATION = 'VALIDATION',
  NOP = 'NOP',
  POLICY_VALIDATE_COMPONENT = 'POLICY_VALIDATE_COMPONENT',
  VALIDATE_COMPONENT = 'VALIDATE_COMPONENT',
  VALIDATE_DATAPRODUCT = 'VALIDATE_DATAPRODUCT',
  VALIDATION_GATEWAY = 'VALIDATION_GATEWAY',
}

export interface Log {
  timestamp: string;
  severity: string;
  task: string;
  message: string;
  phase?: string;
}

/**
 * @example
 * {
 *   'master': 'd52a8bd0d7626c35094e962c70b0fc81c2f06765',
 *   'hotfix/urgent-fix': 'ac4056cee75051beb9789ba95ed5dd65d90169f4',
 *   'new-feature': 'ed0126f8fa091aec11d646ee7a737b7dfd1317f8',
 * };
 */
export type BranchesAndCommitUids = Record<string, string>;
