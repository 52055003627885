import React from 'react';
import { createSvgIcon } from '@material-ui/core/utils';
/* eslint-disable */
export const ExagonIcon = createSvgIcon(
  <>
    <path d="M21 16.5c0 .38-.21.71-.53.88l-7.9 4.44c-.16.12-.36.18-.57.18-.21 0-.41-.06-.57-.18l-7.9-4.44c-.32-.17-.53-.5-.53-.88v-9c0-.38.21-.71.53-.88l7.9-4.44c.16-.12.36-.18.57-.18.21 0 .41.06.57.18l7.9 4.44c.32.17.53.5.53.88v9Z" />
  </>,
  'ExagonIcon',
);

export const BuilderIcon = createSvgIcon(
  <>
    <path d="M21.16.05H2.84C1.64.05.66 1.02.66 2.22v19.57c0 1.2.98 2.17 2.18 2.17h18.32c1.2 0 2.18-.97 2.18-2.17V2.22c0-1.2-.98-2.17-2.18-2.17Zm.76 21.74c0 .42-.34.76-.76.76H2.84a.76.76 0 0 1-.76-.76V7.18h19.84v14.61Zm0-16.03H2.08V2.22c0-.42.34-.76.76-.76h18.32c.42 0 .76.34.76.76v3.54Z" />
    <path d="m9.54 15.49-2.72-.91 2.72-.94V11.6l-5.21 2.13v1.68l5.21 2.12v-2.04zM13.96 11.08h-1.34l-2.76 6.9h1.33l2.77-6.9zM19.67 13.73l-5.19-2.12v2.03l2.71.92-2.6.91-.11.03v2.04l5.19-2.13v-1.68z" />
  </>,
  'BuilderIcon',
);

export const MarketplaceIcon = createSvgIcon(
  <>
    <path d="M9.15.05h-6.4C1.62.05.71.97.71 2.1v6.95c0 1.13.92 2.05 2.04 2.05h6.4c1.13 0 2.04-.92 2.04-2.05V2.1C11.2.97 10.28.05 9.15.05Zm-6.4 1.13h6.4c.51 0 .92.41.92.92v.47H1.83V2.1c0-.51.41-.92.92-.92Zm6.4 8.79h-6.4c-.51 0-.92-.41-.92-.92V3.69h8.24v5.35c0 .51-.41.92-.92.92ZM21.25.05h-6.4c-1.13 0-2.05.92-2.05 2.05v6.95c0 1.13.92 2.05 2.05 2.05h6.4c1.13 0 2.04-.92 2.04-2.05V2.1c0-1.13-.92-2.05-2.04-2.05Zm-6.4 1.12h6.4c.51 0 .92.41.92.92v.47h-8.25v-.47c0-.51.41-.92.92-.92Zm6.4 8.79h-6.4c-.51 0-.92-.41-.92-.92V3.69h8.25v5.35c0 .51-.41.92-.92.92ZM9.15 12.91h-6.4c-1.13 0-2.04.92-2.04 2.04v6.95c0 1.13.92 2.05 2.04 2.05h6.4c1.13 0 2.04-.92 2.04-2.05v-6.95c0-1.13-.92-2.04-2.04-2.04Zm-6.4 1.12h6.4c.51 0 .92.41.92.92v.47H1.83v-.47c0-.51.41-.92.92-.92Zm6.4 8.79h-6.4c-.51 0-.92-.41-.92-.92v-5.35h8.24v5.35c0 .51-.41.92-.92.92ZM21.25 12.91h-6.4c-1.13 0-2.05.92-2.05 2.04v6.95c0 1.13.92 2.05 2.05 2.05h6.4c1.13 0 2.04-.92 2.04-2.05v-6.95c0-1.13-.92-2.04-2.04-2.04Zm-6.4 1.12h6.4c.51 0 .92.41.92.92v.47h-8.25v-.47c0-.51.41-.92.92-.92Zm6.4 8.79h-6.4c-.51 0-.92-.41-.92-.92v-5.35h8.25v5.35c0 .51-.41.92-.92.92Z" />
  </>,
  'MarketplaceIcon',
);

export const CreateIcon = createSvgIcon(
  <>
    <path d="M17.38 2.67H6.62L1.23 12l5.39 9.32h10.76L22.77 12l-5.39-9.33Zm-4.16 14.42h-2.44v-3.93H7.04v-2.37h3.74V6.91h2.44v3.88h3.74v2.37h-3.74v3.93Z" />
  </>,
  'CreateIcon',
);

export const DpCatalogIcon = createSvgIcon(
  <>
    <path d="M19.54 23.72H4.46c-1.09 0-1.97-.88-1.97-1.97V2.25c0-1.09.88-1.97 1.97-1.97h15.08c1.09 0 1.97.88 1.97 1.97v19.5c0 1.08-.88 1.97-1.97 1.97ZM4.46 1.56c-.38 0-.69.31-.69.69v19.5c0 .38.31.69.69.69h15.08c.38 0 .69-.31.69-.69V2.25c0-.38-.31-.69-.69-.69H4.46Z" />
    <path d="M15.01 6.79H8.99l-3 5.21 3 5.21h6.02l3-5.21-3-5.21z" />
  </>,
  'DpCatalogIcon',
);

export const DpGraphIcon = createSvgIcon(
  <>
    <rect
      width="12.98"
      height="12.98"
      x="1.37"
      y="2.26"
      rx="6.49"
      ry="6.49"
      transform="rotate(-180 7.86 8.75)"
    />
    <rect
      width="8.74"
      height="8.74"
      x="13.89"
      y="11.01"
      rx="4.37"
      ry="4.37"
      transform="rotate(180 18.26 15.385)"
    />
    <rect
      width="5.62"
      height="5.62"
      x="7.86"
      y="16.12"
      rx="2.81"
      ry="2.81"
      transform="rotate(180 10.67 18.93)"
    />
    <rect
      width="4.12"
      height="4.12"
      x="15.91"
      y="5.18"
      rx="2.06"
      ry="2.06"
      transform="rotate(180 17.97 7.245)"
    />
  </>,
  'DpGraphIcon',
);

export const PoliciesIcon = createSvgIcon(
  <>
    <path d="M19.76 3.95c-2.44-.71-4.81-1.68-7-2.86C12.3.8 11.7.8 11.28 1.07c-2.23 1.2-4.6 2.17-7.05 2.89-.61.19-1.02.77-.99 1.41l.26 7.99c.53 3.57 4.51 6.71 6.84 8.26.49.33 1.07.5 1.66.5s1.17-.17 1.66-.5c2.33-1.55 6.31-4.7 6.85-8.35l.25-7.9c.03-.64-.38-1.22-1-1.42Zm-3.1 5.22-4.79 4.99a.71.71 0 0 1-.53.22h-.05a.737.737 0 0 1-.55-.29l-1.99-2.67a.72.72 0 0 1-.14-.44.737.737 0 0 1 1.33-.44l1.47 1.97 4.18-4.37c.29-.29.75-.3 1.05-.02.29.29.3.75.02 1.05Z" />
  </>,
  'PoliciesIcon',
);

export const MeshSupervisionIcon = createSvgIcon(
  <>
    <path d="M23.09 4.63a2.074 2.074 0 0 1-2.49 2.03l-3.2 6.4a2.066 2.066 0 0 1-1.49 3.5c-1.14 0-2.07-.92-2.07-2.06 0-.23.04-.45.11-.66l-3.53-3.1c-.33.22-.73.35-1.16.35-.18 0-.35-.02-.51-.06l-1.34 2.4h-.08v.14l-2.4 4.31c.39.38.63.9.63 1.48 0 1.14-.93 2.06-2.07 2.06s-2.06-.92-2.06-2.06A2.066 2.066 0 0 1 4 17.35l3.83-6.84c-.39-.38-.63-.91-.63-1.49 0-1.14.92-2.07 2.06-2.07s2.07.93 2.07 2.07c0 .33-.08.64-.22.92l3.43 3.01c.36-.32.84-.52 1.37-.52.2 0 .4.03.58.08l3.17-6.33c-.43-.38-.7-.93-.7-1.55 0-1.14.92-2.06 2.06-2.06s2.07.92 2.07 2.06Z" />
    <path d="m7.41 13.43-.08.14v-.14h.08zM.47 13.43H1.5v1.03H.47z" />
    <path d="M3.45 13.43v1.03h1.94v-1.03H3.45Zm3.88 0v1.03h1.94v-1.03H7.33Zm3.88 0v1.03h1.95v-1.03h-1.95Zm7.77 0v1.03h1.94v-1.03h-1.94ZM22.86 13.43h1.03v1.03h-1.03z" />
  </>,
  'MeshSupervisionIcon',
);

export const MyDataProductIcon = createSvgIcon(
  <>
    <path d="m17.09 12-2.55 4.4H9.46L6.91 12l2.55-4.41h5.08L17.09 12z" />
    <path d="M17.38 2.67H6.62L1.23 12l5.39 9.32h10.76L22.77 12l-5.39-9.33Zm-10 17.32L2.77 12l4.61-8h9.24l4.61 8-4.61 7.99H7.38Z" />
    <path d="m17.09 12-2.55 4.4H9.46L6.91 12l2.55-4.41h5.08L17.09 12z" />
  </>,
  'MyDataProductIcon',
);

export const SoftwareCatalogIcon = createSvgIcon(
  <>
    <path d="M19.54 23.72H4.46c-1.09 0-1.97-.88-1.97-1.97V2.25c0-1.09.88-1.97 1.97-1.97h15.08c1.09 0 1.97.88 1.97 1.97v19.5c0 1.08-.88 1.97-1.97 1.97ZM4.46 1.56c-.38 0-.69.31-.69.69v19.5c0 .38.31.69.69.69h15.08c.38 0 .69-.31.69-.69V2.25c0-.38-.31-.69-.69-.69H4.46Z" />
    <path d="m16.91 13.78-1.49-.83a3.387 3.387 0 0 0 0-1.27l1.49-.83c.14-.08.19-.26.11-.4l-.91-1.51a.291.291 0 0 0-.4-.1l-1.51.83c-.34-.27-.75-.49-1.18-.63V7.39c0-.16-.13-.29-.3-.29h-1.78c-.16 0-.3.13-.3.29v1.65c-.42.14-.84.36-1.18.63l-1.51-.83a.3.3 0 0 0-.4.1l-.91 1.51c-.08.14-.04.32.11.4l1.49.83a3.36 3.36 0 0 0 0 1.27l-1.49.83c-.14.08-.19.26-.11.4l.91 1.51c.08.14.26.18.4.1l1.51-.83c.34.27.75.49 1.18.63v1.65c0 .16.13.29.3.29h1.78c.16 0 .3-.13.3-.29v-1.65c.42-.14.84-.36 1.18-.63l1.51.83c.14.08.32.03.4-.11l.91-1.51c.08-.14.04-.32-.11-.4Zm-5.07.28a1.741 1.741 0 0 1 0-3.48 1.741 1.741 0 0 1 0 3.48Z" />
  </>,
  'SoftwareCatalogIcon',
);

export const GovernanceIcon = createSvgIcon(
  <>
    <path d="m12 2.97.04.02a37.06 37.06 0 0 0 7.15 2.93l-.24 7.7c-.52 3.14-4.46 6.06-6.14 7.18-.23.16-.51.24-.81.24s-.58-.08-.82-.25c-1.68-1.12-5.61-4.03-6.12-7.09l-.25-7.78c2.5-.74 4.92-1.74 7.19-2.96m-.01-1.52c-.25 0-.5.07-.71.2a36.906 36.906 0 0 1-7 2.87c-.61.19-1.01.76-.98 1.4l.26 7.93c.53 3.54 4.48 6.66 6.79 8.2.49.33 1.06.5 1.65.5s1.16-.17 1.65-.5c2.31-1.54 6.26-4.66 6.8-8.29l.25-7.84a1.42 1.42 0 0 0-.99-1.41c-2.42-.7-4.77-1.67-6.95-2.84-.23-.15-.5-.22-.76-.22Z" />
  </>,
  'GovernanceIcon',
);

export const BlueprintIcon = createSvgIcon(
  <>
    <path d="M17.38 2.67H6.62L1.23 12l5.39 9.32h10.76L22.77 12l-5.39-9.33ZM8.62 16.91V6.34h3.62c1.2 0 2.11.24 2.73.72.62.48.94 1.19.94 2.14 0 .48-.13.92-.39 1.31s-.64.69-1.15.9c.57.16 1.01.45 1.33.87.31.43.47.94.47 1.54 0 .99-.32 1.75-.96 2.29s-1.55.8-2.73.8H8.62Zm1.84-6.12h1.8c.57 0 1.02-.13 1.34-.39s.48-.63.48-1.1c0-.52-.15-.9-.45-1.13-.3-.23-.76-.35-1.39-.35h-1.79v2.97Zm0 1.35v3.3h2.04c.58 0 1.03-.14 1.35-.43.32-.29.49-.68.49-1.19 0-1.1-.56-1.66-1.69-1.69h-2.19Z" />
  </>,
  'BlueprintIcon',
);

export const PoliciesOverviewIcon = createSvgIcon(
  <>
    <path d="m16.52,3.08c-2.43-.71-4.8-1.68-7-2.86-.46-.29-1.06-.29-1.48-.02C5.81,1.4,3.44,2.37.99,3.09.38,3.28-.02,3.86,0,4.49l.26,8c.53,3.57,4.52,6.7,6.84,8.25.5.33,1.07.51,1.66.51s1.17-.18,1.66-.5c2.33-1.56,6.31-4.7,6.85-8.36l.26-7.89c.02-.64-.39-1.22-1.01-1.42ZM2.83,5.27h3.91v2h-3.91v-2Zm0,3.28h8.28v2H2.83v-2Zm12.37,5.28H2.83v-2h12.37v2Z" />
  </>,
  'PoliciesOverviewIcon',
);

export const GovernanceRegistryIcon = createSvgIcon(
  <>
    <path d="m16.54,3.09h-.02c-2.41-.71-4.78-1.68-6.99-2.86-.46-.3-1.06-.31-1.49-.03C5.77,1.41,3.41,2.38.99,3.09.39,3.28-.02,3.86,0,4.49l.26,8c.53,3.55,4.51,6.7,6.84,8.26.49.32,1.06.49,1.66.49s1.17-.17,1.66-.49c2.33-1.55,6.31-4.7,6.85-8.43l.25-7.83c.02-.64-.37-1.21-.98-1.4Zm-3.37,5.18l-4.79,4.99c-.14.15-.33.23-.53.23h-.05c-.22-.02-.42-.12-.55-.3l-1.99-2.66c-.1-.13-.14-.29-.14-.44,0-.23.1-.46.3-.6.32-.24.79-.17,1.03.15l1.47,1.97,4.18-4.36c.29-.29.76-.3,1.05-.02.3.28.31.75.02,1.04Z" />
  </>,
  'GovernanceRegistryIcon',
);

export const GovernanceSearchResultsIcon = createSvgIcon(
  <>
    <path d="m9.69,8.66c0,1.38-1.13,2.51-2.52,2.51s-2.51-1.13-2.51-2.51,1.13-2.52,2.51-2.52,2.52,1.13,2.52,2.52Z" />
    <path d="m16.53,3.08c-2.44-.71-4.81-1.68-7-2.86-.46-.29-1.06-.29-1.48-.02C5.81,1.4,3.45,2.37.99,3.09.39,3.28-.02,3.86,0,4.5l.27,7.99c.53,3.57,4.51,6.7,6.84,8.26.49.33,1.07.5,1.66.5s1.16-.17,1.66-.5c2.32-1.55,6.31-4.7,6.85-8.35l.25-7.91c.02-.63-.38-1.21-1-1.41Zm-7.14,8.84c-.69.56-1.32.67-2.22.67-2.17,0-3.94-1.76-3.94-3.93s1.77-3.94,3.94-3.94,3.94,1.76,3.94,3.94c0,.9-.12,1.5-.68,2.2l.31.31,3.06,3.06-1.05,1.05-3.06-3.05" />
  </>,
  'GovernanceSearchResultsIcon',
);

/* eslint-enable */
