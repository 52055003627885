import { TextFieldProps, TextField, FormControl } from '@material-ui/core';
import React, { useState } from 'react';
import { ClearButton } from './ClearButton';
import { useStyles } from './styles';
import { WbMarkdownHelperText } from './WbMarkdownHelperText';

type Props = TextFieldProps & {
  clearable?: boolean;
  cursorType?: 'pointer';
};

export const WbTextField = React.forwardRef(
  (
    {
      InputProps,
      SelectProps,
      InputLabelProps,
      clearable = false,
      placeholder,
      helperText,
      fullWidth,
      ...props
    }: Props,
    ref?: React.Ref<any>,
  ) => {
    const classes = useStyles();
    const [internalValue, setInternalValue] = useState(props.value || '');

    return (
      <FormControl fullWidth={fullWidth}>
        <TextField
          placeholder={placeholder}
          inputRef={ref}
          fullWidth={fullWidth}
          variant="outlined"
          value={internalValue}
          onChange={e => {
            if (props.onChange) props.onChange(e);
            setInternalValue(e.target.value);
          }}
          size="small"
          classes={{
            root: classes.root,
          }}
          helperText={
            helperText && typeof helperText !== 'string'
              ? helperText
              : undefined
          }
          InputProps={{
            classes: {
              root: classes.inputRoot,
              input: `${classes.input}${
                props.cursorType === 'pointer'
                  ? ` ${classes.pointerCursor}`
                  : ''
              }`,
            },
            endAdornment: clearable && !props.disabled && (
              <ClearButton
                style={{ marginRight: -6 }}
                onClear={e => {
                  const event = e as unknown as React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >;
                  setInternalValue('');
                  event.target.value = '';
                  if (props.onChange) props.onChange(event);
                }}
              />
            ),
            ...InputProps,
          }}
          SelectProps={{
            classes: {
              root: classes.selectRoot,
            },
            ...SelectProps,
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputLabelRoot,
            },
            shrink: true,
            ...InputLabelProps,
          }}
          {...props}
        />
        {helperText && typeof helperText === 'string' && (
          <WbMarkdownHelperText helperText={helperText} />
        )}
      </FormControl>
    );
  },
);
