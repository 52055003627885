import {
  CompoundEntityRef,
  Entity,
  entityKindSchemaValidator,
  GroupEntity,
  KindValidator,
  parseEntityRef,
  ResourceEntity,
  UserEntity,
} from '@backstage/catalog-model';
import { WitboostComponent, WitboostSystem } from './model';
import { WitboostDomain } from './model/WitboostDomain';
import { Config } from '@backstage/config';
import { toLower } from 'lodash';

// copied from Backstage "ajvCompiledJsonSchemaValidator" to simplify the schema validation
export const generateAjvJsonSchemaValidator: (
  schema: unknown,
) => KindValidator = (schema: unknown) => {
  let validator: undefined | ((data: unknown) => any);
  return {
    async check(data) {
      if (!validator) {
        validator = entityKindSchemaValidator(schema);
      }
      return validator(data) === data;
    },
  } as KindValidator;
};

/**
 * Takes an entityRef and transforms it a compact entity ref like "user:name.surname.." used in the marketplace.
 *
 * @param entityRef
 */
export function compactStringRef(entityRef: string) {
  const compoundEntityRef = parseEntityRef(entityRef);
  return compactCompoundEntityRef(compoundEntityRef);
}

/**
 * Takes an entityRef and transforms it a compact entity ref like "user:name.surname.." used in the marketplace.
 *
 * @param compoundEntityRef
 */
export function compactCompoundEntityRef(compoundEntityRef: CompoundEntityRef) {
  return `${compoundEntityRef.kind.toLowerCase()}:${compoundEntityRef.name.toLowerCase()}`;
}

export function getEntityDisplayName(entity: Entity): string {
  if (entity.kind === 'Component') {
    const component = entity as WitboostComponent;
    return component.spec.mesh.name;
  }

  if (entity.kind === 'Resource') {
    const resource = entity as ResourceEntity;
    return resource.metadata.name;
  }

  if (entity.kind === 'User') {
    const user = entity as UserEntity;
    return user.metadata.name;
  }

  if (entity.kind === 'Group') {
    const group = entity as GroupEntity;
    return group.metadata.name;
  }

  if (entity.kind === 'System') {
    const system = entity as WitboostSystem;
    const version = system.spec.mesh.version
      ? system.spec.mesh.version.split('.')[0]
      : undefined;
    return version
      ? `${system.spec.mesh.name} v${version}`
      : system.spec.mesh.name;
  }

  if (entity.kind === 'Domain') {
    const domain = entity as WitboostDomain;
    return domain.spec.mesh.name;
  }

  return entity.metadata.name;
}

/**
 * Given a catalog-info, checks if its kind is one of the allowed kinds specified in config.
 *
 * @param catalogInfo
 * @param config
 */
export async function checkAllowedKinds(
  catalogInfo: any,
  config: Config,
): Promise<Error | undefined> {
  if (config.has('catalog.rules')) {
    const allowedKinds = config
      .getConfigArray('catalog.rules')
      .flatMap(sub => sub.getStringArray('allow'))
      .map(toLower);
    const entityKind = catalogInfo.kind.toLowerCase();
    if (!allowedKinds.includes(entityKind)) {
      return new Error(
        `The entity kind ${catalogInfo.kind} is not one of the allowed kinds. Please change the entity kind to one of the allowed ones, or add your kind to the configured ones`,
      );
    }
  }
  return undefined;
}
