import useAsync from 'react-use/lib/useAsync';
import { useApi } from '@backstage/core-plugin-api';
import {
  CustomPresentationRetrieverApiRef,
  CustomPresentationRetrieverApi,
} from '../apis';

export function useCustomPresentation(id: string, spec: string = ''): any {
  const CPRetriever: CustomPresentationRetrieverApi = useApi(
    CustomPresentationRetrieverApiRef,
  );
  const customPresentation = useAsync(async () => {
    const view = await CPRetriever.retrieve(id, spec);
    return view;
  }, [id, spec]);
  return customPresentation;
}
