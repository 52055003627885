export const REMOVE_DOMAIN = `
  mutation REMOVE_DOMAIN($domainId: String) {
    update_marketplace_DataProduct(
      where: { domain: { _eq: $domainId } }
      _set: { domain: null }
    ) {
      affected_rows
    }

    update_marketplace_Domain(
      where: { sub_domain_of: { _eq: $domainId } }
      _set: { sub_domain_of: null }
    ) {
      affected_rows
    }

    delete_marketplace_Domain(where: { id: { _eq: $domainId } }) {
      affected_rows
    }
  }
`;

export const SEARCH_FOR_PARENT_DOMAIN = `
  query SEARCH_FOR_PARENT_DOMAIN($subDomainOf: String!) {
    domainData: marketplace_Domain_by_pk(id: $subDomainOf) {
      name
      sub_domain_of
    }
  }
`;
