/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Header, Link, Page, RoutedTabs } from '@backstage/core-components';
import React from 'react';
import { useOutlet } from 'react-router';
import {
  configApiRef,
  useApi,
  useElementFilter,
} from '@backstage/core-plugin-api';
import { UserSettingsAuthProviders } from './AuthProviders';
import { UserSettingsGeneral } from './General';
import { USER_SETTINGS_TAB_KEY, UserSettingsTabProps } from './UserSettingsTab';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { DataAccessTab } from './DataAccess';

/**
 * @public
 */
export const SettingsPage = (props: { providerSettings?: JSX.Element }) => {
  const { providerSettings } = props;
  const outlet = useOutlet();
  const configApi = useApi(configApiRef);
  const dataAccess = configApi.getOptionalBoolean(
    'mesh.profileSettings.dataAccess.enabled',
  );

  const tabs = useElementFilter(outlet, elements =>
    elements
      .selectByComponentData({
        key: USER_SETTINGS_TAB_KEY,
      })
      .getElements<UserSettingsTabProps>()
      .flatMap(({ props: elemProps }) => {
        return [
          {
            path: elemProps.path,
            title: elemProps.title,
            children: <>{elemProps.children}</>,
          },
        ];
      }),
  );

  const routes = [
    {
      path: '/',
      title: 'General',
      children: <UserSettingsGeneral />,
    },
    {
      path: '/auth-providers',
      title: 'Authentication Providers',
      children: (
        <UserSettingsAuthProviders providerSettings={providerSettings} />
      ),
    },

    ...tabs,
  ];

  if (dataAccess) {
    routes.push({
      path: '/data-access',
      title: 'Data Access',
      children: <DataAccessTab />,
    });
  }

  return (
    <Page themeId="home">
      <Header
        title="Settings and Profile"
        subtitle={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              witboost
            </Link>
            <Typography color="textPrimary">Settings and Profile</Typography>
          </Breadcrumbs>
        }
      />

      <RoutedTabs routes={routes} />
    </Page>
  );
};
