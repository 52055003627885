import { Brand } from './utils';
import { z } from 'zod';

export const userRefSchema = z.string().refine(s => s.startsWith('user:'));
export type UserEntityRef = Brand<
  z.infer<typeof userRefSchema>,
  'UserEntityRef'
>;

export function isUserEntityRef(ref: string): boolean {
  return userRefSchema.safeParse(ref).success;
}

export const groupRefSchema = z.string().refine(s => s.startsWith('group:'));
export type GroupEntityRef = Brand<
  z.infer<typeof groupRefSchema>,
  'GroupEntityRef'
>;

export const systemRefSchema = z.string().refine(s => s.startsWith('system:'));
export type SystemEntityRef = Brand<
  z.infer<typeof systemRefSchema>,
  'SystemEntityRef'
>;

export const systemUrnSchema = z
  .string()
  .refine(s => s.startsWith('urn:dmb:dp:')); // TODO not only data mesh affects this
export type SystemUrn = Brand<z.infer<typeof systemUrnSchema>, 'SystemUrn'>;
