import { useDrawersContext, WbTag } from '@agilelab/plugin-wb-platform';
import { Box, Typography, TableRow, TableCell } from '@material-ui/core';
import React from 'react';
import { Task } from '../../EditorPage/types';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import { TestStatus } from '@agilelab/plugin-wb-platform';
import { ErrorBox } from '@agilelab/plugin-wb-governance';
import { useStyles } from './styles';

interface Props {
  task: Task;
}

const ComponentRowDrawerContent: React.FC<Props> = ({ task }) => {
  const classes = useStyles();
  const [currentErrorIndex, setCurrentErrorIndex] = React.useState<number>(0);
  const handleSwitch = (index: number) => {
    setCurrentErrorIndex(index);
  };
  return (
    <Box>
      <Box className={classes.detailContainer}>
        <TestStatus status={task.status} filled variant="reduced" />
        <Typography variant="h6" style={{ fontWeight: 700 }}>
          {task.displayName ?? task.name}
        </Typography>
      </Box>
      <ErrorBox
        error={{
          message: task.errors.at(currentErrorIndex)!,
        }}
        navigation={{
          current: currentErrorIndex,
          total: task.errors.length,
          handleSwitch: handleSwitch,
        }}
        headerTitle="Error Info"
      />
    </Box>
  );
};

export const ComponentRow: React.FC<Props> = ({ task }) => {
  const classes = useStyles();
  const { toggleBoolean } = useDrawersContext();
  const { setValidationTestResultDetailContent } =
    useReleaseDetailPageContext();

  const hasErrors = task.governanceEntityId || task.errors.length > 0;

  const onRowClick = () => {
    if (hasErrors) {
      setValidationTestResultDetailContent(
        <ComponentRowDrawerContent task={task} />,
      );
      toggleBoolean(1);
    }
  };

  return (
    <TableRow
      onClick={onRowClick}
      style={{ cursor: hasErrors ? 'pointer' : 'default' }}
      className={classes.row}
    >
      <TableCell width="20%" align="left">
        <TestStatus status={task.status} filled />
      </TableCell>
      <TableCell width="30%">
        <Typography variant="body2" style={{ fontWeight: 700 }}>
          {task.displayName ?? task.name}
        </Typography>
      </TableCell>
      <TableCell width="30%">
        <Typography variant="body2" color="primary" style={{ fontWeight: 400 }}>
          {task.componentName}
        </Typography>
      </TableCell>
      <TableCell width="20%" align="right">
        <WbTag
          tag={{
            tagFQN: 'Technology Adapter',
          }}
        />
      </TableCell>
    </TableRow>
  );
};
