import { Box, ChipProps, useTheme } from '@material-ui/core';
import React from 'react';
import { Tag, TagKind, WbTag } from './WbTag';

type WbTagsArrayProps = ChipProps & {
  tags: Array<Tag>;
  kind?: TagKind;
  tooltip?: boolean;
};

export const WbTagsArray: React.FC<WbTagsArrayProps> = ({
  tags,
  kind = TagKind.generic,
  tooltip = true,
}: WbTagsArrayProps) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        display: 'flex',
        gap: theme.spacing(1),
        flexWrap: 'wrap',
        maxWidth: '100%',
      }}
    >
      {tags.map(t => (
        <WbTag key={t.tagFQN} tag={t} kind={kind} tooltip={tooltip} />
      ))}
    </Box>
  );
};
