/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
import {
  ErrorInfo,
  GovernanceEntityType,
  MetricItem,
  Outcome,
  ResourceEvaluation,
} from '@agilelab/plugin-wb-governance-common';
import { IdentityApi } from '@backstage/core-plugin-api';
import { GovernanceApi } from '..';

type NamedAndVersioned = {
  name: string;
  version: string | number;
};

export const generateHeaderTitle = (
  entity: NamedAndVersioned | undefined,
  defaultValue: string,
  suffix: string = '',
) => {
  return entity ? `${entity.name} ${entity.version}${suffix}` : defaultValue;
};

const extractErrorMessage = (text: string) => {
  return text.replace(new RegExp(`(?:\\s)\\w*:\\d*:\\d*`, 'g'), '');
};

const extractErrorRow = (error: string, regx: RegExp) => {
  const errorRows: Array<number> = [];
  let item: RegExpExecArray | null;

  while ((item = regx.exec(error))) {
    errorRows.push(+item[1]);
  }
  return errorRows;
};

export const extractGovernanceErrorInfo = ({
  policyId,
  error,
  regxPolicy,
  regxDescriptor,
}: {
  policyId: string;
  error: string;
  regxPolicy?: RegExp;
  regxDescriptor?: RegExp;
}) => {
  if (!regxPolicy) {
    regxPolicy = new RegExp(`(?:\\s)${policyId}:(\\d*)`, 'g');
  }
  if (!regxDescriptor) {
    regxDescriptor = new RegExp(`(?:\\s)descriptor:(\\d*)`, 'g');
  }

  const errorInfo: ErrorInfo = {
    message: extractErrorMessage(error),
    policyErrorRows: extractErrorRow(error, regxPolicy),
    descriptorErrorRows: extractErrorRow(error, regxDescriptor),
  };

  return errorInfo;
};

export const debounceFunction = (fn: Function, ms = 500) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(args), ms);
  };
};

export function roundWithoutApprossimation(
  precision: number,
  n?: number,
): string {
  if (n !== null && n !== undefined) {
    const factor = Math.pow(10, precision);
    return String(Math.floor(n * factor) / factor);
  }
  return '-';
}

export async function fetchLatestEvaluations(
  resourceIds: string[],
  environment: string,
  outcomes: Outcome[],
  includeGovernanceEntityDetails: boolean,
  governanceEntityType: GovernanceEntityType,
  identityApi: IdentityApi,
  governanceApi: GovernanceApi,
  sortByEvaluationTime?: 'DESC' | 'ASC',
): Promise<ResourceEvaluation[]> {
  const { token } = await identityApi.getCredentials();
  const evaluations = await governanceApi.getLatestResourceEvaluations(
    {
      resources: resourceIds,
      environment: environment,
      governanceEntityType: governanceEntityType,
      outcomes: outcomes,
      includeGovernanceEntityDetails: includeGovernanceEntityDetails,
    },
    { token: token },
  );
  if (sortByEvaluationTime === 'ASC')
    return evaluations.sort(
      (a, b) =>
        new Date(a.evaluationTime).getTime() -
        new Date(b.evaluationTime).getTime(),
    );
  else if (sortByEvaluationTime === 'DESC')
    return evaluations.sort(
      (a, b) =>
        new Date(b.evaluationTime).getTime() -
        new Date(a.evaluationTime).getTime(),
    );
  return evaluations;
}

export async function fetchAndParseLatestMetricEvaluations(
  resourceIds: string[],
  environment: string,
  outcomes: Outcome[],
  includeGovernanceEntityDetails: boolean,
  identityApi: IdentityApi,
  governanceApi: GovernanceApi,
  sortByEvaluationTime?: 'DESC' | 'ASC',
): Promise<MetricItem[]> {
  const metricEvals = await fetchLatestEvaluations(
    resourceIds,
    environment,
    outcomes,
    includeGovernanceEntityDetails,
    GovernanceEntityType.Metric,
    identityApi,
    governanceApi,
    sortByEvaluationTime,
  );
  const parsedEvals = metricEvals.reduce((acc, e) => {
    const metricItem = {
      id: e.evaluationResultId,
      name: e.governanceEntity?.name ?? '',
      description: e.governanceEntity?.description ?? '',
      version: e.governanceEntity?.version ?? 0,
      resource: e.resource,
      date: e.evaluationTime,
    };
    if (e.evaluationResult.errors?.length) {
      acc.push(
        Object.assign(metricItem, {
          error: !!e.evaluationResult.errors.length,
        }) as MetricItem,
      );
      return acc;
    }
    if (e.evaluationResult.value) {
      acc.push(
        Object.assign(metricItem, {
          result: e.evaluationResult.thresholdResult,
          value: e.evaluationResult.value,
        }) as MetricItem,
      );
      return acc;
    }
    return acc;
  }, [] as Array<MetricItem>);
  return parsedEvals;
}
