/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Content, Header, Link, Page } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Breadcrumbs, StepLabel, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ImportInfoCard } from '../ImportInfoCard';
import {
  ImportStepper,
  StepperProvider,
  defaultStepper,
} from '../ImportStepper';
import { StepInitAnalyzeUrl } from '../StepInitAnalyzeUrl';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';

/**
 * The default catalog import page.
 *
 * @public
 */
export const DefaultImportPage = () => {
  const configApi = useApi(configApiRef);
  const appTitle = configApi.getOptional('app.title') || 'Backstage';
  const ontologyKinds = configApi.getOptionalStringArray('ontology.kinds');
  const defaultCatalogRules = configApi.get('catalog.rules') as
    | [{ allow: string[] }];

  const allowedLocationKinds = useMemo(() => {
    const excludedKinds = [
      'blueprint',
      'accesscontrolrequesttemplate',
      ...(ontologyKinds ?? []),
    ];
    return defaultCatalogRules
      .flatMap(v => v.allow)
      .filter(k => k && !excludedKinds.includes(k.toLowerCase()));
  }, [defaultCatalogRules, ontologyKinds]);

  const stepper: StepperProvider = useMemo(
    () => ({
      ...defaultStepper,
      analyze: (state, { apis }) => ({
        stepLabel: <StepLabel>Select URL</StepLabel>,
        content: (
          <StepInitAnalyzeUrl
            key="analyze"
            analysisUrl={state.analysisUrl}
            onAnalysis={state.onAnalysis}
            disablePullRequest={!apis.catalogImportApi.preparePullRequest}
            locationKindsToImport={allowedLocationKinds}
          />
        ),
      }),
    }),
    [allowedLocationKinds],
  );

  return (
    <Page themeId="home">
      <Header
        title="Register an existing component"
        subtitle={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
              witboost
            </Link>
            <Typography>Builder</Typography>
            <Link color="inherit" to="/create">
              Templates
            </Link>
            <Typography color="textPrimary">
              Register an existing component
            </Typography>
          </Breadcrumbs>
        }
      />
      <Content>
        <WbCard
          title={`Start tracking your component in ${appTitle}`}
          cardStyle={{ height: '100%' }}
        >
          <WbCardContent>
            <ImportInfoCard />
            <ImportStepper defaultStepper={stepper} />
          </WbCardContent>
        </WbCard>
      </Content>
    </Page>
  );
};
