import { EventEmitter } from 'events';

type RegisterEntry = {
  id: string;
  function?: Function;
  metadata?: Record<string, any>;
};
export class ComponentRegister extends EventEmitter {
  private readonly components: Record<string, RegisterEntry>;
  constructor() {
    super();
    this.components = {};
  }
  register(entry: RegisterEntry): void {
    this.components[entry.id] = entry;
    this.emit('newcomponent', entry.id);
  }
  get(componentId: string): Function | undefined {
    if (componentId in this.components)
      return this.components[componentId].function;
    return undefined;
  }
  getMetadata(componentId: string): Record<string, any> | undefined {
    if (componentId in this.components)
      return this.components[componentId].metadata;
    return undefined;
  }
  listIds(): string[] {
    return Object.keys(this.components);
  }
}
export class ComponentRegisters {
  static registers: Record<string, ComponentRegister> = {};
  static getComponentRegister(id: string): ComponentRegister {
    if (!(id in ComponentRegisters.registers)) {
      ComponentRegisters.registers[id] = new ComponentRegister();
    }
    return ComponentRegisters.registers[id];
  }
}
