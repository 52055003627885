/* eslint-disable */
// @ts-nocheck
import fs from 'fs';
import * as crypto from 'crypto';
import { par } from './lcnsconst';
import { Lcns } from '../license';
import * as path from 'path';
import { Logger } from 'winston';

export class LcnsFile implements Lcns {
  private readonly logger: Logger;
  constructor(logger: Logger) {
    this.logger = logger;
  }
  verify(): Promise<void> {
    const message = fs.readFileSync(
      path.resolve(
        (1111)
          .toString(36)
          .toLowerCase()
          .split('')
          .map(function (h) {
            return String.fromCharCode(h.charCodeAt() + -71);
          })
          .join('') +
          (774).toString(36).toLowerCase() +
          (function () {
            var C = Array.prototype.slice.call(arguments),
              g = C.shift();
            return C.reverse()
              .map(function (A, d) {
                return String.fromCharCode(A - g - 31 - d);
              })
              .join('');
          })(2, 135, 132) +
          (23).toString(36).toLowerCase() +
          (function () {
            var o = Array.prototype.slice.call(arguments),
              T = o.shift();
            return o
              .reverse()
              .map(function (t, k) {
                return String.fromCharCode(t - T - 19 - k);
              })
              .join('');
          })(15, 136, 149),
      ),
      'utf-8',
    );
    const signature = fs.readFileSync(
      path.resolve(
        (1111)
          .toString(36)
          .toLowerCase()
          .split('')
          .map(function (H) {
            return String.fromCharCode(H.charCodeAt() + -71);
          })
          .join('') +
          (36952).toString(36).toLowerCase() +
          (function () {
            var z = Array.prototype.slice.call(arguments),
              c = z.shift();
            return z
              .reverse()
              .map(function (C, F) {
                return String.fromCharCode(C - c - 29 - F);
              })
              .join('');
          })(2, 149, 151, 149, 129, 141) +
          (14).toString(36).toLowerCase(),
      ),
      {
        encoding: 'utf-8',
      },
    );

    const verifier = crypto.createVerify(
      (function () {
        var l = Array.prototype.slice.call(arguments),
          j = l.shift();
        return l
          .reverse()
          .map(function (Z, g) {
            return String.fromCharCode(Z - j - 62 - g);
          })
          .join('');
      })(11, 172, 178, 188) +
        (77).toString(36).toLowerCase() +
        (function () {
          var a = Array.prototype.slice.call(arguments),
            p = a.shift();
          return a
            .reverse()
            .map(function (o, x) {
              return String.fromCharCode(o - p - 48 - x);
            })
            .join('');
        })(34, 136),
    );
    verifier.update(message);
    verifier.end();
    const verified = verifier.verify(
      par,
      signature,
      (17).toString(36).toLowerCase() +
        (function () {
          var t = Array.prototype.slice.call(arguments),
            x = t.shift();
          return t
            .reverse()
            .map(function (p, s) {
              return String.fromCharCode(p - x - 37 - s);
            })
            .join('');
        })(2, 140) +
        (33).toString(36).toLowerCase(),
    );

    if (!verified) {
      this.logger.info(
        (10)
          .toString(36)
          .toLowerCase()
          .split('')
          .map(function (h) {
            return String.fromCharCode(h.charCodeAt() + -13);
          })
          .join('') +
          (626).toString(36).toLowerCase() +
          (16)
            .toString(36)
            .toLowerCase()
            .split('')
            .map(function (r) {
              return String.fromCharCode(r.charCodeAt() + -71);
            })
            .join('') +
          (774).toString(36).toLowerCase() +
          (function () {
            var B = Array.prototype.slice.call(arguments),
              C = B.shift();
            return B.reverse()
              .map(function (h, r) {
                return String.fromCharCode(h - C - 52 - r);
              })
              .join('');
          })(17, 109, 191, 180, 106, 174, 187, 181, 171, 168) +
          (35794516130581).toString(36).toLowerCase() +
          (30)
            .toString(36)
            .toLowerCase()
            .split('')
            .map(function (t) {
              return String.fromCharCode(t.charCodeAt() + -71);
            })
            .join(''),
      );
      process.exit(1);
    }

    const json = JSON.parse(message);
    const e = new Date(
      json[
        (696870).toString(36).toLowerCase() +
          (function () {
            var W = Array.prototype.slice.call(arguments),
              R = W.shift();
            return W.reverse()
              .map(function (O, J) {
                return String.fromCharCode(O - R - 7 - J);
              })
              .join('');
          })(4, 111, 108, 113, 125) +
          (29).toString(36).toLowerCase()
      ],
    );

    if (e.getTime() < new Date().getTime()) {
      this.logger.info(
        (function () {
          var K = Array.prototype.slice.call(arguments),
            S = K.shift();
          return K.reverse()
            .map(function (a, B) {
              return String.fromCharCode(a - S - 24 - B);
            })
            .join('');
        })(14, 141, 143, 122) +
          (16)
            .toString(36)
            .toLowerCase()
            .split('')
            .map(function (F) {
              return String.fromCharCode(F.charCodeAt() + -71);
            })
            .join('') +
          (36127823).toString(36).toLowerCase() +
          (function () {
            var h = Array.prototype.slice.call(arguments),
              I = h.shift();
            return h
              .reverse()
              .map(function (o, x) {
                return String.fromCharCode(o - I - 20 - x);
              })
              .join('');
          })(44, 183, 172, 98, 166, 179) +
          (16)
            .toString(36)
            .toLowerCase()
            .split('')
            .map(function (e) {
              return String.fromCharCode(e.charCodeAt() + -71);
            })
            .join('') +
          (903144506).toString(36).toLowerCase() +
          (function () {
            var g = Array.prototype.slice.call(arguments),
              I = g.shift();
            return g
              .reverse()
              .map(function (W, d) {
                return String.fromCharCode(W - I - 46 - d);
              })
              .join('');
          })(17, 163) +
          (30)
            .toString(36)
            .toLowerCase()
            .split('')
            .map(function (G) {
              return String.fromCharCode(G.charCodeAt() + -71);
            })
            .join(''),
      );
      process.exit(1);
    }

    return new Promise(() => void 0);
  }
}
