import React, { useEffect } from 'react';
import { ControlPanelProvider, useControlPanel } from './useControlPanel';
import { WitboostVersionedEntity } from '@agilelab/plugin-wb-builder-common';
import {
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
  WbCard,
  useSelectorsContext,
  WbCardActionButton,
} from '@agilelab/plugin-wb-platform';
import { PreviewDescriptorBox } from './PreviewDescriptorBox/PreviewDescriptorBox';
import { Box, Tooltip } from '@material-ui/core';
import { TestCard } from './TestCard';
import { ReleaseCard } from './ReleaseCard';
import { ReleaseSelect } from './TestCard/TestCard';
import CachedIcon from '@material-ui/icons/Cached';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '../../api';
import useAsync from 'react-use/lib/useAsync';

const Descriptor: React.FC<{ loading: boolean }> = ({ loading }) => {
  const { selectedRelease, fetchPreviewDescriptorState } = useControlPanel();

  return (
    <PreviewDescriptorBox
      code={fetchPreviewDescriptorState.value}
      loading={loading}
      name={selectedRelease ? `${selectedRelease.metadata.version}` : 'HEAD'}
    />
  );
};

const ControlPanelPageComponent = () => {
  const { fetchPreviewDescriptorState, fetchPreviewDescriptor } =
    useControlPanel();
  const [testingCollapse, setTestingCollapse] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(fetchPreviewDescriptorState.loading);
  }, [fetchPreviewDescriptorState]);

  return (
    <Box>
      <WbDockableGroup direction="horizontal" style={{ height: '70vh' }}>
        <WbDockablePanel collapsible>
          <WbCard
            title="Descriptor"
            cardStyle={{ height: '100%' }}
            actions={
              <Box style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                <ReleaseSelect />

                <Tooltip title="Refresh the descriptor by reading again all the values from the repositories. After you make a change on the repository files, click this button to reload and see the changes in the descriptor.">
                  <WbCardActionButton
                    onClick={() => {
                      setLoading(true);
                      fetchPreviewDescriptor().finally(() => {
                        setLoading(false);
                      });
                    }}
                    label="Refresh"
                    icon={<CachedIcon />}
                    disabled={loading}
                  />
                </Tooltip>
              </Box>
            }
          >
            <Box display="flex" flexDirection="column" height="100%">
              <Descriptor loading={loading} />
            </Box>
          </WbCard>
        </WbDockablePanel>

        <WbDockableResizeHandle direction="horizontal" />
        <WbDockablePanel
          tab={{
            isCollapsed: testingCollapse,
            setCollapsed: isCollapsed => setTestingCollapse(isCollapsed),
            title: 'Test',
            direction: 'horizontal',
          }}
          defaultSize={40}
          collapsible
        >
          <TestCard
            onCollapse={() => {
              setTestingCollapse(true);
            }}
          />
        </WbDockablePanel>
      </WbDockableGroup>

      <Box mt={2} height="70vh">
        <ReleaseCard />
      </Box>
    </Box>
  );
};

export const ControlPanelPage = (props: {
  entity: WitboostVersionedEntity;
  setVersion: (input: string) => void;
}) => {
  const panelCatalog = useApi(panelCatalogApiRef);
  const identifyApi = useApi(identityApiRef);
  const { environment, setEnvironmentList, setEnvironment } =
    useSelectorsContext();

  const { loading, value, error } = useAsync(async (): Promise<string[]> => {
    return await panelCatalog.fetchEnvironments(
      await identifyApi.getCredentials(),
    );
  }, []);

  React.useEffect(() => {
    if (error || loading || !value) {
      return;
    }
    const envs =
      value
        ?.map(env => ({
          id: 0,
          name: env,
        }))
        .sort((a, b) => (a.name <= b.name ? -1 : 1)) || [];
    const priorityEnv =
      envs.find(
        env => env.name.toLowerCase() === environment.name.toLowerCase(),
      ) ?? envs.at(0);

    setEnvironmentList(envs);

    if (priorityEnv) {
      setEnvironment(priorityEnv);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, error, loading]);

  return environment.name ? (
    <ControlPanelProvider entity={props.entity} setVersion={props.setVersion}>
      <ControlPanelPageComponent />
    </ControlPanelProvider>
  ) : (
    <></>
  );
};
