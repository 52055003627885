import { serializeError } from '@backstage/errors';
import { Logger } from 'winston';

type RetryDelayStrategy = (
  attempt: number,
  error: Error | undefined,
  response: Response | undefined,
) => number;

export const exponentialBackoff: RetryDelayStrategy = (
  attempt: number,
  _error: Error | undefined,
  _response: Response | undefined,
): number => Math.pow(2, attempt) * 5000;

export type FetchRetryOptions = {
  /**
   * Number of retries to try in the advent of a failure
   */
  retries: number;
  /**
   * Delay between each retry in milliseconds. Or, a function to implement a delay strategy (e.g. exponential backoff).
   */
  retryDelay: number | RetryDelayStrategy;
  polling?: {
    /**
     * number of milliseconds between each polling cycle
     */
    interval: number;
    stopCondition: (responseJson: any) => boolean;
    /**
     * number of milliseconds after which polling stops even if stop condition is not reached
     */
    timeout?: number;
  };

  /**
   * Winston's logger
   */
  logger: Logger;
};

type FetchRetryContext = {
  retries: number;
  attempt: number;
};

const retryOn = (
  attemptFetch: (context: FetchRetryContext) => Promise<Response | Error>,
  options: FetchRetryOptions,
  context: FetchRetryContext,
  error?: Error,
  response?: Response,
): Promise<Response | Error> => {
  return new Promise(resolve => {
    const delay: number =
      typeof options.retryDelay === 'number'
        ? options.retryDelay
        : (options.retryDelay as RetryDelayStrategy)(
            context.attempt,
            error,
            response,
          );

    setTimeout(() => {
      return resolve(
        attemptFetch({
          retries: context.retries - 1,
          attempt: context.attempt + 1,
        }),
      );
    }, delay);
  });
};

export const fetchRetry = async (
  fetch: typeof _fetch,
  fetchParams: { input: RequestInfo | URL; init?: RequestInit | undefined },
  options: FetchRetryOptions,
): Promise<Response | Error> => {
  let forceExit = false;
  if (options.polling && options.polling.timeout) {
    setTimeout(() => {
      forceExit = true;
    }, options.polling.timeout);
  }

  const attemptFetch = async (context: {
    retries: number;
    attempt: number;
  }): Promise<Response | Error> => {
    const { logger } = options;
    if (forceExit) {
      logger.warn(`[fetchRetry] Polling timeout reached`);
      return Error('Polling timeout reached');
    }

    try {
      logger.info(`[fetchRetry] fetch request to ${fetchParams.input}`);
      const response = await fetch(
        fetchParams.input as RequestInfo,
        fetchParams.init,
      );

      if (!response.ok) {
        logger.debug(
          `[fetchRetry] response is not ok. (HTTP status: ${response.status})`,
        );
        if (context.retries === 0) {
          logger.info(`[fetchRetry] no attempts left`);
          return response.clone(); // no attempts left
        }

        logger.debug(
          `[fetchRetry] attempting a retry. attempts left: ${context.retries}`,
        );
        return retryOn(attemptFetch, options, context, undefined, response);
      }

      logger.debug(`[fetchRetry] response ok!`);
      if (!options.polling) {
        return response.clone();
      }

      const clonedResponse = response.clone();
      const json = await response.json();
      if (options.polling.stopCondition(json)) {
        logger.info(`[fetchRetry] stop condition reached.`);
        return clonedResponse;
      }
      logger.debug(`[fetchRetry] stop condition not reached.`);

      return new Promise(resolve => {
        setTimeout(
          () => resolve(attemptFetch({ retries: options.retries, attempt: 0 })),
          options.polling?.interval,
        );
      });
    } catch (error) {
      logger.debug(
        `[fetchRetry] fetch failed. maybe URL is unreachable? ${
          error instanceof Error ? `(error: ${error.message})` : ''
        }`,
      );
      if (context.retries === 0) {
        // no attempts left. return the error
        return error instanceof Error
          ? error
          : Error(
              serializeError(error, { includeStack: false }).message ??
                'fetch failed. maybe URL is unreachable?',
            );
      }

      logger.debug(
        `[fetchRetry] attempting a retry. attempts left: ${context.retries}`,
      );
      return retryOn(attemptFetch, options, context, error, undefined);
    }
  };

  return attemptFetch({ retries: options.retries, attempt: 0 });
};
