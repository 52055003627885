import { WbTag } from '@agilelab/plugin-wb-platform';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useDeploymentPreviewContext } from './context/useDeploymentPreviewContext';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    boxShadow: '0px 2px 8px 0px #00000012',
    padding: '16px 16px 8px 16px',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    background: theme.palette.white,
    zIndex: 99,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
}));

export const DeploymentPreviewTitle: React.FC = () => {
  const classes = useStyles();
  const {
    release,
    queryParamEnvironment,
    environment,
    queryParamVersion,
    dataProductName,
  } = useReleaseDetailPageContext();
  const { isAdvanced, setIsAdvanced } = useDeploymentPreviewContext();

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        <Typography
          style={{ fontWeight: 'normal' }}
          color="primary"
          variant="h6"
        >
          <b>{queryParamVersion || release.metadata.version}</b> of{' '}
          {dataProductName}
        </Typography>
        <WbTag
          tag={{
            tagFQN: snakeCaseToTitleCase(
              queryParamEnvironment || environment.name,
            ),
          }}
        />
      </Box>

      {isAdvanced ? (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsAdvanced(false);
          }}
          startIcon={<ArrowBackIcon />}
        >
          Basic Provisioning
        </Button>
      ) : (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsAdvanced(true);
          }}
          startIcon={<EditIcon />}
        >
          Advanced Provisioning
        </Button>
      )}
    </Box>
  );
};
