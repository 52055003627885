import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/ComponentType.v2alpha1.schema.json';

declare interface ComponentTypeEntity extends Entity {
  apiVersion: 'witboost.com/v2';
  kind: 'ComponentType';
  metadata: {
    displayName?: string;
    pluralizedDisplayName?: string;
  } & EntityMeta;
  spec: {
    abstract: boolean;
    resourceTypeId: string;
    shoppable: boolean;
    belongsTo?: string;
    partOfDomain?: string;
    partOfComponent?: string;
    partOfSystem?: string;
    readsFrom?: string[];
    dependsOn?: string[];
    extends?: string;
    ownedBy?: string;
  };
}

export type { ComponentTypeEntity as ComponentType };

export const componentTypeEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
