import {
  TableCellProps,
  customAlertApiRef,
  useTruncatedStyles,
} from '@agilelab/plugin-wb-platform';
import { Button } from '@material-ui/core';
import React from 'react';
import LaunchIcon from '@material-ui/icons/Launch';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { documentsApiRef } from '@agilelab/plugin-wb-builder-catalog';
import { WitboostDocumentWithAcceptanceDate } from '@agilelab/plugin-wb-documents-common';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';

export function useDocumentsTabColumns(): TableCellProps<WitboostDocumentWithAcceptanceDate>[] {
  const documentsApi = useApi(documentsApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);
  const truncatedClass = useTruncatedStyles();

  const fetchDocument = async (document_id: number) => {
    try {
      const { token } = await identityApi.getCredentials();
      const documentFile = await documentsApi.retrieveSingleDocumentBlob(
        document_id,
        { token },
      );

      const fileURL = URL.createObjectURL(documentFile);

      const pdfWindow = window.open();
      if (!pdfWindow) {
        alertApi.post({
          message: 'Unable to open new window.',
        });
      }
      pdfWindow!.location.href = fileURL;
    } catch (error) {
      alertApi.post({
        error: error,
        severity: 'error',
      });
    }
  };

  return [
    {
      field: 'name',
      headerName: 'Name',
      cellProps: {
        align: 'left',
        width: '10%',
        style: { wordBreak: 'break-word' },
      },
      sortable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '20%',
        style: { wordBreak: 'break-word' },
      },
      typographyProps: {
        component: 'p',
        variant: 'body2',
        className: truncatedClass.truncated,
      },
      sortable: true,
    },
    {
      field: 'document_type',
      headerName: 'Document type',
      cellProps: {
        align: 'left',
        width: '10%',
        size: 'small',
      },
      sortable: true,
    },
    {
      field: 'acceptance_date',
      headerName: 'Acknowledgment date',
      fieldRender: document =>
        document.acceptance_date
          ? format(new Date(document.acceptance_date), 'dd/MM/yyyy, HH:mm:ss', {
              locale: it,
            })
          : null,
      cellProps: {
        align: 'left',
        width: '10%',
        size: 'small',
      },
      sortable: true,
    },
    {
      field: 'id',
      headerName: 'View',
      fieldRender: document => (
        <>
          <Button
            onClick={() => {
              fetchDocument(document.id!);
            }}
          >
            <LaunchIcon />
          </Button>
        </>
      ),
      cellProps: {
        align: 'center',
        width: '10%',
        size: 'small',
      },
    },
  ];
}
